import ListGroup from 'react-bootstrap/ListGroup';
import DoneIcon from '@mui/icons-material/Done';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const smallerBadgeStyle = {
    padding: '2px 6px', // Adjust padding as needed
    fontSize: '10px', // Adjust font size as needed
};

const tooltipContent = 'This is a tooltip';

const BadgeWithTooltip = () => {
    return (<OverlayTrigger overlay={<Tooltip>{tooltipContent}</Tooltip>}>
        <span className="pull-right" style={smallerBadgeStyle}>
            <DoneIcon width="20" />
        </span>
    </OverlayTrigger>)
}

const listItemStyle = {
    cursor: 'pointer',
    fontWeight: 'bold',
};

const render = (list) => {
    if (list.length > 0) {
        return (
            <ListGroup>
                {
                    <ListGroup.Item className="px-4 py-2" style={{ 'background': 'grey', 'color': 'white' }}>
                        <span><b>Name</b></span>
                        <span className='pull-right'><b>Has Attended</b></span>
                    </ListGroup.Item>
                }
                {
                    list.filter(e => e.participant).map((item) => (
                        <ListGroup.Item className="px-4 py-2" style={listItemStyle} key={item?.participant?.id}>
                            {item?.participant?.name}
                            {item?.has_attended ? <BadgeWithTooltip /> : ''}
                        </ListGroup.Item>
                    ))
                }
            </ListGroup>
        );
    } else {
        return (<p className="d-flex align-items-center justify-content-center">
        <b>No booking found!</b>
      </p>)
    }
}

function AttendeeList(props) {
    return (
        render(props.list)
    )
}
export default AttendeeList





