import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { TextField } from '@mui/material';
import { useEffect } from 'react';
import { axiosFetch } from '../../../Utlis/AxiosFetch';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const WorkoutPreferrence = (props) => {

    const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      transform:'translateY(-48px)',
   
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right"
  },
};
const [dailyActivity, setDailyActivity] = useState("")
const [dailyActivityForMobileUse, setDailyActivityForMobileUse] = useState({})
const [execiseTiming, setExeciseTiming] = useState("")
const [interestedActivities, setInterestedActivities] = useState([])
const [otherActivities, setOtherActivities] = useState(false)
const [workoutPreference, setWorkoutPreference] = useState("")
const [healthLimitation, setHealthLimitation] = useState("")
const [healthLimitationYes, setHealthLimitationYes] = useState(false)
const [editable, setEditable] = useState(false)
const history=useHistory()
const [dailyActive, setDailyActive] = useState([])
const [activities, setActivities] = useState([])
const [preferredExerciseTime, setPreferredExerciseTime] = useState([])
const [workotPreference, setWorkotPreference] = useState([])
const [ifHealthCausingPainIsYes, setIfHealthCausingPainIsYes] = useState("")
const [otherInterstedActivities, setOtherInterstedActivities] = useState("")
const [errorForDailyActivity, setErrorForDailyActivity] = useState("")
const [errorForInterstedActivities, setErrorForInterstedActivities] = useState("")
const [errorForPreferredExerciseTime, setErrorForPreferredExerciseTime] = useState("")
const [errorForWorkoutPreference, setErrorForWorkoutPreference] = useState("")
const [errorForHealthIssues, setErrorForHealthIssues] = useState("")
const [errorForOtherInterstedActivities, setErrorForOtherInterstedActivities] = useState("")
const [errorForIfHealthCausingIsYes, setErrorForIfHealthCausingIsYes] = useState("")
const [interstedActivitiesForId, setinterstedActivitiesForId] = useState([])
const LeadId=useParams()
// const Activities=[
//     "Walking",
//     "Gym",
//     "Yoga",
//     "Strength Training",
//     "HIIT",
//     "Dance",
//     "Aerobics",
//     "Sports",
//     "Others(Please Specify)"
// ]

const handleChangeDailyActivity=(e)=>{
    setDailyActivity(e.target.value)
    setErrorForDailyActivity("")
  let dailyActivityInObjectForm=dailyActive?.filter(c=>(c?.title===e.target.value))
  setDailyActivityForMobileUse(dailyActivityInObjectForm[0])
}

const handleChangeExcerciseTiming=(e)=>{
    setExeciseTiming(e.target.value)
    setErrorForPreferredExerciseTime("")
}

const handleChangeInterestedActivities=(e)=>{
    const {
        target: { value },
      } = e;
      setInterestedActivities(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );

      if((e.target.value).includes(9)
    ){
       
        setOtherActivities(true)
        // if(otherInterstedActivities ===""){
          setErrorForOtherInterstedActivities("Please Enter Something")
        // }
    }
    else{
      setOtherActivities(false)
      setErrorForOtherInterstedActivities("")
      setOtherInterstedActivities("")
    }
    if((e.target.value)?.length ===0){
        setOtherActivities(false)

    }
    
    setErrorForInterstedActivities("")
}


const handleChangeWorkoutPreference=(e)=>{
    setWorkoutPreference(e.target.value)
    setErrorForWorkoutPreference("")
}

const handleChangeHealthLimitation=(e)=>{
    setHealthLimitation(e.target.value)

    if(e.target.value===1){
        setHealthLimitationYes(true)
          setErrorForIfHealthCausingIsYes("Please Enter Something")
    }
    if(e.target.value===2){
        setHealthLimitationYes(false)
        setErrorForIfHealthCausingIsYes("")
        setIfHealthCausingPainIsYes("")
    }
    setErrorForHealthIssues("")

}

// const dailyActive=[
//   "Little or No Activity",
//   "Lightly Active",
//   "Moderately Active",
//   "Very Active",
//   "Evening",
//   ""
// ]


// const preferredExerciseTime=[
//   "Early morning",
//   "Afternoon",
//   "Evening",
//   "Late evening"
  
// ]

// const workotPreference=[
//   "Home",
//   "Gym"
// ]

const healthIssues=[
  {
    id:1,
    title:"Yes"
  },
  {
    id:2,
    title:"No"
  }
  
  
]

const editWorkoutPreference=(e)=>{
  e.preventDefault()
  setEditable(true)
  if(interestedActivities.includes(9)){
    setOtherActivities(true)
  }
  if(healthLimitation===1){
    setHealthLimitationYes(true)
  }
}

const getDailyActivities=()=>{
  axiosFetch({
    url:`/getAllWorkoutdropdownCRM`,
    method:'get'
  }).then(res=>{
    if(res.status===200){
  
      setDailyActive(res?.data?.DailyActivity)
      setActivities(res?.data?.activities)
      setPreferredExerciseTime(res?.data?.preferredExerciseTime)
      setWorkotPreference(res?.data?.WorkoutPreference)
    }
    else if(res.status===400){
      toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
    }
    else if (res.status === 401) {
      history.push("/");
    } 
  }).catch(err=>console.error(err))
}

// const getWorkoutPreference=()=>{
//   axiosFetch({
//     url:`/getWorkoutPreferencesByLeadIdCRM?leadId=${LeadId?.id}`,
//     method:'get'
//   }).then(res=>{
//     if(res.status===200){
//       if(res?.data?.active_you_are !==null){
//         setDailyActivity(res?.data?.active_you_are?.title?res?.data?.active_you_are?.title:res?.data?.active_you_are)

//       }
    
//       if(
        
//         res?.data?.interested_activities !==null &&
//         res?.data?.preferred_exercise_time !==null &&
//         res?.data?.workout_preference !==null &&
//         res?.data?.health_issues_causing_pain !==null
//         ){
//           let healthIssuesId=healthIssues?.find(c=>c.title===res?.data?.health_issues_causing_pain)?.id
//           setinterstedActivitiesForId(res?.data?.interested_activities)
//           setExeciseTiming(res?.data?.preferred_exercise_time)
//           setWorkoutPreference(res?.data?.workout_preference)
//           setHealthLimitation(healthIssuesId)
//           setIfHealthCausingPainIsYes(res?.data?.health_issues)
//           setOtherInterstedActivities(res?.data?.other_interested_activites)
//         }
//     }
//     else if(res.status===400){
//       toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
//     }
//     else if (res.status === 401) {
//       history.push("/");
//     } 
//   }).catch(err=>console.error(err))
// }

const getWorkoutPreference=()=>{
  
  let healthIssuesId=healthIssues?.find(c=>c.title===props?.props?.health_issues_causing_pain)?.id
if(props?.props !== null && props?.props !==undefined){
  if(props?.props?.active_you_are !==null && props?.props?.active_you_are !==undefined){
    setDailyActivity(props?.props?.active_you_are?.title)
  }
  if(props?.props?.health_issues !==null &&props?.props?.health_issues !==undefined ){
    setIfHealthCausingPainIsYes(props?.props?.health_issues)
  }
  if(props?.props?.health_issues_causing_pain!==null &&  props?.props?.health_issues_causing_pain!==undefined){
    setHealthLimitation(healthIssuesId)
  }
  if(props?.props?.interested_activities!==null && props?.props?.interested_activities!==undefined ){
    setinterstedActivitiesForId(props?.props?.interested_activities)
  }
  if(props?.props?.other_interested_activites !==null &&  props?.props?.other_interested_activites!==undefined){
    setOtherInterstedActivities(props?.props?.other_interested_activites)
  }
  if(props?.props?.preferred_exercise_time!==null && props?.props?.preferred_exercise_time!==undefined){
    setExeciseTiming(props?.props?.preferred_exercise_time)
  }
  if(props?.props?.workout_preference !==null &&   props?.props?.workout_preference !==undefined){
    setWorkoutPreference(props?.props?.workout_preference)
  }
}
}

useEffect(() => {
  getDailyActivities()
  getWorkoutPreference()
}, [])
const getSelectedInterstedActivitiesIds = (selectedNames, allActivities) => {
  const selectedIds = [];
  selectedNames?.forEach((selectedName) => {
    const interstedActivity = allActivities?.find((activity) => activity.title === selectedName);
    if (interstedActivity) {
      selectedIds.push(interstedActivity.id);
      
    }
  });
  return selectedIds;
};

useEffect(() => {

 const preferredActivities=  getSelectedInterstedActivitiesIds(interstedActivitiesForId,activities)
 setInterestedActivities(preferredActivities)


}, [activities,interstedActivitiesForId])


const saveWorkoutPreference=()=>{

  const requestBody={
    id:LeadId.id,
    active_you_are:(Object.keys(dailyActivityForMobileUse).length !==0?  dailyActivityForMobileUse : props?.props?.active_you_are) ,
    preferred_exercise_time:execiseTiming,
    interested_activities:activities?.filter(c=>interestedActivities?.includes(c.id)).map(c=>c.title),
    workout_preference:workoutPreference,
    health_issues_causing_pain:healthIssues?.find(c=>c.id===healthLimitation)?.title,
    health_issues:ifHealthCausingPainIsYes,
    other_interested_activites:otherInterstedActivities
  }
  if((Object.keys(dailyActivityForMobileUse).length !==0 || props?.props?.active_you_are !==undefined)&&//Object.keys(dailyActivityForMobileUse).length == 0
    (dailyActivityForMobileUse !==undefined || props?.props?.active_you_are !==undefined)&&
   execiseTiming.trim() !== "" &&
   interestedActivities?.length !==0 &&
   workoutPreference.trim() !=="" &&
   healthLimitation !== ""  
     
  //  (healthLimitation==='Yes' && ifHealthCausingPainIsYes ==="" )
  ){

    // if(healthLimitation==='Yes' && ifHealthCausingPainIsYes !=="" ){
    
    //   axiosFetch({
    //     url:`/updateLead/${props?.props?.LeadID?.id}`,
    //     method:'post',
    //     requestConfig:requestBody
    //   }).then(res=>{
    //     if(res.status===200){
          
    //       toast("Saved Successfully",{ position: toast.POSITION.TOP_CENTER })
    //     }
    //     else if(res.status===400){
    //       toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
    //     }
    //     else if (res.status === 401) {
    //       history.push("/");
    //     } 
    //   }).catch(err=>console.error(err))
    // }
    // else if(healthLimitation==='No'){
    //   axiosFetch({
    //     url:`/updateLead/${props?.props?.LeadID?.id}`,
    //     method:'post',
    //     requestConfig:requestBody
    //   }).then(res=>{
    //     if(res.status===200){
          
    //       toast("Saved Successfully",{ position: toast.POSITION.TOP_CENTER })
    //     }
    //     else if(res.status===400){
    //       toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
    //     }
    //     else if (res.status === 401) {
    //       history.push("/");
    //     } 
    //   }).catch(err=>console.error(err)) 
    // }
    // else{
    //   setErrorForIfHealthCausingIsYes("Please specify health issues causing pain")

    // }

    if((interestedActivities.includes(9)&& otherInterstedActivities.trim() !=="" ) || (!interestedActivities.includes(9))
    // (healthLimitation==='Yes' && ifHealthCausingPainIsYes !=="")
    ){
      if((healthLimitation===1 && ifHealthCausingPainIsYes.trim() !=="") || (healthLimitation===2)){

        axiosFetch({
          url:`/updateLead/${LeadId.id}`,
          method:'post',
          requestConfig:requestBody
        }).then(res=>{
          if(res.status===200){
            
            toast.success("Saved Successfully",{ position: toast.POSITION.TOP_CENTER })
            setTimeout(() => {
        
              window.location.reload()
            }, 1000);
          }
          else if(res.status===400){
            toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
          }
          else if (res.status === 401) {
            history.push("/");
          } 
        }).catch(err=>console.error(err))
      }
      else{
        setErrorForIfHealthCausingIsYes("Please Enter Something")
      }

    }
    
    else{

      setErrorForOtherInterstedActivities("Please Enter Something")

    }
    
    

     
  
    
  
    

  
  
}
  else{
    if(Object.keys(dailyActivityForMobileUse).length ===0){
      setErrorForDailyActivity("Please Select Something")
    }
    // if(healthLimitation==='Yes' && ifHealthCausingPainIsYes ===""){

    // }

    // if(interestedActivities.includes('Others')&& otherInterstedActivities ===""){
    //   setErrorForOtherInterstedActivities("Please specify other intersted activities")

    // }
    if(execiseTiming.trim() ===""){
      setErrorForPreferredExerciseTime("Please Select Something")
    }
    if(interestedActivities?.length===0){
      setErrorForInterstedActivities("Please Select Something")
    }
    if(workoutPreference ===""){
      setErrorForWorkoutPreference("Please Select Something")
    }
    if(healthLimitation === ""){
      setErrorForHealthIssues("Please Select Something")
    }
  }
}

const handleChangeIfYesForHealthCausing=(e)=>{
  setIfHealthCausingPainIsYes(e.target.value)
  if((e.target.value).trim()===""){
    setErrorForIfHealthCausingIsYes("Please Enter Something")
  }else{
    setErrorForIfHealthCausingIsYes("")
  }
}

const handleChangeOtherInterstedActivities=(e)=>{
  setOtherInterstedActivities(e.target.value)
  if((e.target.value).trim()===""){
    setErrorForOtherInterstedActivities("Please Enter Something")
  }else{
    
    setErrorForOtherInterstedActivities("")
  }
}
  return (
    <div>
      <div className='row'>
            
            <div className='col-md-4'>
                <div className='form-group'>
                    {/* <label>Food Dislikes</label> */}
                    <FormControl sx={{  width: '100%' }}  disabled={!editable}>
                      <label>Daily Activity &nbsp;  <small className='req'>*</small></label>
       
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          
          value={dailyActivity}
          onChange={handleChangeDailyActivity}
      
          
          // MenuProps={MenuProps}
          MenuProps={{
            anchorOrigin: {
              vertical: "center",
              horizontal: "center"
            },
            transformOrigin: {
              vertical: "center",
              horizontal: "center"
            },
            getContentAnchorEl: null
          }}
          // style={{height:'45px'}}
        >
          {dailyActive?.length>0 && dailyActive?.map(c=>{
           return <MenuItem value={c?.title}>{c?.title}</MenuItem>
          })}
          
            
           

            
         
        </Select>
      </FormControl>
            <p className='req'>{errorForDailyActivity}</p>
                    
                    {/* <input className='form-control' type='text' placeholder='Please Specify'/> */}
                </div>
            </div>
            <div className='col-md-4'>
                <div className='form-group'>
                    {/* <label>Number Of Meals</label> */}
                    
                    <FormControl sx={{  width: '100%' }}  disabled={!editable}>
                      <label>Interested Activities &nbsp;  <small className='req'>*</small></label>
        
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={interestedActivities}
          onChange={handleChangeInterestedActivities}
     
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={activities?.length>0 && activities.find(c=>c.id===value)?.title} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          // style={{height:'45px'}}
        >
         
          {activities?.length>0 && activities.map((name) => (
            <MenuItem key={name?.id} value={name?.id}>
            <Checkbox checked={ interestedActivities.indexOf(name?.id) > -1} />
            <ListItemText primary={name?.title} />
          </MenuItem>
            
          ))}
        </Select>
      </FormControl>
            <p className='req'>{errorForInterstedActivities}</p>
                    
                    {/* <select className='form-control'>
                        <option disabled hidden selected>Select</option>
                        {Array.apply(null, { length: 8 }).map(
                                  (value, index) => (
                                    <option
                                    
                                      value={index + 1}
                                    >
                                      {index + 1}
                                    </option>
                                  )
                                )}
                        

                    </select> */}
                </div>
            </div>

            <div className='col-md-4'>
                <div className='form-group'>
                    {/* <label>Are Seeds taken in seed cycling?</label> */}

                   
                    {/* <label>Preferred Cuisine</label> */}

{
     
                    <TextField  disabled={!otherActivities } value={otherInterstedActivities} onChange={handleChangeOtherInterstedActivities} type='text' placeholder='Please Specify'   sx={{width:'100%',marginTop:'20px'}}/>
}

            <p className='req'>{errorForOtherInterstedActivities}</p>

                    {/* <select className='form-control'>
                        <option disabled hidden selected>Select</option>
                        <option>North Indian</option>
                        <option>South Indian</option>
                        <option>East Indian</option>
                        <option>West Indian</option>
                        <option>Continental</option>
                        <option>Chinese</option>


                    </select> */}
               
                    
                    {/* <select className='form-control'>
                        <option disabled hidden selected>Select</option>
                        <option>Yes</option>
                        <option>No</option>

                    </select> */}
                </div>
            </div>
        </div>

        <div className='row'>
            <div className='col-md-4'>
                <div className='form-group'>
                    {/* <label>Diet Preference</label> */}

                    <FormControl fullWidth  disabled={!editable}>
                      <label>Preferred exercise time &nbsp;  <small className='req'>*</small></label>
 
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
  
    value={execiseTiming}
    // label="Age"
    onChange={handleChangeExcerciseTiming}
    // style={{height:'45px'}}
    MenuProps={MenuProps}
  >

    {preferredExerciseTime?.length>0 && preferredExerciseTime?.map(c=>{
      return <MenuItem value={c?.title}>{c?.title}</MenuItem>
    })}
    
   

  

  </Select>
</FormControl>

<p className='req'>{errorForPreferredExerciseTime}</p>
                    
                    {/* <select className='form-control'>
                        <option disabled hidden selected>Select</option>
                        <option>Vegetarian</option>
                        <option>Vegan</option>
                        <option>Eggetarian</option>
                        <option>Non-Vegetarian</option>

                    </select> */}
                </div>
            </div>

            <div className='col-md-4'>
                <div className='form-group'>
                    {/* <label>Any Allergies</label> */}


                    <FormControl fullWidth  disabled={!editable}>
                      <label>Workout Preference &nbsp;  <small className='req'>*</small></label>
 
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"

    value={workoutPreference    }
    // label="Age"
    onChange={handleChangeWorkoutPreference}
    // style={{height:'45px'}}
    MenuProps={{
      anchorOrigin: {
        vertical: "center",
        horizontal: "center"
      },
      transformOrigin: {
        vertical: "center",
        horizontal: "right"
      },
      PaperProps:{
        style:{
          transform:'translateY(-52px)'
        }
      }
      // getContentAnchorEl: null
    }}
  >

    {workotPreference?.length>0 && workotPreference?.map(c=>{
   return <MenuItem value={c?.title}>{c?.title}</MenuItem>
    })}
  
    

  </Select>
</FormControl>
<p className='req'>{errorForWorkoutPreference}</p>




                   
                    {/* <select className='form-control'>
                        <option disabled hidden selected>Select</option>
                        <option>Peanuts</option>
                        <option>Egg</option>
                        <option>Poultry</option>
                        <option>Pork</option>
                        <option>Beef</option>
                        <option>Egg</option>
                        <option>Lamb/Mutton</option>
                        <option>Dairy</option>
                        <option>Soy</option>
                        <option>Gluten</option>
                        <option>Tree nuts</option>
                        <option>Seafoods</option>
                        <option>Sesame seeds</option>
                        <option>Others (Please specify)</option>


                    </select> */}
                </div>
            </div>

            

        </div>

        <div className='row'>
            <div className='col-md-4'>
                <div className='form-group'>
                <FormControl sx={{  width: '100%' }}  disabled={!editable}>
                  <label>Health issues causing pain or limitations? &nbsp;  <small className='req'>*</small></label>
     
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          
          value={healthLimitation}
          onChange={handleChangeHealthLimitation}
         
          
          MenuProps={MenuProps}
          // style={{height:'45px'}}
        >
          {healthIssues?.length>0 && healthIssues?.map(c=>{
            return <MenuItem key={c?.id} value={c?.id}>{c?.title}</MenuItem>
          
          })}
            

         
        </Select>
      </FormControl>
      <p className='req'>{errorForHealthIssues}</p>

                </div>
            </div>
            <div className='col-md-4'>
                <div className='form-group'>
                    {/* <label>Preferred Cuisine</label> */}

{
     
                    <TextField  disabled={!healthLimitationYes} value={ifHealthCausingPainIsYes} onChange={handleChangeIfYesForHealthCausing} type='text' placeholder='Please Specify'   sx={{width:'100%',marginTop:'20px'}}/>
}
<p className='req'>{errorForIfHealthCausingIsYes}</p>

                    
                    {/* <select className='form-control'>
                        <option disabled hidden selected>Select</option>
                        <option>North Indian</option>
                        <option>South Indian</option>
                        <option>East Indian</option>
                        <option>West Indian</option>
                        <option>Continental</option>
                        <option>Chinese</option>


                    </select> */}
                </div>
            </div>
        </div>
        <div className="box-footer d-flex justify-content-center" >
            <button type="submit" onClick={editWorkoutPreference}   className="btn btn-info pull-right" style={{marginRight:'20px',backgroundColor:'white',color:'black',border:'1px solid black',width:'120px'}}>Edit</button>
            <button type="submit" disabled={!editable} onClick={saveWorkoutPreference}  className="btn btn-info pull-right" style={{backgroundColor:'black',color:'white',width:'120px'}} >Save</button>


            </div>
    </div>
  )
}

export default WorkoutPreferrence
