// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-container {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img {
  width: 400px;
  height: 400px;
}

.img-success {
  width: 500px;
  height: 400px;
}

.payment-container span {
  font-size: 18px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/PaymentStatus/Status.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".payment-container {\n  width: 100%;\n  height: 100vh;\n  background-color: #fff;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.img {\n  width: 400px;\n  height: 400px;\n}\n\n.img-success {\n  width: 500px;\n  height: 400px;\n}\n\n.payment-container span {\n  font-size: 18px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
