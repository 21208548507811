import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import Badge from 'react-bootstrap/Badge';

function generate(list, tab, pHandler, rHandler) {
    return list.map((value) => {
            const el = (
                <Row className='ml-5 mr-4 my-4' key={value.id}>
                    <Col md={4}>
                        <span>{formatDate(value.start_time)}</span>
                    </Col>

                    {tab != 2? <Col md={4}>
                        {getStatus(value)}
                    </Col>: ''}

                    <Col md={4}>
                        <IconButton edge="end" aria-label="delete" onClick={(e)=>pHandler(value.session_id, value.id)}>
                            <SettingsAccessibilityIcon />
                        </IconButton>
                    </Col>

                    {tab == 2? <Col md={4}>
                        <IconButton edge="end" aria-label="delete" onClick={(e)=>rHandler(value)}>
                            <PlayCircleOutlineIcon />
                        </IconButton>
                    </Col>: ''}
                </Row>
            )

            return el
        }
    );
}

function formatDate(start_time) {
    return moment(start_time).format('YYYY-MM-DD')
}

function getStatus(meeting) {
    if(meeting.is_cancelled) {
        return <Badge bg="danger" style={{'fontSize': '15px'}}> Cancelled </Badge>
    }
    if(meeting.is_completed) {
        return <Badge bg="success" style={{'fontSize': '15px'}}> Completed </Badge>
    }
    if(meeting.has_started && !meeting.is_completed && !meeting.is_cancelled) {
        return <Badge bg="warning" style={{'fontSize': '15px'}}> Ongoing </Badge>
    }

    return <Badge bg="dark" style={{'fontSize': '15px'}}> Pending </Badge>
}

export default function SessionHistory(props) {

    return (
        <div>
            <Row className='ml-5 mr-4 my-4'>
                <Col md={4}> <b>Date</b> </Col>
                {props.tab != 2? <Col md={4}> <b>Status</b> </Col>: ''}
                <Col md={4}> <b>Participants</b> </Col>
                {props.tab == 2? <Col md={4}> <b>Recording</b> </Col>: ''}
            </Row>

            {generate(props.list, props.tab, props.handleMeetingClick, props.handlePlay)}
        </div>
    );
}