// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#text_Center{
     position: relative;
    /* right: 1120px; */
    bottom: 45px;
    /* display: flex; */
    /* flex-direction: row; */
    flex: 1 1;
    /* transform: translateX(500px);
    transform: translateY(-45px); 
    /* left: 800px; */
    left: 200px;
    inline-size: 150px;
    /* bottom: 690px; */
    z-index: 1;
    /* transform: translateY(-45px); 
    transform: translateX(250px); */
}
#userName{
    height: 35px;
}

#button{
    position: relative;
    bottom: 25px;
    right: 10px;
}
@media (max-width:1050px){
   #text_Center{
    position: fixed;
    display: flex;
    flex-direction: row;
    bottom: 580px;
    /* left: 400px; */
    left: 250px;
   }
   #button{
    position: relative;
    display: flex;
    bottom: 50px;
    left: 350px;
   }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/ListClient.css"],"names":[],"mappings":"AAAA;KACK,kBAAkB;IACnB,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,SAAO;IACP;;qBAEiB;IACjB,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,UAAU;IACV;mCAC+B;AACnC;AACA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;AACA;GACG;IACC,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,iBAAiB;IACjB,WAAW;GACZ;GACA;IACC,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,WAAW;GACZ;AACH","sourcesContent":["#text_Center{\n     position: relative;\n    /* right: 1120px; */\n    bottom: 45px;\n    /* display: flex; */\n    /* flex-direction: row; */\n    flex: 1;\n    /* transform: translateX(500px);\n    transform: translateY(-45px); \n    /* left: 800px; */\n    left: 200px;\n    inline-size: 150px;\n    /* bottom: 690px; */\n    z-index: 1;\n    /* transform: translateY(-45px); \n    transform: translateX(250px); */\n}\n#userName{\n    height: 35px;\n}\n\n#button{\n    position: relative;\n    bottom: 25px;\n    right: 10px;\n}\n@media (max-width:1050px){\n   #text_Center{\n    position: fixed;\n    display: flex;\n    flex-direction: row;\n    bottom: 580px;\n    /* left: 400px; */\n    left: 250px;\n   }\n   #button{\n    position: relative;\n    display: flex;\n    bottom: 50px;\n    left: 350px;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
