import moment from "moment-timezone";
import Button from 'react-bootstrap/Button';

function AskHtml({ onClose, meeting }) {
    return (
        <div className='custom-ui'>
            <h1>Are you sure?</h1>
            {getTs(meeting)}
            <Button variant="primary"
                onClick={() => {
                    window.open(meeting.start_url, '_blank');
                    onClose();
                }}
            >
                Yes, Start it!
            </Button>
            
            <Button variant="light" onClick={onClose} className="ml-5">No</Button>
        </div>
    );


    function getTs(meeting) {
        const userTimezone = moment.tz.guess()
        let sessionOn = moment(meeting.start_time).tz(userTimezone).format('MMM Do YYYY, h:mm A')
        const now = moment()
        const duration = moment.duration(moment(meeting.start_time).diff(now))
        const days = duration.days()
        const hours = duration.hours()
        const minutes = duration.minutes()

        let str = ''
        let showImpNotice = false
        // We will show message as important if user starts the meeting much before 
        // the sceduled time
        if(days > 0) {
            str += `${days} day`
            showImpNotice = true
        }
        if(hours > 0) {
            str += ` ${hours} hours`
            showImpNotice = true
        }
        if(minutes > 0) {
            str += ` ${minutes} minutes`

            if(minutes > 5) {
                showImpNotice = true
            }
        }

        if(!showImpNotice) {
            return ''
        }

        str += ` from now`

        return <p>
            <div> The meeting is scheduled on {sessionOn}</div>
            <div> Which is 
                <span style={{color: 'red', fontSize: '20px', fontWeight: 'bold'}}>
                    {str}
                </span>
            </div>
        </p>
    }
}

export default AskHtml