// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-title{
    display: "flex";
    align-items: "center";
    font-size: large;
    font-weight: bold;

}`, "",{"version":3,"sources":["webpack://./src/css/Client.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,qBAAqB;IACrB,gBAAgB;IAChB,iBAAiB;;AAErB","sourcesContent":[".box-title{\n    display: \"flex\";\n    align-items: \"center\";\n    font-size: large;\n    font-weight: bold;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
