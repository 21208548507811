import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useReducer,
} from "react";
import * as $ from "jquery";
import moment from "moment";
import BoxHeader from "../../components/BoxHeader";
import DataTable from "../../components/DataTable";
import axios from "axios";
import SelectBox from "../../components/SelectBox";
import Dialog from "../../components/Dialog";
import FollowupModal from "../../components/FollowupModal";
import BulkUpdateModal from "../../components/BulkUpdateModal";
import EmailModal from "../../components/EmailModal";
import BulkUploadModal from "./BulkUploadModal";
import SearchIcon from "@mui/icons-material/Search";
import {
  returnTime,
  getNormalDate,
  returnDate,
} from "../../Helpers/DateHelpers";
import { changeHandler as oldChangeHandler } from "../../Helpers/ChangeHandler";
import {
  closeInputModal,
  openInputModalLeads,
  openFollowUpModal,
  closeFollowUpModal,
  openEmailModal,
  closeEmailModal,
  updateFollowUp,
  updateFollowUpStatus,
  deleteFollowUp,
} from "../../Helpers/ModalHandler";
import {
  ATLAS_URI,
  ADMIN,
  CALLER_ID,
  INDIA_CALLER_ID,
  CALL_METHOD,
  ENDPOINT_PASSWORD,
} from "../../Constants";
import stateContext from "../../context/StateContext";
import { GrClose, GrFormView } from "react-icons/gr";
import Popupmodel from "./Popupmodel";
import PopupmodelCalendar from "./PopupmodelCalendar";
import GridTable from "../../components/GridTable";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { TbUserPlus, TbCalendar, TbRefresh } from "react-icons/tb";
import { BiRefresh } from "react-icons/bi";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaHistory } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import { MdDelete } from "react-icons/md";
import PopupPDFUpload from "./PopupPDFUpload";
import { toast } from "react-toastify";
// import './AllLeads.css'
import { IoMdArrowDropdown } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { BiListCheck } from "react-icons/bi";
import { PlivoContext } from "../../context/PlivoContext";
import { getLeadList } from "../store/leads/leadActions";
import { getSourceList } from "../store/Source/SourceActions";
import LeadList from "./LeadList copy";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Card,
  Collapse,
  IconButton,
  Typography,
  CardActions,
  CardContent,
  Grid,
  Select,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Stack,
  InputAdornment,
} from "@mui/material";

import { ApiDataProvider } from "./MasterDataContextProvider";
// import ApiDataContext from './MasterDataContextProvider'
import Box, { BoxProps } from "@mui/material/Box";

//==================================================================
const initialPanel = {
  from_date: "",
  to_date: "",
  keyword: "",
  per_page: "",
};
const panelReducer = (state, action) => {
  switch (action.type) {
    case "FROM_DATE":
      state.from_date = action.value;
      return state;
    case "TO_DATE":
      state.to_date = action.value;
      return state;
    case "KEYWORD":
      state.keyword = action.value;
      return state;
    case "PER_PAGE":
      state.per_page = action.value;
      return state;

    default:
      return state;
  }
};
//==================================================================

function AllLeads() {
  // const [plivoBrowserSdk, setPlivoBrowserSdk] = useState('')

  // const plivoBrowserSdk = useRef(null);
  const location = useLocation();
  const leadListDate = location?.state?.LeadListDate;

  const [hideColumn, setHideColumn] = useState(false);
  const contextState = useContext(stateContext);

  const routeParams = useLocation().search;
  const searchParams = new URLSearchParams(routeParams);

  const {
    updateEditDetails,
    updateList,
    updateLeadsList,
    // onCallRemoteRinging,
    setCurrentCallStatus,
    setCallDurationInfo,
    callDurationInfo,
    plivoBrowserSdk,
    setIsTimerOn,
    isTimerOn,
    setDuration,
    duration,
  } = contextState;
  const {
    configToken,
    leadsList,
    usersList,
    sourcesList,
    propertyTypeList,
    statusList,
    operator,
    featureAccessible,
  } = contextState.state;

  const [expand, setExpand] = useState(true);
  const assignToSelect = useRef(null);

  const [initialSaved, setInitialSaved] = useState(false);

  /////////////////////////////////////////
  // For clearing data grid filters
  const [clearChildFilter, setClearChildFilter] = useState(0);
  function resetDataGridFilterUI() {
    setClearChildFilter(Math.random());
  }
  // For clearing row selections after user assigned selected rows
  const [clearRowTicks, setClearRowTicks] = useState(0);
  function resetDataGridRowSelectionUI() {
    setClearRowTicks(Math.random());
  }
  // For inform to grid to save state
  const [saveGrid, setSaveGrid] = useState(0);
  function triggerSaveGrid() {
    setSaveGrid(Math.random());
  }

  const [callFromName, setCallFromName] = useState("");
  const [callFromNumber, setCallFromNumber] = useState("");

  //------------------------------------------------------
  const [panelValues, panelDispatch] = useReducer(panelReducer, initialPanel);
  //------------------------------------------------------

  // const [dateFromHandler, setDateFromHandler] = useState(''); // Filter - "from"
  // const [dateToHandler, setDateToHandler] = useState(''); // Filter - "to"
  // const [kywd, setKywd] = useState('');

  const [totalRecords, setTotalRecords] = useState("");

  const [createdFromDateErrMsg, setCreatedFromDateErrMsg] = useState("");
  const [createdToDateErrMsg, setCreatedToDateErrMsg] = useState("");

  const [salesRoles, setSalesRoles] = useState([]);
  const [assignTo, setAssignTo] = useState();
  const [assignLeads, setAssignLeads] = useState([]);

  const [state, setState] = useState({
    resetNewRow: {
      id: "",
      Client: "",
      name: "",
      ClientContact: "",
      email_id: "",
      ClientLocation: "",
      LeadStatus: "",
      Remarks: [],
      PropertyType: "Select",
      RequiredLocation: "",
      MinArea: "",
      MaxArea: "",
      MinBudget: "",
      MaxBudget: "",
      RequiredLoan: "",
      source: "",
      call_status: "",
      LeadDate: "",
      LastFollowUpDate: "",
      next_followup_date: "",
      FollowUpList: [],
      CreatedBy: "",
      User: "",
      Role: "Select",
    },
    newTableRow: {
      // Source: "Select",
      // User: "Select",
      Role: "Select",
      // CreatedAt: "",
    },
    //From Data
    tableBodyList: [],
    sourceList: [],
    userList: [],
    roleList: [],
    tableBodyDisplayList: [],
    editingActivated: false,
    editingID: "",
    isFollowUpModalOpen: false,
    currentCallUUID: "",
    dialogInfo: {
      isOpened: false,
      text: "",
      type: "",
    },
    Email: "",
    EmailInfo: {},
    isModalOpen: false,
    SelectedLeads: [],
    isEmailModalOpen: false,
  });

  const [show, setShow] = useState(false);
  const [deleteIds, setDeleteIds] = useState(null);
  const [text, setText] = useState("Delete");

  const [isHFReportGenerationLoading, setIsHFReportGenerationLoading] =
    useState(false);

  const [isCallModalShow, setIsCallModalShow] = useState(false);
  const callObjectInfo = useRef(null);
  const [gridColumnList, setGridColumnList] = useState([]);
  const { plivo, microphoneDevices, speakerDevices } = useContext(PlivoContext);
  const [leadData, setLeadData] = useState([]);
  const [selectedSpeaker, setSelectedSpeaker] = useState("");
  const [selectedMicroPhone, setSelectedMicroPhone] = useState("");

  const handleChangeSpeaker = (e) => {
    setSelectedSpeaker(e.target.value);
    plivo.client.audio.speakerDevices.set(e.target.value);
  };

  const handleChangeMicroPhone = (e) => {
    setSelectedMicroPhone(e.target.value);
    plivo.client.audio.microphoneDevices.set(e.target.value);
  };

  useEffect(() => {
    let isRestore = localStorage.getItem("RESTORE_LEAD_LISTING_SCREEN_PANEL");
    if (!isRestore) {
      performSearch({
        filter: {
          filterModel: {
            items: [],
            logicOperator: "and",
            quickFilterValues: [],
            quickFilterLogicOperator: "and",
          },
        },
        pagination: {
          paginationModel: {
            page: 0,
            pageSize: 25,
          },
        },
      });
    } else {
      let LEAD_LC_STORAGE =
        localStorage.getItem("LEAD_LC_STORAGE_PANEL") || "{}";
      LEAD_LC_STORAGE = JSON.parse(LEAD_LC_STORAGE) || {};

      if (LEAD_LC_STORAGE.from_date) {
        // setDateFromHandler(LEAD_LC_STORAGE.from_date)
        panelDispatch({ type: "FROM_DATE", value: LEAD_LC_STORAGE.from_date });
        if (myRef1 && myRef1.current) {
          myRef1.current.value = LEAD_LC_STORAGE.from_date;
        }
      }

      if (LEAD_LC_STORAGE.to_date) {
        // setDateToHandler(LEAD_LC_STORAGE.to_date)
        panelDispatch({ type: "TO_DATE", value: LEAD_LC_STORAGE.to_date });
        if (myRef2.current) {
          myRef2.current.value = LEAD_LC_STORAGE.to_date;
        }
      }

      if (LEAD_LC_STORAGE.keyword) {
        // setKywd(LEAD_LC_STORAGE.keyword)
        panelDispatch({ type: "KEYWORD", value: LEAD_LC_STORAGE.keyword });
        if (myRef3.current) {
          myRef3.current.value = LEAD_LC_STORAGE.keyword;
        }
      }

      localStorage.removeItem("RESTORE_LEAD_LISTING_SCREEN_PANEL");
      performSearch();
    }
  }, []);

  const mounted = React.useRef(true);

  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );

  useEffect(() => {
    const newEmailInfo = {};
    axios
      .get(`${ATLAS_URI}/getRoles/`, configToken)
      .then((resp) => {
        const rolesData = resp.data;

        if (typeof rolesData !== "undefined") {
          axios
            .get(`${ATLAS_URI}/getConfiguration/`, configToken)
            .then((response) => {
              const resData = response.data[0];
              if (typeof resData !== "undefined") {
                newEmailInfo.UserID = resData.UserID;
                newEmailInfo.ServiceID = resData.ServiceID;
                newEmailInfo.TemplateID = resData.TemplateID;
              }

              // let leadsData = leadData;
              let leadsData = leadData;

              let userData = usersList;
              let sourcesData = sourcesList;
              let propertyTypesData = propertyTypeList;
              let statusData = statusList;

              if (leadsData.length !== 0) setLoading(false);

              if (
                leadsData.length !== 0 ||
                sourcesData.length !== 0 ||
                // && propertyTypesData.length !== 0
                (userData.length !== 0 && leadsData)
              ) {
                if (leadsData) {
                  leadsData.map((lead) => {
                    const filteredUser = userData.filter(
                      (user) => user.id == lead.assigned_to
                    );
                    lead._AssignedTo =
                      filteredUser.length === 0 ? "" : filteredUser[0].Username;

                    let tempSources = sourcesData.filter(
                      (source) => source.id === lead.Source
                    );
                    lead.Sources =
                      tempSources.length !== 0 && tempSources[0].Source;

                    let tempPropertyType = propertyTypesData.filter(
                      (propertyType) => propertyType.id === lead.PropertyType
                    );
                    lead.PropertyTypes =
                      tempPropertyType.length !== 0 &&
                      tempPropertyType[0].PropertyType;

                    lead.PropertyTypes =
                      tempPropertyType.length !== 0 &&
                      tempPropertyType[0].PropertyType;
                    lead.FollowUpList = lead.FollowUps;
                    lead.LeadStatus =
                      lead.LeadStatusID && lead.LeadStatusID.Status;

                    lead.LastRemarks =
                      lead.Remarks &&
                      (lead.Remarks.length !== 0
                        ? lead.Remarks[lead.Remarks.length - 1].Remarks
                        : "");

                    lead.User = "Seect";
                    lead.Role = "Seect";
                    return lead;
                  });
                }

                const userID = operator.id;

                leadsData = operator.Feature.find(
                  (fe) => fe.sub_feature_info.sub_feature_name === "All Leads"
                ).is_view
                  ? leadsData
                  : leadsData.filter((lead) => lead.assigned_to == userID);

                const newUserList = userData.map((user) => {
                  const temp = rolesData.filter(
                    (role) => user.Role === role.id
                  );

                  user.AssignedTo = temp[0]?.Role + ": " + user.Name;

                  return user;
                });

                setTimeout(() => {
                  mounted.current &&
                    setState((prevState) => ({
                      ...prevState,
                      EmailInfo: newEmailInfo,
                      tableBodyList: leadsData,
                      tableBodyDisplayList: leadsData.map((leadInfo) => {
                        return {
                          ...leadInfo /* , leadStage: leadInfo?.leadStageList[0]?.leadStageInfo?.display_value */,
                        };
                      }),
                      userList: newUserList,
                      sourceList: sourcesData,
                      roleList: rolesData,
                      statusList: statusData,
                    }));
                  setLoading(false);
                }, 400);
              }
            })
            .catch((err) => console.error(err));
        }
        getSalesRoles();
      })
      .catch((err) => console.error(err));

    // }, []);
  }, [contextState]);

  function getSalesRoles() {
    axios
      .get(`${ATLAS_URI}/getSalesRoles/`, configToken)
      .then((response) => {
        setSalesRoles(response.data);
      })
      .catch((err) => console.error(err));
  }
  function bulkAssign() {
    const data = {};
    data.ids = assignLeads.map((data) => data);
    data.assignTo = assignTo;

    axios
      .post(`${ATLAS_URI}/updateLeadsAssignment`, data, configToken)
      .then((response) => {
        if (response.status === 200) {
          setShow(false);
          toast.success("Leads assigned successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          setShow(false);
          assignToSelect.current.value = "";
          setAssignTo(null);
          resetDataGridRowSelectionUI();
          performSearch();
        }
      })
      .catch((err) => console.error(err));
  }

  let history = useHistory();
  function editRecord(e) {
    const temp = e;

    updateEditDetails({
      id: temp,
      editingActivated: true,
      redirectFrom: "/Leads/listLeads",
      redirectTo: "/Leads/leadDetails",
    });
    history.push({ pathname: "/Leads/leadDetails/" + temp, state: temp });
  }

  function openDialog(e) {
    const newDialogInfo = {
      isOpened: true,
      delID: e,
      text: "Are you sure you want to delete this Lead?",
      type: "Confirm",
    };
    setState((prevState) => ({ ...prevState, dialogInfo: newDialogInfo }));
  }
  function openWhatsapp(e) {
    /* 
        e.preventDefault();
        const id = e.target.parentElement.parentElement.id */

    // e.preventDefault();
    const id = e;

    const phone = state.tableBodyList
      .filter((data) => data.id === id)[0]
      .contact_number.replace("+", "");
    window.open(`https://wa.me/${phone}`, "_blank");
  }
  function deleteFromTable(e) {
    const delID = state.dialogInfo.delID;
    axios
      .delete(`${ATLAS_URI}/deleteManyLeads/` + delID, configToken)
      .then(() => {
        // const newTableBodyList = state.tableBodyList.filter(data => data.id.toString() !== delID);
        const newTableBodyList = state.tableBodyList.filter((data) => {
          return !delID.includes(data.id);
        });

        updateList("leadsList", newTableBodyList);

        setState((prevState) => ({
          ...prevState,
          tableBodyList: newTableBodyList,
          dialogInfo: { isOpened: false, text: "", delID: "" },
        }));
        toast.success("Lead Deleted Successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        handleClear();
      })
      .catch((err) => console.error(err));
  }

  const handleSubmit = () => {
    if (!!assignTo) {
      bulkAssign();
    } else {
      if (panelValues.date_from !== "") {
        if (panelValues.date_to === "") {
          setCreatedToDateErrMsg(true);
          return false;
        }
      }
      if (panelValues.date_to !== "") {
        if (panelValues.date_from === "") {
          setCreatedFromDateErrMsg(true);
          return false;
        }
      }
      performSearch();
    }

    localStorage.setItem("fromDate", myRef1);
  };

  async function searchCriteria(e) {
    e?.preventDefault();
    performSearch();
  }

  async function performSearch(state) {
    // let uiState = {
    //   panel: {}, grid: {}
    // }

    // if(!state) {
    //   let LEAD_LC_STORAGE = localStorage.getItem('LEAD_LC_STORAGE')
    //   if(LEAD_LC_STORAGE) {
    //     LEAD_LC_STORAGE = JSON.parse(LEAD_LC_STORAGE)
    //     uiState.grid = state
    //   }
    // } else {
    //   uiState.grid = state
    // }

    /*
    if(!panel) {
      let LEAD_LC_STORAGE = localStorage.getItem('LEAD_LC_STORAGE') 
      if(LEAD_LC_STORAGE) {
        LEAD_LC_STORAGE = JSON.parse(LEAD_LC_STORAGE)
        uiState.panel = panel
      }
    } else {
      uiState.panel = panel
    }
    */
    if (!initialSaved) {
      //inital load
      setInitialSaved(true);
      triggerSaveGrid();
    }

    if (!state) {
      let parameters = {
        page: 1, //LEAD_LC_STORAGE.pageState["page"] + 1,
        perPage: 25, //LEAD_LC_STORAGE.pageState["pageSize"],
        from_date:
          panelValues.from_date && panelValues.to_date
            ? panelValues.from_date
            : null,
        to_date:
          panelValues.from_date && panelValues.to_date
            ? panelValues.to_date
            : null,
        search: panelValues.keyword ? panelValues.keyword : null,
        timezone:
          panelValues.from_date && panelValues.to_date
            ? encodeURIComponent(
                Intl.DateTimeFormat().resolvedOptions().timeZone
              )
            : null,
      };

      localStorage.setItem(
        "LEAD_LC_STORAGE_PANEL",
        JSON.stringify(panelValues)
      );

      const queryString = Object.entries(parameters)
        .filter(([_, value]) => value !== null)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

      await axios
        .post(
          `${ATLAS_URI}/getLeads?${queryString}`,
          { items: [] },
          configToken
        )
        .then((res) => {
          setIslead_Loading(false);
          setLeadData(res.data.leads);
          setTotalRecords(res.data.count);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      panelDispatch({
        type: "PER_PAGE",
        value: state?.pagination?.paginationModel?.pageSize,
      });
      localStorage.setItem(
        "LEAD_LC_STORAGE_PANEL",
        JSON.stringify(panelValues)
      );

      let parameters = {
        page: state?.pagination?.paginationModel?.page + 1, //LEAD_LC_STORAGE.pageState["page"] + 1,
        perPage: state?.pagination?.paginationModel?.pageSize, //LEAD_LC_STORAGE.pageState["pageSize"],
        from_date:
          panelValues.from_date && panelValues.to_date
            ? panelValues.from_date
            : null,
        to_date:
          panelValues.from_date && panelValues.to_date
            ? panelValues.to_date
            : null,
        search: panelValues.keyword ? panelValues.keyword : null,
        timezone:
          panelValues.from_date && panelValues.to_date
            ? encodeURIComponent(
                Intl.DateTimeFormat().resolvedOptions().timeZone
              )
            : null,
      };

      const queryString = Object.entries(parameters)
        .filter(([_, value]) => value !== null)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

      setIslead_Loading(true);

      await axios
        .post(
          `${ATLAS_URI}/getLeads?${queryString}`,
          state?.filter?.filterModel,
          configToken
        )
        .then((res) => {
          setIslead_Loading(false);
          setLeadData(res.data.leads);
          setTotalRecords(res.data.count);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  const updateColumns = (newColumns, originalColumns) => {
    let newColArr = [];
    originalColumns.map((item) => {
      if (newColumns[item.field]) {
        newColArr.push(item.field);
      }
    });

    //*Bug in data-grid when "show all" is enabled it gives all item in newColumns as false */
    const allFalse = Object.values(newColumns).every(
      (value) => value === false
    );
    if (allFalse) {
      //enable all columns
      const cols = originalColumns.map((e) => e.field);
      setGridColumnList(cols);
    } else {
      setGridColumnList(newColArr);
    }
  };

  // We need to get the column info which columns are shown and which hide from
  // child component
  function postListView() {
    const listViewData = {
      fieldList: gridColumnList,
      userId: operator.id,
      screen: 1, // lead screen
    };

    axios
      .post(`${ATLAS_URI}/postListView`, listViewData, configToken)
      .then((res) => {
        if (res.status == 200) {
          toast.success("List view saved", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error("List view save has problem", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  function changeHandler(e) {
    oldChangeHandler(e, state, setState);
  }

  function openInputModal(leads) {
    openInputModalLeads(setState, leads);
  }

  const [show1, setShow1] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [openUploadHAndFPdf, setOpenUploadHAndFPdf] = useState(false);
  const [openUploadHAndFPdfLeadId, setOpenUploadHAndFPdfLeadId] = useState("");

  let showHAndFUploadModal = (e) => {
    setOpenUploadHAndFPdf(true);
    setOpenUploadHAndFPdfLeadId(e);
  };

  //   const handleShow = () => setShow1(true);
  const [id, setId] = useState();

  function viewDetails(e) {
    // setId(e)

    // const id = e.target.parentElement.parentElement.id

    const temp = e;

    // setShow1(false);

    // <Popupmodel show1={show1} />
    updateEditDetails({
      id: temp,
      editingActivated: true,
      redirectFrom: "/Leads/listLeads",
      redirectTo: "/Leads/leadView",
    });
    // setShow1(true)
    // history.push("/Leads/leadView")
    history.push({ pathname: "/Leads/leadView/" + temp, state: temp });
  }

  function viewCallModal(leadId) {
    if (!operator.Mobile_number) {
      toast.error("Agent mobile number is not provided", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    callObjectInfo.current = {
      agentPhoneNumber: operator.Mobile_number,
      leadPhoneNumber: leadData.filter((data) => data.id === leadId)[0]
        ?.contact_number,
      leadId: leadId,
      userId: operator.id,
      alternativeNumber: "",
    };

    // setCallObjectInfo(callObject)

    setIsCallModalShow(true);
  }

  function sanitizeNumber(input) {
    let sanitized = input.replace("-", "");
    sanitized = sanitized.replace(" -", "");
    sanitized = sanitized.replace("- ", "");
    // sanitized     = sanitized.replace('+','');
    sanitized = sanitized.replace(/\s+/g, "");
    return sanitized;
  }

  const toggleRowSelection = (argBool) => {
    setShow(argBool);
  };

  function callAction(callNumber) {
    setIsCallModalShow(false);

    const callObj = { ...callObjectInfo.current, leadPhoneNumber: callNumber };

    if (CALL_METHOD == "WebRTC") {
      const dest = sanitizeNumber(callObj.leadPhoneNumber);

      let customCallerId = CALLER_ID;
      if (dest.startsWith("+91")) {
        customCallerId = INDIA_CALLER_ID;
      }

      const extraHeaders = {
        "X-PH-Test1": "test1",
        "X-PH-header1": customCallerId,
        "X-PH-leadId": callObjectInfo.current.leadId,
        "X-PH-userId": operator.id,
        "X-PH-page": 1,
      };

      plivo.client.call(dest, extraHeaders);
    } else {
      axios
        .post(`${ATLAS_URI}/makeOutboundCall/`, callObj, configToken)
        .then((callRes) => {
          if (callRes.status == 200) {
            setState((prevState) => ({
              ...prevState,
              currentCallUUID: callRes.data.requestUuid,
            }));
            // openFollowUpModal(callObjectInfo.leadId, state, setState)
            history.push({
              pathname: "/Leads/leadView/" + callObjectInfo.current.leadId,
              state: {
                currentCallUUID: callRes.data.requestUuid,
                isCallHistoryView: true,
              },
            });
          }
        });
    }
  }

  const customActions = [
    { title: "View", icon: "fas fa-eye", onClickEvent: viewDetails },
    {
      title: "Call",
      icon: "fas fa-phone-alt text-green",
      onClickEvent: viewCallModal,
      // (e) => {

      /* 

                if (!operator.Mobile_number) {
                    toast.error('Agent mobile number is not provided',{position: toast.POSITION.TOP_CENTER})
                }

                const callObject = {
                    agentPhoneNumber: operator.Mobile_number,
                    leadPhoneNumber: state.tableBodyList.filter(data => data.id === e)[0].contact_number,
                    leadId: e,
                    userId: operator.id
                }
                axios.post(`${ATLAS_URI}/makeOutboundCall/`, callObject, configToken).then(callRes => {
                   
                    if (callRes.status == 200) {
                        setState(prevState => ({ ...prevState, currentCallUUID: callRes.data.requestUuid }))
                        openFollowUpModal(e, state, setState)
                    }
                }) */

      // setState(prevState => ({ ...prevState, currentCallUUID: '60756291-59d8-4c2b-a635-88c658c471e2' }))
      // openFollowUpModal(e, state, setState)

      // }
    },
    {
      title: "Call History",
      icon: "fa fa-history",
      onClickEvent: (e) => {
        // openFollowUpModal(e, state, setState)
        history.push({
          pathname: "/Leads/leadView/" + e,
          state: {
            // currentCallUUID: callRes.data.requestUuid,
            isCallHistoryView: true,
          },
        });
      },
    },
  ];

  if (
    operator?.Feature?.find(
      (fe) => fe.sub_feature_id === 2 && fe.is_edit && fe.is_view
    )
  ) {
    customActions.push(
      ...[
        { title: "Update", icon: "fas fa-edit", onClickEvent: editRecord },
        // { title: "Delete", icon: "fas fa-times text-red", onClickEvent: openDialog },
        {
          title: "Email",
          icon: "fa fa-envelope",
          onClickEvent: (e) => openEmailModal(e, state, setState),
        },
        {
          title: "Whatsapp",
          icon: "fab fa-brand fa-whatsapp",
          onClickEvent: openWhatsapp,
        },
      ]
    );
  } else if (
    operator?.Feature?.find(
      (fe) => fe.sub_feature_id === 3 && fe.is_edit && fe.is_view
    )
  ) {
    customActions.push(
      ...[
        { title: "Update", icon: "fas fa-edit", onClickEvent: editRecord },
        // { title: "Delete", icon: "fas fa-times text-red", onClickEvent: openDialog },
      ]
    );
  }
  const h_f_report_actions = [
    {
      title: "Upload H&F report",
      icon: "fas fa-upload",
      onClickEvent: showHAndFUploadModal,
    },
  ];

  let showPopupModel = () => {
    // <Popupmodel show1={show1} />
    setShow1(true);
  };

  let bulkUploadModel = () => {
    setShowBulkUploadModal(true);
  };

  let showCalendarModal = () => {
    setOpenCalendar(true);
  };

  /* Spinner */
  const [loading, setLoading] = useState(false);

  const [islead_Loading, setIslead_Loading] = useState(false);

  const toggleReportGeneration = (param) => {
    setIsHFReportGenerationLoading(param);
  };

  const toggleLoading = (param) => {
    setLoading(param);
  };

  const handleClear = () => {
    let parameters = {
      page: 1, //LEAD_LC_STORAGE.pageState["page"] + 1,
      perPage: panelValues.per_page, //LEAD_LC_STORAGE.pageState["pageSize"],
    };

    const queryString = Object.entries(parameters)
      .filter(([_, value]) => value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    setCreatedFromDateErrMsg(false);
    setCreatedToDateErrMsg(false);

    // setDateToHandler("");
    // setDateFromHandler("");
    // setKywd("");
    panelDispatch({ type: "FROM_DATE", value: "" });
    panelDispatch({ type: "TO_DATE", value: "" });
    panelDispatch({ type: "KEYWORD", value: "" });

    if (myRef1) {
      setMyRef1("");
    }

    if (myRef2.current) {
      myRef2.current.value = "";
    }

    if (myRef3.current) {
      myRef3.current.value = "";
    }

    setIslead_Loading(true);
    resetDataGridFilterUI();

    history.push(`/Leads/listLeads?${queryString}`);

    axios
      .post(`${ATLAS_URI}/getLeads?${queryString}`, { items: [] }, configToken)
      .then((res) => {
        setLeadData(res.data.leads);
        setIslead_Loading(false);
        setTotalRecords(res.data.count);
        let data = res.data.leads;

        if (
          operator.Feature.find(
            (fe) => fe.sub_feature_info.sub_feature_name === "All Leads"
          ).is_view
        ) {
          setLeadData(data);
        } else {
          setLeadData(data.filter((lead) => lead.assigned_to == operator.id));
        }

        setState((prevState) => ({
          ...prevState,
          tableBodyDisplayList: res.data.leads.filter((c) => {
            let name, emailId, phNum;
            name = c.name;
            emailId = c.email_id;
            phNum = c.contact_number;

            return c;
          }),
        }));
      })
      .catch((err) => console.error(err));
  };

  const handleChange = (state) => {
    performSearch(state);
  };

  //const myRef1 = useRef(null);
  const myRef2 = useRef(null);
  const myRef3 = useRef(null);

  const [myRef1, setMyRef1] = useState(null);

  useState(() => {
    if (leadListDate) {
      setMyRef1(localStorage.getItem("fromDate"));
    }
  }, [leadListDate]);

  return (
    <section className="content">
      <Card>
        <CardContent style={{ paddingBottom: "2px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              p: 1,
              m: 1,
              bgcolor: "background.paper",
              borderRadius: 1,
            }}
          >
            <div className="">
              <div className="form-group">
                <label>From </label> &nbsp;
                <input
                  onChange={(e) => setMyRef1(e.target.value)}
                  value={myRef1}
                  type="date"
                  id="searchEnquiryFromDate"
                  onInput={(e) => {
                    panelDispatch({ type: "FROM_DATE", value: e.target.value });
                    // setDateFromHandler(e.target.value);
                    setCreatedFromDateErrMsg(false);
                  }}
                  className="form-control"
                  style={{ display: "inline-block", width: "200px" }}
                  max={moment().format("YYYY-MM-DD")}
                />
              </div>
              <p style={{ color: "red" }}>
                {createdFromDateErrMsg ? "Please Select Something" : ""}
              </p>
            </div>

            {/* To date */}
            <div className="" style={{ marginLeft: "20px" }}>
              <div className="form-group">
                <label>To</label> &nbsp;
                <input
                  ref={myRef2}
                  type="date"
                  id="searchEnquiryToDate"
                  onInput={(e) => {
                    panelDispatch({ type: "TO_DATE", value: e.target.value });
                    // setDateToHandler(e.target.value);
                    setCreatedToDateErrMsg(false);
                    // LEAD_LC_STORAGE['to_date'] = e.target.value;
                  }}
                  className="form-control"
                  style={{ display: "inline-block", width: "200px" }}
                  min={myRef1}
                  max={moment().format("YYYY-MM-DD")}
                />
              </div>
              <p style={{ color: "red" }}>
                {" "}
                {createdToDateErrMsg ? "Please Select Something" : ""}
              </p>
            </div>

            {/* Keyword search */}
            <div className="" style={{ marginLeft: "20px" }}>
              <div className="form-group" style={{ width: "100%" }}>
                <input
                  ref={myRef3}
                  type="text"
                  placeholder="Search by name, number, email"
                  onInput={(e) => {
                    panelDispatch({ type: "KEYWORD", value: e.target.value });
                    // setKywd(e.target.value);
                    // LEAD_LC_STORAGE['keyword'] = e.target.value
                  }}
                  className="form-control"
                />
              </div>
            </div>

            {/* Assign to */}
            {operator.Feature.find(
              (fe) => fe.sub_feature_id === 4 && fe.is_edit && fe.is_view
            ) ? (
              <>
                <div className="" style={{ marginLeft: "20px" }}>
                  <div className="form-group">
                    <label>Assign To</label> &nbsp;
                    <select
                      disabled={!show}
                      id="searchFollowUps"
                      onChange={(e) => setAssignTo(e.target.value)}
                      ref={assignToSelect}
                      style={{
                        width: "66%",
                        minHeight: "40px",
                        display: "inline-block",
                        width: "200px",
                      }}
                    >
                      {/* <select> */}
                      <option selected disabled>
                        Select
                      </option>
                      {salesRoles.map((data) => {
                        return (
                          <option value={data?.id}>{data?.Username}</option>
                        );
                      })}
                    </select>
                  </div>
                  <p style={{ color: "red" }}>
                    {" "}
                    {createdToDateErrMsg ? "Please Select Something" : ""}
                  </p>
                </div>

                <div className="" style={{ marginLeft: "5px" }}>
                  <Button
                    style={{
                      backgroundColor: "#2d2d2d",
                      border: "none",
                      color: "white",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    type="submit"
                    onClick={() => handleSubmit()}
                    // disabled={!assignTo || !show}
                  >
                    Submit
                  </Button>
                </div>

                <div className="" style={{ marginLeft: "5px" }}>
                  <Button
                    style={{
                      backgroundColor: "#2d2d2d",
                      border: "none",
                      color: "white",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    type="clear"
                    onClick={() => handleClear()}
                    // disabled={!assignTo || !show}
                  >
                    Clear
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="" style={{ marginLeft: "5px" }}>
                  <Button
                    style={{
                      backgroundColor: "#2d2d2d",
                      border: "none",
                      color: "white",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    type="submit"
                    onClick={() => handleSubmit()}
                    // disabled={!assignTo || !show}
                  >
                    Submit
                  </Button>
                </div>

                <div className="" style={{ marginLeft: "5px" }}>
                  <Button
                    style={{
                      backgroundColor: "#2d2d2d",
                      border: "none",
                      color: "white",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    type="clear"
                    onClick={() => handleClear()}
                    // disabled={!assignTo || !show}
                  >
                    Clear
                  </Button>
                </div>
              </>
            )}

            <div className="" style={{ marginLeft: "80px" }}>
              <Button
                style={{
                  backgroundColor: "#2d2d2d",
                  border: "none",
                  color: "white",
                  height: "40px",
                  borderRadius: "5px",
                }}
                onClick={showPopupModel}
              >
                <TbUserPlus fontSize="25px" title="Add New Lead" />
              </Button>
            </div>

            <div className="" style={{ marginLeft: "20px" }}>
              <Button
                style={{
                  backgroundColor: "#2d2d2d",
                  border: "none",
                  color: "white",
                  height: "40px",
                  borderRadius: "5px",
                }}
                onClick={bulkUploadModel}
              >
                <SiMicrosoftexcel
                  fontSize="25px"
                  title="Add bulk leads from excel"
                />
              </Button>
            </div>

            <div className="" style={{ marginLeft: "20px" }}>
              <Button
                style={{
                  backgroundColor: "#2d2d2d",
                  border: "none",
                  color: "white",
                  height: "40px",
                  borderRadius: "5px",
                }}
                onClick={postListView}
              >
                <BiListCheck fontSize="25px" title="Save List View" />
              </Button>
            </div>

            <div className="" style={{ marginLeft: "20px" }}>
              {featureAccessible.find(
                (feature) =>
                  feature.sub_feature_id === 84 && feature.is_edit == true
              ) && (
                <Button
                  style={{
                    backgroundColor: "#2d2d2d",
                    border: "none",
                    color: "white",
                    height: "40px",
                    borderRadius: "5px",
                  }}
                  className="btn btn-danger"
                  onClick={() => openDialog(deleteIds)}
                  disabled={!show}
                >
                  <MdDelete fontSize="25px" title="Delete" />
                </Button>
              )}
            </div>
          </Box>
        </CardContent>
      </Card>

      {/* **HERE */}
      <ApiDataProvider>
        <LeadList
          // page={paginationModel.page}
          leadList={leadData}
          toggleReportGeneration={toggleReportGeneration}
          toggleLoading={toggleLoading}
          viewCallModal={viewCallModal}
          toggleRowSelection={toggleRowSelection}
          updateColumns={updateColumns}
          setDeleteIds={setDeleteIds}
          setAssignLeads={setAssignLeads}
          searchCriteria={searchCriteria}
          islead_Loading={islead_Loading}
          totalRecords={totalRecords}
          handleChange={handleChange}
          clearChildFilter={clearChildFilter}
          clearRowTicks={clearRowTicks}
          saveGrid={saveGrid}
        />
      </ApiDataProvider>

      {isHFReportGenerationLoading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            background: "#000000d5",
            zIndex: 1,
          }}
        >
          <div
            style={{
              width: "64px",
              height: "64px",
              border: "8px solid",
              borderColor: "#3d5af1 transparent #3d5af1 transparent",
              borderRadius: "50%",
              animation: "spin-anim 1.2s linear infinite",
            }}
          ></div>
          <p style={{ color: "blue" }}>H & F report generating</p>
        </div>
      )}

      {state.isFollowUpModalOpen && (
        <FollowupModal
          state={state}
          closeFollowUpModal={(e) => closeFollowUpModal(setState)}
          updateFollowUp={(e) =>
            updateFollowUp(e, contextState, state, setState)
          }
          deleteFollowUp={(e) =>
            deleteFollowUp(e, contextState, state, setState)
          }
          updateFollowUpStatus={(e) =>
            updateFollowUpStatus(e, contextState, state, setState)
          }
          currentCallUUID={state.currentCallUUID}
        />
      )}
      {state.isEmailModalOpen && (
        <EmailModal
          state={state}
          closeEmailModal={(e) => closeEmailModal(e, setState)}
          changeHandler={changeHandler}
        />
      )}

      {isCallModalShow && (
        <Modal show={isCallModalShow}>
          <Modal.Header closeButton>
            <Modal.Title>Call Option</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex" }}>
              <label>Lead Mobile Number: </label>{" "}
              <p>{callObjectInfo.current.leadPhoneNumber}</p>
              <button
                className="btn btn-default btn-xs no-focus"
                title="Call"
                onClick={() =>
                  callAction(callObjectInfo.current.leadPhoneNumber)
                }
              >
                <i
                  className="fas fa-phone-alt text-green"
                  aria-hidden="true"
                ></i>
              </button>
            </div>

            <div>
              <label>Speaker</label>
              <select value={selectedSpeaker} onChange={handleChangeSpeaker}>
                {speakerDevices.map((item) => (
                  <option value={item.deviceId}>{item.label}</option>
                ))}
              </select>
            </div>
            <br />
            <div>
              <label>Micro Phone</label>
              <select
                value={selectedMicroPhone}
                onChange={handleChangeMicroPhone}
              >
                {microphoneDevices.map((item) => (
                  <option value={item.deviceId}>{item.label}</option>
                ))}
              </select>
            </div>
            <br />
            <div>
              <label>Alternative Number: </label>
              <label /* className='col-md-8 floatclass' */>
                <PhoneInput
                  value={callObjectInfo.current.alternativeNumber}
                  onChange={(e) => {
                    // setCallObjectInfo(prevState => ({ ...prevState, alternativeNumber: "+" + e }))
                    callObjectInfo.current.alternativeNumber = "+" + e;
                  }}
                  country={"in"}
                  enableSearch={true}
                  inputStyle={{
                    paddingLeft: "15%",
                    backgroundColor: "#fce4e4",
                    borderColor: "#ff01416a",
                  }}
                />
              </label>
              <button
                className="btn btn-default btn-xs no-focus"
                title="Call"
                onClick={() => {
                  // setCallObjectInfo(prevState => ({...prevState, leadPhoneNumber: callObjectInfo.alternativeNumber }))
                  callAction(callObjectInfo.current.alternativeNumber);
                }}
              >
                <i
                  className="fas fa-phone-alt text-green"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setIsCallModalShow(false);
              }}
            >
              Close
            </Button>
            {/* <Button variant="primary" onClick={()=>handleClose()}>
                    Save Changes
                  </Button> */}
          </Modal.Footer>
        </Modal>
      )}

      {state.isModalOpen && (
        <BulkUpdateModal
          state={state}
          closeModal={closeInputModal}
          SelectedLeads={state.SelectedLeads}
          userList={state.userList}
          ATLAS_URI={ATLAS_URI}
        />
      )}

      {/* Delete lead confirmation */}
      <Dialog
        onFalse={(e) =>
          setState((prevState) => ({
            ...prevState,
            dialogInfo: { isOpened: false, text: "" },
          }))
        }
        onTrue={(e) => deleteFromTable(e)}
        dialogInfo={state.dialogInfo}
      />

      {show1 && <Popupmodel show1={show1} setShow1={setShow1} />}
      {openCalendar && (
        <PopupmodelCalendar show1={openCalendar} setShow1={setOpenCalendar} />
      )}

      {openUploadHAndFPdf && (
        <PopupPDFUpload
          show1={openUploadHAndFPdf}
          setShow1={setOpenUploadHAndFPdf}
          leadId={openUploadHAndFPdfLeadId}
        />
      )}

      {showBulkUploadModal && (
        <BulkUploadModal
          show1={showBulkUploadModal}
          setShow1={setShowBulkUploadModal}
        />
      )}
    </section>
  );
}

export default AllLeads;
