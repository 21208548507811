// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workoutTemplate {
  background-color: #fff;
  height: 1500px;
}

.back-btn {
  border: none;
  background-color: #2d2d2d;
  color: #fff;
  border-radius: 6px;
  padding: 8px 16px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Templates/WorkoutTemplate/WorkoutTemplate.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".workoutTemplate {\n  background-color: #fff;\n  height: 1500px;\n}\n\n.back-btn {\n  border: none;\n  background-color: #2d2d2d;\n  color: #fff;\n  border-radius: 6px;\n  padding: 8px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
