import ManageUsers from './ManageUsers'
import UserActivity from "./UserActivity";
import ManageRoles from './ManageRoles'
import UsersListAndLastLogin from './UsersListAndLastLogin'
import Notifications from './Notifications';

export {
    ManageRoles,
    ManageUsers,
    UsersListAndLastLogin,
    UserActivity,
    Notifications
}