export { AssignObject }
function AssignObject(dbLead, leadIn) {

    return {
        AssignedTo: dbLead.AssignedTo ? dbLead.AssignedTo : (leadIn.AssignedTo ? leadIn.AssignedTo : ""),
        call_status: dbLead.call_status ? dbLead.call_status : (leadIn.call_status ? leadIn.call_status : ""),
        Client: dbLead.Client ? dbLead.Client : (leadIn.Client ? leadIn.Client : null),
        ClientEmail: dbLead.ClientEmail ? dbLead.ClientEmail : (leadIn.ClientEmail ? leadIn.ClientEmail : ""),
        ClientName: dbLead.ClientName ? dbLead.ClientName : (leadIn.ClientName ? leadIn.ClientName : ""),
        ClientPhone: dbLead.ClientPhone ? dbLead.ClientPhone : (leadIn.ClientPhone ? leadIn.ClientPhone : ""),
        CreatedBy: dbLead.CreatedBy ? dbLead.CreatedBy : (leadIn.CreatedBy ? leadIn.CreatedBy : ""),
        FollowUpList: dbLead.FollowUpList ? dbLead.FollowUpList : (leadIn.FollowUpList ? leadIn.FollowUpList : []),
        FollowUps: dbLead.FollowUps ? dbLead.FollowUps : (leadIn.FollowUps ? leadIn.FollowUps : []),
        InterestedIn: dbLead.InterestedIn ? dbLead.InterestedIn : (leadIn.InterestedIn ? leadIn.InterestedIn : ""),
        LastFollowUpDate: dbLead.LastFollowUpDate ? dbLead.LastFollowUpDate : (leadIn.LastFollowUpDate ? leadIn.LastFollowUpDate : ""),
        LastRemarks: dbLead.LastRemarks ? dbLead.LastRemarks : (leadIn.LastRemarks ? leadIn.LastRemarks : ""),
        LeadDate: dbLead.LeadDate ? dbLead.LeadDate : (leadIn.LeadDate ? leadIn.LeadDate : ""),
        LeadStatus: dbLead.LeadStatus ? dbLead.LeadStatus : (leadIn.LeadStatus ? leadIn.LeadStatus : ""),
        LeadStatusID: dbLead.LeadStatusID ? dbLead.LeadStatusID : (leadIn.LeadStatusID ? leadIn.LeadStatusID : ""),
        Location: dbLead.Location ? dbLead.Location : (leadIn.Location ? leadIn.Location : ""),
        MaxArea: dbLead.MaxArea ? dbLead.MaxArea : (leadIn.MaxArea ? leadIn.MaxArea : ""),
        MaxBudget: dbLead.MaxBudget ? dbLead.MaxBudget : (leadIn.MaxBudget ? leadIn.MaxBudget : ""),
        MinArea: dbLead.MinArea ? dbLead.MinArea : (leadIn.MinArea ? leadIn.MinArea : ""),
        MinBudget: dbLead.MinBudget ? dbLead.MinBudget : (leadIn.MinBudget ? leadIn.MinBudget : ""),
        next_followup_date: dbLead.next_followup_date ? dbLead.next_followup_date : (leadIn.next_followup_date ? leadIn.next_followup_date : ""),
        PropertyType: dbLead.PropertyType ? dbLead.PropertyType : (leadIn.PropertyType ? leadIn.PropertyType : ""),
        PropertyTypes: dbLead.PropertyTypes ? dbLead.PropertyTypes : (leadIn.PropertyTypes ? leadIn.PropertyTypes : ""),
        Remarks: dbLead.Remarks ? dbLead.Remarks : (leadIn.Remarks ? leadIn.Remarks : []),
        RequiredLoan: dbLead.RequiredLoan ? dbLead.RequiredLoan : (leadIn.RequiredLoan ? leadIn.RequiredLoan : ""),
        RequiredLocation: dbLead.RequiredLocation ? dbLead.RequiredLocation : (leadIn.RequiredLocation ? leadIn.RequiredLocation : ""),
        Role: dbLead.Role ? dbLead.Role : (leadIn.Role ? leadIn.Role : ""),
        Source: dbLead.Source ? dbLead.Source : (leadIn.Source ? leadIn.Source : ""),
        Sources: dbLead.Sources ? dbLead.Sources : (leadIn.Sources ? leadIn.Sources : ""),
        User: dbLead.User ? dbLead.User : (leadIn.User ? leadIn.User : ""),
        VisitInfos: dbLead.VisitInfos ? dbLead.VisitInfos : (leadIn.VisitInfos ? leadIn.VisitInfos : []),
        createdAt: dbLead.createdAt ? dbLead.createdAt : (leadIn.createdAt ? leadIn.createdAt : ""),
        id: dbLead.id ? dbLead.id.toString() : "",
        updatedAt: dbLead.updatedAt ? dbLead.updatedAt : (leadIn.updatedAt ? leadIn.updatedAt : ""),
        Payments: dbLead.Payments ? dbLead.Payments : (leadIn.Payments ? leadIn.Payments : []),
        lead_payments: dbLead.lead_payments ? dbLead.lead_payments : (leadIn.lead_payments ? leadIn.lead_payments : []),
    }
}