import React from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import moment from 'moment';

const SavedFormData = ({ data, programs, formats, levels, trainers }) => {
    return (
        <Container>
            {/* <p>{JSON.stringify(data)}</p> */}
            

            <Row>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Session Name</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <p>{data.session_name}</p>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col md={4} className='mt-2'>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Program Name</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <Form.Control as="select" disabled value={data.program_id}>
                            {
                                programs.map(e => {
                                    return <option value={e.id} key={e.id}>{e.name}</option>
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>

            <Row className='mt-2'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Session Details</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <p>{data.session_details}</p>
                    </Form.Group>
                </Col>
            </Row>

            {/* format */}
            <Row className='mt-2'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Format</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <Form.Control as="select" name="format_id" value={data.format_id} disabled>
                            <option value="" selected>Choose a format</option>
                            {
                                formats.map(e => {
                                    return <option value={e.id} key={e.id}>{e.name}</option>
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>

            {/* level */}
            <Row className='mt-2'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Level</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <Form.Control as="select" value={data.level_id} disabled>
                            <option value="" selected>Choose a format</option>
                            {
                                levels.map(e => {
                                    return <option value={e.id} key={e.id}>{e.name}</option>
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>

            {/* start date */}
            <Row className='mt-3'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Start Date</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <p>{data.start_date}</p>
                    </Form.Group>
                </Col>
            </Row>

            {/* start time */}
            <Row className='mt-2'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Start Time</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <p>{moment(data.start_time, 'HH:mm:ss').format('hh:mm A')}</p>
                    </Form.Group>
                </Col>
            </Row>

            {/* timezone */}
            <Row className='mt-2'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Time Zone</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <p>{data.timezone}</p>
                    </Form.Group>
                </Col>
            </Row>

            {/* duration */}
            <Row className='mt-2'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Duration</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <p>{data.duration}</p>
                    </Form.Group>
                </Col>
            </Row>

            {/* duration unit */}
            <Row className='mt-2'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Duration Unit</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <Form.Control as="select" name="duration_unit" value={data.duration_unit} disabled>
                            <option value="minute">Minutes</option>
                            <option value="hour">Hours</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>

            {/* coach */}
            <Row className='mt-2'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Coach</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <Form.Control as="select" name="trainer_id" value={data.trainer_id} disabled>
                            <option value="" selected>Select</option>
                            {
                                trainers.map(e => {
                                    return <option value={e.id} key={e.id}>{e.firstName} {e.lastName}</option>
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>

            {/* coach details */}
            <Row className='mt-3'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Coach Details</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <div>{data.coach_details}</div>
                    </Form.Group>
                </Col>
            </Row>

            {/* equipments required */}
            <Row className='mt-3'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Equipments Required</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <div>{data.equipments_required}</div>
                    </Form.Group>
                </Col>
            </Row>

            {/* benefits */}
            <Row className='mt-2'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Benefits</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <div>{data.benefits}</div>
                    </Form.Group>
                </Col>
            </Row>

            {/* routine */}
            <Row className='mt-2'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Routine</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <div>{data.routine}</div>
                    </Form.Group>
                </Col>
            </Row>

            {/* who can attend */}
            <Row className='mt-2'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Who can attend the session?</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <div>{data.who_should_attend}</div>
                    </Form.Group>
                </Col>
            </Row>

            {/* who should avoid */}
            <Row className='mt-2'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Who should avoid?</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <div>{data.who_should_avoid}</div>
                    </Form.Group>
                </Col>
            </Row>

            {/* repeat type */}
            <Row className='mt-2'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Is Repeating?</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <div>{data.is_recurring? 'Yes': 'No'}</div>
                    </Form.Group>
                </Col>
            </Row>

            {/* repeat by */}
            { data.is_recurring && <Row className='mt-2'>
                <Col md={4}>
                    <Form.Group controlId="sessionName">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Repeat Every</Form.Label>
                    </Form.Group>
                </Col>

                <Col md={8}>
                    <Form.Group controlId="sessionProgram">
                        <div>{data.repeat_amount} {data.repeat_by}</div>
                    </Form.Group>
                </Col>
            </Row>}

        </Container>
    );
};

export default SavedFormData;
