// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-container {
  width: 100%;
}

.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.accordion__header {
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  padding: 10px;
  padding-right: 20px;
}

.accordion-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.accordion-title-container h5 {
  margin: 0;
  padding: 0;
}

.expand-icon {
  transition: transform 0.3s ease;
  -webkit-user-select: none;
          user-select: none;
  display: inline-block;
}

.expand-icon.open {
  transform: rotate(180deg);
}
`, "",{"version":3,"sources":["webpack://./src/components/Accordion/AccordionList.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,iDAAiD;EACjD,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,+BAA+B;EAC/B,yBAAiB;UAAjB,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".history-container {\n  width: 100%;\n}\n\n.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {\n  margin-left: 10px;\n  margin-right: 10px;\n  margin-bottom: 20px;\n}\n\n.accordion__header {\n  width: 100%;\n  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n  border-radius: 10px;\n  padding: 10px;\n  padding-right: 20px;\n}\n\n.accordion-title-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 20px;\n  margin-bottom: 20px;\n  cursor: pointer;\n}\n.accordion-title-container h5 {\n  margin: 0;\n  padding: 0;\n}\n\n.expand-icon {\n  transition: transform 0.3s ease;\n  user-select: none;\n  display: inline-block;\n}\n\n.expand-icon.open {\n  transform: rotate(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
