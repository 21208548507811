import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import "../LiveSession.css";

import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import ImageIcon from "@mui/icons-material/Image";
import LoopIcon from "@mui/icons-material/Loop";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { toast } from "react-toastify";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import Button from "../../../components/CustomButton/Button";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import dayjs from "dayjs";

const Backdrop = (props) => {
  return <div className={"session-backdrop"} onClick={props.closeModel}></div>;
};

const SessionModal = ({
  closeModel,
  selectedRow,
  view,
  onSession,
  disableBtn,
}) => {
  const fileInputRef = useRef(null);
  const [selectImage, setSelectedImage] = useState("");
  const [activeDay, setActiveDays] = useState([]);
  const [sessionType, setSessionType] = useState("single");
  const [isToday, setIsToday] = useState(false);

  const [isRecording, setIsRecording] = useState(true);
  const [isVisibility, setIsVisibility] = useState(false);
  const [program, setProgram] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [sessionImage, setSessionImage] = useState("");
  const [sessionDetails, setSessionDetails] = useState("");
  const [format, setFormat] = useState("");
  const [level, setLevel] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [timeZone, setTimezone] = useState("");
  const [duration, setDuration] = useState("");
  const [durationUnit, setDurationUnit] = useState("");
  const [coachName, setCoachName] = useState("");
  const [moderatorName, setModeratorName] = useState("");

  const [every, setEvery] = useState(1);
  const [endDate, setEndDate] = useState(null);
  const [repeat, setRepeat] = useState("week");

  const [coachDetails, setCoachDetails] = useState("");
  const [equipment, setEquipment] = useState("");
  const [benefits, setBenefits] = useState("");
  const [routine, setRoutine] = useState("");
  const [attendSession, setAttendSession] = useState("");
  const [avoid, setAvoid] = useState("");

  const [editRowFlag, setEditRowFlag] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  // get values

  const [allTimeZone, setAllTimeZone] = useState([]);
  const [getformat, setGetFormat] = useState([]);
  const [getLevel, setGetLevel] = useState([]);
  const [getcoach, setGetCoach] = useState([]);
  const [getLiveProgram, setGetLiveProgram] = useState([]);

  const days = [
    { id: 0, name: "sunday" },
    { id: 1, name: "monday" },
    { id: 2, name: "tuesday" },
    { id: 3, name: "wednesday" },
    { id: 4, name: "thursday" },
    { id: 5, name: "friday" },
    { id: 6, name: "saturday" },
  ];

  const zoomList = [
    { host: "primary", name: "Primary" },
    { host: "host_1", name: "Host 01" },
    { host: "host_2", name: "Host 02" },
    { host: "host_4", name: "Host 04" },
    { host: "host_5", name: "Host 05" },
  ];
  const [selectedZoom, setSelectedZoom] = useState("");

  const handleDayClick = (value, index) => {
    const isActive = activeDay.includes(value);
    if (isActive) {
      setActiveDays(activeDay.filter((day) => day !== value));
    } else {
      setActiveDays([...activeDay, value]);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.size < 2097152) {
      setSessionImage(file);
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.onload = (e) => {
          setSelectedImage(e.target.result);
        };

        reader.readAsDataURL(file);
      }
    } else {
      fileInputRef.current.value = "";
      toast.error("Please select an image less than 2MB", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleSession = (value) => {
    setSessionType(value);
  };

  const dateFormat = (value) => {
    const date = new Date(value);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  const timeFormat = (value) => {
    const date = new Date(value);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const handleProgramName = (e) => {
    const selectedName = getLiveProgram.filter(
      (live) => live.id === e.target.value
    )[0];

    setProgram(e.target.value);
    setSessionName(selectedName.name);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsDisable(true);
    let url;
    const coachObject = getcoach.filter((item) => item.id === coachName)[0];
    const request = {
      recording: isRecording,
      visibility: isVisibility,
      program_id: program,
      session_name: sessionName,
      session_image: sessionImage,
      session_details: sessionDetails,
      format_id: format,
      level_id: level,
      start_date: dateFormat(startDate),
      start_time: timeFormat(startTime),
      timezone: timeZone,
      duration: duration,
      duration_unit: durationUnit,
      coach_name: coachObject?.firstName,
      trainer_id: +coachObject?.id,
      is_recurring: sessionType === "single" ? false : true,
      moderator_name: moderatorName,
      interval_value: every,
      recurrence_ends_at: endDate ? dateFormat(endDate) : "",
      repeat_on_weekdays: activeDay,
      repeat_by: repeat,
      coach_details: coachDetails,
      equipments_required: equipment,
      benefits: benefits,
      routine: routine,
      who_should_attend: attendSession,
      who_should_avoid: avoid,
      host: selectedZoom,
    };

    if (sessionType === "single") {
      delete request.every;
      delete request.recurrence_ends_at;
      delete request.weekday;
      delete request.repeat;
    }

    if (selectedRow) {
      let id = selectedRow?.meeting?.id;
      if (selectedRow?.updated_session_details) {
        id = selectedRow?.updated_session_details?.id;
      }

      url = `/updateProgramSession/${id}`;
    } else {
      url = "/createProgramSession";
    }

    const formData = new FormData();

    for (const key in request) {
      if (request.repeat_on_weekdays && key === "repeat_on_weekdays") {
        request[key].forEach((day, index) => {
          formData.append(`repeat_on_weekdays[${index}]`, day);
        });
      } else {
        formData.append(key, request[key]);
      }
    }

    // for (const pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1], "checkkkk");
    // }

    for (const key in request) {
      if (request[key] === "") {
        toast.error(`One of the field is empty. Please fill it.`, {
          position: toast.POSITION.TOP_CENTER,
        });
        setIsDisable(false);
        return;
      }
    }

    const submitData = await axiosFetch({
      url: url,
      method: "post",
      requestConfig: formData,
    });

    if (submitData.status === 200) {
      toast.success(submitData.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      closeModel();
      onSession("upcoming");
    } else {
      toast.error(submitData.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getAllSelectBox = async () => {
    const allTimeData = await axiosFetch({
      url: "/getTimeZones",
      method: "get",
    });

    const formatAndLevel = await axiosFetch({
      url: "/admin-programSessionConstants",
      method: "get",
    });

    const coachName = await axiosFetch({
      url: "/admin-programSessionCoaches",
      method: "get",
    });

    const liveProgram = await axiosFetch({
      url: "/getLiveCoachingPrograms",
      method: "get",
    });

    setAllTimeZone(allTimeData.data);
    setGetFormat(formatAndLevel.data.body.VMAX_WORKOUT_FORMAT);
    setGetLevel(formatAndLevel.data.body.VMAX_WORKOUT_LEVEL);
    setGetCoach(coachName.data.body);
    setGetLiveProgram(liveProgram.data);
  };

  useEffect(() => {
    if (startDate) {
      setIsToday(dateFormat(startDate) === dateFormat(new Date()));
    }
  }, [startDate]);

  useEffect(() => {
    getAllSelectBox();
  }, []);

  useEffect(() => {
    if (coachName) {
      const value = getcoach.filter((item) => item.id === coachName)[0];

      setCoachDetails(value?.about);
    }
  }, [coachName]);

  useEffect(() => {
    if (selectedRow) {
      setIsRecording(selectedRow.meeting.recording);
      setIsVisibility(selectedRow.meeting.visibility);
      setProgram(selectedRow.meeting.program_id);
      setSessionName(selectedRow.meeting.session_name);
      setSelectedImage(selectedRow.meeting.session_image);
      setSessionImage(selectedRow.meeting.session_image);
      setSessionDetails(selectedRow.meeting.session_details);
      setFormat(selectedRow.meeting.format_id);
      setLevel(selectedRow.meeting.level_id);
      setStartDate(dayjs(new Date(selectedRow.meeting.start_date)));
      setStartTime(
        dayjs(
          `${selectedRow.meeting.start_date} ${selectedRow.meeting.start_time}`
        )
      );
      setTimezone(selectedRow.meeting.timezone);
      setDuration(selectedRow.meeting.duration);
      setDurationUnit(selectedRow.meeting.duration_unit);
      setCoachName(selectedRow.meeting.trainer_id);
      setModeratorName(selectedRow.meeting.moderator_name);
      setCoachDetails(selectedRow.meeting.coach_details);
      setEquipment(selectedRow.meeting.equipments_required);
      setBenefits(selectedRow.meeting.benefits);
      setRoutine(selectedRow.meeting.routine);
      setAttendSession(selectedRow.meeting.who_should_attend);
      setAvoid(selectedRow.meeting.who_should_avoid);
      setSessionType(!selectedRow.meeting.is_recurring ? "single" : "repeat");
      setEvery(selectedRow.interval_value);

      const editZoom = zoomList.filter(
        (value) => value.host === selectedRow.meeting.host
      )[0];

      setSelectedZoom(editZoom.host);

      if (selectedRow.meeting.recurrence_ends_at)
        setEndDate(dayjs(new Date(selectedRow.meeting.recurrence_ends_at)));

      setRepeat(selectedRow.repeat_by);
      setActiveDays(selectedRow.repeat_on_weekdays);

      setEditRowFlag(true);
    }
  }, [selectedRow]);

  return (
    <form className="session-card" autoComplete="off" onSubmit={submitHandler}>
      <div className="session-header">
        <h4>New Session</h4>
      </div>
      <div className="zoom-container">
        <div className="session-subHeader">
          <div>
            <span>Recording</span>
            <Switch
              onChange={(e) => setIsRecording(e.target.checked)}
              checked={isRecording}
              disabled={view}
            />
          </div>
          <div>
            <span>Visibility </span>
            <Switch
              disabled={view ? view : !isRecording}
              onChange={(e) => setIsVisibility(e.target.checked)}
              checked={isVisibility}
            />
          </div>
        </div>
        <div className="zoom">
          <label>Zoom Name</label>
          <FormControl sx={{ width: "50%" }} size="small">
            <Select
              onChange={(e) => setSelectedZoom(e.target.value)}
              labelId="demo-simple-select-label"
              id="demo-select-small"
              value={selectedZoom}
              disabled={editRowFlag}
            >
              {zoomList.map((item) => (
                <MenuItem key={item.host} value={item.host}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="session-row">
        <div className="session-col">
          <label>Program</label>
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              onChange={(e) => handleProgramName(e)}
              labelId="demo-simple-select-label"
              id="demo-select-small"
              value={program}
              disabled={editRowFlag}
            >
              {getLiveProgram.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="session-col">
          <label>Session Name</label>
          <TextField
            id="outlined-size-small"
            fullWidth
            size="small"
            onChange={(e) => setSessionName(e.target.value)}
            value={sessionName}
            disabled={view}
          />
        </div>
      </div>
      <div className="session-row sub-margin">
        <div className="session-col">
          <div className="choose-img-container">
            <div className="choose-img-input">
              <label>Session Image</label>
              <input
                className="form-control choose-img"
                type="file"
                id="formFile"
                ref={fileInputRef}
                onChange={handleFileChange}
                disabled={view}
              />
            </div>
            <div className="choose-img-icon">
              {selectImage ? (
                <img
                  alt="choose-image"
                  className="choose-image"
                  src={selectImage}
                />
              ) : (
                <ImageIcon
                  style={{
                    fontSize: "100px",
                    marginLeft: "50px",
                    color: "#EBEBEB",
                  }}
                />
              )}
            </div>
          </div>

          {/* <div className="session-col"> */}
          <div className="session-row sub-margin">
            <div className="session-col">
              <label>Format</label>
              <FormControl sx={{ width: "100%" }} size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-select-small"
                  onChange={(e) => setFormat(e.target.value)}
                  value={format}
                  disabled={view}
                >
                  {getformat.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="session-col">
              <label>Level</label>
              <FormControl sx={{ width: "100%" }} size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-select-small"
                  onChange={(e) => setLevel(e.target.value)}
                  value={level}
                  disabled={view}
                >
                  {getLevel.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          {/* </div> */}
          <div className="session-row">
            <div className="session-col session-mode">
              <Button
                type={"button"}
                inline
                className={`session-btn ${
                  sessionType !== "single" ? "repeat" : ""
                }`}
                // className={"session-btn"}
                onClick={() => handleSession("single")}
                disabled={view || disableBtn}
              >
                <span>
                  <KeyboardBackspaceIcon />
                </span>
                <span>Single Session</span>
              </Button>
              <Button
                type={"button"}
                disabled={view || disableBtn}
                inline
                className={`session-btn ${
                  sessionType !== "repeat" ? "repeat" : ""
                }`}
                //className={"session-btn repeat"}
                onClick={() => handleSession("repeat")}
              >
                <span>
                  <LoopIcon />
                </span>
                <span>Repeating Session</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="session-col">
          <label>Session Details</label>
          <textarea
            className="form-control choose-img"
            id="floatingTextarea2"
            minLength={5}
            style={{
              height: "300px",
            }}
            onChange={(e) => setSessionDetails(e.target.value)}
            value={sessionDetails}
            disabled={view}
          ></textarea>
        </div>
      </div>
      <div className="session-row format-input sub-margin">
        <div className="session-col">
          <label>Start Date</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                disablePast
                onChange={(e) => setStartDate(e.$d)}
                value={startDate}
                disabled={view}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div className="session-col">
          <label>Start Time</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                disablePast={isToday}
                onChange={(e) => setStartTime(e.$d)}
                value={startTime}
                disabled={view}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div className="session-col">
          <label>Time Zone</label>
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-select-small"
              onChange={(e) => setTimezone(e.target.value)}
              value={timeZone}
              disabled={view}
            >
              {allTimeZone.map((time, index) => (
                <MenuItem value={time} key={index}>
                  {time}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="session-col">
          <label>Duration</label>
          <TextField
            id="outlined-size-small"
            fullWidth
            size="small"
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            onChange={(e) => setDuration(e.target.value)}
            value={duration}
            disabled={view}
          />
        </div>
      </div>
      <div className="session-row format-input sub-margin">
        <div className="session-col">
          <label>Duration Unit</label>
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-select-small"
              onChange={(e) => setDurationUnit(e.target.value)}
              value={durationUnit}
              disabled={view}
            >
              <MenuItem value={"minute"}>Minutes</MenuItem>
              <MenuItem value={"hour"}>Hours</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="session-col">
          <label>Coach Name</label>
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-select-small"
              onChange={(e) => setCoachName(e.target.value)}
              value={coachName}
              disabled={view}
            >
              {getcoach.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.firstName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="session-col">
          <label>Moderator Name</label>
          <TextField
            id="outlined-size-small"
            fullWidth
            size="small"
            onChange={(e) => setModeratorName(e.target.value)}
            value={moderatorName}
            disabled={view}
          />
        </div>
        <div className="session-col">
          {sessionType === "repeat" && (
            <>
              <label>Every</label>
              <TextField
                id="outlined-size-small"
                fullWidth
                disabled={view ? view : repeat === "week" ? true : false}
                size="small"
                onChange={(e) => setEvery(e.target.value)}
                type="number"
                value={every}
                InputProps={{
                  inputProps: { min: 1 },
                }}
              />
            </>
          )}
        </div>
      </div>

      {sessionType === "repeat" && (
        <div className="session-row">
          <div className="session-col custom-col format-input">
            <label>End Date</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  disablePast
                  onChange={(e) => setEndDate(e.$d)}
                  value={endDate}
                  disabled={view}
                  minDate={dayjs(startDate)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div className="session-col custom-col">
            <label>Repeat by</label>
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-select-small"
                onChange={(e) => setRepeat(e.target.value)}
                value={repeat}
                disabled={view}
              >
                <MenuItem value={"day"}>Day</MenuItem>
                <MenuItem value={"week"}>Week</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="session-col session-week-btn">
            {repeat === "week" &&
              days.map((value, index) => (
                <button
                  type="button"
                  key={value.id}
                  className={` ${
                    activeDay.includes(value.name) ? "active-day" : ""
                  }`}
                  onClick={() => handleDayClick(value.name, index)}
                >
                  {value.name.charAt(0).toUpperCase()}
                </button>
              ))}
          </div>
          <div className="session-col empty"> </div>
        </div>
      )}

      <div className="session-row">
        <div className="session-col sub-margin">
          <label>Coach Details</label>
          <textarea
            className="form-control choose-img"
            id="floatingTextarea2"
            minLength={5}
            style={{
              height: "100px",
            }}
            onChange={(e) => setCoachDetails(e.target.value)}
            value={coachDetails}
            disabled={view}
          ></textarea>
        </div>
        <div className="session-col sub-margin">
          <label>Equipment's Required</label>
          <textarea
            className="form-control choose-img"
            id="floatingTextarea2"
            minLength={5}
            style={{
              height: "100px",
            }}
            onChange={(e) => setEquipment(e.target.value)}
            value={equipment}
            disabled={view}
          ></textarea>
        </div>
      </div>

      <div className="session-row">
        <div className="session-col sub-margin">
          <label>Benefits</label>
          <textarea
            className="form-control choose-img"
            id="floatingTextarea2"
            minLength={5}
            style={{
              height: "100px",
            }}
            onChange={(e) => setBenefits(e.target.value)}
            value={benefits}
            disabled={view}
          ></textarea>
        </div>
        <div className="session-col sub-margin">
          <label>Routine</label>
          <textarea
            className="form-control choose-img"
            id="floatingTextarea2"
            minLength={5}
            style={{
              height: "100px",
            }}
            onChange={(e) => setRoutine(e.target.value)}
            value={routine}
            disabled={view}
          ></textarea>
        </div>
      </div>

      <div className="session-row">
        <div className="session-col sub-margin">
          <label>Who can attend the session?</label>
          <textarea
            className="form-control choose-img"
            id="floatingTextarea2"
            minLength={5}
            style={{
              height: "100px",
            }}
            onChange={(e) => setAttendSession(e.target.value)}
            value={attendSession}
            disabled={view}
          ></textarea>
        </div>
        <div className="session-col sub-margin">
          <label>Who should avoid?</label>
          <textarea
            className="form-control choose-img"
            id="floatingTextarea2"
            minLength={5}
            style={{
              height: "100px",
            }}
            onChange={(e) => setAvoid(e.target.value)}
            value={avoid}
            disabled={view}
          ></textarea>
        </div>
      </div>

      <div className="session-btn-container sub-margin">
        <Button
          outline
          type={"button"}
          className={`session-btn-confirm`}
          onClick={closeModel}
        >
          Cancel
        </Button>
        <Button
          inline
          disabled={view ? view : isDisable}
          type={"submit"}
          className={"session-btn-confirm"}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

const CreateSessionForm = (props) => {
  const backdropRoot = document.getElementById("backdrop-root");
  if (!backdropRoot) {
    return null;
  }
  const editTableModal = document.getElementById("open__modal-root");
  if (!editTableModal) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop closeModel={props.closeModel} />,
        backdropRoot
      )}
      {ReactDOM.createPortal(
        <SessionModal
          closeModel={props.closeModel}
          selectedRow={props.selectedRow}
          view={props.view}
          onSession={props.onSession}
          disableBtn={props.disableBtn}
        />,
        editTableModal
      )}
    </>
  );
};

export default CreateSessionForm;
