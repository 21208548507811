// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leftSidealignment{
  position: sticky;
  left: 300px;
  font-size: 18px;
  /* right: 200px; */
  z-index: 1;
}

.fontSize{
  font-size: 180px;
}

.reduce_length{

  position: sticky;
  left: 300px;
  font-size: 18px;
  /* right: 200px; */
  inline-size: 450px;

  word-wrap: break-word;
  z-index: 1;
  
}

#textFormat{
  font-size: 18px;
}
.rightSidealignment{
  position: sticky;
  left: 1200px;
  /* flex: 1; */
  
  font-size: 18px;
  /* right: 200px; */
  z-index: 2;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Leads/leadview.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,gBAAgB;EAChB,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,kBAAkB;;EAElB,qBAAqB;EACrB,UAAU;;AAEZ;;AAEA;EACE,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;;EAEb,eAAe;EACf,kBAAkB;EAClB,UAAU;AACZ","sourcesContent":[".leftSidealignment{\n  position: sticky;\n  left: 300px;\n  font-size: 18px;\n  /* right: 200px; */\n  z-index: 1;\n}\n\n.fontSize{\n  font-size: 180px;\n}\n\n.reduce_length{\n\n  position: sticky;\n  left: 300px;\n  font-size: 18px;\n  /* right: 200px; */\n  inline-size: 450px;\n\n  word-wrap: break-word;\n  z-index: 1;\n  \n}\n\n#textFormat{\n  font-size: 18px;\n}\n.rightSidealignment{\n  position: sticky;\n  left: 1200px;\n  /* flex: 1; */\n  \n  font-size: 18px;\n  /* right: 200px; */\n  z-index: 2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
