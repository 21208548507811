const ClientDietPlanView = (props) => {
  // const contextState = useContext(stateContext)
  // const { configToken } = contextState.state;

  // useEffect(() => {
  //     axios.get(`${ATLAS_URI}/getDietPlanByPlanId/${props.dietPlanId}`, configToken).then(res => {
  //         if (res.status == 200) {
  //             console.log('res', res)
  //         }
  //     })
  // })

  const headingStyle = {
    fontFamily: "Lato",
    fontWeight: "400",
    fontSize: "14px",
    display: "flex",
  };

  const valueStyle = {
    fontFamily: "Lato",
    fontWeight: "700",
    fontSize: "14px",
  };

  const nutritionStyle = {
    boxSizing: "border-box",
    position: "absolute",
    width: 79,
    height: "50px",
    background: "#FAFAFA",
    borderRadius: 4,
  };

  const nutritionTextStyle = {
    textAlign: "center",
  };

  const mealTableFonts = {
    height: "13px",
    fontSize: "14px",
    fontWeight: "700",
  };

  const tableStyle = {
    border: "1px solid #dddddd",
  };

  const mealNames = [];
  const food_menu = props.dietPlanInfo?.dietPlanDetails?.[0]?.food_menu?.map(
    (foodMenu) => {
      let isRepeat = false;
      if (mealNames.indexOf(foodMenu.name) == -1) {
        mealNames.push(foodMenu.name);
      } else {
        isRepeat = true;
      }
      return { ...foodMenu, isRepeat };
    }
  );

  const totalNutrition = food_menu
    ?.filter((foodMenu) => !foodMenu.isRepeat)
    .map((foodMenu) => foodMenu.total)
    .reduce(
      (acc, foodDetail) => {
        acc.carbs += parseFloat(foodDetail.carbs);
        acc.fats += parseFloat(foodDetail.fats);
        acc.protein += parseFloat(foodDetail.protein);
        acc.calories += parseFloat(foodDetail.calories);
        acc.fibre += parseFloat(foodDetail.fibre);

        return acc;
      },
      { carbs: 0, fats: 0, protein: 0, calories: 0, fibre: 0 }
    );

  console.log("totalNutrition:::: ", totalNutrition);

  return (
    <div
      style={{
        backgroundColor: "white",
        fontSize: "14px",
        width: "800px",
      }}
    >
      <div>
        <section
          className="row"
          style={{
            //   backgroundColor: "red",
            fontSize: "14px",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              background: "azure",
            }}
          >
            <div className="row">
              <div
                style={{
                  float: "left",
                  width: "50%",
                }}
              >
                <div className="row">
                  <p style={headingStyle}>
                    Client Name:{" "}
                    <span style={valueStyle}>
                      {props.dietPlanInfo?.dietPlan?.Lead?.name}
                    </span>
                  </p>
                  <p style={headingStyle}>
                    Height:{" "}
                    <span style={valueStyle}>
                      {props.dietPlanInfo?.dietPlan?.Lead?.height} (
                      {props.dietPlanInfo?.dietPlan?.Lead?.height_unit == 0
                        ? `cm`
                        : `ft'in`}
                      )
                    </span>
                  </p>
                  <p style={headingStyle}>
                    Weight:{" "}
                    <span style={valueStyle}>
                      {props.dietPlanInfo?.dietPlan?.Lead?.current_weight} (
                      {props.dietPlanInfo?.dietPlan?.Lead?.weight_unit == 0
                        ? `kg`
                        : `lb`}
                      )
                    </span>
                  </p>
                  <p style={headingStyle}>
                    Age:{" "}
                    <span style={valueStyle}>
                      {props.dietPlanInfo?.dietPlan?.Lead?.age}
                    </span>
                  </p>
                </div>
              </div>
              <div
                style={{
                  float: "right",
                  width: "50%",
                }}
              >
                <div className="row">
                  <p style={headingStyle}>
                    Primary Fitness Goals: <span style={valueStyle}>20</span>
                  </p>
                  <p style={headingStyle}>
                    Diet Restrictions: <span style={valueStyle}>Sea Foods</span>
                  </p>
                  <p style={headingStyle}>
                    Health Issues: <span style={valueStyle}>No</span>
                  </p>
                  <p style={headingStyle}>
                    Diet Type: <span style={valueStyle}>Non-Vegetarian</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div style={{ paddingTop: "50px" }}>
        <section
          className="row"
          style={{
            fontSize: "14px",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              background: "azure",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p>
                Nutrionist Name:{" "}
                <span>
                  {
                    props.dietPlanInfo?.dietPlan?.Lead?.ClientInfo
                      ?.ClientDietitian?.Name
                  }
                </span>
              </p>
              <div style={{ display: "flex", width: "100%" }}>
                <p style={{ width: "20%" }}>Nutrition Information:</p>
                <div style={{ display: "flex", gap: "20px", width: "80%" }}>
                  <span
                    style={{
                      border: "1px solid #7777",
                    }}
                  >
                    <p>Calories</p>
                    <p>{totalNutrition?.calories.toFixed(3)} cal</p>
                  </span>
                  <span
                    style={{
                      border: "1px solid #7777",
                    }}
                  >
                    <p>Protein</p>
                    <p>{totalNutrition?.protein.toFixed(2)}</p>
                  </span>
                  <span
                    style={{
                      border: "1px solid #7777",
                    }}
                  >
                    <p>Water Intake</p>
                    <p>3.5-4 L</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div style={{ paddingTop: "50px" }}>
        <section
          className="row"
          style={{
            fontSize: "14px",
            justifyContent: "center",
          }}
        >
          {food_menu?.map((t, id) => (
            <div
              style={{
                width: "100%",
                background: "azure",
              }}
            >
              {food_menu.findIndex((each) => each.name == t.name) == id && (
                <p style={mealTableFonts}>
                  {t.name} ({t.time})
                </p>
              )}

              <table
                style={{
                  minWidth: "100%",
                  fontSize: "15px",
                  border: "1px solid #dddd",
                }}
              >
                {food_menu.findIndex((each) => each.name == t.name) == id && (
                  <tr>
                    <th style={tableStyle}>Meal</th>
                    <th style={tableStyle}>Specification</th>
                  </tr>
                )}

                {!t.isRepeat && (
                  <tr>
                    <td style={tableStyle}>
                      {t.food
                        .map((a) => `${a.food_item}-${a.quantity} ${a.unit}`)
                        .toString()}
                    </td>

                    <td style={tableStyle}>{t.note}</td>
                  </tr>
                )}

                {t.isRepeat && (
                  <>
                    <tr>
                      <td>or</td>
                    </tr>

                    <tr>
                      <td style={tableStyle}>
                        {t.food
                          .map((a) => `${a.food_item}-${a.quantity} ${a.unit}`)
                          .toString()}
                      </td>
                      <td style={tableStyle}>{t.note}</td>
                    </tr>
                  </>
                )}
              </table>
            </div>
          ))}
        </section>
      </div>

      <div style={{ paddingTop: "50px" }}>
        <section
          className="row"
          style={{
            fontSize: "14px",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              background: "azure",
            }}
          >
            <div
              style={{
                width: "100%",
                border: "1px solid black",
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "10px",
                }}
              >
                Vegetables To Be Included
              </div>
              <div
                style={{
                  padding: "20px",
                }}
              >
                Coming soon
              </div>
            </div>
          </div>
        </section>
      </div>

      <div style={{ paddingTop: "50px" }}>
        <section
          className="row"
          style={{
            fontSize: "14px",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              background: "azure",
            }}
          >
            <div
              style={{
                width: "100%",
                border: "1px solid black",
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "10px",
                }}
              >
                Fruits To Be Included
              </div>
              <div
                style={{
                  padding: "20px",
                }}
              >
                Coming soon
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ClientDietPlanView;
