

const basicinfoform = [
    {
        "type": "TextField",
        "label": "Name",
        "name": "name",
        "rules": {

        }
    },
    {
        "type": "TextField",
        "label": "Email ID",
        "name": "email_id",
        "rules": {

        }
    },
    {
        "type": "TextField",
        "label": "Phone Number",
        "name": "contact_number",
        "rules": {

        }
    },
    {
        "type": "Autocomplete",
        "label": "Gender",
        "name": "name",
        "rules": {

        }
    },
    {
        "type": "Datepicker",
        "label": "DOB",
        "name": "dob",
        "rules": {

        }
    },
    {
        "type": "Autocomplete",
        "label": "Goal",
        "name": "goal",
        "rules": {

        }
    },
    {
        "type": "Autocomplete",
        "label": "Best time to call you",
        "name": "calltime",
        "rules": {

        }
    },
    {
        "type": "Autocomplete",
        "label": "1st Prefered Language",
        "name": "mother_tongue",
        "rules": {

        }
    },
    {
        "type": "Autocomplete",
        "label": "2nd Prefered Language",
        "name": "other language",
        "rules": {

        }
    },
    {
        "type": "TextField",
        "label": "Address",
        "name": "address",
        "rules": {

        }
    },
    {
        "type": "TextField",
        "label": "City",
        "name": "city",
        "rules": {

        }
    },
    {
        "type": "TextField",
        "label": "Country",
        "name": "country",
        "rules": {

        }
    },
    {
        "type": "TextField",
        "label": "Pin Code",
        "name": "pincode",
        "rules": {

        }
    },
    {
        "type": "TextField",
        "label": "Account Name",
        "name": "accountname",
        "rules": {

        }
    },
    {
        "type": "TextField",
        "label": "Facebook ID",
        "name": "facebookId",
        "rules": {

        }
    },
    {
        "type": "TextField",
        "label": "Instagram ID",
        "name": "instagram_id",
        "rules": {

        }
    }
    
]

export default basicinfoform
