import { useEffect, useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import { alpha, styled } from "@mui/material/styles";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  getGridStringOperators,
  GridFilterOperator,
} from "@mui/x-data-grid-pro";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import UploadIcon from "@mui/icons-material/Upload";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getLeadList } from "../store/leads/leadActions";
import { renderToString } from "react-dom/server";
import jsPDF from "jspdf";
import HFReport from "./HFReport";
import HandFReportpdfOverweight from "../Leads/HandFReportpdfOverweight";
import HandFReportpdfUnderweight from "../Leads/HandFReportpdfUnderweight";
import { ATLAS_URI } from "../../Constants";
import { useContext } from "react";
import axios from "axios";
import moment from "moment";
import stateContext from "../../context/StateContext";
import { dateConverter } from "../../Utlis/dateConversion";
import LeadContextProvider from "./LeadContextProvider";

import LeadTable from "./LeadTable copy";

import ApiDataContext from "./MasterDataContextProvider";
import useListLead from "../../hooks/tableColumns/useLeadList";

export default function LeadList(props) {
  const masterData = useContext(ApiDataContext);

  const interestedValues = [
    {
      value: 2,
      label: "High",
    },
    {
      value: 1,
      label: "Moderate",
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ODD_OPACITY = 0.2;
  const history = useHistory();
  const dispatch = useDispatch();
  const contextState = useContext(stateContext);

  const { configToken } = contextState.state;

  const [currentLead, setCurrentLead] = useState("");

  const [menuPosition, setMenuPosition] = useState(null);
  const [leadId, setLeadId] = useState("");
  const [isReportExist, setIsReportExist] = useState(false);

  const leadColumList = useListLead(props.handleClick, props.open);

  const handleClick = (event, data) => {
    setCurrentLead(data.row);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const openWhatsapp = () => {
    let phoneNumber = props.leadList
      .filter((data) => data.id === leadId)[0]
      ?.contact_number.replace("+", "");
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    name: true,
    account_name: true,
    dob: true,
    gender: true,
    lead_status: true,
    email_id: true,
    contact_number: true,
    createdAt: true,
    height: false,
    current_weight: false,
    city: false,
    state: false,
    address: false,
    country: false,
    pincode: false,
    sleep_goal: false,
    mother_tongue: false,
    preferred_language: false,
    assigned_to_user: true,
    occupation: false,
    // work_hours: false,
    family_details: false,
    emergency_contact: false,
    medications: false,
    medical_history: false,
    current_medical_condition: false,
    weight_goal: false,
    water_goal: false,
    nutrition_goal: false,
    steps_goal: false,
    fitness_specifications: false,
    food_specifications: false,
    body_measurements: false,
    updated_by_details: false,
    notes: false,
    created_by_details: false,
    updatedAt: false,
    age: false,
    leadStageInfo: true,
    leadDispositionInfo: false,
    leadSubDispositionInfo: false,
    leadSubNotInterestedInfo: false,
    referral_code: true,
    next_followup_date: true,
  });

  const { operator } = contextState.state;
  const handleItemClick = (event) => {
    setMenuPosition(null);
  };
  // const [paginationModel, setPaginationModel] = useState({
  //   pageSize: 25,
  //   page: 0,
  // });

  const handleChangeRowSelection = (id) => {
    props.setAssignLeads(id);
    props.setDeleteIds(id);
    if (id.length !== 0) {
      props.toggleRowSelection(true);
    } else {
      props.toggleRowSelection(false);
    }
  };

  const defautlColHeader = () => {
    axiosFetch({
      url: `/getListViewByUserIdAndScreen/${operator.id}/1`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          let obj = {
            name: true,
            account_name: true,
            dob: true,
            gender: true,
            lead_status: true,
            email_id: true,
            contact_number: true,
            createdAt: true,
            height: false,
            current_weight: false,
            city: false,
            state: false,
            address: false,
            country: false,
            pincode: false,
            sleep_goal: false,
            mother_tongue: false,
            preferred_language: false,
            assigned_to_user: false,
            leadStageInfo: true,
            leadDispositionInfo: false,
            leadSubDispositionInfo: false,
            leadSubNotInterestedInfo: false,
            occupation: false,
            work_hours: false,
            family_details: false,
            emergency_contact: false,
            medications: false,
            medical_history: false,
            current_medical_condition: false,
            weight_goal: false,
            water_goal: false,
            nutrition_goal: false,
            steps_goal: false,
            fitness_specifications: false,
            food_specifications: false,
            body_measurements: false,
            updated_by_details: false,
            notes: false,
            created_by_details: false,
            updatedAt: false,
            age: false,
            referral_code: true,
            next_followup_date: true,
            form_filled_date: false,
            form_submission_count: false,
          };
          // modif ying data based on selected header

          Object.keys(obj).filter((key) => {
            if (res.data !== null && res.data.fieldList.includes(key)) {
              obj[key] = true;
            } else {
              obj[key] = false;
            }
          });

          setColumnVisibilityModel(obj);
        } else if (res.status === 400) {
          toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const ViewReport = (type) => {
    history.push({
      pathname: `/Leads/leadView/${leadId}`,
      state: {
        isHFReport: type === 3 ? true : false,
        isCallHistoryView: type === 2 ? true : false,
        isLeadView: type === 1 ? true : false,
        qryStr: window.location.href,
      },
    });
  };

  const updateDetails = () => {
    history.push({ pathname: `/Leads/leadDetails/${leadId}` });
    handleClose();
  };
  const makeCall = () => {
    props.viewCallModal(leadId);
    handleClose();
  };

  const downloadReport = () => {
    setAnchorEl(null);
    axiosFetch({
      url: `/downloadByUser/${leadId}`,
      method: "get",
    }).then((res) => {
      if (res.status === 200) {
        window.open(res.data);
        setReport("");
      } else if (res.status === 400) {
        toast.error(res.data.mesaage, { position: toast.POSITION.TOP_CENTER });
      } else if (res.status === 401) {
        history.push("/");
      }
    });
  };
  const deleteReport = () => {
    setAnchorEl(null);
    setIsReportExist(false);
    axiosFetch({
      url: `/deleteHFReport/${leadId}`,
      method: "delete",
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Report deleted Successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          props.searchCriteria();
          setReport("");
        } else if (res.status === 400) {
          toast.error("H & F report is not uploaded", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleChange = (state) => {
    props.handleChange(state);
    //update visible cols to prevent bug initally gridColumnList is empty
    //when just change the per page value and save the list it saves empty array
    onChangeColumn(state.columns.columnVisibilityModel);
  };

  useEffect(() => {
    defautlColHeader();
  }, []);
  const [report, setReport] = useState();

  const onChangeColumn = (newModel) => {
    setColumnVisibilityModel(newModel);
    props.updateColumns(newModel, leadColumList);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  const getRowId = (row) => {
    // console.log('-----------', row);
    return row.id || Math.random();
  };
  // const [page, setPage] = useState(1);

  return (
    <LeadContextProvider
      page={props.page}
      totalRecords={props.totalRecords}
      leadList={props.leadList}
      columns={leadColumList}
      open={open}
    >
      <LeadTable
        currentLead={currentLead}
        selectedRow={currentLead.id}
        page={props.page}
        handleClick={handleClick}
        totalRecords={props.totalRecords}
        leadList={props.leadList}
        open={open}
        columnVisibilityModel={columnVisibilityModel}
        CustomToolbar={CustomToolbar}
        anchorEl={anchorEl}
        isReportExist={isReportExist}
        getRowId={getRowId}
        handleChange={handleChange}
        onChangeColumn={onChangeColumn}
        handleChangeRowSelection={handleChangeRowSelection}
        setLeadId={setLeadId}
        setIsReportExist={setIsReportExist}
        handleClose={handleClose}
        handleItemClick={handleItemClick}
        ViewReport={ViewReport}
        makeCall={makeCall}
        updateDetails={updateDetails}
        openWhatsapp={openWhatsapp}
        downloadReport={downloadReport}
        deleteReport={deleteReport}
        clearChildFilter={props.clearChildFilter}
        clearRowTicks={props.clearRowTicks}
        saveGrid={props.saveGrid}
      ></LeadTable>
    </LeadContextProvider>
  );
}
