"use strict";

import React, { useCallback, useContext, useMemo, useState } from "react";
import { render } from "react-dom";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Link, Route, useHistory, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { ATLAS_URI } from "../Constants";
import axios from "axios";
import stateContext from "../context/StateContext";
import { MdDelete } from "react-icons/md";
import "./GridTable.css";
import { data } from "jquery";
import { renderToString } from "react-dom/server";
import jsPDF from "jspdf";
import HandFReportpdfOverweight from "../Pages/Leads/HandFReportpdfOverweight";
import HandFReportpdfUnderweight from "../Pages/Leads/HandFReportpdfUnderweight";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import HandFReportViewer from "../Pages/Leads/HandFReportViewer";

const GridTable = (props) => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [loading, setLoading] = useState(false);

  const leadFieldList = [
    "name",
    "email_id",
    "contact_number",
    "account_name",
    "dob",
    "gender",
    "height",
    "current_weight",
    "address",
    "city",
    "state",
    "country",
    "pincode",
    "mother_tongue",
    "preferred_language",
    "occupation",
    "work_hours",
    "family_details",
    "emergency_contact",
    "medications",
    "medical_history",
    "current_medical_condition",
    "weight_goal",
    "nutrition_goal",
    "sleep_goal",
    "water_goal",
    "steps_goal",
    "fitness_specifications",
    "food_specifications",
    "body_measurements",
    "LeadStatus",
    "Payment",
    "assignToUser" /* , 'leadStage' */,
  ];

  const userListViewAvailableFields = [];

  // const [gridColumnListState, setGridColumnListState] = useState([])

  // const [show, setShow] = useState(false)
  // const [deleteIds, setDeleteIds] = useState(null)
  // const [text, setText] = useState('Delete')
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    // props.setGridColumnList([])

    const updateData = (data) => {
      // setRowData(data);
    };

    /* fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
      .then((resp) => resp.json())
      .then((data) => updateData(data)); */
  };
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  props.tableBody.map((leadInfo) => {
    leadInfo.assignToUser = leadInfo?.assigned_to_user?.Username ?? "";
    return leadInfo;
  });

  const checkboxSelection = (params, e) => {
    if (params.data.id == params.node.data.id) {
    }

    return !params.node.group;
  };

  function updateGridColumnList(e) {
    if (e.visible == false) {
      props.setGridColumnList((prevState) => {
        if (prevState.indexOf(e.column.colId) > -1) {
          prevState.splice(prevState.indexOf(e.column.colId), 1);
        }

        return prevState;
      });
    } else {
      props.setGridColumnList((prevState) => {
        prevState.push(e.column.colId);

        return prevState;
      });
    }
  }

  const checkbox = (params) => {
    // console.log(params.data.id,'params... checking')
    return params.node.group === true;
  };

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Athlete",
      field: "athlete",
      minWidth: 250,
      cellRenderer: (params) => {},
      cellRendererParams: {
        checkbox,
      },
    };
  }, []);

  const onSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length >= 2) {
      props.setText("Delete All");
      props.setShow(true);
      props.setDeleteIds(event.api.getSelectedRows());
      props.assignLeads(event.api.getSelectedRows());
    } else {
      props.setText("Delete");
      props.setShow(true);
      props.setDeleteIds(event.api.getSelectedRows());
      props.assignLeads(event.api.getSelectedRows());
    }

    if (event.api.getSelectedRows().length == 0) {
      props.setShow(false);
    }
  };

  const rowSelectionType = "multiple";
  const contextState = useContext(stateContext);
  const { updateLeadsList } = contextState;
  const { configToken, featureAccessible } = contextState.state;
  const newTableBody = [];

  const cellRenderer = (params) => {
    // alert(params.data.id)
  };

  /*     For Deleting......                   */

  //   const deleteAll=()=>{
  //     const delID=deleteIds.map((c)=>{return c.id})
  //   console.log(deleteIds,'deleteIds');
  //   // console.log(delId[0],'delId');
  //   // props.tableBody.filter((c,pos)=>{return deleteIds.filter((cs)=>{return cs.id!==c.id}) })
  //   // setDeleteIds(props.tableBody)
  // let deletedData= props.tableBody.filter((each)=>{

  //   return !deleteIds.includes(each.id)
  // })
  // // // setRowData(deletedData)

  // axios.delete(`${ATLAS_URI}/deleteLead/` +delID.toString() , configToken).then(res=>{const newTableBodyList=deletedData
  // props.setState(prevState => ({
  //   ...prevState,
  //   tableBodyList: newTableBodyList}))
  // })
  //   }

  const changeToolPanel = (e) => {};

  // const Ids=deleteIds.map((c)=>{return props.tableBody.indexOf(c.id)})
  // console.log(Ids,'Ids.......');
  const [params, setParams] = useState(null);

  async function downloadHFReport(leadInfo) {
    await axios
      .get(`${ATLAS_URI}/downloadByUser/${leadInfo.id}`, configToken)
      .then((res) => {
        if (res.status == 200) {
          window.open(res.data);
        }
      });
  }
  let bulkLeads = props.tableBody.map((data) => {
    const LeadStatus = data?.LeadStatusID?.Status ?? "";
    data.LeadStatus = LeadStatus;
    data.Payments && data.Payments.length > 0
      ? (data.Payment = "Paid")
      : (data.Payment = "Un-Paid");
    return data;
  });

  async function generateHandFreport(params) {
    props.setIsHFReportGenerationLoading(true);
    //props.setLoading(true)
    console.log(params.data.id, "params.id");

    try {
      const leadDetails = await axios.get(
        `${ATLAS_URI}/getLeadDetailsForHandF/${params.data.id}`,
        configToken
      );
      const leadsData = leadDetails.data;

      console.log("leadsData:::: ", leadsData);

      // console.log('leadsData.current_weight::: ', leadsData.current_weight)
      // console.log('leadsData.ideal_weight_1:::: ', leadsData.ideal_weight_1)

      // let name = '- Mr. Vignesh Prabakaran (Holistic Health and Wellness Coach)'

      // if(leadsData.source == "1") name = '- Mrs.Pritika Srinivasan (Pn Certified Nutritionist)'

      // if(leadsData.source == "9") name = '- Miss. Lakshmi Kala (Pn Certified Nutritionist)'
      //  console.log(leadsData,'Lakshmi')

      let string;
      if (
        parseFloat(leadsData?.current_weight) >
        parseFloat(leadsData?.ideal_weight_1)
      ) {
        string = renderToString(
          <HandFReportpdfOverweight
            leadDetails={leadsData}
            coachName={leadsData?.SourceID?.title}
          />
        );
      } else {
        string = renderToString(
          <HandFReportpdfUnderweight
            leadDetails={leadsData}
            coachName={leadsData?.SourceID?.title}
          />
        );
      }

      const pdf = new jsPDF("p", "pt", [595.28, 841.89]);
      const headerImg = leadsData?.SourceID?.logo_url;

      await pdf.html(string, {
        width: 460,
        windowWidth: 800,
        margin: [150, 10, 10, 10],
        autoPaging: "text",
        pagesplit: true,
      });
      for (var i = 1; i <= pdf.getNumberOfPages(); i++) {
        pdf.setPage(i);

        if (headerImg) pdf.addImage(headerImg, "PNG", 15, 15, 600, 100);
      }

      const temp = pdf.output("blob");
      var data = new FormData();

      data.append("H_F_report", temp, `H_F_report.pdf`);

      await axios
        .put(`${ATLAS_URI}/upload/${params.data.id}`, data, configToken)
        .then((response) => {
          props.setLoading(false);
          //return console.log(response)

          if (response.status == 200) {
            toast.success("H & F report generated", {
              position: toast.POSITION.TOP_CENTER,
            });
            updateLeadsList();
            props.setIsHFReportGenerationLoading(false);
          }
        })
        .catch((error) => {});
    } catch (error) {}
  }

  /* View H&F Report */

  let history = useHistory();
  const ViewReport = (params) => {
    history.push({
      pathname: "/Leads/leadView/" + params.data.id,
      state: {
        isLeadView: true,
      },
    });
  };

  /* delete Report */

  const deleteReport = (params) => {
    axios
      .delete(`${ATLAS_URI}/deleteHFReport/${params.data.id}`, configToken)
      .then(
        toast.success("Report deleted Successfully", {
          position: toast.POSITION.TOP_CENTER,
        }),
        updateLeadsList()
      )
      .catch((err) =>
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        })
      );
  };
  // const [sendId, setSendId] = useState()

  return (
    <div style={{ width: "100%", height: "100%", overflowX: "auto" }}>
      <div
        id="myGrid"
        style={{
          height: "100%",
          width: "100%",
          overflowX: "auto",
        }}
        className="ag-theme-alpine"
      >
        {/* {(<div><Button className='btn btn-danger' onClick={()=>props.openDialog(deleteIds.map((c)=>c.id))} disabled={!show} ><MdDelete fontSize='25px' title='Delete'/></Button></div>)}         */}
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            // suppressPivotMode: true,
            sortable: true,
            filter: true,
            resizable: true,
            // valueGetter: (params) => {
            //   if (params.node.group) {
            //     return params.node.key;
            //   } else {
            //     return params.data[params.colDef.field];
            //   }
            // },
            // allowDragFromColumnsToolPanel:true
          }}
          // sideBar={'columns'}
          sideBar={{
            toolPanels: [
              {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filters",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true,
                  suppressSideButtons: true,
                  suppressColumnFilter: true,
                  suppressColumnSelectAll: true,
                  suppressColumnExpandAll: true,
                  onChange: () => console.log("first"),
                },
              },
            ],
            // defaultToolPanel:'filters',
            // hiddenByDefault:true,
            position: "bottom",
            defaultToolPanel: "columns",
          }}
          onGridReady={onGridReady}
          rowData={props.tableBody}
          // rowData={bulkLeads}

          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
          suppressDragLeaveHidesColumns={true}
          // sideBar={true}
          rowSelection={rowSelectionType}
          onSelectionChanged={onSelectionChanged}
          onColumnVisible={(e) => {
            updateGridColumnList(e);
          }}
        >
          <AgGridColumn
            // field="id"
            filter="agTextColumnFilter"
            minWidth={100}
            suppressColumnsToolPanel={true}
            checkboxSelection={true}
            headerCheckboxSelection={true}
            autoGroupColumnDef={autoGroupColumnDef}
            rowSelection={"multiple"}

            // rowGroup= {true}
          />

          {leadFieldList
            .filter((field) => props.gridColumnList.indexOf(field) > -1)
            .map((availableField) => {
              if (availableField == "assignToUser")
                return (
                  <AgGridColumn
                    hide={false}
                    minWidth={200}
                    field="assignToUser"
                    headerName="Sales person"
                  />
                );
              else
                return (
                  <AgGridColumn
                    hide={false}
                    minWidth={200}
                    field={availableField}
                  />
                );
            })}

          {leadFieldList
            .filter((field) => props.gridColumnList.indexOf(field) == -1)
            .map((notAvailableField) => {
              if (notAvailableField == "assignToUser")
                return (
                  <AgGridColumn
                    hide={true}
                    minWidth={200}
                    field="assignToUser"
                    headerName="Sales person"
                  />
                );
              else
                return (
                  <AgGridColumn
                    hide={true}
                    minWidth={200}
                    field={notAvailableField}
                  />
                );
            })}

          <AgGridColumn
            hide={false}
            minWidth={200}
            field="handfreport"
            headerName="H & F report"
            cellRenderer={(params) => (
              <>
                <td>
                  <React.Fragment>
                    <button
                      className="btn btn-default btn-xs no-focus"
                      title="View Report"
                      onClick={() => ViewReport(params)}
                    >
                      <i className="fas fa-eye" aria-hidden="true"></i>
                    </button>
                    {params.data.vmax_lead_hf_report ? (
                      <button
                        className="btn btn-default btn-xs no-focus"
                        title="Delete Report"
                        onClick={() => deleteReport(params)}
                      >
                        <i className="fas fa-trash" aria-hidden="true"></i>
                      </button>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                  <React.Fragment>
                    <button
                      className="btn btn-default btn-xs no-focus"
                      title="Generate H&F Report"
                      onClick={() => generateHandFreport(params)}
                    >
                      <i className="fas fa-file-pdf" aria-hidden="true"></i>
                    </button>
                  </React.Fragment>
                  <React.Fragment>
                    {props.h_f_report_actions.map((action) => (
                      <button
                        key={action.title}
                        className="btn btn-default btn-xs no-focus"
                        title={action.title}
                        onClick={() => action.onClickEvent(params.data.id)}
                      >
                        <i className={`${action.icon}`} aria-hidden="true"></i>
                      </button>
                    ))}
                    {params.data.vmax_lead_hf_report ? (
                      <button
                        className="btn btn-default btn-xs no-focus"
                        title="Download H&F report"
                        onClick={() => downloadHFReport(params.data)}
                      >
                        <i className="fas fa-download" aria-hidden="true"></i>
                      </button>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                </td>
              </>
            )}
          />
          <AgGridColumn
            suppressColumnsToolPanel={true}
            field="Action"
            minWidth={200}
            cellRenderer={(params) => (
              <>
                {/* {props.customAction.push()} */}

                {typeof props.noActions === "undefined" && (
                  <>
                    <td className="bodyDataActions">
                      {typeof props.customAction === "undefined" ? (
                        <React.Fragment>
                          <button
                            className="btn btn-default btn-xs no-focus"
                            title="Edit"
                            onClick={props.editTableRow}
                          >
                            <i className="fas fa-pencil" aria-hidden="true"></i>
                          </button>
                          <button
                            className="btn btn-default btn-xs no-focus"
                            title="Delete"
                            onClick={props.deleteFromTable}
                          >
                            <i
                              className="fas fa-times text-red"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {props.customAction.map((action) =>
                            action.title === "Attempt" ||
                            action.title === "Update" ||
                            action.title === "View" ? (
                              <Link
                                key={action.title}
                                className="btn btn-default btn-xs no-focus"
                                onClick={() =>
                                  action.onClickEvent(params.data.id)
                                }
                                to={action.redirectTo}
                              >
                                <i
                                  className={`${action.icon}`}
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            ) : (
                              (!action.fieldCondition ||
                                props.tableBody[
                                  Object.keys(action.fieldCondition)[0]
                                ] ===
                                  Object.values(action.fieldCondition)[0]) && (
                                <button
                                  key={action.title}
                                  className="btn btn-default btn-xs no-focus"
                                  title={action.title}
                                  onClick={() =>
                                    action.onClickEvent(params.data.id)
                                  }
                                >
                                  <i
                                    className={`${action.icon}`}
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )
                            )
                          )}
                        </React.Fragment>
                      )}
                    </td>
                    {/*  */}
                  </>
                )}
              </>
            )}
          />

          {/* <AgGridColumn field="age" />
            <AgGridColumn field="country" minWidth={200} />
          </AgGridColumn>
          <AgGridColumn headerName="Competition">
            <AgGridColumn field="year" />
            <AgGridColumn field="date" minWidth={180} />
          </AgGridColumn>
          <AgGridColumn field="sport" minWidth={200} />
          <AgGridColumn headerName="Medals">
            <AgGridColumn field="gold" />
            <AgGridColumn field="silver" />
            <AgGridColumn field="bronze" />
            <AgGridColumn field="total" /> */}
        </AgGridReact>
      </div>
    </div>
  );
};
export default GridTable;

// render(<GridExample></GridExample>, document.querySelector('#root'));
