import Dashboard from './Dashboard'
import Login from './Login'
import AssignedLeadsCalls from './AssignedLeadsCalls'
import AssignedLeadsVisits from './AssignedLeadsVisits'
import Configurations from './Configurations'

export {
    Dashboard,
    Login,
    AssignedLeadsCalls,
    AssignedLeadsVisits,
    Configurations
}