// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub{
    cursor: pointer;
    color: blue;
}
#drop-down{
    width: 160px;
    
}



.submit{
    margin-left: 670px;
    position: fixed;
    bottom: 681px;
}
@media (max-width:991px){
    .submit{
        position: fixed;
        top: 210px;
       
    }
}


.dataTables_filter {
    display: flex;
    gap: 20px;
}


.btn-payment {
font-size: 18px;
padding: 6px 12px;
color: #fff; 
background: #2D2D2D;
border-radius: 6px;

}

`, "",{"version":3,"sources":["webpack://./src/components/DataTable.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,YAAY;;AAEhB;;;;AAIA;IACI,kBAAkB;IAClB,eAAe;IACf,aAAa;AACjB;AACA;IACI;QACI,eAAe;QACf,UAAU;;IAEd;AACJ;;;AAGA;IACI,aAAa;IACb,SAAS;AACb;;;AAGA;AACA,eAAe;AACf,iBAAiB;AACjB,WAAW;AACX,mBAAmB;AACnB,kBAAkB;;AAElB","sourcesContent":[".sub{\n    cursor: pointer;\n    color: blue;\n}\n#drop-down{\n    width: 160px;\n    \n}\n\n\n\n.submit{\n    margin-left: 670px;\n    position: fixed;\n    bottom: 681px;\n}\n@media (max-width:991px){\n    .submit{\n        position: fixed;\n        top: 210px;\n       \n    }\n}\n\n\n.dataTables_filter {\n    display: flex;\n    gap: 20px;\n}\n\n\n.btn-payment {\nfont-size: 18px;\npadding: 6px 12px;\ncolor: #fff; \nbackground: #2D2D2D;\nborder-radius: 6px;\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
