// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollmenu{
    /* background-color: #333; */
    overflow: auto;
    /* white-space: nowrap; */
}`, "",{"version":3,"sources":["webpack://./src/components/GridTable.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":[".scrollmenu{\n    /* background-color: #333; */\n    overflow: auto;\n    /* white-space: nowrap; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
