// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-common {
  /* width: 100%; */
  font-size: 16px;
  padding: 8px 30px;
  border-radius: 6px;
  font-weight: 600;
  border: 1px solid #2d2d2d;
  cursor: pointer;
}

.btn-outline {
  background-color: transparent;
  color: #2d2d2d;
}

.btn-inline {
  background-color: #2d2d2d;
  color: #fff;
}

.btn-disabled {
  opacity: 80%;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomButton/Button.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,6BAA6B;EAC7B,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,YAAY;AACd","sourcesContent":[".btn-common {\n  /* width: 100%; */\n  font-size: 16px;\n  padding: 8px 30px;\n  border-radius: 6px;\n  font-weight: 600;\n  border: 1px solid #2d2d2d;\n  cursor: pointer;\n}\n\n.btn-outline {\n  background-color: transparent;\n  color: #2d2d2d;\n}\n\n.btn-inline {\n  background-color: #2d2d2d;\n  color: #fff;\n}\n\n.btn-disabled {\n  opacity: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
