import { useState, useRef, useEffect, useContext } from "react";
import {
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  InputLabel,
  DialogTitle,
  TextField,
  Card,
  CardActions,
  CardContent,
  Grid,
  Popover,
  FormControlLabel,
  RadioGroup,
  FormHelperText,
  Radio,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { TextareaAutosize } from "@mui/base";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

import SelectPaymentMode from "../../components/FeedBackModals/SelectPaymentMode";

import { BiLinkAlt } from "react-icons/bi";

import stateContext from "../../context/StateContext";

const ProgramDetails = ({ lead_id }) => {
  const history = useHistory();
  const routeParams = useParams();
  const [open, setOpen] = useState(false);
  const paymentDateRef = useRef();
  const startDateRef = useRef();
  const [selectedProgram, setSelectedProgram] = useState("");

  const [openPaymentPopup, setOpenPaymentPopup] = useState(null);

  const paymentPopup = Boolean(openPaymentPopup);
  const [anchorEl, setAnchorEl] = useState(null);

  const [amount, setAmount] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentDate, setPaymentDate] = useState("");

  const [description, setDescription] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isKeywordErr, setIsKeywordErr] = useState(false);
  const [startDateErrMsg, setStartDateErrMsg] = useState(false);
  const [showStartDate, setShowStartDate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [disablebtn, setDisableBtn] = useState(true);
  const [clientProgramList, setClientProgramList] = useState([]);
  const [openSDPopup, setOpenSDPopup] = useState(false);
  const [editId, setEditId] = useState("");
  const [programId, setProgramId] = useState("");
  const [leadId, setLeadId] = useState("");

  const [amountErrMsg, setAmountErrMsg] = useState(false);
  const [typeErrMsg, setTypeErrMsg] = useState(false);
  const [dateErrMsg, setDateErrMsg] = useState(false);

  const [show, setShow] = useState(false);
  const [client, setClient] = useState("");
  const contextState = useContext(stateContext);

  const [paymentLink, setPaymentLink] = useState("");

  const { configToken } = contextState.state;

  const submitHandler = async (paymentType, amount) => {
    try {
      const paymentObj = {
        payment_gateway: paymentType,
        amount: amount,
        lead_client_product_id: client.id,
        old_payment_link: client.payment_link,
      };

      const response = await axiosFetch({
        url: `/createPaymentLink-program`,
        requestConfig: paymentObj,
        method: "post",
      });

      if (response.status === 200) {
        setPaymentLink(response.data.link);
        // setShow(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePaymentModal = (e) => {
    setShow(true);
  };

  useEffect(() => {
    if (show) {
      setAnchorEl(null);
    }
  }, [show]);

  const closeModel = () => {
    setShow(false);
    setPaymentLink(false);
    getLeadProgram();
  };

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleClickOpenSDPopup = () => {
    setOpenSDPopup(true);
    setAnchorEl(null);
  };

  const handleCloseSDPopup = () => {
    setOpenSDPopup(false);
  };

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
    if (e.target.value === "") {
      setAmountErrMsg(true);
      return false;
    } else {
      setAmountErrMsg(false);
    }
  };

  const handleClickPopover = (event, obj) => {
    setClient(obj);

    setProgramId(obj.product_details.id);
    setStartDate(obj.start_date.split("T")[0]);
    setEditId(obj.id);
    setLeadId(obj.prod_id);
    setAnchorEl(event.currentTarget);
  };

  const handleChangePaymentDate = (e) => {
    setPaymentDate(e.target.value);
    if (e.target.value === "") {
      setDateErrMsg(true);
      return false;
    } else {
      setDateErrMsg(false);
    }
  };

  const handleChangeStartDate = (e) => {
    setStartDate(e.target.value);
    if (e.target.value === "") {
      setStartDateErrMsg(true);
      return false;
    } else {
      setStartDateErrMsg(false);
    }
  };

  const handlePaymentType = (e) => {
    setPaymentType(e.target.value);
    if (e.target.value === "") {
      setTypeErrMsg(true);
      return false;
    } else {
      setTypeErrMsg(false);
    }
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClickOpenPayment = () => {
    setOpenPaymentPopup(true);
    handleClosePopover();
  };

  const handleClosePayment = () => {
    setOpenPaymentPopup(false);
    clearPayment();
  };
  const openPopover = Boolean(anchorEl);

  const handleClickOpen = () => {
    getProgramList();
    setOpen(true);
    setStartDate("");
  };

  const [programList, setProgramList] = useState([]);
  const [dealSize, setDealSize] = useState("");

  const handleDealSize = (e) => {
    setDealSize(e.target.value);
  };

  const handleClose = () => {
    setDisableBtn(true);
    setOpen(false);
    setSelectedProgram("");
    setShowStartDate(false);
    setStartDateErrMsg(false);
    setStartDate("");
    setKeyword("");
    setIsKeywordErr(false);
  };

  const handleChange = (event) => {
    setSelectedProgram(event.target.value);
    let id = event.target.value;
    let refinedData = programList.filter(
      (program) => program.id === parseInt(id)
    );
    setProgramList(refinedData);

    setShowStartDate(true);
    setDisableBtn(false);
  };

  const handleSearch = (event) => {
    setKeyword(event.target.value);
    if (event.target.value === "") {
      setIsKeywordErr(true);
      return false;
    } else {
      setIsKeywordErr(false);
    }
  };

  const onSearch = () => {
    if (keyword === "") {
      setIsKeywordErr(true);
      return false;
    }
    if (keyword !== "") {
      let url = `?search=${keyword}`;
      getProgramList(url);
    }
  };

  const getLeadProgram = () => {
    axiosFetch({
      method: "get",
      url: `/getProductsByClientId/${routeParams.id}`,
    })
      .then((res) => {
        if (res.status === 200) {
          setClientProgramList(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getLeadProgram();
  }, []);

  function Row(props) {
    const { row, index } = props;
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              sx={{ float: "right !important" }}
            >
              {/* {row.product_payment.length !== 0 ? (
                open ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )
              ) : (
                ""
              )} */}
            </IconButton>
          </TableCell>
          <TableCell>{index}</TableCell>
          <TableCell component="th" scope="row">
            {!!row?.product_details?.name ? row?.product_details?.name : ""}
          </TableCell>
          <TableCell align="left">
            {!!row?.product_details?.duration
              ? row?.product_details?.duration
              : "1"}
          </TableCell>
          <TableCell align="left">
            {!!row?.product_details?.duration_unit
              ? row?.product_details?.duration_unit
              : "-"}
          </TableCell>
          <TableCell align="left">
            {!!row.start_date ? row.start_date.split("T")[0] : "-"}
          </TableCell>
          <TableCell align="left">
            {!!row.end_date ? row.end_date.split("T")[0] : "-"}
          </TableCell>
          <TableCell align="left">
            {!!row.deal_size ? row.deal_size : "-"}
          </TableCell>
          <TableCell align="left">
            {!!row.deal_size ? row.deal_size : "-"}
          </TableCell>
          <TableCell align="left">
            {!!row.deal_size ? row.deal_size : "-"}
          </TableCell>
          <TableCell align="left">
            {!!row.deal_size ? row.deal_size : "-"}
          </TableCell>
          <TableCell align="left">
            {!!row.deal_size ? row.deal_size : "-"}
          </TableCell>

          <TableCell align="left">{!!row.status ? row.status : "-"}</TableCell>
          <TableCell align="left" onClick={(e) => handleClickPopover(e, row)}>
            <MoreHorizIcon />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Payment Details
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Amount</TableCell>
                      <TableCell align="left">Type</TableCell>
                      <TableCell align="left">Date</TableCell>
                      <TableCell align="left">Created At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {row.product_payment.length !== 0 &&
                      row.product_payment.map((historyRow, index) => (
                        <TableRow key={historyRow.id}>
                          <TableCell component="th" scope="row">
                            {!!historyRow.Amount ? historyRow.Amount : "-"}
                          </TableCell>
                          <TableCell>
                            {!!historyRow.Type ? historyRow.Type : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {!!historyRow.Date ? historyRow.Date : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {!!historyRow.createdAt
                              ? historyRow.createdAt.split("T")[0]
                              : "-"}
                          </TableCell>
                        </TableRow>
                      ))} */}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  const getProgramList = (params) => {
    if (params === undefined) {
      params = "";
    }

    axiosFetch({
      method: "get",
      url: `/getProducts/${params}`,
    })
      .then((res) => {
        if (res.status === 200) {
          setProgramList(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const clear = () => {
    setKeyword("");
    getProgramList();
    setShowStartDate(false);
    setStartDate("");
    setSelectedProgram("");
    setDisableBtn(true);
    setShowStartDate(false);
  };

  const clearPayment = () => {
    setAmount("");
    setPaymentDate("");
    setPaymentType("");
    setDescription("");
  };
  const handleSelect = () => {
    if (startDate === "") {
      setStartDateErrMsg(true);
      return false;
    }
    if (selectedProgram !== "" && startDate !== "") {
      axiosFetch({
        method: "post",
        url: "/addProductToLead",
        requestConfig: {
          isClient: "true",
          lead_id,
          prod_id: selectedProgram,
          start_date: startDate,
          client_id: routeParams.id,
          deal_size: !!dealSize ? dealSize : undefined,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Program Added Successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
            handleClose();
            getLeadProgram();
          } else if (res.status === 400) {
            toast.error("Bad Request", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.status === 401) {
            history.push("/");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const updateStartDate = () => {
    handleCloseSDPopup();
    axiosFetch({
      method: "put",
      url: `/updateProductAddedToLeadClient/${editId}`,
      requestConfig: {
        start_date: !!startDate ? startDate : "",
        prod_id: leadId,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Start Date Updated Successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
          handleCloseSDPopup();
          getLeadProgram();
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // const addClient = () => {

  //   let client = {
  //     Name: lead.name,
  //     Phone: lead.contact_number,
  //     Email: lead.email_id,
  //     Lead: routeParams.id,
  //     DOB: lead.dob,
  //     Address: lead.address,
  //     City: lead.city,
  //     State: lead.state,
  //     PINCode: lead.pincode,
  //     Occupation: lead.occupation,
  //     Source: lead.source,
  //   };

  //   axiosFetch({
  //     method: "post",
  //     url: "/addClient/",
  //     requestConfig: client,
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setClientId(res.data.id);
  //         handlePayment(res.data.id);
  //       } else if (res.status === 400) {
  //         toast.error("Bad Request", {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //       } else if (res.status === 401) {
  //         history.push("/");
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  const handlePayment = (clientId) => {
    if (amount === "") {
      setAmountErrMsg(true);
      return false;
    }
    if (paymentType === "") {
      setTypeErrMsg(true);
      return false;
    }
    if (paymentDate === "") {
      setDateErrMsg(true);
      return false;
    }

    if (
      amount !== "" &&
      paymentType !== "" &&
      paymentDate !== "" &&
      clientId !== undefined
    ) {
      axiosFetch({
        url: "/addPayment",
        method: "post",
        requestConfig: {
          Amount: !!amount ? amount : undefined,
          Type: !!paymentType ? paymentType : undefined,
          Date: !!paymentDate ? paymentDate : undefined,
          clientId: routeParams.id,
          LeadId: lead_id,
          prodId: !!programId ? programId : undefined,
          Description: !!description ? description : undefined,
          leadClientProductId: editId,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Payment made successfully", {
              position: toast.POSITION.TOP_CENTER,
            });
            handleClosePayment();
            getLeadProgram();
          } else if (res.status === 400) {
            toast.error("Bad Request", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.status === 401) {
            history.push("/");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={{ top: "13px", float: "right", right: "29px" }}
      >
        <AddIcon />
        Add Program
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ width: "44% !important" }}
      >
        <DialogTitle id="alert-dialog-title">Existing Programs</DialogTitle>
        <DialogContent>
          <TextField label="Search" value={keyword} onChange={handleSearch} />
          <IconButton
            title="clear"
            aria-label="search"
            style={{ transform: "translate(-41px, 7px) !important" }}
            onClick={clear}
          >
            <CloseIcon />
          </IconButton>
          <IconButton title="search" aria-label="search" onClick={onSearch}>
            <SearchIcon />
          </IconButton>
          <FormHelperText sx={{ color: "red" }}>
            {isKeywordErr ? "Please Enter Something" : ""}
          </FormHelperText>
          <br />
          {programList.map((program) => (
            <div>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      onChange={handleChange}
                      value={selectedProgram}
                    >
                      <FormControlLabel
                        value={program.id}
                        control={<Radio />}
                        label={program.name}
                      />
                    </RadioGroup>
                  </FormControl>
                  <p
                    style={{
                      marginLeft: "31px !important",
                      color: "gray !important",
                    }}
                  >
                    {!!program.comments
                      ? ` Description :  ${program.comments}`
                      : ""}
                  </p>
                </CardContent>
              </Card>
              <br />
            </div>
          ))}
          {showStartDate ? (
            <input
              placeholder="Start Date"
              class="textbox-n"
              type="text"
              onFocus={() => (startDateRef.current.type = "date")}
              onBlur={() => (startDateRef.current.type = "text")}
              ref={startDateRef}
              value={startDate}
              min={new Date().toISOString().split("T")[0]}
              onChange={handleChangeStartDate}
              onKeyDown={(e) =>
                new Date().toISOString().split("T")[0] && e.preventDefault()
              }
            />
          ) : (
            ""
          )}
          <br /> <br />
          {showStartDate ? (
            <TextField
              placeholder="Deal Size"
              value={dealSize}
              onChange={handleDealSize}
            />
          ) : (
            ""
          )}
          {showStartDate ? (
            <FormHelperText sx={{ color: "red" }}>
              {startDateErrMsg ? "Please select date" : ""}
            </FormHelperText>
          ) : (
            ""
          )}
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSelect} disabled={disablebtn}>
            Select
          </Button>
        </DialogActions>
      </Dialog>

      <Popover
        anchorEl={paymentPopup}
        open={openPaymentPopup}
        onClose={handleClosePayment}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        sx={{
          width: "80% !important",
        }}
      >
        <DialogTitle id="alert-dialog-title">Add Payment</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <label>
                Amount<span style={{ color: "red" }}>&nbsp;*</span>
              </label>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Amount"
                variant="outlined"
                value={amount}
                onChange={handleChangeAmount}
                sx={{ width: "225px" }}
                type="number"
              />
              <FormHelperText sx={{ color: "red" }}>
                {amountErrMsg ? "Please Enter Something" : ""}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}></Grid>
            <br />
            <br />
            <Grid item xs={4}>
              <label>
                Payment Type<span style={{ color: "red" }}>&nbsp;*</span>
              </label>
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  Payment Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={paymentType}
                  label="Payment Type"
                  onChange={handlePaymentType}
                  sx={{ width: "225px" }}
                >
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Cheque">Cheque</MenuItem>
                  <MenuItem value="Card">Card</MenuItem>
                  <MenuItem value="Net Banking">Net Banking</MenuItem>
                  <MenuItem value="Paytm">Paytm</MenuItem>
                  <MenuItem value="Razorpay">Razorpay</MenuItem>
                  <MenuItem value="Upi">Upi</MenuItem>
                  <MenuItem value="Phonepe">Phonepe</MenuItem>
                  <MenuItem value="Gpay">Gpay</MenuItem>
                  <MenuItem value="Easypay">Easypay</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText sx={{ color: "red" }}>
                {typeErrMsg ? "Please Select Something" : ""}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}></Grid>
            <br />
            <br />

            <Grid item xs={4}>
              <label>
                Payment Date<span style={{ color: "red" }}>&nbsp;*</span>
              </label>
            </Grid>
            <Grid item xs={4}>
              <input
                placeholder="Payment Date"
                class="textbox-n"
                type="date"
                value={paymentDate}
                min={new Date().toISOString().split("T")[0]}
                onChange={handleChangePaymentDate}
                onKeyDown={(e) =>
                  new Date().toISOString().split("T")[0] && e.preventDefault()
                }
              />
              <FormHelperText sx={{ color: "red" }}>
                {dateErrMsg ? "Please Select Something" : ""}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}></Grid>
            <br />
            <br />

            <Grid item xs={4}>
              <label>Description</label>
            </Grid>
            <Grid item xs={4}>
              <TextareaAutosize
                aria-label="empty textarea"
                placeholder="Empty"
                value={description}
                onChange={handleChangeDescription}
                style={{
                  padding: "12px",
                  width: "225px",
                }}
              />
            </Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <Stack direction="row">
                <DialogActions>
                  <Button onClick={handleClosePayment} variant="contained">
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handlePayment}>
                    Make Paymentr
                  </Button>
                </DialogActions>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </Popover>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell sx={{ fontWeight: "600" }}>S.No</TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Program Name
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Program Duration
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Program Duration Units
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Start Date
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                End Date
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Deal Size
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Program Status
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Remaining Days
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Unused Days Amount
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Nutritionist Call Count
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Fitness Coach Call Count
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientProgramList.map((row, index) => (
              <Row key={row.id} row={row} index={index + 1} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        id="basic-menu"
        anchorEl={Boolean(anchorEl)}
        open={openPopover}
        onClose={handleClosePopover}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        transformOrigin={{ horizontal: "center" }}
        // style={{ transform: "translateX(300px)" }}
      >
        <MenuItem onClick={handleClickOpenPayment}>
          <AddIcon />
          &nbsp; Add Payment
        </MenuItem>
        <hr />
        {/* <MenuItem onClick={handleClosePopover}>
          <EditIcon />
          &nbsp; Edit
        </MenuItem>
        <hr /> */}
        <MenuItem onClick={handleClickOpenSDPopup}>
          <EditIcon />
          &nbsp; Edit Start Date
        </MenuItem>
        <hr />
        <MenuItem onClick={handlePaymentModal}>
          <BiLinkAlt size={23} />
          &nbsp; Create payment link
        </MenuItem>
      </Menu>

      <Dialog
        open={openSDPopup}
        onClose={handleCloseSDPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Start Date</DialogTitle>
        <DialogContent>
          <input
            placeholder="Start Date"
            class="textbox-n"
            type="text"
            onFocus={() => (startDateRef.current.type = "date")}
            onBlur={() => (startDateRef.current.type = "text")}
            ref={startDateRef}
            value={startDate}
            min={new Date().toISOString().split("T")[0]}
            onChange={handleChangeStartDate}
            onKeyDown={(e) =>
              new Date().toISOString().split("T")[0] && e.preventDefault()
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSDPopup}>Cancel</Button>
          <Button onClick={updateStartDate} autoFocus>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      {show && (
        <SelectPaymentMode
          paymentLink={paymentLink}
          oldPaymentLink={client.payment_link}
          programUnit={client.product_details.duration_unit}
          programDuration={client.product_details.duration}
          programName={client.product_details.name}
          closeModel={closeModel}
          submitForm={submitHandler}
          paymentType={client.payment_gateway}
          paymentAmount={client.payment_amount}
        />
      )}
    </div>
  );
};

export default ProgramDetails;
