// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectedFood{
    border: 1px solid black;
    border-radius: 150px;
    padding: 5px;
    background-color: #c8cacf4f;
    color: rgb(44, 41, 41);
    outline: #fff;
    border-color:#c8cacf4f ;
}

.alignRight{
    margin-left: 800px;
   position: absolute;
   top: 10px;
   left: 200px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Leads/Popupmodel.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,oBAAoB;IACpB,YAAY;IACZ,2BAA2B;IAC3B,sBAAsB;IACtB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;GACnB,kBAAkB;GAClB,SAAS;GACT,WAAW;AACd","sourcesContent":[".selectedFood{\n    border: 1px solid black;\n    border-radius: 150px;\n    padding: 5px;\n    background-color: #c8cacf4f;\n    color: rgb(44, 41, 41);\n    outline: #fff;\n    border-color:#c8cacf4f ;\n}\n\n.alignRight{\n    margin-left: 800px;\n   position: absolute;\n   top: 10px;\n   left: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
