// import { ViewState, EditingState, IntegratedEditing } from '@devexpress/dx-react-scheduler'
// import { Scheduler, MonthView, Toolbar, AppointmentForm, Appointments, WeekView, DateNavigator, TodayButton } from '@devexpress/dx-react-scheduler-material-ui'
// import axios from 'axios'
// import { toast } from 'react-toastify';

// import React, { useContext, useEffect, useState } from 'react'

// import { ATLAS_URI } from '../../Constants'
// import stateContext from '../../context/StateContext'

// const Calendar = (props) => {

//     const [addedAppointment, setAddedAppointment] = React.useState({});
//     const [appointmentChanges, setAppointmentChanges] = React.useState({});
//     const [editingAppointment, setEditingAppointment] = React.useState(undefined);

//     const contextState = useContext(stateContext)
//     const { configToken, operator } = contextState.state

//     // const [data, setData] = useState([])

//     const [followup, setFollowup] = useState([])

//     const [leadList, setLeadList] = useState([])


//     function addHours(date, hours) {
//         const dateCopy = new Date(date);
      
//         dateCopy.setHours(dateCopy.getHours() + hours);
      
//         return dateCopy;
//       }


//     async function getSchedularData() {
//         const dataToset = await axios.get(`${ATLAS_URI}/getFollowUpByTakenBy`, configToken)
//             .then(res => {
//                 if (res.status === 200) {
//                     let eventsTest = res.data
//                     eventsTest = eventsTest.map(eventInfo => {
//                         console.log('eventInfo::::::::', eventInfo)
//                         // const date = eventInfo.NextFollowUpDate.split('-')
//                         let followUpAt = new Date(eventInfo.NextFollowUpDate)
//                         let startAt = followUpAt
//                         const endAt = addHours(followUpAt, 1)
//                         eventInfo.startDate = startAt
//                         eventInfo.endDate = endAt
//                         const { id, name } = JSON.parse(eventInfo.id)
//                         eventInfo.title = name
//                         eventInfo.id = id
//                         //   console.log('eventInfo::: ', eventInfo)
//                         //   console.log('followup_date_col_formed', eventInfo.followup_date_col_formed)
//                         //   console.log('first::: ', new Date(eventInfo.followup_date_col_formed).getTime() )
//                         //   eventInfo.day = new Date(eventInfo.followup_date_col_formed).getTime()
//                         //   eventInfo.description = eventInfo.Note
//                         //   if(eventInfo.status) eventInfo.label = "indigo"
//                         //   else eventInfo.label = "red"
//                         //   eventInfo.leadDet = eventInfo.LeadInfo.name
//                         //   eventInfo.title = 'FollowUp'
//                         return eventInfo
//                     })
//                     // localStorage.setItem("savedEvents", JSON.stringify(eventsTest));
//                     return eventsTest
//                     // setData(eventsTest)
//                 }
//             })
//             props.setData(dataToset)
//     }

//     useEffect(async () => {
//         getSchedularData()
//     }, [])


//     async function getLeads() {

//         const leadsList = await axios.get(`${ATLAS_URI}/getLeadsForFollowup`, configToken)
//             .then(res => {
//                 if (res.status === 200) {
//                     let leadsData = res.data

//                     if (leadsData.length != 0) {
//                         const userID = operator.id;
//                         console.log('leadsData::::: in getLeads(): ', leadsData)
//                         console.log('operator.Feature:::::::::::::::::', operator.Feature)
//                         leadsData = operator.Feature.find(fe=>fe.sub_feature_id===2).is_view ? leadsData : leadsData.filter(lead => lead.assigned_to == userID);
//                         console.log('leadsData::::: in:::::::getLeads(): ', leadsData)
//                         return leadsData;
//                     }
//                     return leadsData
//                 }
//             })
//             setLeadList(leadsList)
//     }

//     useEffect(() => {
//         getLeads()
//     }, [])
    
//     // const data = [
//     //     { startDate: '2022-11-05', title: 'test1' },
//     //     { startDate: '2022-11-05', title: 'test2' },
//     //     { startDate: '2022-11-05', title: 'test3' }
//     // ]

//     const currentDate = new Date();

//     console.log('props.data::: ', props.data)


//     const TextEditor = (props) => {
//         console.log('props::: in TextEditor: ', props)


//         // eslint-disable-next-line react/destructuring-assignment
//         if (props.type === 'multilineTextEditor') {
//             return null;
//         } return <div><AppointmentForm.Select availableOptions={leadList} onValueChange={(leadInfo) => {console.log('leadInfo', leadInfo)}} {...props} />

//         </div>;
//     };

//     const BooleanEditor = props => {
//         console.log('props  = ', props)
//         return <AppointmentForm.BooleanEditor {...props} readOnly />
//     };

//     const getDaysArray = function(start, end) {
//         for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
//             arr.push(new Date(dt));
//         }
//         return arr;
//     };

//     function commitChanges({ added, changed, deleted }) {
//         console.log('added', added)
//         console.log('changed', changed)
//         console.log('deleted', deleted)

//         props.setData(prevState => {
//             if (added) {
//                 console.log('prevState::::::::: in added::  ', prevState)
//                 const startingAddedId = prevState.length > 0 ? prevState[prevState.length - 1].id + 1 : 0;
        
//                 console.log('added.startDate', added.startDate)
//                 console.log('added.endDate', added.endDate)
        
//                 const startAt = `${added.startDate.getFullYear()}-${added.startDate.getMonth()+1}-${added.startDate.getDate()}`
//                 const endAt = `${added.endDate.getFullYear()}-${added.endDate.getMonth()+1}-${added.endDate.getDate()}`
        
//                 // const startAt = added.startDate
//                 // const endAt = added.endDate

//                 const dates = getDaysArray(startAt, endAt)
                        
        
//                 console.log('dates:::: ', dates)
        
//                 const addedDates = dates.map((date, index) => {
//                     console.log('startingAddedId+index:::::::::::::::::: ', startingAddedId+index)
//                   // console.log('date1111', date)

//                   date = `${date.getFullYear()}-${('0'+(date.getMonth()+1)).slice(-2)}-${('0'+date.getDate()).slice(-2)}`
                  
//                 // console.log('date2222', date)
//                   // console.log('iiii: ', {id: startingAddedId+index, ...added, startDate: new Date(date + ':00:00'), endDate: new Date(date+':23:59')})
//                   return {id: startingAddedId+index, text: 'yyyy', ...added, startDate: new Date(date+' :00:00'), endDate: new Date(date+' :23:59')}
//                 })
        
//                 console.log('[...prevState, { id: startingAddedId, ...added }]:: ', [...prevState, ...addedDates])
//                 return [...prevState, ...addedDates]
//               }

//               if (changed) {
//                 prevState = prevState.map(workoutPlanInfo => (changed[workoutPlanInfo.id] ? { ...workoutPlanInfo, ...changed[workoutPlanInfo.id] } : workoutPlanInfo));
//                 return [...prevState]
//               }
//               if (deleted !== undefined) {
//                 prevState = prevState.filter(workoutPlanInfo => workoutPlanInfo.id !== deleted);
//                 return [...prevState]
//               }
//         })
//     }


//     const isDataValid = (data) => data.title;

//     const CommandButon = React.useCallback(
//         (props) => {
//           console.log('props::: ', props)
//           const { id } = props;
//           if (id !== "saveButton") {
//             return <AppointmentForm.CommandButton {...props} />;
//           }
    
//           const nextData = editingAppointment
//             ? { ...editingAppointment, ...appointmentChanges }
//             : addedAppointment;
//           console.log('nextData::', nextData)
//           const isValid = isDataValid(nextData);
//           const onExecute = isValid
//             ? props.onExecute
//             : () => toast.error('Please select title and link', { position: toast.POSITION.TOP_CENTER });
//           return <AppointmentForm.CommandButton {...props} onExecute={onExecute} />;
//         },[]
//         [addedAppointment, appointmentChanges, editingAppointment]
//       );

//     return (
//         <div id="calendar">
//             <Scheduler data={props.data}>
//           <ViewState />
//           <EditingState onCommitChanges={commitChanges} addedAppointment={addedAppointment}
//           onAddedAppointmentChange={setAddedAppointment}
//           appointmentChanges={appointmentChanges}
//           onAppointmentChangesChange={setAppointmentChanges}
//           editingAppointment={editingAppointment}
//           onEditingAppointmentChange={setEditingAppointment} />
//           <MonthView />
//           <Toolbar />
//           <DateNavigator />
//           <TodayButton />
//           <Appointments />
//           <AppointmentForm booleanEditorComponent={BooleanEditor} textEditorComponent={TextEditor} commandButtonComponent={CommandButon} />
//         </Scheduler>
//         </div>
//     )
// }

// export default Calendar;
