import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Badge from 'react-bootstrap/Badge';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';


function ProgramRender(props) {
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>

                    <Grid item xs={3}>
                        <Card>
                            <h3 className="text-center py-2"> <Badge bg="secondary">Programs</Badge> </h3>
                            {props.children && props.children.length && props.children[0]? props.children[0]: ''}
                        </Card>
                    </Grid>
                    
                    <Grid item xs={6}>
                        <Card>
                            <h3 className="text-center py-2"> <Badge bg="secondary">Sessions</Badge> 
                            {
                                (
                                    (props.showAddBtn && props.currentProgramId && props.currentProgramId>0)? 
                                    
                                    (<Button variant="outlined" startIcon={<AddIcon />} className="" onClick={props.showModal}>
                                        Add
                                    </Button>
                                    )
                                : <span className='ml-5'> &nbsp; </span>)
                            }
                            </h3>
                            
                            {
                                props.children && props.children.length && props.children[1]? props.children[1]: 
                                <div className="d-flex justify-content-center">
                                    <h4>Please select the Program</h4>
                                </div>
                            }
                        </Card>
                    </Grid>

                    <Grid item xs={3}>
                        <Card>
                            <h3 className="text-center py-2"> <Badge bg="secondary">Bookings</Badge> </h3>
                            {
                                props.children && props.children.length && props.children[2]? props.children[2]: 
                                <div className="d-flex justify-content-center">
                                    <h4>Please select the Session</h4>
                                </div>
                            }
                        </Card>
                    </Grid>

                </Grid>
            </Box>
        </>
    )
}

export default ProgramRender