import {
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid-pro";

import useCreateProgram from "../../../hooks/tableColumns/useCreateProgram";
import DataTable from "../../../components/DataTable/DataTable";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { useEffect, useReducer } from "react";
import ActionMenu from "../modals/ActionMenu";

const initialState = {
  rows: [],
  count: 0,
  currentRow: [],
  anchorEl: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "getprogramList":
      return {
        ...state,
        rows: action.payload?.list,
        count: action.payload?.count,
      };

    case "action":
      return {
        ...state,
        anchorEl: action.payload.anchorEl,
        currentRow: action.payload.currentRow,
      };

    default:
      throw new Error("Unknown action");
  }
};

const ProgramListTable = () => {
  const { columns } = useCreateProgram(handleClick);
  const apiRef = useGridApiRef();

  const [{ rows, count, anchorEl, currentRow }, dispatch] = useReducer(
    reducer,
    initialState
  );

  // Get Program list

  const getProgramList = async (filterObject = null) => {
    let fullstate = null;
    let url = "getProgramsList";

    if (filterObject) {
      fullstate = filterObject;
    } else {
      fullstate = apiRef.current.exportState();
    }

    let api = `${url}?page=${
      fullstate.pagination?.paginationModel.page + 1
    }&pageSize=${fullstate?.pagination?.paginationModel.pageSize}`;

    console.log(fullstate, "testtt");

    const request = await axiosFetch({
      url: api,
      method: "post",
      requestConfig: fullstate?.filter?.filterModel,
    });

    dispatch({ type: "getprogramList", payload: request.data });
  };

  // Next And Prev Page handler

  function handleDataGridChange() {
    apiRef.current.exportState();
    getProgramList();
  }

  // handle action click

  function handleClick(e, params) {
    dispatch({
      type: "action",
      payload: { anchorEl: e.target, currentRow: params },
    });
  }

  // handle action close

  const handleClose = () => {
    dispatch({ type: "action", payload: { anchorEl: null } });
  };

  useEffect(() => {
    getProgramList();
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div>
      <DataTable
        apiRef={apiRef}
        columns={columns}
        rows={rows}
        count={count}
        slots={{
          toolbar: CustomToolbar,
        }}
        handlePagination={handleDataGridChange}
        onFilterChange={handleDataGridChange}
      />
      <ActionMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        currentRow={currentRow}
        getProgramList={getProgramList}
      />
    </div>
  );
};

export default ProgramListTable;
