import { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const ClientList = ()=>{
    return(
        <div>
          <br/><br/>
 <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><strong>Name</strong></TableCell>
            <TableCell><strong>Client ID</strong></TableCell>
            <TableCell><strong>Phone Number</strong></TableCell>
            <TableCell><strong>Email ID</strong></TableCell>
            <TableCell><strong>Gender</strong></TableCell>
            <TableCell><strong>Age</strong></TableCell>
            <TableCell><strong>Height</strong></TableCell>
            <TableCell><strong>Current Weight</strong></TableCell>
            <TableCell><strong>BMI</strong></TableCell>
            <TableCell><strong>City</strong></TableCell>
            <TableCell><strong>Country</strong></TableCell>
            <TableCell><strong>Primary Goal</strong></TableCell>
            <TableCell><strong>Medical Condition</strong></TableCell>
            <TableCell><strong>Referral</strong></TableCell>
            <TableCell><strong>Salesperson</strong></TableCell>
            <TableCell><strong>Nutritionist</strong></TableCell> 
            <TableCell><strong>Fitness Coach</strong></TableCell> 
            <TableCell><strong>Status</strong></TableCell> 
            <TableCell><strong>Call Status</strong></TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
         
        </TableBody>
      </Table>
    </TableContainer>
        </div>
    )
}
export default ClientList;