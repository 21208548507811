import {
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Stack,
  Container,
  Button,
} from "@mui/material";

import { TextareaAutosize } from "@mui/base";
import { styled } from "@mui/system";
const Others = () => {
  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#f6f8fa",
    100: "#eaeef2",
    200: "#d0d7de",
    300: "#afb8c1",
    400: "#8c959f",
    500: "#6e7781",
    600: "#57606a",
    700: "#424a53",
    800: "#32383f",
    900: "#24292f",
  };

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
   
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );
  return (
    <div>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={4}>
          <Typography>How did you get to know about us?</Typography>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              placeholder="Please specify"
            />
            {/* <FormHelperText id="outlined-weight-helper-text">
              Weight22
            </FormHelperText> */}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography>More details about your Profession</Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledTextarea
            aria-label="empty textarea"
            placeholder="Please specify"
            width="100%"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>
            What is your Most Primary Fitness Goal from the list below
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              placeholder="Please specify"
            />
            {/* <FormHelperText id="outlined-weight-helper-text">
              Weight
            </FormHelperText> */}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            What is your Most Challenging Pain Point which is stopping you from
            achieving your fitness goals?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              placeholder="Please specify"
            />
            {/* <FormHelperText id="outlined-weight-helper-text">
              Weight
            </FormHelperText> */}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography>Any existing Body Pain?</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              placeholder="Please specify"
            />
            {/* <FormHelperText id="outlined-weight-helper-text">
              Weight
            </FormHelperText> */}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            {" "}
            On a scale of 1-10, how committed are you to achieving your Fitness
            Goals?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              placeholder="Please specify"
            />
            {/* <FormHelperText id="outlined-weight-helper-text">
              Weight
            </FormHelperText> */}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {" "}
            If your answer to the above question was below 8, then Please
            specify why?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledTextarea
            aria-label="empty textarea"
            placeholder="Please specify"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {" "}
            On a scale of 1-10, how committed are you to achieving your Fitness
            Goals?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              placeholder="Please specify"
            />
            {/* <FormHelperText id="outlined-weight-helper-text">
              Weight
            </FormHelperText> */}
          </FormControl>
        </Grid>
      </Grid>
      <Container>
        <Stack
          direction="row"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
          spacing={5}
        >
          <Button
            variant="outlined"
            sx={{
              textTransform: "capitalize",
              color: "black",
              borderColor: "black",
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: "capitalize",
              backgroundColor: "black",
              color: "white",
            }}
          >
            Save
          </Button>
        </Stack>
      </Container>
    </div>
  );
};

export default Others;
