import React, { useState, useEffect } from "react";
import { changeHandler as oldChangeHandler } from "../../Helpers/ChangeHandler";
import * as $ from "jquery";
import axios from "axios";
import DataTable from "../../components/DataTable";
import SelectBox from "../../components/SelectBox";
import BoxHeader from "../../components/BoxHeader";
import SideBarItem from "../../components/SideBarItem";
import Accordion from "react-bootstrap/Accordion";
import Box from "../Clients/Box";

function ClientNotes(props) {
  const [state, setState] = useState({
    newTableRow: props.state.id,
  });
  useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        newTableRow: props.state.LeadID,
      }));
    }, 100);
  }, []);

  const [ifBackPressed, setBackPressed] = useState(false);
  function changeHandler(e) {
    //oldChangeHandler(e, state, setState);
  }

  return (
    <section>
      <Box
        title={`Payment Info`}
        ifBackBox={ifBackPressed}
        onBackPress={(e) => {
          setBackPressed(false);
        }}
        // boxTools={[
        //   {
        //     title: "Add New",
        //     icon: "fas fa-plus",
        //     onClickEvent: (e) => { setBackPressed(true) },
        //   },
        // ]}
      />
      {ifBackPressed ? addPayment() : showPayments(state)}
    </section>
  );
}

function addPayment() {
  return (
    <div className="box-body bozero ">
      <div className="box-body">
        <form>
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label>Amount</label>
                <input
                  name="Amount"
                  type="text"
                  className="form-control"
                  id="newAmount"
                  required
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Payment Type1</label>
                <select id="newPaymentType" className="form-control" required>
                  <option value="Cash">Cash</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Card">Card</option>
                  <option value="Net Banking">Net Banking</option>
                  <option value="Paytm">Paytm</option>
                  <option value="Razorpay">Razorpay</option>
                  <option value="Upi">Upi</option>
                  <option value="Phonepe">Phonepe</option>
                  <option value="Gpay">Gpay</option>
                  <option value="Easypay">Easypay</option>
                  <option value="Others">Others</option>
                </select>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Payment Date</label> <small className="req"> *</small>
                <input
                  id="newPaymentDate"
                  name="PaymentDate"
                  type="date"
                  required
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Description</label>

            <textarea
              name="Description"
              id="newPaymentDescription"
              className="form-control"
              rows="4"
            ></textarea>
          </div>

          <div className="box-footer">
            <button type="submit" className="btn btn-info pull-right">
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

function showPayments(state) {
  return (
    <div className="px-3">
      <div>
        {typeof state.newTableRow?.Payments !== "undefined" && (
          <DataTable
            tableHeader={[
              "id",
              "Amount",
              "Type",
              "Description",
              "Date",
              "ProductName",
              "ProductId",
            ]}
            tableBody={state.newTableRow.Payments}
            searchField="Date"
            deleteFromTable={(e) => {}}
            noActions=""
          />
        )}
      </div>
    </div>
  );
}
export default ClientNotes;
