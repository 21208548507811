import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { TextField } from '@mui/material';
import { axiosFetch } from '../../../Utlis/AxiosFetch';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';


const DietPreferrence = (props) => {

  
    const theme = useTheme();
  const [cuisinesName, setcuisinesName] = useState([]);
  const [allergiName, setAllergiName] = useState([])
  const [dietPreference, setDietPreference] = useState("")
  const [mealQuantity, setMealQuantity] = useState("")
  const [seedsQuantity, setseedsQuantity] = useState("")
  const [allergiSelectedOthers, setAllergiSelectedOthers] = useState(false)
  const [editable, setEditable] = useState(false)
  const history=useHistory()
  const [dietList, setDietList] = useState([])
  const [allerigies, setAllerigies] = useState([])
  const [cuisines, setCuisines] = useState([])
  const [foodDislikes, setFoodDislikes] = useState("")
  const [otherAllergies, setOtherAllergies] = useState("")
  let routeParams=useParams()
  const [errorForDietPreference, setErrorForDietPreference] = useState("")
  const [errorForallergies, setErrorForallergies] = useState("")
  const [errorForPreferredCuisine, setErrorForPreferredCuisine] = useState("")
  const [errorForFoodDisLikes, setErrorForFoodDisLikes] = useState("")
  const [errorForNumberOfMeals, setErrorForNumberOfMeals] = useState("")
  const [errorForSeedsTaken, setErrorForSeedsTaken] = useState("")
  const [errorForOtherAllergies, setErrorForOtherAllergies] = useState("")

  const handleChangeAllergyName = (event) => {
    const {
      target: { value },
    } = event;
    setAllergiName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  
    if((event.target.value).includes(13)){
       
        setAllergiSelectedOthers(true)
        setErrorForOtherAllergies("Please Enter Something")
    }
    else{
      setAllergiSelectedOthers(false)
      setErrorForOtherAllergies("")
      setOtherAllergies("")

    }
    if(event.target.value==[" "]){
        setAllergiSelectedOthers(false)

    }
    setErrorForallergies("")
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setcuisinesName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    setErrorForPreferredCuisine("")
  };

  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      transform:'translateY(-48px)'
    },
   
  },
};

// const cuisines = [
//   "North Indian",
//   "South Indian",
//   "East Indian",
//   "West Indian",
//   "Continental",
//   "Chinese"
// ];

// const allerigies=[
// "Peanuts",
// "Egg",
// "Poultry",
// "Pork",
// "Beef",
// "Egg",
// "Lamb/Mutton",
// "Dairy",
// "Soy",
// "Gluten",
// "Tree nuts",
// 'Seafoods',
// 'Sesame seeds',
// "Others(Please Specify)"
// ]

const seedsTaken=[
  "Yes",
   "No"
]

function getStyles(name, cuisinesName, theme) {
  return {
    fontWeight:
      cuisinesName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const handleChangeDietPreference=(e)=>{
setDietPreference(e.target.value)
setErrorForDietPreference("")
}

const handleChangeMealQuantity=(e)=>{
setMealQuantity(e.target.value)
setErrorForNumberOfMeals("")
}

const NumberOfSeedsQuantity=(e)=>{
    setseedsQuantity(e.target.value)
    setErrorForSeedsTaken("")
}
// const dietList=[
//   "Vegetarian",
//   "Vegan",
//   "Eggetarian",
//   "Non-Vegetaria"
// ]

const editAllDietPreference=(e)=>{
  e.preventDefault()
  setEditable(true)
  
  if(allergiName.includes(13)){
  setAllergiSelectedOthers(true)
  }
}

const handleChangeFoodDislikes=(e)=>{
  setFoodDislikes(e.target.value)
  setErrorForFoodDisLikes("")
}

const handleChangeAllergies=(e)=>{
setOtherAllergies(e.target.value)
if((e.target.value).trim()===""){
  setErrorForOtherAllergies("Please Enter Something")
}else{
  
  setErrorForOtherAllergies("")
}
}
//[04:59 pm] Nikhita modak


const getAllDropdown=()=>{
axiosFetch({
  url:`/getAllDietDropdownCRM`,
  method:'get'
}).then(res=>{
  if(res.status===200){
    
    setDietList(res?.data?.body?.DietPreference)
    setAllerigies(res?.data?.body?.DietAnyAllergies)
    setCuisines(res?.data?.body?.DietPreferredCuisine)
  }
  else if(res.status===400){
    toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
  }
  else if (res.status === 401) {
    history.push("/");
  } 
}).catch(err=>console.error(err))
}

const getDietPreference=()=>{
  

      if(props?.props !== null){

        if(props?.props?.dietPreference!== null && props?.props?.dietAnyAllergies!==null
          && props?.props?.dietPreferredCuisine!==null 
          && props?.props?.food_dislike !==null
          && props?.props?.number_of_meals !==null
          && props?.props?.seeds_taken !==null
          
          ){
            // let vkk= allerigies.filter(c=>(props?.props?.LeadID?.dietAnyAllergies.includes(c.title)))
          
            setDietPreference(props?.props?.dietPreference)
            // setAllergiName(props?.props?.dietAnyAllergies)
            setcuisinesName(props?.props?.dietPreferredCuisine)
            setFoodDislikes(props?.props?.food_dislike)
            setMealQuantity(props?.props?.number_of_meals)
            setseedsQuantity(props?.props?.seeds_taken)
          }
          if(props?.props?.otherAllergies !== null){
            setOtherAllergies(props?.props?.otherAllergies)
          }

      }
     
}
// Function to get allergen IDs from names
const getSelectedAllergenIds = (selectedNames, allAllergies) => {
  const selectedIds = [];
  selectedNames?.forEach((selectedName) => {
    const allergen = allAllergies?.find((allergy) => allergy.title === selectedName);
    if (allergen) {
      selectedIds.push(allergen.id);
      
    }
  });
  return selectedIds;
};


useEffect(() => {

  getAllDropdown()
  getDietPreference()
}, [])
useEffect(() => {
  const dietAllergiId=getSelectedAllergenIds(props?.props?.dietAnyAllergies,allerigies)
  setAllergiName(dietAllergiId)
}, [props?.props?.dietAnyAllergies,allerigies])






const LeadId=useParams()
const saveDietPreference=()=>{




  let requestBody={
    id:LeadId.id,
    dietPreference:dietPreference,
    dietAnyAllergies: allerigies.filter((allergy) => allergiName.includes(allergy.id)).map(c=>c.title),  //allerigies.find((allergy) => allergy.id === allergiName)?.title
    dietPreferredCuisine:cuisinesName,
    food_dislike:foodDislikes,
    otherAllergies:otherAllergies,
    number_of_meals:mealQuantity,
    seeds_taken:seedsQuantity
  }
  if(dietPreference.trim() !== "" &&
  dietPreference !==undefined &&
  allergiName?.length !==0 &&
  cuisinesName?.length !==0 &&
  ((foodDislikes?.length>0) && ( foodDislikes.trim() !=="") ) &&
  mealQuantity !=="" &&
  mealQuantity !== undefined &&
  seedsQuantity !== "" &&
  seedsQuantity !==undefined
  
  )
  
  {

    if( (allergiName.includes(13)   && otherAllergies.trim() !=="" )|| (!allergiName.includes(13)  )){

      axiosFetch({
        url:`/updateLead/${LeadId.id}`,
        method:'post',
        requestConfig:requestBody
      }).then(res=>{
        if(res.status===200){
        
          toast.success("Saved Successfully",{ position: toast.POSITION.TOP_CENTER })
          setTimeout(() => {
        
            window.location.reload()
          }, 1000);
    
        }
        else if(res.status===400){
          toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
        }
        else if (res.status === 401) {
          history.push("/");
        } 
      }).catch(err=>console.error(err))
    }
    else{
      setErrorForOtherAllergies("Please Enter Something")
    }

  }
  else{
    if(dietPreference.trim() ==="" || dietPreference===undefined){
      setErrorForDietPreference("Please Select Something")
    }
    if(allergiName?.length===0){
      setErrorForallergies("Please Select Something")
    }
   
    if(cuisinesName?.length===0){
      setErrorForPreferredCuisine("Please Select Something")
    }
    if((foodDislikes.trim() ==="") || (foodDislikes ===" ")){
      setErrorForFoodDisLikes("Please Enter Something")
    }
    if(mealQuantity ===""  || mealQuantity === undefined){
      setErrorForNumberOfMeals("Please Select Something")
    }
    if(seedsQuantity === "" || seedsQuantity===undefined){
      setErrorForSeedsTaken("Please Select Something")
    }
  }
}

// axios.get(`${ATLAS_URI}/getClientByID/` + routeParams.id, configToken)


  return (
    <div>
        <div className='row'>
            <div className='col-md-4'>
                <div className='form-group'>
                    {/* <label>Diet Preference</label> */}

                    <FormControl fullWidth disabled={!editable}>
                      <label>Diet preferences &nbsp;  <small className='req'>*</small></label>
  
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={dietPreference}
    // label="Age"
    
    onChange={handleChangeDietPreference}
    // style={{height:'45px'}}
    MenuProps={{
      anchorOrigin: {
        vertical: "center",
        horizontal: "center"
      },
      transformOrigin: {
        vertical: "center",
        horizontal: "center"
      },
      getContentAnchorEl: null
    }}
  >

    {dietList?.length > 0 && dietList?.map(c=>{
     return  <MenuItem  value={c?.title}> {c?.title}</MenuItem>
    })}
    

  </Select>
</FormControl>
<p className='req'>{errorForDietPreference}</p>
                    {/* <select className='form-control'>
                        <option disabled hidden selected>Select</option>
                        <option>Vegetarian</option>
                        <option>Vegan</option>
                        <option>Eggetarian</option>
                        <option>Non-Vegetarian</option>

                    </select> */}
                </div>
            </div>

            <div className='col-md-4'>
                <div className='form-group'>
                    {/* <label>Any Allergies</label> */}
                    <FormControl sx={{  width: '100%' }} disabled={!editable}>
                      <label>Any Allergies &nbsp;  <small className='req'>*</small></label>
                     
      
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={ allergiName}
          onChange={handleChangeAllergyName}
          renderValue={(selected) => (
            
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            
              {selected?.map((value) => (
                
                <Chip key={value} label={allerigies?.length>0 && allerigies.find((allergy) =>( allergy.id === value || allergy.title === value ))?.title} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          
         
          // style={{height:'45px'}}
        >
         
          {allerigies?.length>0 &&allerigies?.map((name) => (
            <MenuItem key={name?.id} value={name?.id }>
            <Checkbox checked={ allergiName.indexOf(name?.id)  > -1} />
            <ListItemText primary={name?.title} />
          </MenuItem>
            
          ))}
        </Select>
      </FormControl>
<p className='req'>{errorForallergies}</p>

                  
                </div>
            </div>

            <div className='col-md-4'>
                <div className='form-group' >
                    

{
     otherAllergies !==" "?
                    <TextField disabled={!allergiSelectedOthers} value={otherAllergies} onChange={handleChangeAllergies}  type='text' placeholder='Please Specify'   sx={{width:'100%',marginTop:'20px'}}/>
:
<TextField disabled={!allergiSelectedOthers} value={otherAllergies} onChange={handleChangeAllergies}  type='text' placeholder='Please Specify'   sx={{width:'100%',marginTop:'20px'}}/>
                    }
<p className='req'>{errorForOtherAllergies}</p>
                    
                    {/* <select className='form-control'>
                        <option disabled hidden selected>Select</option>
                        <option>North Indian</option>
                        <option>South Indian</option>
                        <option>East Indian</option>
                        <option>West Indian</option>
                        <option>Continental</option>
                        <option>Chinese</option>


                    </select> */}
                </div>
            </div>

        </div><br/>


        <div className='row'>
            
            <div className='col-md-4'>
                <div className='form-group'>
                    {/* <label>Food Dislikes</label> */}
                    <FormControl sx={{  width: '100%' }} disabled={!editable}>
                      <label>Preferred Cuisine &nbsp;  <small className='req'>*</small></label>
        
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={ cuisinesName || []}
          onChange={handleChange}
           
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected?.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          // style={{height:'45px'}}
        >
         
          {cuisines?.length>0 && cuisines?.map((name) => (
            <MenuItem key={name?.id} value={name?.title}>
            <Checkbox checked={cuisinesName?.indexOf(name?.title) > -1} />
            <ListItemText primary={name?.title} />
          </MenuItem>
            
          ))}
        </Select>
      </FormControl>
<p className='req'>{errorForPreferredCuisine}</p>
                    
                    {/* <input className='form-control' type='text' placeholder='Please Specify'/> */}
                </div>
            </div>
            <div className='col-md-4'>
                <div className='form-group'>
                    <label>Food Dislikes &nbsp;  <small className='req'>*</small></label>
                    <TextField  value={foodDislikes} disabled={!editable} onChange={handleChangeFoodDislikes}  type='text' placeholder='Please Specify'  sx={{width:'100%'}}/>
<p className='req'>{errorForFoodDisLikes}</p>

                    
                    {/* <select className='form-control'>
                        <option disabled hidden selected>Select</option>
                        {Array.apply(null, { length: 8 }).map(
                                  (value, index) => (
                                    <option
                                    
                                      value={index + 1}
                                    >
                                      {index + 1}
                                    </option>
                                  )
                                )}
                        

                    </select> */}
                </div>
            </div>

            <div className='col-md-4'>
                <div className='form-group'>
                    {/* <label>Are Seeds taken in seed cycling?</label> */}

                    <FormControl fullWidth disabled={!editable}>
                      <label>Number Of Meals &nbsp;  <small className='req'>*</small></label>
  
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
   
    value={mealQuantity}
    // label="Age"
    onChange={handleChangeMealQuantity}
    // style={{height:'45px'}}
  >
    {Array.apply(null, { length: 8 }).map(
                                  (value, index) => (
                                    <MenuItem 
                                    
                                      value={index + 1}
                                    >
                                    {index + 1}</MenuItem>
                                   
                                  )
                                )}
  

  </Select>
</FormControl>
<p className='req'>{errorForNumberOfMeals}</p>
                    
                    {/* <select className='form-control'>
                        <option disabled hidden selected>Select</option>
                        <option>Yes</option>
                        <option>No</option>

                    </select> */}
                </div>
            </div>
        </div><br/>

        <div className='row'>
            <div className='col-md-4'>
                <div className='form-group'>
                <FormControl fullWidth disabled={!editable}>
                  <label>Are Seeds taken in seed cycling? &nbsp;  <small className='req'>*</small></label>
  
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"

    value={seedsQuantity}
    // label="Age"
    onChange={NumberOfSeedsQuantity}
    // style={{height:'45px'}}
  >
    {seedsTaken?.length>0 && seedsTaken?.map(c=>{
      return <MenuItem value={c}>{c}</MenuItem>
    })}
    

  

  </Select>
</FormControl>
<p className='req'>{errorForSeedsTaken}</p>

                </div>
            </div>
        </div>
        <div className="box-footer d-flex justify-content-center" >
            <button type="submit" onClick={editAllDietPreference}  className="btn btn-info pull-right" style={{marginRight:'20px',backgroundColor:'white',color:'black',border:'1px solid black',width:'120px'}}>Edit</button>
            <button type="submit" onClick={saveDietPreference} disabled={!editable}   className="btn btn-info pull-right" style={{backgroundColor:'black',color:'white',width:'120px'}} >Save</button>


            </div>

        
    </div>
  )
}

export default DietPreferrence
