import {
  DialogContent,
  DialogContentText,
  Stack,
  DialogTitle,
  Dialog,
  Typography,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
  Button,
  Grid,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Call from "../../assets/Call.png";
import { MuiTelInput } from "mui-tel-input";

import { axiosFetch } from "../../Utlis/AxiosFetch";
import { useHistory } from "react-router-dom";

import PIOPIY from "piopiyjs";

import CallPopup from "./CallPopup";

import {
  updateId,
  updateFlag,
  updateCallType,
  updateTriggerEndCall,
  updateCallPlatForm,
  updateCallId,
} from "../store/Call/CallItem";
import { useDispatch, useSelector } from "react-redux";
import stateContext from "../../context/StateContext";
import { PlivoContext } from "../../context/PlivoContext";
import { toast } from "react-toastify";

const leadCategory = [
  { id: 1, name: "First Call" },
  { id: 3, name: "Follow up call" },
];

const TeliCall = (props) => {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.leadId);
  const flag = useSelector((state) => state.call.flag);
  const theme = useTheme();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(true);

  const contextState = useContext(stateContext);
  const { setCurrentCallStatus, currentCallStatus } = useContext(PlivoContext);

  const [altPhoneNumber, setAltPhoneNumber] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const [disableLeadCall, setDisableLeadCall] = useState(true);
  const [disableAltCall, setDisableAltCall] = useState(true);

  const [isErrCategory, setIsErrCategory] = useState(false);

  const [categoryList, setCategoryList] = useState(leadCategory);

  const [callType, setCallType] = useState("telecmi");

  const [current_pathname, setCurrent_pathname] = useState(false);

  // const [isTeleAccess, setIsTeleAcess] = useState(false);

  const piopiy = new PIOPIY({
    name: "Test Call",
    debug: false,
    autoplay: true,
    ringTime: 60,
  });

  const telecmi_psd = JSON.parse(
    window.localStorage.getItem("operator")
  ).LeadName;

  const telecmi_userId = JSON.parse(
    window.localStorage.getItem("operator")
  ).LeadAddress;

  const initialLogin = () => {
    if (telecmi_psd && telecmi_userId) {
      piopiy.login(telecmi_userId, telecmi_psd, "sbcind.telecmi.com");
      dispatch(updateFlag(false));
    }
  };

  // useEffect(() => {
  //   if (telecmi_psd && telecmi_userId) {
  //     setIsTeleAcess(true);
  //   }
  // }, []);

  // const initialLogin = () => {
  //   piopiy.login(
  //     process.env.REACT_APP_TELE_ID,
  //     process.env.REACT_APP_TELE_KEY,
  //     "sbcind.telecmi.com"
  //   );

  //   dispatch(updateFlag(false));
  // };

  const handleClose = () => {
    setOpen(false);

    if (props.close !== undefined) {
      props.close(true);
    }
  };

  const handleChangeCategory = (event) => {
    dispatch(updateId(event.target.value));

    setSelectedCategory(event.target.value);
    if (event.target.value === "") {
      setIsErrCategory(true);
      setDisableLeadCall(true);
      setDisableAltCall(true);
    } else {
      setDisableLeadCall(false);
      setDisableAltCall(false);
      setIsErrCategory(false);
    }
  };

  const handleChangeAltPhNo = (value) => {
    setAltPhoneNumber(value);
    setDisableAltCall(false);
  };

  // const getCategoryList = () => {
  //   axiosFetch({
  //     url: "/interactionCategories",
  //     method: "get",
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let response = res.data;

  //         if (window.location.pathname.includes("Clients")) {
  //           response = response.filter((data) => data.id !== 5);
  //         }

  //         setCategoryList(response);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  const handleMakeCall = (callNumber) => {
    if (!telecmi_psd && !telecmi_userId) {
      toast.warn("Permission Denied Notification", {
        position: toast.POSITION.TOP_CENTER,
      });

      props.close(true);
      return;
    }

    const leadId = props?.selectedRow?.id;
    const dailNumber = callNumber.replace(/[\s+]/g, "");

    if (
      currentCallStatus === "ended" ||
      currentCallStatus === "Canceled" ||
      currentCallStatus === "Busy" ||
      currentCallStatus === ""
    ) {
    } else {
      // alert(`already call connected : ${currentCallStatus}`)
      toast.warn("During a call, you can not make another call !", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    piopiy.call(dailNumber);

    dispatch(updateTriggerEndCall(piopiy));
    dispatch(updateCallPlatForm("telecmi"));

    // if (window.location.pathname.toLowerCase().includes("lead")) {
    //   history.push({
    //     pathname: `/lead/${leadId}`,
    //     state: { tableValue: 1, callType: "telecmi" },
    //   });
    // }

    history.push({
      pathname: `/lead/${leadId}`,
      state: { tableValue: 1, callType: "telecmi" },
    });
    props.close();
  };

  useEffect(() => {
    if (flag) {
      initialLogin();
    }

    //setCurrent_pathname(true);
    // setDisableLeadCall(false);
  }, []);

  // useEffect(() => {
  //   if (props.role) {
  //     setDisableLeadCall(false);
  //   }
  // }, [props.role]);

  useEffect(() => {
    // let interval = null;
    piopiy.on("ringing", function (object) {
      dispatch(updateCallId(object.call_id));
      dispatch(updateCallType("ringing"));
      setCurrentCallStatus("ringing");
    });

    piopiy.on("answered", function (object) {
      dispatch(updateCallType("answered"));
      setCurrentCallStatus("answered");

      // const startDate = new Date();

      // interval = setInterval(() => {
      //   let endDate = new Date();
      //   let elapsed = endDate - startDate;
      //   let hours = Math.floor(elapsed / 3600000);
      //   let minutes = Math.floor((elapsed - hours * 3600000) / 60000);
      //   let seconds = Math.floor(
      //     (elapsed - hours * 3600000 - minutes * 60000) / 1000
      //   );
      //   if (hours < 10) {
      //     hours = "0" + hours;
      //   }
      //   if (minutes < 10) {
      //     minutes = "0" + minutes;
      //   }
      //   if (seconds < 10) {
      //     seconds = "0" + seconds;
      //   }
      //   let display = hours + ":" + minutes + ":" + seconds;

      //   setDuration(display);
      // }, 1000);
    });

    piopiy.on("hangup", function (object) {
      dispatch(updateCallType("ended"));
      setCurrentCallStatus("ended");
      // setDuration("");
    });

    piopiy.on("ended", function (object) {
      dispatch(updateCallType("ended"));
      setCurrentCallStatus("ended");
      // setDuration("");
    });
  }, [piopiy.on]);

  // useEffect(() => {
  //   getCategoryList();
  // }, []);

  useEffect(() => {
    if (current_pathname) {
      setSelectedCategory(5);
      setDisableLeadCall(false);
      dispatch(updateId(5));
    }
  }, [current_pathname]);

  useEffect(() => {
    if (props.role === "other") {
      setCurrent_pathname(false);
    } else if (props.role === "sale") {
      // setCurrent_pathname(true);
      setSelectedCategory(5);
      setDisableLeadCall(false);
    }
  }, [props.role]);

  // useEffect(() => {
  //   async function testCall() {
  //     await axiosFetch({
  //       url: `/servetel/makeOutboundCall`,
  //       method: "post",
  //       requestConfig: {
  //         agentPhoneNumber: "+917373234134",
  //         leadPhoneNumber: "+916380310955",
  //         leadId: 98839,
  //         userId: 1,
  //       },
  //     });
  //   }

  //   testCall();
  // }, []);

  return (
    <>
      {callType === "telecmi" && (
        <Dialog onClose={handleClose} fullScreen={fullScreen} open={open}>
          <span
            style={{ marginTop: "10px", marginLeft: "83%", color: "#757575" }}
          >
            <CloseIcon onClick={handleClose} />
          </span>

          <DialogTitle
            sx={{ fontWeight: "600", size: "18px", lineHeight: "27px" }}
          >
            Call Option
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                gap: "40px",
                width: {
                  xl: "379px",
                  md: "100%",
                  sm: "100%",
                },
                marginBottom: "20px",
              }}
            >
              {/* <List>
            <ListItem> */}

              <Grid container spacing={3} sx={{ width: "100%" }}>
                <Grid item xl={12} md={12} sm={6}>
                  <Typography
                    sx={{
                      color: "black",
                      width: "150px",
                      height: "24px",
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      fontWeight: "500",
                    }}
                  >
                    Call Type<span style={{ color: "red" }}>*</span>
                  </Typography>

                  <FormControl sx={{ top: "7px" }} fullWidth>
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      // disabled={isTeleAccess}
                      value={callType}
                      onChange={(e) => {
                        setCallType(e.target.value);
                      }}
                    >
                      <MenuItem value={"telecmi"}>TeleCMI</MenuItem>
                      <MenuItem value={"plivo"}>Plivo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xl={12} md={12} sm={6}>
                  <Typography
                    sx={{
                      color: "black",
                      width: "76px",
                      height: "24px",
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      fontWeight: "500",
                    }}
                  >
                    Category<span style={{ color: "red" }}>*</span>
                  </Typography>

                  <FormControl
                    sx={{ top: "7px", marginBottom: "20px" }}
                    fullWidth
                  >
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      disabled={current_pathname}
                      value={selectedCategory}
                      onChange={handleChangeCategory}
                    >
                      <MenuItem value="">
                        <em style={{ color: "#9da5b1" }}>
                          Please Select Category
                        </em>
                      </MenuItem>
                      {categoryList.map((category) => (
                        <MenuItem value={category.id}>{category.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: "red" }}>
                      {isErrCategory ? "Please Select Category" : ""}
                    </FormHelperText>
                  </FormControl>

                  <Stack direction="row" spacing={2}>
                    <Typography
                      sx={{
                        color: "black",
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "24px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Mobile:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "400px",
                        fontSize: "16px",
                        marginLeft: "4px !important",
                      }}
                    >
                      {props?.selectedRow?.contact_number}
                    </Typography>

                    <Button
                      onClick={() =>
                        handleMakeCall(props?.selectedRow?.contact_number)
                      }
                      disabled={disableLeadCall}
                    >
                      <div
                        style={{
                          width: "37px",
                          height: "38px",
                          borderRadius: "6px",
                          backgroundColor: disableLeadCall
                            ? "#D0D0D0"
                            : "green",
                          marginTop: "-10px",
                        }}
                      >
                        <img
                          src={Call}
                          style={{
                            width: "18px",
                            height: "18px",
                            margin: "9px",
                            filter: "brightness(0) invert(1)",
                          }}
                        />
                      </div>
                    </Button>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xl={12}
                  md={12}
                  sm={12}
                  sx={{ marginBottom: "20px" }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Alternative Number
                  </Typography>

                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MuiTelInput
                      // sx={{
                      //   width: "378px",
                      //   height: "46px",
                      //   borderRadius: "6px",
                      //   border: "2px",
                      //   top: "7px",
                      // }}
                      fullWidth
                      value={altPhoneNumber}
                      onChange={handleChangeAltPhNo}
                      defaultCountry="IN"
                    />
                    <Button
                      disabled={disableAltCall}
                      onClick={() => handleMakeCall(altPhoneNumber)}
                    >
                      <div
                        style={{
                          width: "37px",
                          height: "38px",
                          borderRadius: "6px",
                          backgroundColor: disableAltCall ? "#D0D0D0" : "green",
                        }}
                      >
                        <img
                          src={Call}
                          style={{
                            width: "18px",
                            height: "18px",
                            margin: "9px",
                            filter: "brightness(0) invert(1)",
                          }}
                        />
                      </div>
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}

      {callType === "plivo" && (
        <CallPopup
          selectedRow={props?.selectedRow}
          close={props.close}
          callType={"plivo"}
          role={props.role}
        />
      )}
    </>
  );
};

export default TeliCall;
