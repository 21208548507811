import { Grid, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import ControlledTextField from "../ControlledTextField";
import { Controller } from 'react-hook-form';
import ControlledAutocomplete from "../ControlledAutocomplete";
import ControllerDatetimepicker from "../ControlledDatetimepicker";
const FormRenderer = ({
  data,
  name,
  control,
  inline,
  outline,
  className,
  type,
  onClick,
  disabled = false,
}) => {
  return (
    <>
            <Grid container spacing={2}>
    {data.map((formItem, key) => 
        <Grid item xs={3}>
          {formItem.type === "TextField" &&
            <ControlledTextField  
            label={formItem.label}
            name={formItem.name}
            control={control}
            rules={formItem.rules}
            fullWidth={true}
            variant="outlined"
            />
          }
          {formItem.type === "Autocomplete" &&
            <ControlledAutocomplete  
            label={formItem.label}
            name={formItem.name}
            control={control}
            rules={formItem.rules}
            fullWidth={true}
            variant="outlined"
            options={[]}
            />
          }
          {formItem.type === "Datepicker" &&
            <ControllerDatetimepicker    
            label={formItem.label}
            name={formItem.name}
            control={control}
            rules={formItem.rules}
            fullWidth={true}
            variant="outlined"
            options={[]}
            />
          }
          
    </Grid>
    )}
   </Grid>
     
    </>
  );
};

export default FormRenderer;

//btn-disabled
