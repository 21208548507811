

const workoutform = [
    {
        "type": "Autocomplete",
        "label": "Diet Preference",
        "name": "diet_preference",
        "rules": {

        }
    },
    {
        "type": "TextField",
        "label": "Prefered Excercise Time",
        "name": "excercise_time",
        "rules": {

        }
    },
    {
        "type": "Autocomplete",
        "label": "Current Fitness Regime",
        "name": "fitness_regime",
        "rules": {

        }
    },
    {
        "type": "TextField",
        "label": "Any Challenges Faced",
        "name": "challanges",
        "rules": {

        }
    },
    {
        "type": "Autocomplete",
        "label": "How often do you workout in a week",
        "name": "workout_freq",
        "rules": {

        }
    },
    {
        "type": "TextField",
        "label": "Workout Preference",
        "name": "cravings",
        "rules": {

        }
    },
    {
        "type": "Autocomplete",
        "label": "Water Intake",
        "name": "water_intake",
        "rules": {

        }
    }
    
    
]

export default workoutform
