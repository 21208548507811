// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.programnew-date-container {
  width: 200px;
}

.programnew-date-container input {
  padding: 8.5px 10px;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Programs/components/ProgramDetailsForm.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".programnew-date-container {\n  width: 200px;\n}\n\n.programnew-date-container input {\n  padding: 8.5px 10px;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
