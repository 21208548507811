// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.food-con {
    display: flex;
    justify-content:space-between;
    margin-left: 20px;
    
}

.table-con{
    display: flex;
    align-items: flex-start;
    gap: 50px;
}


.food-time-btn-con{
    margin-right: 20px;
     display: flex;
     align-items: flex-start;
}

.food-time-btn-con button {
    border: none;
    padding: 6px 12px;
    display: block;
    background-color: #2D2D2D;
    color: #fff;
    border-radius: 6px;

}

.food-time-btn-con button:first-child {
    margin-right: 40px;
}

.time-input {
    border: none;
}

.time-input:focus {
    outline: none;
  }`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/clientPlans/PreferredFoodTime.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,iBAAiB;;AAErB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;AACb;;;AAGA;IACI,kBAAkB;KACjB,aAAa;KACb,uBAAuB;AAC5B;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,cAAc;IACd,yBAAyB;IACzB,WAAW;IACX,kBAAkB;;AAEtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;EACf","sourcesContent":[".food-con {\n    display: flex;\n    justify-content:space-between;\n    margin-left: 20px;\n    \n}\n\n.table-con{\n    display: flex;\n    align-items: flex-start;\n    gap: 50px;\n}\n\n\n.food-time-btn-con{\n    margin-right: 20px;\n     display: flex;\n     align-items: flex-start;\n}\n\n.food-time-btn-con button {\n    border: none;\n    padding: 6px 12px;\n    display: block;\n    background-color: #2D2D2D;\n    color: #fff;\n    border-radius: 6px;\n\n}\n\n.food-time-btn-con button:first-child {\n    margin-right: 40px;\n}\n\n.time-input {\n    border: none;\n}\n\n.time-input:focus {\n    outline: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
