import React, { useEffect, useState } from "react";
import CloseButton from "react-bootstrap/CloseButton";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FreshLeads from "./FreshLeads";
import LeadDetails from "./LeadDetails";
import { GrClose } from "react-icons/gr";
import Calendar from "../../components/CalendarDev/Calendar";

function PopmodelCalendar(props) {
  const [show, setShow] = useState(props.show1);

  const [data, setData] = useState([]);

  const handleClose = () => {
    props.setShow1(false);
  };

  useEffect(() => {
    props.show1 && setShow(true);
  }, [props.show1]);

  const handleShow = () => setShow(props.show1);
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const saveFollowup = async (e) => {
    e.preventDefault();
  };

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Followup</Modal.Title>
          <GrClose
            style={{
              backgroundColor: isHovering ? "#FF0000" : "",
              color: isHovering ? "#fff" : "",
              border: isHovering ? "1px solid" : "",
              height: isHovering ? "30px" : "",
              width: isHovering ? "30px" : "",
              opacity: isHovering ? "0.7" : "",
            }}
            onMouseOver={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <Calendar data={data} setData={setData} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
          <Button variant="primary" onClick={saveFollowup}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PopmodelCalendar;
