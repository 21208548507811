// import React, { useContext, useEffect, useState } from 'react'
// import Paper from '@mui/material/Paper';
// import stateContext from '../context/StateContext';
// import { ViewState, EditingState, IntegratedEditing } from '@devexpress/dx-react-scheduler';
// import {
//   Scheduler,
//   DateNavigator,
//   WeekView,
  
//   Appointments,
//   AppointmentTooltip,
//   AppointmentForm,
//   Toolbar,
//   ViewSwitcher,
//   MonthView,
//   DayView,
//   TodayButton,
//   ConfirmationDialog,
// } from '@devexpress/dx-react-scheduler-material-ui';
// import { ContactlessOutlined } from '@material-ui/icons';
// import axios from 'axios';
// import { ATLAS_URI } from '../Constants';
// import { Button } from 'react-bootstrap';
// import { toast } from 'react-toastify';

// function CalenderAppointment(props) {
   
// const [data,setData] = useState([])
// const [currentViewName, setCurrentViewName] = useState('Day')
// const [leads,setLeads] = useState()

// const [allocationFormOpen, setAllocationFormOpen] = useState(false);
// const [formOpen, setFormOpen] = useState(false);
// const [appointmentDataInfo, setAppointmentDataInfo] = useState({
//   startDate:'',
//   endDate:''
// })
// const [isAppointmentSlot,setIsAppointmentSlot] = useState(false)
// const [visible,setVisible] = useState(false)

// const [tooltipData, setTooltipData] = useState({})

// const contextState = useContext(stateContext)
// const{configToken, operator}=contextState.state

// const durationCustomFieldMap = [{id: 1, text: '5'}, {id: 2, text: '10'}, {id: 3, text: '15'}, {id: 4, text: '30'}]


// const SelectEditor = (props) => {
//   // eslint-disable-next-line react/destructuring-assignment
//   console.log(props,'props')
//   if (props.type === "multilineTextEditor") {
//     return null;
//   }
//   return (
//     <AppointmentForm.Select
//       availableOptions={leads}
//       {...props}
//     />
//   );
// };

// const TextEditor = (props1) => {
//   console.log(props1, "props1");
//   if (props1.type === "multilineTextEditor") {
//     return null;
//   }
//   return (
//     <AppointmentForm.TextEditor {...props1} value="Slot" readOnly={true} />
//   );
// };

// const DurationLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
//   const onCustomFieldChange = (nextValue) => {
//     onFieldChange({ customField: nextValue });
//   };
//   console.log('appointmentData:::: ', appointmentData)
//   return (
//     <AppointmentForm.BasicLayout
//       appointmentData={appointmentData}
//       onFieldChange={onFieldChange}
//       {...restProps}
//     >
//       <AppointmentForm.Label 
//         text='Duration'
//         type='title'
//       />
//       <AppointmentForm.Select
//         availableOptions={durationCustomFieldMap}
//         value={appointmentData.customField}
//         onValueChange={onCustomFieldChange}
//         placeholder="Custom field"
//       />

//     </AppointmentForm.BasicLayout>
//   )
// }

// const BooleanEditor = props => {

//   if (props.label === 'Repeat') return <AppointmentForm.BooleanEditor {...props} readOnly />
//   return <AppointmentForm.BooleanEditor {...props} />;
// };


// // const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
   
// //     return (
// //       <AppointmentForm.BasicLayout
// //         appointmentData={appointmentData}
// //         onFieldChange={onFieldChange}
// //         {...restProps}
        
// //       >
// //          </AppointmentForm.BasicLayout>
// //     );
// //   };

// useEffect(()=>{
//     getAppointments()
//     getFollowupLeads()
//     getAvailableSlots()
// },[])

// const getFollowupLeads = () =>{
//   axios.get(`${ATLAS_URI}/getLeadsForFollowup`, configToken).then(response=>{
    
//     setLeads(response.data)
// }).catch(err=>console.log(err.response))
// }

//   const getAppointments = () => {
//     axios.get(`${ATLAS_URI}/getFollowUpsforCalender`, configToken).then(response => {
//       const followupDatas = response.data
//       console.log(followupDatas,'followupddddddd')
     
//       let followups = []
//       if (followupDatas.length > 0) {
//         followups.push(...followupDatas.map(data => {
//           return {
//             title: `${data.LeadInfo.name}-${data.LeadId}`,
//             startDate: new Date(data.NextFollowUpDate),
//             endDate: new Date(data.NextFollowUpEndDate),
//             id: data.id,
//             backgroundColor: 'blue'
//  }

//         }))
//       }
//       setData(prev => [ ...followups])
     
//     }).catch(err => console.log(err.response))
//   }


//   const getAvailableSlots = () => {
//     axios.get(`${ATLAS_URI}/getUserAvailableSlotByUserId/${operator.id}`, configToken).then(response => {
//       const availableSlots = response.data
//       console.log('availableSlots ==== ', availableSlots)

//       let userAvailableSlots = []

//       if(availableSlots.length > 0) {
//         userAvailableSlots.push(...availableSlots.map(data => {
//           return {
//             title: 'SLOT',
//             startDate: new Date(data.startDateTime),
//             endDate: new Date(data.endDateTime),
//             id: data.id,
//             backgroundColor: 'green',
//             customField: durationCustomFieldMap.find(a => a.text == data.duration).id
//           }
//         }))
//       }
//       setData(prev => [...prev, ...userAvailableSlots])
     
//     }).catch(err => console.log(err.response))
//   }



//   function commitChanges({ added, changed, deleted }) {
//     console.log('added, changed, deleted', added, changed, deleted)
//   if (added) {
//     const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;

//     //setData((prev) => [...prev, { id: startingAddedId, ...added }]);
//     console.log(added, "addedData");

//     if (added.customField) {  
//       axios.post(`${ATLAS_URI}/checkSlot/${operator.id}`, { start_date: added.startDate, end_date: added.endDate }, configToken).then(slotAvailable => {
//         if (slotAvailable.data.length == 0) {
//           axios.post(`${ATLAS_URI}/postUserAvailableSlot`, {
//             startDateTime: added.startDate,
//             endDateTime: added.endDate,
//             userId: operator.id,
//             duration: durationCustomFieldMap.find(a => a.id == added.customField).text // 10
//           }, configToken)
//           .then((response) => {
//             console.log(response.data, "reponseafterpost");
//             toast.success(response.data,{ position: toast.POSITION.TOP_CENTER })
//             getAppointments()
//             getAvailableSlots()
//           })
//           .catch((err) => toast.error(err.response.data.message,{ position: toast.POSITION.TOP_CENTER }));
//         } else {
//           toast.error('Already slot exists with given period of time',{ position: toast.POSITION.TOP_CENTER });
//         }
//       }).catch((err) => toast.error(err.response.data.message,{ position: toast.POSITION.TOP_CENTER }))
//     }
//     else {
//       axios.post(`${ATLAS_URI}/isAppointmentAvailable/${operator.id}`, { start_date: added.startDate, end_date: added.endDate }, configToken).then(availableAppointment => {
//         if (availableAppointment.data.length == 0) {
//           axios
//             .post(`${ATLAS_URI}/addFollowUpFromCalender`, added, configToken)
//             .then((response) => {
//               console.log(response.data, "reponseafterpost");
//               getAppointments()
//               getAvailableSlots()
//             })
//             .catch((err) => {
//               console.log(err.response)
//               toast.error(err.response.data.message, { position: toast.POSITION.TOP_CENTER });
//             }
//             )
//         } else {
//           toast.error('Already appointment exists with given period of time',{ position: toast.POSITION.TOP_CENTER });
//         }
//       }).catch((err) => toast.error(err.response.data.message,{ position: toast.POSITION.TOP_CENTER }))

//     }
    
//   }

//   if (changed) {
//     const dataEdited1 = Object.values(changed)[0];
//     const dataToUpdate1 = {};
//     for (let i in dataEdited1) {
//       if (i == "startDate") dataToUpdate1["startDateTime"] = dataEdited1[i];
//       if (i == "endDate") dataToUpdate1["endDateTime"] = dataEdited1[i];
//       if (i == "customField") dataToUpdate1["customField"] = dataEdited1[i];
//     }
//     if(isAppointmentSlot){
//       console.log(dataToUpdate1,'datatoupdate',Object.keys(changed)[0])
//       if (dataToUpdate1.customField) {
//        console.log('durationCustomFieldMap == ', durationCustomFieldMap)
//        console.log('Object.keys(changed)[0].customField', Object.keys(changed)[0])
//        console.log('changed[Object.keys(changed)[0]].', changed[Object.keys(changed)[0]])
//         dataToUpdate1.customField = durationCustomFieldMap.find(a => a.id == changed[Object.keys(changed)[0]].customField).text
//       }
//      return axios
//       .put(
//         `${ATLAS_URI}/updateUserAvailableSlot/${Object.keys(changed)[0]}`,
//         dataToUpdate1,
//         configToken
//       )
//       .then((response) => {
//         toast.success(response.data,{position: toast.POSITION.TOP_CENTER});
//         console.log(response.data, "reponseafterpost");
//         setIsAppointmentSlot(false)
//         setAllocationFormOpen(false)
//         setFormOpen(false)
//         getAppointments()
//         getAvailableSlots()
//       })
//       .catch((err) => {
//         console.log(err.response);
//         toast.error(err.response.data.message,{ position: toast.POSITION.TOP_CENTER });
//       });
//     }
//     console.log(changed, "chaned", Object.values(changed)[0]);
//     const dataEdited = Object.values(changed)[0];
//     const dataToUpdate = {};
//     for (const i in dataEdited) {
//       dataToUpdate[i] = dataEdited[i];
//     }
//     console.log(dataToUpdate, "hai");

    
//     // setData(() =>
//     //   data.map((appointment) =>
//     //     changed[appointment.id]
//     //       ? { ...appointment, ...changed[appointment.id] }
//     //       : appointment
//     //   )
//     // );
//     axios
//       .put(
//         `${ATLAS_URI}/updateFollowUpFromCalender/${Object.keys(changed)[0]}`,
//         dataToUpdate,
//         configToken
//       )
//       .then((response) => {
//         toast.success(response.data,{position: toast.POSITION.TOP_CENTER});
//         console.log(response.data, "reponseafterpost");
//         getAppointments()
//         getAvailableSlots()
//       })
//       .catch((err) => {
//         console.log(err.response);
//         toast.error(err.response.data.message,{ position: toast.POSITION.TOP_CENTER });
//       })
//   }

//   if (deleted != undefined) {
//     console.log('deleted==== ', deleted);
//     /* setData(() => data.filter((appointment) => appointment.id !== deleted));
//     axios
//       .delete(`${ATLAS_URI}/deleteFollowUpFromCalender/${deleted}`, configToken)
//       .then((response) => {
//         console.log(response.data);
//         console.log(response.data, "reponseafterpost");
//         getAppointments()
//       })
//       .catch((err) => console.log(err.response)); */
//   }
// }

// const Appointment = ({ children, style, data, ...restProps }) => {
//   console.log('data in Appointment::: ', data)
//   return (
//     <Appointments.Appointment
//       {...restProps}
//       data={data}
//       style={{
//         ...style,
//         backgroundColor: data.backgroundColor
//       }}
//       onClick={() => {
//         console.log('data in onClick:::: ', data)
//         setTooltipData({data: data})
//         setVisible(true)}}
//       onDoubleClick={(e) => {
//         console.log('data:::::::', data)
//         if (data.title == 'SLOT') {
//            setAllocationFormOpen(true)
//            setFormOpen(false)
//            setAppointmentDataInfo(data)
//           } 
//            else {
//            setAllocationFormOpen(false)
//            setFormOpen(true)
//            setAppointmentDataInfo(data)
//           }
//       }}
      
//       // onClick={(e) => {
//       //   console.log('onClick ==', data)
//       // }}
//     >
//       {children}
//     </Appointments.Appointment>
//   )
// }

// const layoutFunction = ({children, data, appointmentMeta, ...restProps}) => {
//   return (
//     <AppointmentTooltip.Layout
//     {...restProps}
//     showOpenButton
//     appointmentMeta={appointmentMeta}
//     onHide={() => setVisible(false)}
//     /* onOpenButtonClick={() => {
//       console.log('datadatadatadata= ', appointmentMeta)
//       if (appointmentMeta?.data?.title == 'SLOT') {
//         setFormOpen(false);
//         setAllocationFormOpen(true);
//       } else {
//         setFormOpen(true);
//         setAllocationFormOpen(false);
//       }
//       // setAppointmentDataInfo(appointmentMeta?.data)
//     }} */
//     onDeleteButtonClick={() => {
//       console.log('appointmentMeta on delete::: ', appointmentMeta)
//       if (appointmentMeta?.data?.title == 'SLOT') {
//         axios
//       .delete(`${ATLAS_URI}/deleteUserAvailableSlot/${appointmentMeta?.data?.id}`, configToken)
//       .then((response) => {
//         console.log(response.data);
//         console.log(response.data, "reponseafterpost");
//         getAppointments()
//         getAvailableSlots()
//         setVisible(false)
        
//       })
//       .catch((err) => {
//         console.log(err.response)
//         toast.error(err.response.data.message,{ position: toast.POSITION.TOP_CENTER })
//       });
//       // setData(() => data.filter((appointment) => appointment.id !== deleted));
//       // setData((prevState) => {
//       //   return prevState.map(appointmentInfo => {
//       //     if (appointmentInfo.id == appointmentMeta?.data?.id && appointmentInfo.title == 'SLOT') {

//       //     } else {
//       //       return appointmentInfo
//       //     }
//       //   }).filter(data => data)
//       // })
//       } else {
//         axios
//       .delete(`${ATLAS_URI}/deleteFollowUpFromCalender/${appointmentMeta?.data?.id}`, configToken)
//       .then((response) => {
//         console.log(response.data);
//         console.log(response.data, "reponseafterpost");
//         setVisible(false)
//         getAppointments()
//         getAvailableSlots()
        
//       })
//       .catch((err) => console.log(err.response));

//       // setData((prevState) => {
//       //   return prevState.map(appointmentInfo => {
//       //     if (appointmentInfo.id == appointmentMeta?.data?.id && appointmentInfo.title != 'SLOT') {

//       //     } else {
//       //       return appointmentInfo
//       //     }
//       //   }).filter(data => data)
//       // })

//       }
//     }}
//     >
//       {children}
//     </AppointmentTooltip.Layout>
//   )
// }

// const commandLayoutFunction = ({children, data, ...restProps}) => {
//   console.log('data', data)
//   console.log('restProps', restProps)
//   return (
//     <AppointmentForm.CommandLayout
//     {...restProps}
//     onCommitButtonClick={() => {
//       console.log('appo::::: ', appointmentDataInfo)
//     }}
//     >
//       {children}
//     </AppointmentForm.CommandLayout>
//   )
// }

//   const AppointmentContent = (props) => {
//     const { data, style } = props;
//     console.log('AppointmentContent:::data:::::', data)
//     return (<Appointments.AppointmentContent
//       style={{
//         ...style,
//         color: data.color,
//       }}
//       {...props}
//     />
//     );
//   };

//   return (
//     <>

//       <Button
//       className='m-3'
//         onClick={() => {
//           setFormOpen(true);
//           setAllocationFormOpen(false);
//         }}
//       >
//         Appointments
//       </Button>
//       <Button
//       className='m-3'
//         onClick={() => {
//           setAllocationFormOpen(true);
//           setFormOpen(false);
//         }}
//       >
//         Available Slots
//       </Button>

//     <Paper>
//         <Scheduler
//           data={data}
//           height={660}
//         >
//           <ViewState
//             defaultCurrentDate={new Date()}
//             currentViewName={currentViewName}
//             onCurrentViewNameChange={(e)=>(setCurrentViewName(e))}
//           />

//           <WeekView
//             // startDayHour={0}
//             // endDayHour={24}
            
//           />
          
//           <MonthView onSelectionChanged={(e)=>console.log('hai')}/>
//           <DayView />
//           <Toolbar />
//           <ViewSwitcher />
//           <Appointments
//           appointmentComponent={Appointment}
//           // appointmentContentComponent={AppointmentContent}
//           />
//           <EditingState
//             onCommitChanges={commitChanges}
//             onAddedAppointmentChange={(adddd) => {
//               console.log('adddd', adddd)
//             }}
//             onEditingAppointmentChange={(ediii) => {
//               console.log('ediii', ediii,data)
//               setAppointmentDataInfo(ediii)

//               if (ediii?.title == 'SLOT') {
//                 setAllocationFormOpen(true)
//                 setFormOpen(false)
//                 setIsAppointmentSlot(true)
                
//                } 
//                 else {
//                 setAllocationFormOpen(false)
//                 setFormOpen(true)
                
//                }
//             }}
            
//             // preCommitChanges={(changes, appointmentInfo, type) => {
//             //   console.log('changes, appointmentInfo, type', changes, appointmentInfo, type)
//             // }}
//           />
//           <IntegratedEditing />
//           <AppointmentTooltip
//            layoutComponent={layoutFunction}
//             showCloseButton
//             showOpenButton
//             showDeleteButton
//             visible={visible}
//             appointmentMeta={tooltipData}
//             // onAppointmentMetaChange={(appointmentMeta) => {
//             //   console.log('onAppointmentMetaChange::::::appointmentMeta::', appointmentMeta)
//             // }}
//           />

// {<AppointmentForm
//  appointmentData={appointmentDataInfo}
// visible={formOpen}
//  onVisibilityChange={() => setFormOpen(!formOpen)}
//             //basicLayoutComponent={BasicLayout}
//             textEditorComponent={SelectEditor}
//             // commandLayoutComponent={commandLayoutFunction}
//             booleanEditorComponent={BooleanEditor}

//           />}

// {allocationFormOpen && <AppointmentForm
//              appointmentData={appointmentDataInfo}
//             visible={allocationFormOpen}
//             onVisibilityChange={() => setAllocationFormOpen(!allocationFormOpen)}
//               basicLayoutComponent={DurationLayout}
//               textEditorComponent={TextEditor}
//               booleanEditorComponent={BooleanEditor}
//               // commandLayoutComponent={commandLayoutFunction}

//             />}
          
//           {/* 
//           {formOpen &&  <AppointmentForm
//           visible={formOpen}
//           onVisibilityChange={() => setFormOpen(prevState => !prevState)}
//             //basicLayoutComponent={BasicLayout}
//             textEditorComponent={SelectEditor}

//           />}
//           {
//             allocationFormOpen && <AppointmentForm
//             visible={allocationFormOpen}
//             onVisibilityChange={() => setAllocationFormOpen(prevState => !prevState)}
//               //basicLayoutComponent={BasicLayout}
//               textEditorComponent={TextEditor}

//             />
//           } */}
//           <DateNavigator />
          
//           <TodayButton />
//         </Scheduler>
//       </Paper></>
//   )
// }

// export default CalenderAppointment