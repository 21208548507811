export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function firstNChars(str, n=1) {
    if(!str || typeof str === 'undefined') {
        return 'N/A'
    }

    return str.substring(0, n);
}
