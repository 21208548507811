import React, { useContext, useEffect, useState } from 'react'
import {AiOutlineEdit} from 'react-icons/ai'
import stateContext from "../../context/StateContext";
import { ATLAS_URI } from "../../Constants";
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {axiosFetch} from '../../Utlis/AxiosFetch';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import ViewPdf from '../../Utlis/ViewPdf';

const ClientProgressView = (props) => {
 
  const contextState = useContext(stateContext);
  const history = useHistory();



  const { configToken } = contextState.state;
  const {id} = useParams();
  const [bodyMeasurement, setBodyMeasurement] = useState([])
  const [idForUpdate, setIdForUpdate] = useState()

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const open = Boolean(anchorEl);




  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



useEffect(() => {
axios.get(`${ATLAS_URI}/getBodyMeasurementsCRMbyLeadId/${props.leadId}`,configToken).then(res=>(setBodyMeasurement(res.data),setIdForUpdate(res.data[0].id),console.log('res:::in body', res))).catch(err=>console.log(err))
}, [])


                                                             /* edit Body Measurement */

// const [editBodyMeasure, setEditBodyMeasure] = useState({
//   weight:false,
//   neck:false
// })                                                             

const [editBody, setEditBody] = useState(false)
const [updateBodyMeasurement, setUpdateBodyMeasurement] = useState({})
const [bodyMeasure, setBodyMeasure] = useState({})                                                        

const [editBodyMeasurementId, setEditBodyMeasurementId] = useState()
const [item, setItem] = useState('')
const [item1, setItem1] = useState('')
const [bodyMeasurementId, setBodyMeasurementId] = useState()
const [pdfData, setPdfData] = useState('');
const  [toggleViewer, setToggleViewer] = useState(false);

  const editBodyMeasurement=(bodyMeasurement,id)=>{
    // alert(idForUpdate)
    setEditBody(true)
    setBodyMeasurementId(bodyMeasurement.id)
    setEditBodyMeasurementId(id)
  } 
  // console.log('editBodyMeasure.weight', editBodyMeasure.weight)
const bodyMeasurementChange=(e,bodyMeasurement,id)=>{
  // alert(typeof e.target.value)
  // setBodyMeasurement(prevState => ({...prevState, [e.target.name]: parseInt(e.target.value)}))
  // alert(e.target.name)
  setItem(e.target.name)
  setItem1(parseFloat(e.target.value))
  setBodyMeasurement(prevState => {
   
    // let name=e.target.name
    if(prevState[id].id==bodyMeasurement.id){
    //  alert(prevState.name)
    if(e.target.name==='progress_datetime'){

      prevState[id][e.target.name]= (e.target.value)
    }
    else{
      prevState[id][e.target.name]= parseFloat(e.target.value)

    }
    }

return prevState
})

  
setBodyMeasure(bodyMeasurement)
  

   
 

  // setBodyMeasurement(prevState => (
  //   if()
  // ))

}  
// useEffect(() => {
//   bodyMeasurementChange()
//   }, [item])


 
                                                        /* Save updated Body Measurement */



const saveUpdateForBodyMeasurement=async()=>{
  // alert(bodyMeasurementId)/*  */
 await axios.put(`${ATLAS_URI}/updateBodyMeasurements/${bodyMeasurementId}`,bodyMeasure,configToken).then(res=>(console.log('res checking in update:::', res))).catch(err=>alert(err))
 setEditBody(false)
}
 
  const generateReport = (id) =>{
   axiosFetch({
    url : `/v3/generateProgressReport/${id}`,
    method : "post"
   })
   .then((res)=>{
    if(res.status === 200){
      handleClose();
      toast.success(res.data,{ position: toast.POSITION.TOP_CENTER });
    }
    else if(res.status === 401){
      history.push('/');
    }
    else if(res.status === 400){
      toast.error(res.data?.message,{ position: toast.POSITION.TOP_CENTER });
    }
   })
   .catch(err =>{
    console.error(err);
   })
  }

  const toggleReport = (id,type) =>{
    axiosFetch({
      url : `/v3/getProgressReport/${id}`,
      method : "get"
    })
    .then((res)=>{
      if(res.status === 200){
        handleClose();
        if(type == 1 ){
         setPdfData(res.data);
         history.push({pathname : '/Clients/progressReport', state : {pdfData : res.data}})
        }
        else if(type == 2){
          window.open(res.data);
        }
      }
      else if(res.status === 401){
        history.push('/');
      }
      else if(res.status === 400){
        toast.error(res.data?.message,{ position: toast.POSITION.TOP_CENTER });
      }
     })
     .catch(err =>{
      console.error(err);
     })
  }

 
  return (
    <div>
      <div style={{transform : "translate(70%, -9px)"}}>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
       Progress Report
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ transform: "translateX(-300px)" }}
      >
        <MenuItem onClick={()=>toggleReport(id,1)}>View Report</MenuItem>
        <hr/>
        <MenuItem onClick={()=>generateReport(id)}>Generate Report</MenuItem>
        <hr/>
        <MenuItem onClick={()=>toggleReport(id,2)}>Download Report</MenuItem>
        {/* <hr/>
        <MenuItem onClick={handleClose}>Delete Report</MenuItem> */}
      </Menu>
      &nbsp; &nbsp;
      <Button onClick={saveUpdateForBodyMeasurement}>Save</Button></div><br/>
        <table className='table-bordered ' style={{'width':'100%'}}>
            <tbody >
            <tr  style={{'backgroundColor':'lightblue'}}>
                <th>Date</th>
                <th>Weight</th>
                <th>Neck</th>
                <th>Left Arm</th>
                <th>Right Arm</th>
                <th>Left Forearm</th>
                <th>Right Forearm</th>
                <th>Shoulder</th>
                <th>Lower Abdomen</th>
                <th>Upper Abdomen</th>
                <th>Hip</th>
                <th>Chest</th>
                <th>Left Thigh</th>
                <th>Right Thigh</th>
                <th>Left Calf</th>
                <th>Right Calf</th>
                <th>Action</th>
            </tr>
            
            {bodyMeasurement&&bodyMeasurement.map((bodyMeasurement,id)=>{
              return (<>
          

                <tr style={{'backgroundColor':'yellow'}} key={bodyMeasurement.id}>
                {/* <td>{editBody & (id==editBodyMeasurementId) ?<input type='date' name='progress_datetime'    defaultValue={bodyMeasurement.progress_datetime} style={{'width':'70%'}} onChange={(e)=>bodyMeasurementChange(e,bodyMeasurement,id)} />  :bodyMeasurement.progress_datetime.slice(0,10)}  */}
                   
                    
                    {/* </td> */}
             <td>{bodyMeasurement.progress_datetime.slice(0,10)}</td>       
      {/* <td></td> */}

                    <td>{editBody & (id==editBodyMeasurementId) ?<input type='number' name='weight' id={id}   defaultValue={bodyMeasurement.weight} style={{'width':'70%'}} onChange={(e)=>bodyMeasurementChange(e,bodyMeasurement,id)} step={'0.1'} />  :bodyMeasurement.weight} 
                    {bodyMeasurement.weight_unit===0?'Kg':'Pound'}
                    
                    </td>
    
                    <td>{editBody & (id==editBodyMeasurementId)?<input type='number' id={id} name='neck' defaultValue={bodyMeasurement.neck} style={{'width':'70%'}} onChange={(e)=>bodyMeasurementChange(e,bodyMeasurement,id)} step={'0.1'}  />:bodyMeasurement.neck} 
                    {bodyMeasurement.measurement_unit==0?'cms':'in'}
                    
                    
                    </td>
    
                    <td>{editBody & (id==editBodyMeasurementId)?<input type='number' name='left_arm' defaultValue={bodyMeasurement.left_arm}  style={{'width':'70%'}} onChange={(e)=>bodyMeasurementChange(e,bodyMeasurement,id)} step={'0.1'}/>:bodyMeasurement.left_arm} {bodyMeasurement.measurement_unit==0?'cms':'in'}</td>
    
                    <td>{editBody & (id==editBodyMeasurementId)?<input type='number' name='right_arm' defaultValue={bodyMeasurement.right_arm} style={{'width':'70%'}} onChange={(e)=>bodyMeasurementChange(e,bodyMeasurement,id)} step={'0.1'}/>:bodyMeasurement.right_arm} {bodyMeasurement.measurement_unit==0?'cms':'in'}</td>
    
                    <td>{editBody & (id==editBodyMeasurementId)?<input type='number' name='left_forearm' defaultValue={bodyMeasurement.left_forearm} style={{'width':'70%'}} onChange={(e)=>bodyMeasurementChange(e,bodyMeasurement,id)} step={'0.1'}/>:bodyMeasurement.left_forearm} {bodyMeasurement.measurement_unit==0?'cms':'in'}</td>
    
                    <td>{editBody & (id==editBodyMeasurementId) ?<input type='number' name='right_forearm' defaultValue={bodyMeasurement.right_forearm} style={{'width':'70%'}} onChange={(e)=>bodyMeasurementChange(e,bodyMeasurement,id)} step={'0.1'}/>:bodyMeasurement.right_forearm} {bodyMeasurement.measurement_unit==0?'cms':'in'}</td>
    
                     <td>{editBody & (id==editBodyMeasurementId)?<input type='number' name='shoulder' defaultValue={bodyMeasurement.shoulder} style={{'width':'70%'}} onChange={(e)=>bodyMeasurementChange(e,bodyMeasurement,id)} step={'0.1'}/>:bodyMeasurement.shoulder} {bodyMeasurement.measurement_unit==0?'cms':'in'}</td>
    
                    <td>{editBody & (id==editBodyMeasurementId)?<input type='number' name='lower_abdomen'  defaultValue={bodyMeasurement.lower_abdomen} style={{'width':'70%'}} onChange={(e)=>bodyMeasurementChange(e,bodyMeasurement,id)} step={'0.1'}/>:bodyMeasurement.lower_abdomen} {bodyMeasurement.measurement_unit==0?'cms':'in'}</td>
    
                     <td>{editBody & (id==editBodyMeasurementId)?<input type='number' name='upper_abdomen' defaultValue={bodyMeasurement.upper_abdomen} style={{'width':'70%'}} onChange={(e)=>bodyMeasurementChange(e,bodyMeasurement,id)} step={'0.1'}/>:bodyMeasurement.upper_abdomen} {bodyMeasurement.measurement_unit==0?'cms':'in'}</td>
    
                     <td>{editBody & (id==editBodyMeasurementId)?<input type='number' name='hip' style={{'width':'70%'}} defaultValue={bodyMeasurement.hip} onChange={(e)=>bodyMeasurementChange(e,bodyMeasurement,id)} step={'0.1'}/>:bodyMeasurement.hip} {bodyMeasurement.measurement_unit==0?'cms':'in'}</td>
    
                    <td>{editBody & (id==editBodyMeasurementId)?<input type='number' name='chest' style={{'width':'70%'}} defaultValue={bodyMeasurement.chest} onChange={(e)=>bodyMeasurementChange(e,bodyMeasurement,id)} step={'0.1'}/>:bodyMeasurement.chest} {bodyMeasurement.measurement_unit==0?'cms':'in'}</td>
    
                    <td>{editBody & (id==editBodyMeasurementId)?<input type='number' name='left_thigh' defaultValue={bodyMeasurement.left_thigh} style={{'width':'70%'}} onChange={(e)=>bodyMeasurementChange(e,bodyMeasurement,id)} step={'0.1'}/>:bodyMeasurement.left_thigh} {bodyMeasurement.measurement_unit==0?'cms':'in'}</td>
    
                    <td>{editBody & (id==editBodyMeasurementId)?<input type='number' name='right_thigh' defaultValue={bodyMeasurement.right_thigh} style={{'width':'70%'}} onChange={(e)=>bodyMeasurementChange(e,bodyMeasurement,id)} step={'0.1'}/>:bodyMeasurement.right_thigh} {bodyMeasurement.measurement_unit==0?'cms':'in'}</td>
    
                    <td>{editBody & (id==editBodyMeasurementId)?<input type='number' name='left_calf' style={{'width':'70%'}} onChange={(e)=>bodyMeasurementChange(e,bodyMeasurement,id)} defaultValue={bodyMeasurement.left_calf} step={'0.1'}/>:bodyMeasurement.left_calf} {bodyMeasurement.measurement_unit==0?'cms':'in'}</td>
    
                    <td>{editBody & (id==editBodyMeasurementId)?<input type='number' name='right_calf' defaultValue={bodyMeasurement.right_calf} style={{'width':'70%'}} onChange={(e)=>bodyMeasurementChange(e,bodyMeasurement,id)} step={'0.1'}/>:bodyMeasurement.right_calf} {bodyMeasurement.measurement_unit==0?'cms':'in'}</td>
                    <td><AiOutlineEdit  style={{'backgroundColor':'blue','color':'white','fontSize':'19px'}} onClick={()=>editBodyMeasurement(bodyMeasurement,id)}/></td>
                </tr>
               
              </>)
            })}
            </tbody>
        </table>

       { toggleViewer  ? <ViewPdf pdfData={pdfData} /> : ""}
    </div>
  )
}

export default ClientProgressView