// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #singleLine{
    position: relative;
    left: 50px;
    bottom: 10px;
    color: red;
} */
@media (max-width: 1000px) and (min-width: 500px) {
  .singleLine {
    position: relative;
    left: 100px;
    bottom: 25px;
  }
}

.backdrop-form {
  width: 100%;
  height: 200vh;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 100 !important;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/ClientDetail.css"],"names":[],"mappings":"AAAA;;;;;GAKG;AACH;EACE;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;EACd;AACF;;AAEA;EACE,WAAW;EACX,aAAa;EACb,kCAAkC;EAClC,eAAe;EACf,OAAO;EACP,QAAQ;EACR,uBAAuB;AACzB","sourcesContent":["/* #singleLine{\n    position: relative;\n    left: 50px;\n    bottom: 10px;\n    color: red;\n} */\n@media (max-width: 1000px) and (min-width: 500px) {\n  .singleLine {\n    position: relative;\n    left: 100px;\n    bottom: 25px;\n  }\n}\n\n.backdrop-form {\n  width: 100%;\n  height: 200vh;\n  background-color: rgba(0, 0, 0, 0);\n  position: fixed;\n  top: 0%;\n  left: 0%;\n  z-index: 100 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
