import React, { useContext, useEffect, useState } from "react";
import CloseButton from "react-bootstrap/CloseButton";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FreshLeads from "./FreshLeads";
import LeadDetails from "./LeadDetails";
import { GrClose } from "react-icons/gr";
import axios from "axios";
import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";
import { toast } from "react-toastify";

function PopupPDFUpload(props) {
  const [show, setShow] = useState(props.show1);

  const [file, setFile] = useState();

  const contextState = useContext(stateContext);
  const { configToken } = contextState.state;

  const handleClose = () => {
    props.setShow1(false);
  };

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const handleSubmit = () => {
    // event.preventDefault()
    const formData = new FormData();
    formData.append("bulkDataFile", file);
    formData.append("fileName", file.name);

    if (
      file.type !=
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      toast.warning("Only excel file is accepted", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    axios
      .post(`${ATLAS_URI}/uploadLeadExcel/`, formData, configToken)
      .then((resp) => {
        if (resp.status == 200) {
          props.setShow1(false);
          if (resp.data.code == 0) {
            toast.success("Leads from the file uploaded succesfully", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.error("Error occured", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  useEffect(() => {
    props.show1 && setShow(true);
  }, [props.show1]);

  const handleShow = () => setShow(props.show1);
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Excel file with lead Details</Modal.Title>
          <GrClose
            style={{
              backgroundColor: isHovering ? "#FF0000" : "",
              color: isHovering ? "#fff" : "",
              border: isHovering ? "1px solid" : "",
              height: isHovering ? "30px" : "",
              width: isHovering ? "30px" : "",
              opacity: isHovering ? "0.7" : "",
            }}
            onMouseOver={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          {/* <form onSubmit={handleSubmit}> */}
          <input
            type="file"
            name="file"
            onChange={handleChange}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          {file ? (
            <div>
              <p>Filename: {file.name}</p>
              <p>Filetype: {file.type}</p>
              <p>Size in bytes: {file.size}</p>
            </div>
          ) : (
            <p></p>
          )}
          {/* <button type="submit">Upload</button>
        </form> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleSubmit()}>
            Submit
          </Button>
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={()=>handleClose()}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PopupPDFUpload;
