//import FreshLeads from './FreshLeads Discard'
import UpComingFollowUps from './UpComingFollowUps'
import FreshLeads from './FreshLeads'
import AllLeads from './AllLeads copy'
import LeadDetails from './LeadDetails'
import OldPendingFollowUps from './OldPendingFollowUps'
import TodaysFollowUps from './TodaysFollowUps'
import  Calendar  from '../Leads/src2/Calendar'

export {
    LeadDetails,
    FreshLeads,
    UpComingFollowUps,
    AllLeads,
    OldPendingFollowUps,
    TodaysFollowUps,
    Calendar
}