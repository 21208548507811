// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CallerPopup_popup__2Pv2j {
  position: absolute;
  width: 300px;
  border: 1px solid #ccc;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  top: 50%;
  left: 50%;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Call/CallerPopup.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;EACtB,iBAAiB;EACjB,uCAAuC;EACvC,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".popup {\n  position: absolute;\n  width: 300px;\n  border: 1px solid #ccc;\n  background: white;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  cursor: pointer;\n  top: 50%;\n  left: 50%;\n  z-index: 9999999;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 60px;\n  height: 60px;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `CallerPopup_popup__2Pv2j`
};
export default ___CSS_LOADER_EXPORT___;
