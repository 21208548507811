// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.movable-div {
    width: 200px;
    height: 150px;
    background-color: #ccc;
    position: absolute;
    cursor: grab;
  }
  .movable-div:active {
    cursor: grabbing;
  }`, "",{"version":3,"sources":["webpack://./src/components/CallStatusBtn/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;EACd;EACA;IACE,gBAAgB;EAClB","sourcesContent":[".movable-div {\n    width: 200px;\n    height: 150px;\n    background-color: #ccc;\n    position: absolute;\n    cursor: grab;\n  }\n  .movable-div:active {\n    cursor: grabbing;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
