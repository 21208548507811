// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServertelForm_form__zKi1v input {
  padding: 9px 10px;
  /* border-radius: 5px; */
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Call/ServertelForm.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,wBAAwB;AAC1B","sourcesContent":[".form input {\n  padding: 9px 10px;\n  /* border-radius: 5px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `ServertelForm_form__zKi1v`
};
export default ___CSS_LOADER_EXPORT___;
