import * as React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import {FormControl, FormHelperText} from '@mui/material';
const DATE_FORMAT = 'dd-MMM-yyyy';

 const ControllerDatetimepicker = ({
  label,
  control,
  name,
  setDate,
  fullWidth,
  helperText,
  error,
  date,
  isDisableColumn = false
}) => {
  return (
	<FormControl  error={error} fullWidth={fullWidth}>
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...rest } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DateTimePicker
              disabled={isDisableColumn}
              fullWidth
              label={label}
			       // size="small"
              renderInput={params => <TextField  size="small" {...params} />}
              value={date ? new Date(date) : null}
              {...rest}
              format={DATE_FORMAT}
            />
          </Stack>
        </LocalizationProvider>
      )}
    />
	<FormHelperText >{error ? helperText : " "}</FormHelperText>
</FormControl>
  );
}
export default ControllerDatetimepicker;