import React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { TextField } from "@mui/material";
import { useState } from "react";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";

const MedicalAssesment = (props) => {
  const [medicalCondition, setMedicalCondition] = useState([]);
  const [nutritionSupplement, setNutritionSupplement] = useState("");
  const [anyDeficiency, setAnyDeficiency] = useState([]);
  const [otherMedicalCondition, setOtherMedicalCondition] = useState(false);
  const [otherNutritionSupplement, setOtherNutritionSupplement] =
    useState(false);
  const [otherDeficiencies, setOtherDeficiencies] = useState(false);
  const [tabletsByDoctor, setTabletsByDoctor] = useState("");
  const [otherTablets, setOtherTablets] = useState(false);
  const [editable, setEditable] = useState(false);
  const [medical, setMedical] = useState([]);
  const [tabletsRecommended, setTabletsRecommended] = useState([]);
  const [deficiencies, setDeficiencies] = useState([]);
  const [supplementIntake, setSupplementIntake] = useState([]);
  const [
    otherMedicalConditionFromDropDown,
    setOtherMedicalConditionFromDropDown,
  ] = useState("");
  const [ifTabletRecommendedIsYes, setIfTabletRecommendedIsYes] = useState("");
  const [otherDeficienciesFromDropdown, setOtherDeficienciesFromDropdown] =
    useState("");
  const [
    otherNutritionSupplementFromIsYes,
    setOtherNutritionSupplementFromIsYes,
  ] = useState("");
  const [errorForMedicalCondition, setErrorForMedicalCondition] = useState("");
  const [errorForTabletsRecommended, setErrorForTabletsRecommended] =
    useState("");
  const [errorForAnyDeficiencies, setErrorForAnyDeficiencies] = useState("");
  const [errorForNutritionSupplement, setErrorForNutritionSupplement] =
    useState("");
  const [errorForOtherMedicalCondition, setErrorForOtherMedicalCondition] =
    useState("");
  const [errorForNoTabletRecommended, setErrorForNoTabletRecommended] =
    useState("");
  const [errorForOtherDeficiency, setErrorForOtherDeficiency] = useState("");
  const [errorForIfNutritionIsYes, setErrorForIfNutritionIsYes] = useState("");
  const [tabletRecommendedId, setTabletRecommendedId] = useState("");
  const [getnutritionSupplementId, setGetnutritionSupplementId] = useState("");
  const LeadId = useParams();

  let history = useHistory();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        transform: "translateY(-100px)",
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  };

  const handleChangeNutritionSupplement = (e) => {
    setNutritionSupplement(e.target.value);
    if (e.target.value === 1) {
      setOtherNutritionSupplement(true);
      setErrorForIfNutritionIsYes("Please Enter Something");
    }
    if (e.target.value === 2) {
      setOtherNutritionSupplement(false);
      setErrorForIfNutritionIsYes("");
      setOtherNutritionSupplementFromIsYes("");
    }
    setErrorForNutritionSupplement("");
  };

  const handleChangeTabletsRecommended = (e) => {
    setTabletsByDoctor(e.target.value);
    if (e.target.value === 1) {
      setOtherTablets(true);
      setErrorForNoTabletRecommended("Please Enter Something");
    }
    if (e.target.value === 2) {
      setOtherTablets(false);
      setErrorForNoTabletRecommended("");
      setIfTabletRecommendedIsYes("");
    }
    setErrorForTabletsRecommended("");
  };

  const handleChangAnyDeficiencies = (event) => {
    const {
      target: { value },
    } = event;
    setAnyDeficiency(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    if (event.target.value.includes(9)) {
      setOtherDeficiencies(true);
      setErrorForOtherDeficiency("Please Enter Something");
    } else {
      setOtherDeficiencies(false);
      setErrorForOtherDeficiency("");
      setOtherDeficienciesFromDropdown("");
    }
    if (event.target.value?.length === 0) {
      setOtherDeficiencies(false);
    }
    setErrorForAnyDeficiencies("");
  };

  const handleChangeMedicalCondition = (event) => {
    const {
      target: { value },
    } = event;
    setMedicalCondition(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    if (event.target.value.includes(8)) {
      setOtherMedicalCondition(true);
      setErrorForOtherMedicalCondition("Please Enter Something");
    } else {
      setOtherMedicalCondition(false);
      setErrorForOtherMedicalCondition("");
      setOtherMedicalConditionFromDropDown("");
    }

    if (event.target.value.includes(7)) {
      setMedicalCondition(event.target.value.filter((c) => c === 7));
      setOtherMedicalCondition(false);
    }
    if (event.target.value?.length === 0) {
      setOtherMedicalCondition(false);
    }
    setErrorForMedicalCondition("");
  };
  //  const medical=[
  //    "Diabetes",
  //    "Pre- Diabetes",
  //    "Thyroid",
  //    "Cholesterol",
  //    "PCOS",
  //    "Hypertension",
  //    "Physical Injury",
  //    "Others(Please Specify)",
  //    "NA"
  //  ]

  //  const deficiencies=[
  //   "Vitamin D Deficiency",
  //   "Iron Deficiency Anemia",
  //   "B12 Deficiency",
  //   "Calcium Deficiency",
  //   "Magnesium Deficiency",
  //   "Vitamin C Deficiency (Scurvy)",
  //   "Zinc Deficiency",
  //   "Omega-3 Fatty Acid Deficiency",
  //   "Others(Please Specify)"
  //  ]

  //  const tabletsRecommended=[
  //   "Yes",
  //   "No"
  //  ]

  const editMedicalAssesment = () => {
    setEditable(true);
    if (medicalCondition.includes(8)) {
      setOtherMedicalCondition(true);
    }
    if (tabletsByDoctor === 1) {
      setOtherTablets(true);
    }
    if (anyDeficiency.includes(9)) {
      setOtherDeficiencies(true);
    }
    if (nutritionSupplement === 1) {
      setOtherNutritionSupplement(true);
    }
  };
  //http://localhost:8080/getAllMedicalDropdown
  const getAllDropdownForMedical = () => {
    axiosFetch({
      url: `/getAllMedicalDropdownCRM`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setMedical(res?.data?.body?.MedicalCondition);
          setTabletsRecommended(res?.data?.body?.MedicalTabletsRecommend);
          setDeficiencies(res?.data?.body?.MedicalDeficency);
          setSupplementIntake(res?.data?.body?.MedicalNutritionSupplement);
        } else if (res.status === 400) {
          toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => console.error(err));
  };

  const getMedicalAssesment = () => {
    if (props?.props !== null) {
      if (
        props?.props?.current_medical_condition !== null &&
        props?.props?.any_deficiency !== null &&
        props?.props?.tablets_recommended !== null &&
        props?.props?.nutrition_supplement_intake !== null
      ) {
        // setMedicalCondition(props?.props?.current_medical_condition)
        // setAnyDeficiency(props?.props?.any_deficiency)
        setTabletRecommendedId(
          (props?.props?.tablets_recommended).toUpperCase()
        );
        setGetnutritionSupplementId(
          (props?.props?.nutrition_supplement_intake).toUpperCase()
        );
      }
      if (props?.props?.nutrition_supplement_intake_if_yes !== null) {
        setOtherNutritionSupplementFromIsYes(
          props?.props?.nutrition_supplement_intake_if_yes
        );
      }
      if (props?.props?.other_medical_condition !== null) {
        setOtherMedicalConditionFromDropDown(
          props?.props?.other_medical_condition
        );
      }
      if (props?.props?.tablets_recommended_if_yes !== null) {
        setIfTabletRecommendedIsYes(props?.props?.tablets_recommended_if_yes);
      }
      if (props?.props?.other_deficiency !== null) {
        setOtherDeficienciesFromDropdown(props?.props?.other_deficiency);
      }
    }
  };

  useEffect(() => {
    getAllDropdownForMedical();
    getMedicalAssesment();
  }, []);

  const getSelectedInIdFormat = (selectedNames, allIds) => {
    const selectedIds = [];
    selectedNames?.forEach((selectedName) => {
      const getIds = allIds?.find(
        (idFormat) => idFormat.title === selectedName
      );
      if (getIds) {
        selectedIds.push(getIds.id);
      }
    });
    return selectedIds;
  };

  useEffect(() => {
    let requiredMedicalId = getSelectedInIdFormat(
      props?.props?.current_medical_condition,
      medical
    );
    setMedicalCondition(requiredMedicalId);
    const requiredTabletsRecommendedId = tabletsRecommended?.find(
      (c) => c.title === tabletRecommendedId
    )?.id;
    setTabletsByDoctor(requiredTabletsRecommendedId);
    const getIdForAnyDeficiency = getSelectedInIdFormat(
      props?.props?.any_deficiency,
      deficiencies
    );
    setAnyDeficiency(getIdForAnyDeficiency);
    const getIdforNutritionSupplement = supplementIntake?.find(
      (c) => c.title === getnutritionSupplementId
    )?.id;
    setNutritionSupplement(getIdforNutritionSupplement);
  }, [
    medical,
    props?.props?.current_medical_condition,
    tabletsRecommended,
    tabletRecommendedId,
    props?.props?.any_deficiency,
    deficiencies,
    getnutritionSupplementId,
    supplementIntake,
  ]);

  const saveMedicalAssesment = () => {
    let requestBody = {
      id: LeadId?.id,
      current_medical_condition: medical
        ?.filter((c) => medicalCondition.includes(c.id))
        .map((c) => c.title),
      other_medical_condition: otherMedicalConditionFromDropDown,
      any_deficiency: deficiencies
        ?.filter((c) => anyDeficiency.includes(c.id))
        .map((c) => c.title),
      other_deficiency: otherDeficienciesFromDropdown,
      tablets_recommended: tabletsRecommended?.find(
        (c) => c.id === tabletsByDoctor
      )?.title,
      tablets_recommended_if_yes: ifTabletRecommendedIsYes,
      nutrition_supplement_intake: supplementIntake?.find(
        (c) => c.id === nutritionSupplement
      )?.title,
      nutrition_supplement_intake_if_yes: otherNutritionSupplementFromIsYes,
    };

    if (
      medicalCondition?.length !== 0 &&
      anyDeficiency?.length !== 0 &&
      tabletsByDoctor !== "" &&
      tabletsByDoctor !== undefined &&
      nutritionSupplement !== "" &&
      nutritionSupplement !== undefined
    ) {
      if (
        (medicalCondition.includes(8) &&
          otherMedicalConditionFromDropDown.trim() !== "") ||
        !medicalCondition.includes(8)
      ) {
        if (
          (anyDeficiency.includes(9) &&
            otherDeficienciesFromDropdown.trim() !== "") ||
          !anyDeficiency.includes(9)
        ) {
          if (
            (tabletsByDoctor === 1 && ifTabletRecommendedIsYes.trim() !== "") ||
            tabletsByDoctor === 2
          ) {
            if (
              (nutritionSupplement === 1 &&
                otherNutritionSupplementFromIsYes.trim() !== "") ||
              nutritionSupplement === 2
            ) {
              axiosFetch({
                url: `/updateLead/${LeadId.id}`,
                method: "post",
                requestConfig: requestBody,
              })
                .then((res) => {
                  if (res.status === 200) {
                    toast.success("Saved Successfully", {
                      position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  } else if (res.status === 400) {
                    toast.error("Bad Request", {
                      position: toast.POSITION.TOP_CENTER,
                    });
                  } else if (res.status === 401) {
                    history.push("/");
                  }
                })
                .catch((err) => console.error(err));
            } else {
              setErrorForIfNutritionIsYes("Please Enter Something");
            }
          } else {
            setErrorForNoTabletRecommended("Please Enter Something");
          }
        } else {
          setErrorForOtherDeficiency("Please Enter Something");
        }
      } else {
        setErrorForOtherMedicalCondition("Please Enter Something");
      }
    } else {
      if (medicalCondition?.length === 0) {
        setErrorForMedicalCondition(" Please Select Something");
      }
      if (anyDeficiency?.length === 0) {
        setErrorForAnyDeficiencies(" Please Select Something");
      }
      if (tabletsByDoctor === "" || tabletsByDoctor === undefined) {
        setErrorForTabletsRecommended("Please Select Something");
      }
      if (nutritionSupplement === "" || nutritionSupplement === undefined) {
        setErrorForNutritionSupplement("Please Select Something");
      }
    }
  };

  const handleChangeOtherMedicalCondition = (e) => {
    setOtherMedicalConditionFromDropDown(e.target.value);
    //
    if (e.target.value.trim() === "") {
      setErrorForOtherMedicalCondition("Please Enter Something");
    } else {
      setErrorForOtherMedicalCondition("");
    }
  };

  const handleChangeOtherTablets = (e) => {
    setIfTabletRecommendedIsYes(e.target.value);
    if (e.target.value.trim() === "") {
      setErrorForNoTabletRecommended("Please Enter Something");
    } else {
      setErrorForNoTabletRecommended("");
    }
  };

  const handleChangeOtherDeficiencies = (e) => {
    setOtherDeficienciesFromDropdown(e.target.value);
    if (e.target.value.trim() === "") {
      setErrorForOtherDeficiency("Please Enter Something");
    } else {
      setErrorForOtherDeficiency("");
    }
  };

  const handleChangeOtherNutritionSupplement = (e) => {
    setOtherNutritionSupplementFromIsYes(e.target.value);
    if (e.target.value.trim() === "") {
      setErrorForIfNutritionIsYes("Please Enter Something");
    } else {
      setErrorForIfNutritionIsYes("");
    }
  };

  return (
    <div>
      <div className="row">
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              {/* <label>Blood Group</label> <small className="req"> *</small> */}
              {/* <select
            required
            className="form-control"
            name="BloodGroup"
            value={state.newTableRow.BloodGroup}
            onChange={changeHandler}
          >
            <option value="">Select</option>
            <option value="A+">A+</option>
            <option value="A-">A-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
          </select> */}
            </div>
          </div>
          {/* <div className="col-md-3">
        <div className="form-group">
          <label>Physical Activity</label>{" "}
          <small className="req"> *</small>
          <select
            required
            className="form-control"
            name="PhysicalActivity"
            value={state.newTableRow.PhysicalActivity}
            onChange={changeHandler}
          >
            <option value="">Select</option>
            <option value="Sedentary">
              Sedentary (Little or no exercise){" "}
            </option>
          </select>
        </div>
      </div> */}
          <div className="col-md-3">
            <div className="form-group">
              {/* <label>Any Weight Loss</label>{" "} */}
              {/* <small className="req"> *</small> */}
              {/* <select
            required
            className="form-control"
            name="WeightLoss"
            value={state.newTableRow.WeightLoss}
            onChange={changeHandler}
          >
            <option value="">Select</option>
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              {/* <label>Medical Condition</label> <small className="req"> *</small> */}
              <FormControl sx={{ width: "100%" }} disabled={!editable}>
                <label>
                  Medical Condition &nbsp; <small className="req">*</small>
                </label>

                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={medicalCondition}
                  onChange={handleChangeMedicalCondition}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={
                            medical?.length > 0 &&
                            medical?.find((c) => c.id === value)?.title
                          }
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}

                  // style={{height:'45px'}}
                >
                  {medical?.length > 0 &&
                    medical.map((name) => (
                      <MenuItem key={name?.id} value={name?.id}>
                        <Checkbox
                          checked={medicalCondition.indexOf(name?.id) > -1}
                        />
                        <ListItemText primary={name?.title} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <p className="req">{errorForMedicalCondition}</p>
              {/* <select
            className="form-control"
            name="Disease"
            value={state.newTableRow.Disease}
            onChange={changeHandler}
          >
            <option value="">Select</option>
            {state.newTableRow?.LeadID?.current_medical_condition?.length>0 &&
            state.newTableRow?.LeadID?.current_medical_condition?.map(condition=>(
              <option>{condition}</option>
            ))
            }
          </select> */}
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              {/* <label>Nutrition Supplement Intake</label> <small className="req"> *</small> */}
              {otherMedicalConditionFromDropDown?.trim() !== "" ? (
                <TextField
                  disabled={!otherMedicalCondition}
                  value={otherMedicalConditionFromDropDown}
                  onChange={handleChangeOtherMedicalCondition}
                  type="text"
                  placeholder="Please Specify"
                  sx={{ width: "100%", marginTop: "20px" }}
                />
              ) : (
                <TextField
                  disabled={!otherMedicalCondition}
                  value={otherMedicalConditionFromDropDown}
                  onChange={handleChangeOtherMedicalCondition}
                  type="text"
                  placeholder="Please Specify"
                  sx={{ width: "100%", marginTop: "20px" }}
                />
              )}
              <p className="req">{errorForOtherMedicalCondition}</p>

              {/* <select  className="form-control">
          <option>Yes</option>
          <option>No</option>

        </select> */}
            </div>
          </div>

          {/* <div className="col-md-3">
        <div className="form-group">
          <label>Duration</label> <small className="req"> *</small>
          <select
            className="form-control"
            name="Type"
            value={state.newTableRow.PhysicalActivity}
            onChange={changeHandler}
          >
            <option value="">Select</option>
          </select>
        </div>
      </div> */}
          {/* <div className="col-md-2">
        <div className="form-group">
          <label>Severity</label> <small className="req"> *</small>
          <select
            required
            className="form-control"
            name="Severity"
            value={state.newTableRow.Severity}
            onChange={changeHandler}
          >
            <option value="">Select</option>
            <option value="High">High</option>
            <option value="Medium">Medium</option>
            <option value="Low">Low</option>
          </select>
        </div>
      </div> */}
          {/* <div className="col-md-1">
        <button type="submit" className="btn btn-info pull-right mt-3">
          Add
        </button>
      </div> */}
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <FormControl fullWidth disabled={!editable}>
                <label>
                  Tablets recommended by doctor &nbsp;{" "}
                  <small className="req">*</small>
                </label>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={tabletsByDoctor}
                  // label="Age"
                  onChange={handleChangeTabletsRecommended}
                  // style={{height:'45px'}}
                  MenuProps={MenuProps}
                >
                  {tabletsRecommended?.length > 0 &&
                    tabletsRecommended?.map((c) => {
                      return <MenuItem value={c?.id}>{c?.title}</MenuItem>;
                    })}
                </Select>
              </FormControl>
              <p className="req">{errorForTabletsRecommended}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {ifTabletRecommendedIsYes.trim() !== "" ? (
                <TextField
                  disabled={!otherTablets}
                  value={ifTabletRecommendedIsYes}
                  onChange={handleChangeOtherTablets}
                  type="text"
                  placeholder="Please Specify"
                  sx={{ width: "100%", marginTop: "20px" }}
                />
              ) : (
                <TextField
                  disabled={!otherTablets}
                  value={ifTabletRecommendedIsYes}
                  onChange={handleChangeOtherTablets}
                  type="text"
                  placeholder="Please Specify"
                  sx={{ width: "100%", marginTop: "20px" }}
                />
              )}
              <p className="req">{errorForNoTabletRecommended}</p>
            </div>
          </div>
        </div>{" "}
        <br />
        <br />
        {/* <label >Any Deficiencies?</label><br/><br/> */}
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <FormControl sx={{ width: "100%" }} disabled={!editable}>
                <label>
                  Any Deficiency?&nbsp; &nbsp; &nbsp;{" "}
                  <small className="req">*</small>
                </label>

                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={anyDeficiency}
                  onChange={handleChangAnyDeficiencies}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={
                            deficiencies?.length > 0 &&
                            deficiencies?.find((c) => c?.id === value)?.title
                          }
                        />
                      ))}
                    </Box>
                  )}
                  // MenuProps={MenuProps}
                  MenuProps={MenuProps}
                  // style={{height:'45px'}}
                >
                  {deficiencies?.length > 0 &&
                    deficiencies.map((name) => (
                      <MenuItem key={name?.id} value={name?.id}>
                        <Checkbox
                          checked={anyDeficiency.indexOf(name?.id) > -1}
                        />
                        <ListItemText primary={name?.title} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <p className="req">{errorForAnyDeficiencies}</p>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              {otherDeficienciesFromDropdown.trim() !== "" ? (
                <TextField
                  disabled={!otherDeficiencies}
                  value={otherDeficienciesFromDropdown}
                  onChange={handleChangeOtherDeficiencies}
                  type="text"
                  placeholder="Please Specify"
                  sx={{ width: "100%", marginTop: "20px" }}
                />
              ) : (
                <TextField
                  disabled={!otherDeficiencies}
                  value={otherDeficienciesFromDropdown}
                  onChange={handleChangeOtherDeficiencies}
                  type="text"
                  placeholder="Please Specify"
                  sx={{ width: "100%", marginTop: "20px" }}
                />
              )}
              <p className="req">{errorForOtherDeficiency}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <FormControl fullWidth disabled={!editable}>
                <label>
                  Nutrition Supplement Intake &nbsp;{" "}
                  <small className="req">*</small>
                </label>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={nutritionSupplement}
                  // label="Age"
                  onChange={handleChangeNutritionSupplement}
                  // style={{height:'45px'}}
                  MenuProps={MenuProps}
                >
                  {supplementIntake?.length > 0 &&
                    supplementIntake?.map((c) => {
                      return <MenuItem value={c?.id}>{c?.title}</MenuItem>;
                    })}
                </Select>
              </FormControl>
              <p className="req">{errorForNutritionSupplement}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {otherNutritionSupplementFromIsYes.trim() !== "" ? (
                <TextField
                  disabled={!otherNutritionSupplement}
                  value={otherNutritionSupplementFromIsYes}
                  onChange={handleChangeOtherNutritionSupplement}
                  type="text"
                  placeholder="Please Specify"
                  sx={{ width: "100%", marginTop: "20px" }}
                />
              ) : (
                <TextField
                  disabled={!otherNutritionSupplement}
                  value={otherNutritionSupplementFromIsYes}
                  onChange={handleChangeOtherNutritionSupplement}
                  type="text"
                  placeholder="Please Specify"
                  sx={{ width: "100%", marginTop: "20px" }}
                />
              )}
              <p className="req">{errorForIfNutritionIsYes}</p>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
      {/* style={{position:'relative',right:'775px'}} */}
      <div className="row">
        <div className="col-md-3">
          <div
            className="form-group"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              type="submit"
              onClick={editMedicalAssesment}
              className="btn btn-info "
              style={{
                marginRight: "8px",
                backgroundColor: "white",
                color: "black",
                border: "1px solid black",
                width: "120px",
              }}
            >
              Edit
            </button>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <button
              type="submit"
              onClick={saveMedicalAssesment}
              disabled={!editable}
              className="btn btn-info "
              style={{
                backgroundColor: "black",
                color: "white",
                width: "120px",
                marginRight: "230px",
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalAssesment;
