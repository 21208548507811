import ListGroup from 'react-bootstrap/ListGroup';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import PageviewIcon from '@mui/icons-material/Pageview';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import StartIcon from '@mui/icons-material/Start';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListIcon from '@mui/icons-material/List';
import Badge from 'react-bootstrap/Badge';
import moment from 'moment-timezone';
import './Divider.css';

function SessionList(props) {

    const listItemStyle = {
        cursor: 'pointer',
        fontWeight: 'bold',
    };

    const shouldBeEditable = (sess) => {
        let meeting = null
        if(sess.meeting && sess.meeting) {
            meeting = sess.meeting
        }

        if(sess.is_recurring) {
            return true
        } else {
            if(meeting && (meeting.is_cancelled || meeting.is_completed)) {
                return false
            } else {
                return true
            }
        }
    }

    const meetingCanStart = (sess) => {
        if(sess.is_recurring) {
            return true
        } else {
            let meeting = null
            if(sess.meeting) {
                meeting = sess.meeting
            }
    
            if(meeting /*&& !meeting.has_started*/ && !meeting.is_cancelled && !meeting.is_completed) {
                return true
            } else {
                return false
            }
        }
    }

    const upcomingMeetingDate = (sess) => {
        const userTimezone = moment.tz.guess();

        let date = ''
        if(sess.meeting) {
            let meeting = sess.meeting
            date = moment(meeting.start_time).tz(userTimezone).format('DD-MM-YYYY')
        }
        return date
    }

    const upcomingMeetingTime = (sess) => {
        const userTimezone = moment.tz.guess();

        let date = ''
        if(sess.meeting) {
            let meeting = sess.meeting
            date = moment(meeting.start_time).tz(userTimezone).format('h:mm A')
        }
        return date
    }


    function getStatus(meeting) {
        if(meeting.is_cancelled) {
            return <Badge bg="danger" className='ml-1' style={{'fontSize': '12px'}}> Cancelled </Badge>
        }
        if(meeting.is_completed) {
            return <Badge bg="success" className='ml-1' style={{'fontSize': '12px'}}> Completed </Badge>
        }
        if(meeting.has_started && !meeting.is_completed && !meeting.is_cancelled) {
            return <Badge bg="warning" className='ml-1' style={{'fontSize': '12px'}}> Ongoing </Badge>
        }
    
        return <Badge bg="dark" className='ml-1' style={{'fontSize': '12px'}}> Pending </Badge>
    }
    

    return (
        <ListGroup>
            {
                props.list.map((item) => (
                    <ListGroup.Item className="px-4 py-2" style={listItemStyle} key={item.id} 
                    active={props.currentSessionId === item.id}
                    onClick={() => props.handleSelection(item.id, item.meeting?.id)}>
                        <Row>
                            <Col md={5}> 
                                {item.session_name} 
                            </Col>

                            <Col md={4}> 
                                <Badge bg="info" className='' style={{'fontSize': '15px'}}> 
                                    {upcomingMeetingDate(item)}
                                </Badge>

                                <Badge bg="warning" className='ml-1' style={{'fontSize': '15px'}}> 
                                    {upcomingMeetingTime(item)}
                                </Badge>

                                {/* {isInProgress(item)? <Badge bg="warning" className='ms-2'>[In Progress]</Badge>: ''} */}
                                {item.meeting? getStatus(item.meeting): ''}
                            </Col>

                            <Col md={3} className='list-control p-0' >

                                {props.action === 'play'?
                                // Play
                                <span className='mx-1' data-toggle="tooltip" data-placement="top" title="Play">
                                    <PlayCircleOutlineIcon 
                                    width="50"
                                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.handlePlay(item.meeting) }} 
                                    className={`pull-right ${props.currentSessionId === item.id ? 'white-icon' : ''}`}
                                    sx={{ fontSize: 25, color: 'grey', marginRight: '3px' }}/>
                                </span>: ''}

                                {props.action === 'edit' && meetingCanStart(item)?
                                // Start
                                <span className='mx-1' data-toggle="tooltip" data-placement="top" title="Start the session">
                                    <StartIcon width="50"
                                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.handleStartMeeting(item.meeting) }} 
                                    className={`pull-right ${props.currentSessionId === item.id ? 'white-icon' : ''}`}
                                    sx={{ fontSize: 25, color: 'grey', marginRight: '3px' }}/>
                                </span>: ''}

                                {props.action === 'edit' && shouldBeEditable(item)?
                                // Cancel
                                <span className='mx-1' data-toggle="tooltip" data-placement="top" title="Cancel">
                                    <CancelIcon 
                                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.handleCancel(item.id) }} 
                                    className={`pull-right ${props.currentSessionId === item.id ? 'white-icon' : ''}`}
                                    sx={{ fontSize: 25, color: 'grey', marginRight: '3px' }}/>
                                </span>: ''}

                                {/* View */}
                                <span className='mx-1' data-toggle="tooltip" data-placement="top" title="View">
                                    <PageviewIcon width="50"
                                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.handleView(item.id) }} 
                                    className={`pull-right ${props.currentSessionId === item.id ? 'white-icon' : ''}`}
                                    sx={{ fontSize: 25, color: 'grey', marginRight: '3px' }}/>
                                </span>

                                {props.action === 'edit' && shouldBeEditable(item)?
                                // Edit
                                <span className='mx-1' data-toggle="tooltip" data-placement="top" title="Edit">
                                    <ModeEditOutlinedIcon 
                                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.handleEdit(item.id) }} 
                                    className={`pull-right ${props.currentSessionId === item.id ? 'white-icon' : ''}`}
                                    sx={{ fontSize: 25, color: 'grey', marginRight: '3px' }}/>
                                </span>: ''}
                                
                                {item.is_recurring?
                                // History
                                <span className='mx-1' data-toggle="tooltip" data-placement="top" title="Repeating session history">
                                    <ListIcon 
                                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.handleHistory(item.id) }} 
                                    className={`pull-right ${props.currentSessionId === item.id ? 'white-icon' : ''}`}
                                    sx={{ fontSize: 25, color: 'grey', marginRight: '3px' }}/>
                                </span>: ''}

                            </Col>
                        </Row>
                        
                    </ListGroup.Item>
                ))
            }
        </ListGroup>
    )
}

export default SessionList