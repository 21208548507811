import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ImageIcon from "@mui/icons-material/Image";
import MessageIcon from "@mui/icons-material/Message";

const useReportProgress = (handleClick, handleComment) => {
  const progressColumn = [
    {
      field: "accountId",
      headerName: "Account ID",
      width: 200,
      headerClassName: "super-app-theme--header",
      //   renderCell: (params) => {
      //     return "";
      //   },
    },
    {
      field: "Name",
      headerName: "Client Name",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Phone",
      headerName: "Phone Number",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "programs",
      headerName: "Program Name",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "nutritionist",
      headerName: "Nutritionist",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "trainer",
      headerName: "Trainer",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "score",
      headerName: "Progress %",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const progressValue = params.row?.score;
        let colorValue;
        if (progressValue < 75)
          colorValue = { color: "red", hexa: "#FF00001A" };
        if (progressValue >= 75 && progressValue < 95)
          colorValue = { color: "orange", hexa: "#FFA5001A" };
        if (progressValue >= 95 && progressValue <= 100)
          colorValue = { color: "green", hexa: "#0080001A" };

        return (
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <Stack sx={{ color: colorValue.color }} direction="row">
              <CircularProgress
                variant="determinate"
                color="inherit"
                value={progressValue}
              />
            </Stack>
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: colorValue.hexa,
                borderRadius: "50%",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {progressValue}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "weight",
      headerName: "Weight (Kg)",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const isWeight = params.row?.isWeightUpdatedIn7Days
          ? "#d3f9d8"
          : "#ffc9c9";

        if (params.row?.weight) {
          return (
            <span
              style={{
                backgroundColor: isWeight,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "70%",
                fontWeight: "600",
              }}
            >
              {params.row?.weight}
            </span>
          );
        }
      },
    },
    {
      field: "pciture",
      headerName: "Pictures Adder",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", gap: "50px" }}>
            <span style={{ width: "20px" }}>{params.row?.isImageAdded}</span>
            <ImageIcon
              style={{ fontSize: "20px", color: "#A1A1A1", cursor: "pointer" }}
              onClick={(event) => handleClick(event, params)}
            />
          </div>
        );
      },
    },
    {
      field: "neck",
      headerName: "Neck (In)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "left_arm",
      headerName: "Left Arm (In)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "right_arm",
      headerName: "Right Arm (In)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "left_forearm",
      headerName: "Left Forearm (In)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "right_forearm",
      headerName: "Right Forearm (In)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "shoulder",
      headerName: "Shoulder(In)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "lower_abdomen",
      headerName: "Lower Abdomen (In)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "upper_abdomen",
      headerName: "Upper Abdomen (In)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "hip",
      headerName: "Hip (In)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "chest",
      headerName: "Chest (In)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "left_thigh",
      headerName: "Left Thigh (In)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "right_thigh",
      headerName: "right Thigh (In)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "left_calf",
      headerName: "Left calf (In)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "right_calf",
      headerName: "right calf (In)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "progress_datetime",
      headerName: "Date",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", gap: "50px" }}>
            <span>{params.row?.comments}</span>
            <MessageIcon
              style={{ fontSize: "20px", color: "#A1A1A1", cursor: "pointer" }}
              onClick={(event) => handleComment(event, params)}
            />
          </div>
        );
      },
    },
  ];

  return { progressColumn };
};

export default useReportProgress;
