// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}


.repeating-history-modal .modal-dialog {
  max-width: 500px;
  /* Adjust the value as per your requirements */
}

.repeating-participant-modal .modal-dialog {
  max-width: 600px;
  /* Adjust the value as per your requirements */
}

.list-control .white-icon {
  color: #d9d9d9;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Studio/Divider.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,0BAA0B;EAC1B,cAAc;AAChB;;;AAGA;EACE,gBAAgB;EAChB,8CAA8C;AAChD;;AAEA;EACE,gBAAgB;EAChB,8CAA8C;AAChD;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".divider {\n  border: none;\n  border-top: 1px solid #ccc;\n  margin: 20px 0;\n}\n\n\n.repeating-history-modal .modal-dialog {\n  max-width: 500px;\n  /* Adjust the value as per your requirements */\n}\n\n.repeating-participant-modal .modal-dialog {\n  max-width: 600px;\n  /* Adjust the value as per your requirements */\n}\n\n.list-control .white-icon {\n  color: #d9d9d9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
