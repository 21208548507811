// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.extension-backdrop {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.379);
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 100;
}

.extension-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 1rem;
}

.extension-row {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 10px;
}

.extension-col {
  width: 100%;
}

.extension-col label {
  margin-bottom: 10px;
  font-weight: 500;
}

.extension-sub-margin {
  margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Leads/Programs/modals/Extension.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sCAAsC;EACtC,eAAe;EACf,OAAO;EACP,QAAQ;EACR,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,4CAA4C;EAC5C,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,SAAS;EACT,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".extension-backdrop {\n  width: 100%;\n  height: 100vh;\n  background-color: rgba(0, 0, 0, 0.379);\n  position: fixed;\n  top: 0%;\n  left: 0%;\n  z-index: 100;\n}\n\n.extension-card {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 600px;\n  z-index: 1000;\n  background: #ffffff;\n  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);\n  border-radius: 8px;\n  padding: 1rem;\n}\n\n.extension-row {\n  display: flex;\n  gap: 20px;\n  width: 100%;\n  margin-bottom: 10px;\n}\n\n.extension-col {\n  width: 100%;\n}\n\n.extension-col label {\n  margin-bottom: 10px;\n  font-weight: 500;\n}\n\n.extension-sub-margin {\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
