// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meal {
  position: relative;
  right: 230px;
  color: red;
}
#datePicker {
  width: 170px !important;
}
#datePicker div {
  height: 50px !important;
}

.mine {
  display: none;
}

.targetcalories {
  border: none;
  border-radius: 8px;

  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
  padding: 1em;
  margin-bottom: 2em;
}

.instructionsWorkout {
  border: 1px solid lightgray;
  border-radius: 6px;

  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12); */
  padding: 10px 8px;
  margin-bottom: .7em;
}

#waterQty {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
}

.template {
  display: flex;
  justify-content: space-between;
  border: none;

  padding: 1em;
  margin-bottom: 2em;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
}

.waterinfo {
  display: flex;
  width: 100%;
  gap: 1em;
}

@media (max-width: 1300px) and (min-width: 700px) {
  .meal {
    position: relative;
    right: 100px;
  }
  .targetCalorie {
    position: relative;
    left: 10px;
    right: 60px;
  }
  .dietGrid {
    position: absolute;
    right: 10px;
    left: 10px;
  }
  #timePicker {
    position: relative;
    left: 100px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/ClientPlans.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;AACZ;AACA;EACE,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,kBAAkB;;EAElB,gDAAgD;EAChD,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;;EAElB,sDAAsD;EACtD,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,gDAAgD;AAClD;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,YAAY;;EAEZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,gDAAgD;AAClD;;AAEA;EACE,aAAa;EACb,WAAW;EACX,QAAQ;AACV;;AAEA;EACE;IACE,kBAAkB;IAClB,YAAY;EACd;EACA;IACE,kBAAkB;IAClB,UAAU;IACV,WAAW;EACb;EACA;IACE,kBAAkB;IAClB,WAAW;IACX,UAAU;EACZ;EACA;IACE,kBAAkB;IAClB,WAAW;EACb;AACF","sourcesContent":[".meal {\n  position: relative;\n  right: 230px;\n  color: red;\n}\n#datePicker {\n  width: 170px !important;\n}\n#datePicker div {\n  height: 50px !important;\n}\n\n.mine {\n  display: none;\n}\n\n.targetcalories {\n  border: none;\n  border-radius: 8px;\n\n  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);\n  padding: 1em;\n  margin-bottom: 2em;\n}\n\n.instructionsWorkout {\n  border: 1px solid lightgray;\n  border-radius: 6px;\n\n  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12); */\n  padding: 10px 8px;\n  margin-bottom: .7em;\n}\n\n#waterQty {\n  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);\n}\n\n.template {\n  display: flex;\n  justify-content: space-between;\n  border: none;\n\n  padding: 1em;\n  margin-bottom: 2em;\n  border-radius: 8px;\n  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);\n}\n\n.waterinfo {\n  display: flex;\n  width: 100%;\n  gap: 1em;\n}\n\n@media (max-width: 1300px) and (min-width: 700px) {\n  .meal {\n    position: relative;\n    right: 100px;\n  }\n  .targetCalorie {\n    position: relative;\n    left: 10px;\n    right: 60px;\n  }\n  .dietGrid {\n    position: absolute;\n    right: 10px;\n    left: 10px;\n  }\n  #timePicker {\n    position: relative;\n    left: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
