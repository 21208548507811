// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backdrop-vmax {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.379);
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 100;
}

.card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1250px;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;

  height: 600px;
  overflow-y: auto;
}

.menu-bar-con {
  width: 100%;
}

.menu-bar {
  width: 100%;
  list-style: none;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  /* border-bottom: 2px solid #ccc; */
}

.menu-item {
  cursor: pointer;
  padding: 10px;
  padding-bottom: 0px;
  position: relative;
}

.menu-item.active::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/clientPlans/WorkoutPlan/WorkoutModel.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sCAAsC;EACtC,eAAe;EACf,OAAO;EACP,QAAQ;EACR,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,4CAA4C;EAC5C,kBAAkB;EAClB,aAAa;;EAEb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;;EAEnB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,SAAS;EACT,2BAA2B;EAC3B,mCAAmC;AACrC;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,OAAO;EACP,WAAW;EACX,WAAW;EACX,sBAAsB;AACxB","sourcesContent":[".backdrop-vmax {\n  width: 100%;\n  height: 100vh;\n  background-color: rgba(0, 0, 0, 0.379);\n  position: fixed;\n  top: 0%;\n  left: 0%;\n  z-index: 100;\n}\n\n.card {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 1250px;\n  z-index: 1000;\n  background: #ffffff;\n  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);\n  border-radius: 8px;\n  padding: 50px;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  height: 600px;\n  overflow-y: auto;\n}\n\n.menu-bar-con {\n  width: 100%;\n}\n\n.menu-bar {\n  width: 100%;\n  list-style: none;\n  display: flex;\n  gap: 10px;\n  justify-content: flex-start;\n  /* border-bottom: 2px solid #ccc; */\n}\n\n.menu-item {\n  cursor: pointer;\n  padding: 10px;\n  padding-bottom: 0px;\n  position: relative;\n}\n\n.menu-item.active::after {\n  content: \"\";\n  position: absolute;\n  bottom: -8px;\n  left: 0;\n  width: 100%;\n  height: 2px;\n  background-color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
