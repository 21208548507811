

const medicalform = [
    {
        "type": "Autocomplete",
        "label": "Medical Condition",
        "name": "medical_condition",
        "rules": {

        }
    },
    {
        "type": "Autocomplete",
        "label": "Are you suffering from any Health issues?",
        "name": "health_issues",
        "rules": {

        }
    },
    {
        "type": "Autocomplete",
        "label": "Body Aches",
        "name": "preferred_cuisine",
        "rules": {

        }
    },
    {
        "type": "RadioGroup",
        "label": "Any Recent Health Checkup",
        "name": "health_checkup",
        "rules": {

        }
    },
    {
        "type": "Radio Group",
        "label": "Any History of Surgery",
        "name": "surgery",
        "rules": {

        }
    }
    
]

export default medicalform
