import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
  Card,
  Button,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import {
  useParams,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: "1px solid #D0D0D0",
    },
  },
});
const ProgressView = () => {
  const classes = useStyles();
  let params = useParams();
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [bodyMeasurement, setBodyMeasurement] = useState([]);
  const [rowId, setRowId] = useState("");
  let [showMenu, setShowMenu] = useState(false);

  const current_route = window.location.pathname;

  useEffect(() => {
    if (current_route.includes("/lead")) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openPopup = () => {
    setOpen(true);
  };
  const closePopup = () => {
    setOpen(false);
  };

  const getMesurements = () => {
    axiosFetch({
      url: `/getBodyMeasurementsCRMbyLeadId/${params.id}`,
      method: "get",
    }).then((res) => {
      if (res.status === 200) {
        setBodyMeasurement(res.data);
      } else if (res.status === 400) {
        toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
      } else if (res.status === 401) {
        history.push("/");
      } else if (res.status === 500) {
        toast.error("Internal Server Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const generateReport = () => {
    axiosFetch({
      url: `/v3/generateProgressReport/${params.id}`,
      method: "post",
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Report Generated Successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 500) {
          toast.error("Internal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getMesurements();
  }, []);
  return (
    <div style={{ overflowX: "auto" }}>
      <Stack direction="row" spacing={6}>
        <Typography
          sx={{
            color: "#2D2D2D",
            fontFamily: "Poppins",
            fontWeight: "600",
            Size: "18px",
          }}
        >
          Progress View
        </Typography>

        {showMenu && (
          <Button
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "black",
              textTransform: "capitalize",
              marginLeft: "86%",
              float: "right",
              "&:hover": {
                color: "white",
                backgroundColor: "black",
              },
            }}
            onClick={generateReport}
          >
            Generate Report
          </Button>
        )}
      </Stack>
      <br />

      <Table className={classes.table} aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#F4F4F4" }}>
          <TableRow>
            <TableCell align="left">
              <b>Date</b>
            </TableCell>
            <TableCell align="left">
              <b>Weight (kg)</b>
            </TableCell>
            <TableCell align="left">
              <b>Neck (in)</b>
            </TableCell>
            <TableCell>
              <b>Left arm (in)</b>
            </TableCell>
            <TableCell align="left">
              <b>Right arm (in)</b>
            </TableCell>
            <TableCell align="left">
              <b>Left forearm (in)</b>
            </TableCell>
            <TableCell align="left">
              <b>Right forearm (in)</b>
            </TableCell>
            <TableCell align="left">
              <b>Shoulder (in)</b>
            </TableCell>
            <TableCell align="left">
              <b>Lower abdomen (in)</b>
            </TableCell>
            <TableCell align="left">
              <b>Upper abdomen (in)</b>
            </TableCell>
            <TableCell align="left">
              <b>Hip (in)</b>
            </TableCell>
            <TableCell align="left">
              <b>Chest (in)</b>
            </TableCell>
            <TableCell align="left">
              <b>Left thigh (in)</b>
            </TableCell>
            <TableCell align="left">
              <b>Right thigh (in)</b>
            </TableCell>
            <TableCell align="left">
              <b>Left calf (in)</b>
            </TableCell>
            <TableCell align="left">
              <b>Right calf (in)</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bodyMeasurement.length !== 0 ? (
            bodyMeasurement.map((mesurement) => (
              <TableRow sx={{ color: "#6B6B6B" }} key={mesurement.id}>
                <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                  {mesurement.progress_datetime !== null
                    ? mesurement.progress_datetime
                    : "-"}
                </TableCell>
                <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                  {mesurement.weight !== null ? mesurement.weight : "-"}
                </TableCell>
                <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                  {mesurement.neck !== null ? mesurement.neck : "-"}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ color: "#6B6B6B" }}>
                  {mesurement.left_arm !== null ? mesurement.left_arm : "-"}
                </TableCell>
                <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                  {mesurement.right_arm !== null ? mesurement.right_arm : "-"}
                </TableCell>
                <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                  {mesurement.left_forearm !== null
                    ? mesurement.left_forearm
                    : "-"}
                </TableCell>
                <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                  {mesurement.right_forearm !== null
                    ? mesurement.right_forearm
                    : "-"}
                </TableCell>
                <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                  {mesurement.shoulder !== null ? mesurement.shoulder : "-"}
                </TableCell>
                <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                  {mesurement.lower_abdomen !== null
                    ? mesurement.lower_abdomen
                    : "-"}
                </TableCell>
                <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                  {mesurement.upper_abdomen !== null
                    ? mesurement.upper_abdomen
                    : "-"}
                </TableCell>
                <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                  {mesurement.hip !== null ? mesurement.hip : "-"}
                </TableCell>
                <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                  {mesurement.chest !== null ? mesurement.chest : "-"}
                </TableCell>
                <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                  {mesurement.left_thigh !== null ? mesurement.left_thigh : "-"}
                </TableCell>
                <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                  {mesurement.right_thigh !== null
                    ? mesurement.right_thigh
                    : "-"}
                </TableCell>
                <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                  {mesurement.left_calf !== null ? mesurement.left_calf : "-"}
                </TableCell>{" "}
                <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                  {mesurement.right_calf !== null ? mesurement.right_calf : "-"}
                </TableCell>{" "}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={15}
                align="center"
                color="red"
                style={{ color: "red" }}
              >
                No Results Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <br />
    </div>
  );
};

export default ProgressView;
