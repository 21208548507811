import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

// import dayjs from "dayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AlertPopup from "./AlertPopup";

import { axiosFetch } from "../../../../Utlis/AxiosFetch";

import {
  updateVmax,
  updateVmaxList,
  updateRestList,
  updateRest,
} from "../../../store/WorkoutPlan/WorkoutPlan";

import "./Vmax.css";

const Vmax = (props) => {
  const dispatch = useDispatch();
  const liveList = useSelector((state) => state.workoutPlan.liveList);
  const vmaxList = useSelector((state) => state.workoutPlan.vmaxList);
  const customList = useSelector((state) => state.workoutPlan.customList);
  const restList = useSelector((state) => state.workoutPlan.restList);
  const getEvents = useSelector((state) => state.workoutPlan.getEvents);
  const plan = useSelector((state) => state.workoutPlan.startDate);
  const editEvent = useSelector((state) => state.workoutPlan.editEvent);
  const clickEvent = useSelector((state) => state.workoutPlan.clickEvent);

  const [activeDay, setActiveDays] = useState(["monday"]);
  const [workout, setWorkout] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [repeat, setRepeat] = useState(false);
  const everyRepeat = useRef("");
  const typeOfMode = useRef("");

  // const [workoutType, setWorkoutType] = useState("");
  const workoutType = useRef("");
  const exerciseName = useRef("");
  const reps = useRef("");
  const sets = useRef("");
  const [duration, setDuration] = useState("00:00:00");
  const [exerciseList, setExerciseList] = useState([]);

  const [workoutTypeCate, setWorkoutTypeCate] = useState("");
  const [exerciseNameType, setExerciseNameType] = useState("");
  const [everyRepeatt, seteveryRepeatt] = useState("");
  const [dayOfType, setDayOfType] = useState("");
  const [noOfReps, setNoOfReps] = useState("");
  const [noOfSets, setNoOfSets] = useState("");
  const [hasdate, setHasdate] = useState("");
  const [link, setLink] = useState("");

  const [open, setOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState("");
  const [snackBarColor, setSnackBarColor] = useState("");

  const [typeOfDay, setTypeOfDay] = useState("weeks");

  const [endDateFlag, setEndDateFalg] = useState(false);
  const [startDateFlag, setStartDateFlag] = useState(false);
  const [week, setWeek] = useState([1, 2, 3, 4, 5]);

  const days = [
    { id: 0, name: "sunday" },
    { id: 1, name: "monday" },
    { id: 2, name: "tuesday" },
    { id: 3, name: "wednesday" },
    { id: 4, name: "thursday" },
    { id: 5, name: "friday" },
    { id: 6, name: "saturday" },
  ];

  const handleCloseSnackbar = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosFetch({
          url: `/getWorkoutNames`,
          method: "get",
        });
        setWorkout(response.data);
      } catch (error) {
        //console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (editEvent.plan_type === "vmax") {
      setHasdate(editEvent.date);
      setStartDate(dayjs(editEvent.date));
      setWorkoutTypeCate(editEvent.workout_type);
      setLink(editEvent.workout_details[0].link);
      setNoOfReps(editEvent.workout_details[0].reps);
      setNoOfSets(editEvent.workout_details[0].set);
      setDuration(editEvent.workout_details[0].duration);
      setExerciseList([
        {
          id: 1,
          name: editEvent.workout_details[0].exercise,
        },
      ]);

      setExerciseNameType(1);
    }
  }, [editEvent]);

  const handleCheckboxChange = (event) => {
    setRepeat(event.target.checked);
  };

  function getDateFromString(dateStr) {
    const [month, day, year] = dateStr.split("-");
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

  const handleDatePicker = (selectedDate, params) => {
    const date = new Date(selectedDate.$d);
    const formattedDate = date
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-");

    if (params === "start") {
      const planStartDate = getDateFromString(plan.planStart);
      const vmaxStartDate = getDateFromString(formattedDate);

      if (vmaxStartDate < planStartDate) {
        setOpen(true);
        setSnackBarColor("warning");
        setsnackMessage(
          `Start date must be within plan start date ${plan.planStart}`
        );
        setStartDateFlag(true);
        setStartDate(null);
        setTimeout(() => {
          setStartDateFlag(false);
        }, 100);
      } else {
        setStartDate(formattedDate);
      }
    } else {
      const date = getDateFromString(plan.date);
      const endDate = getDateFromString(formattedDate);

      if (endDate > date) {
        setOpen(true);
        setSnackBarColor("warning");
        setsnackMessage(`End date must be within plan end date ${plan.date}`);
        // alert(`End date must be within plan start date ${plan.date}`);
        setEndDateFalg(true);
        setEndDate(null);
        setTimeout(() => {
          setEndDateFalg(false);
        }, 100);
        return;
      } else if (endDate <= date) {
        setEndDate(formattedDate);
      }
    }
  };

  const handleStartDate = (selectedDate) => {
    handleDatePicker(selectedDate, "start");
  };

  const handleEndDate = (selectedDate) => {
    handleDatePicker(selectedDate);
  };

  const handleDayClick = (value, index) => {
    const isActive = activeDay.includes(value);
    if (isActive) {
      setActiveDays(activeDay.filter((day) => day !== value));
    } else {
      setActiveDays([...activeDay, value]);
    }
  };

  const handleDurationChange = (e) => {
    setDuration(e.target.value);
  };

  const handleWorkoutType = async (e) => {
    setWorkoutTypeCate(e.target.value);
    try {
      const response = await axiosFetch({
        url: `getExerciseByWorkoutType/${e.target.value}`,
        method: "get",
      });

      setExerciseList(response.data);
    } catch (error) {
      //console.log(error);
    }
  };

  const handleExerciseName = (e) => {
    setExerciseNameType(e.target.value);
  };

  const handleEveryRepeat = (e) => {
    seteveryRepeatt(e.target.value);
  };

  const handleWeek = (e) => {
    const day = e.target.value;
    setTypeOfDay(e.target.value);
    if (day === "month") {
      setWeek([1]);
    }
    if (day === "weeks") {
      setWeek([1, 2, 3, 4]);
    }

    setDayOfType(e.target.value);
  };

  // Help to find dates
  const findDates = () => {
    function getDateFormat(dateStr) {
      const date = new Date(dateStr.$d);
      const formattedDate = date
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-");
      return formattedDate;
    }

    function getDateFromString(dateStr) {
      if (dateStr) {
        const [month, day, year] = dateStr.split("-");
        return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      }
    }

    let startDateFind;
    let endDateFind;

    if (editEvent.plan_type === "vmax") {
      const start = getDateFormat(startDate);
      const end = getDateFormat(startDate);
      startDateFind = getDateFromString(start);
      endDateFind = getDateFromString(end);
    } else {
      startDateFind = getDateFromString(startDate);
      endDateFind = getDateFromString(endDate);
    }

    const resultDates = [];

    let currentDate = startDateFind;
    while (currentDate <= endDateFind) {
      const formattedDate = `${currentDate.getDate()}-${
        currentDate.getMonth() + 1
      }-${currentDate.getFullYear()}`;
      resultDates.push(formattedDate);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return resultDates;
  };

  // Help to find days
  const findDays = (dayOfWeek, start, end, repeatMode) => {
    function getNextDate(currentDate) {
      const nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + 1);
      return nextDate;
    }

    const startDate = new Date(
      parseInt(start.split("-")[2]),
      parseInt(start.split("-")[0]) - 1,
      parseInt(start.split("-")[1])
    );
    const endDate = new Date(
      parseInt(end.split("-")[2]),
      parseInt(end.split("-")[0]) - 1,
      parseInt(end.split("-")[1])
    );

    const resultDates = [];

    let currentDate = startDate;
    let weekCount = 1;

    let isWeek = repeatMode.current ? +repeatMode.current.value : 1;

    if (typeOfDay === "weeks") {
      while (currentDate <= endDate) {
        const currentDayOfWeek = currentDate.getDay();

        if (dayOfWeek.includes(currentDayOfWeek) && weekCount === isWeek) {
          const formattedDate = `${currentDate.getDate()}-${
            currentDate.getMonth() + 1
          }-${currentDate.getFullYear()}`;
          resultDates.push({
            date: formattedDate,
            day: currentDayOfWeek,
          });
        }

        currentDate = getNextDate(currentDate);

        if (currentDate.getDay() === 0) {
          weekCount++;
        }
      }
    } else {
      while (currentDate <= endDate) {
        const currentDayOfWeek = currentDate.getDay();

        if (dayOfWeek.includes(currentDayOfWeek)) {
          const formattedDate = `${currentDate.getDate()}-${
            currentDate.getMonth() + 1
          }-${currentDate.getFullYear()}`;
          resultDates.push({
            date: formattedDate,
            day: currentDayOfWeek,
          });
        }

        // Get the next date
        currentDate = getNextDate(currentDate);
      }
    }

    return resultDates;
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!startDate) {
      setOpen(true);
      setSnackBarColor("warning");
      setsnackMessage("Please fill in all the data before proceeding");
      return;
    }

    let exerciseId;
    if (exerciseList.length > 0) {
      [exerciseId] = exerciseList.filter((data) => {
        return +exerciseName.current.value === data.id;
      });
    }

    if (exerciseId) {
      setLink(exerciseId.link);
    }

    const activeDaysId = days
      .filter((day) => activeDay.includes(day.name))
      .map((day) => day.id);

    const dateFormat = (date) => {
      const dateStr = date;
      const [day, month, year] = dateStr.split("-");
      const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0"
      )}`;
      return formattedDate;
    };

    let allDates;

    if (repeat) {
      allDates = findDays(activeDaysId, startDate, endDate, everyRepeat);
    } else {
      allDates = findDates();
    }

    let vmaxData;
    if (editEvent.plan_type === "vmax") {
      const dateObject = new Date(startDate);
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, "0");
      const day = String(dateObject.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;

      let planName;

      if (plan.planName) {
        planName = plan.planName;
      } else if (plan.templateName) {
        planName = plan.templateName;
      }

      vmaxData = [
        {
          id: editEvent.plan_type === "vmax" ? editEvent.id : "",
          plan_name: planName,
          date: formattedDate,
          repeat: repeat,
          repeatCount: 0,
          repeatUnit: "",
          dayOfWeek: "",
          workout_type: +workoutType.current.value,
          workout_details: [
            {
              id: exerciseId ? exerciseId.id : "",
              exercise: exerciseId ? exerciseId.name : "",
              link: link ? link : "",
              reps: +reps.current.value,
              set: +sets.current.value,
              duration: duration,
            },
          ],
          plan_type: "vmax",
        },
      ];
    } else {
      vmaxData = allDates.map((date) => {
        let currentDate;
        let currentDay;

        if (date.date) {
          currentDate = date.date;
          currentDay = date.day;
        } else {
          currentDate = date;
        }

        let planName;

        if (plan.planName) {
          planName = plan.planName;
        } else if (plan.templateName) {
          planName = plan.templateName;
        }

        return {
          plan_name: planName,
          date: dateFormat(currentDate),
          repeat: repeat,
          repeatCount: everyRepeat.current ? +everyRepeat.current.value : 1,
          repeatUnit: typeOfMode.current ? typeOfMode.current.value : "weeks",
          dayOfWeek: currentDay ? [currentDay] : "",
          workout_type: +workoutType.current.value,
          workout_details: [
            {
              id: exerciseId ? exerciseId.id : "",
              exercise: exerciseId ? exerciseId.name : "",
              link: exerciseId ? exerciseId.link : "",
              reps: +reps.current.value,
              set: +sets.current.value,
              duration: duration,
            },
          ],
          plan_type: "vmax",
        };
      });
    }

    if (
      startDate === "" ||
      endDate === "" ||
      workoutType.current.value === "" ||
      reps.current.value === "" ||
      sets.current.value === "" ||
      duration === "" ||
      exerciseName.current.value === ""
    ) {
      setOpen(true);
      setSnackBarColor("warning");
      setsnackMessage("Please fill in all the data before proceeding");
      // alert("Please fill in all the data before proceeding.");
    } else {
      dispatch(updateVmax(vmaxData));
      const isDuplicate = vmaxList.some((vmax) => {
        return vmaxData.some((vmaxEvent) => {
          return vmax.date === vmaxEvent.date;
        });
      });
      const isCustomDuplicate = customList.some((custom) => {
        return vmaxData.some((vmaxEvent) => {
          return custom.date === vmaxEvent.date;
        });
      });
      const isLiveDuplicate = liveList.some((live) => {
        return vmaxData.some((vmaxEvent) => {
          return live.date === vmaxEvent.date;
        });
      });

      const isRestList = restList.some((rest) => {
        return vmaxData.some((vmaxEvent) => {
          return rest.date === vmaxEvent.date;
        });
      });

      const isEventsDuplicate = getEvents.some((event) => {
        return vmaxData.some((vmaxEvent) => {
          if (event.start === vmaxEvent.date && event.start === hasdate) {
            return false;
          }
          return event.start === vmaxEvent.date;
        });
      });

      if (
        !isDuplicate &&
        !isCustomDuplicate &&
        !isEventsDuplicate &&
        !isLiveDuplicate &&
        !isRestList
      ) {
        props.closeModel();
        dispatch(updateVmaxList(vmaxData));
      }
      props.closeModel();
    }
  };

  const deleteEvent = async (event) => {
    const response = await axiosFetch({
      url: `/postWorkoutPlans`,
      requestConfig: {
        action: "delete",
        workoutTypeId: event.id,
      },
      method: "post",
    });
  };

  const handleRest = () => {
    let data;

    if (editEvent) {
      let formattedDate;
      if (startDate.$d) {
        const date = new Date(startDate.$d);
        formattedDate = date
          .toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/\//g, "-");
      } else {
        formattedDate = startDate;
      }

      const [month, day, year] = formattedDate.split("-");

      const dateObject = `${year}-${month}-${day}`;

      data = [
        {
          plan_type: "rest",
          date: dateObject,
          workout_details: [{ exercise: "Rest" }],
        },
      ];
    } else {
      if (!startDate || !endDate) {
        setOpen(true);
        setSnackBarColor("warning");
        setsnackMessage(
          "To enable rest Start date and End Date is must needed"
        );
        // alert("To enable rest Start date and End Date is must needed");
        return;
      } else {
        data = findDates().map((data) => {
          const originalDate = data;
          const [day, month, year] = originalDate.split("-");

          const dateObject = new Date(`${year}-${month}-${day}`);

          const formattedYear = dateObject.getFullYear();
          const formattedMonth = String(dateObject.getMonth() + 1).padStart(
            2,
            "0"
          );
          const formattedDay = String(dateObject.getDate()).padStart(2, "0");

          const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;

          return {
            plan_type: "rest",
            date: formattedDate,
            workout_details: [{ exercise: "Rest" }],
          };
        });
      }
    }

    const isDuplicate = vmaxList.some((vmax) => {
      return data.some((dataEvent) => {
        return vmax.date === dataEvent.date;
      });
    });
    const isCustomDuplicate = customList.some((custom) => {
      return data.some((dataEvent) => {
        return custom.date === dataEvent.date;
      });
    });
    const isLiveDuplicate = liveList.some((live) => {
      return data.some((dataEvent) => {
        return live.date === dataEvent.date;
      });
    });

    const isRestList = restList.some((rest) => {
      return data.some((dataEvent) => {
        return rest.date === dataEvent.date;
      });
    });

    const isEventsDuplicate = getEvents.some((event) => {
      return data.some((dataEvent) => {
        if (event.start === dataEvent.date && event.start === hasdate) {
          return false;
        }
        return event.start === dataEvent.date;
      });
    });

    const result = window.confirm("Are you sure you want to create rest event");
    if (result) {
      dispatch(updateRest(data));
    } else {
      return;
    }

    if (
      !isDuplicate &&
      !isCustomDuplicate &&
      !isEventsDuplicate &&
      !isLiveDuplicate &&
      !isRestList
    ) {
      props.closeModel();
      dispatch(updateRestList(data));
      deleteEvent(clickEvent);
    }
    props.closeModel();
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="card-items">
        <Row className="mb-4 row-1">
          {editEvent.plan_type === "vmax" ? (
            <Col sm={4}>
              <label>Start date</label>
              {!startDateFlag && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DesktopDatePicker
                      value={startDate}
                      onChange={handleStartDate}
                    />
                  </DemoItem>
                </LocalizationProvider>
              )}
              {startDateFlag && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DesktopDatePicker value={startDate} />
                  </DemoItem>
                </LocalizationProvider>
              )}
            </Col>
          ) : (
            <Col sm={4}>
              <label>Start date</label>
              {!startDateFlag && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DesktopDatePicker
                      value={startDate}
                      onChange={handleStartDate}
                    />
                  </DemoItem>
                </LocalizationProvider>
              )}
              {startDateFlag && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DesktopDatePicker value={startDate} />
                  </DemoItem>
                </LocalizationProvider>
              )}
            </Col>
          )}

          {editEvent.plan_type === "vmax" ? (
            ""
          ) : (
            <>
              <Col sm={4}>
                <label>End date</label>

                {!endDateFlag && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DesktopDatePicker onChange={handleEndDate} />
                    </DemoItem>
                  </LocalizationProvider>
                )}
                {endDateFlag && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DesktopDatePicker value={endDate} />
                    </DemoItem>
                  </LocalizationProvider>
                )}
              </Col>
              <Col className="radio-con mb-1">
                <p className="radio">
                  <input
                    className="repeat"
                    id="repeat"
                    type="checkbox"
                    checked={repeat}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="repeat">Repeat</label>
                </p>
              </Col>
            </>
          )}
          <Col className="rest-btn">
            <button type="button" onClick={handleRest}>
              Rest
            </button>
          </Col>
        </Row>
        {repeat && (
          <Row className="row-2 mb-4">
            <Col sm={4} className="custom-row">
              <label className="custom-label">Repeat Every</label>
              <select
                id="repeat"
                className="custom-input"
                ref={everyRepeat}
                onChange={handleEveryRepeat}
                value={everyRepeatt}
              >
                {week.length > 0 &&
                  week.map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </Col>
            <Col sm={2} className="col-week">
              <select
                id="repeat"
                className="custom-select"
                ref={typeOfMode}
                value={dayOfType}
                onChange={handleWeek}
              >
                <option value="weeks">Week</option>
                <option value="month">Month</option>
              </select>
            </Col>
            <Col sm={6} className="week-btn">
              {days.map((value, index) => (
                <button
                  type="button"
                  key={value.id}
                  className={` ${
                    activeDay.includes(value.name) ? "active-day" : ""
                  }`}
                  onClick={() => handleDayClick(value.name, index)}
                >
                  {value.name.charAt(0).toUpperCase()}
                </button>
              ))}
            </Col>
          </Row>
        )}

        <Row className="mb-4">
          <Col sm={8} className="select-box-con">
            <label>Type of Workout</label>
            <select
              id="type-of-workout"
              onChange={handleWorkoutType}
              value={workoutTypeCate}
              ref={workoutType}
            >
              <option disabled value="">
                Select
              </option>
              {workout.length > 0 &&
                workout.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </Col>
        </Row>
        <Row className="mb-4 row-3">
          <Col sm={4} className="select-box-con">
            <label>Exercise Name</label>

            <select
              id="exercise-name"
              ref={exerciseName}
              onChange={handleExerciseName}
              value={exerciseNameType}
            >
              <option defaultValue="">Select</option>
              {exerciseList.length > 0 &&
                exerciseList.map((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </Col>
          <Col sm={2}>
            <label>No of Reps</label>
            <input
              type="number"
              className="custom-input"
              placeholder="No of Reps"
              ref={reps}
              defaultValue={noOfReps}
            />
          </Col>
          <Col sm={2}>
            <label>No of Sets</label>
            <input
              type="number"
              className="custom-input"
              placeholder="No of sets"
              ref={sets}
              defaultValue={noOfSets}
            />
          </Col>
          <Col sm={4} className="duration">
            <label>Duration</label>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DesktopTimePicker"]}>
                <DemoItem>
                  <DesktopTimePicker
                    label=""
                    onChange={(e) => handleDurationChange(e)}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider> */}
            <input
              className="vmax-duration"
              type="time"
              step="1"
              placeholder="duration"
              onChange={handleDurationChange}
              value={duration}
            />
          </Col>
        </Row>
      </div>
      {/* <input type="time" value={time} step="1" /> */}
      <div className="workout-btn-con">
        <button type="submit">Save</button>
        <button
          type="button"
          onClick={() => {
            props.closeModel();
          }}
        >
          Cancel
        </button>
      </div>
      <AlertPopup
        snackMessage={snackMessage}
        snackBarColor={snackBarColor}
        open={open}
        handleCloseSnackbar={handleCloseSnackbar}
      />
    </form>
  );
};

export default Vmax;
