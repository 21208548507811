// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FreezeProgram_form__0PGZN input {
  padding: 10px 10px;
  /* border-radius: 5px; */
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Leads/Programs/modals/FreezeProgram.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,wBAAwB;AAC1B","sourcesContent":[".form input {\n  padding: 10px 10px;\n  /* border-radius: 5px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `FreezeProgram_form__0PGZN`
};
export default ___CSS_LOADER_EXPORT___;
