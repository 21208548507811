// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floatclass{
    float: right;
    margin-right: 20px;
}

.leftside_move{
    position: relative;
    left: 180px;
}

input.input-error,
select.input-error
{
      
    background-color: #fce4e4;
    border: 0.1px solid #ff01416a;
    outline: none;
}

label.checkbox-error,
.gender-error{
    color:red
}

p.error-message{
    color: red;
    display: flex;
    justify-content: center;
}

`, "",{"version":3,"sources":["webpack://./src/Pages/Leads/FreshLeads.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;;;;IAII,yBAAyB;IACzB,6BAA6B;IAC7B,aAAa;AACjB;;AAEA;;IAEI;AACJ;;AAEA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".floatclass{\n    float: right;\n    margin-right: 20px;\n}\n\n.leftside_move{\n    position: relative;\n    left: 180px;\n}\n\ninput.input-error,\nselect.input-error\n{\n      \n    background-color: #fce4e4;\n    border: 0.1px solid #ff01416a;\n    outline: none;\n}\n\nlabel.checkbox-error,\n.gender-error{\n    color:red\n}\n\np.error-message{\n    color: red;\n    display: flex;\n    justify-content: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
