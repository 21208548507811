import ListGroup from 'react-bootstrap/ListGroup';

function ProgramList(props) {
    const listItemStyle = {
        cursor: 'pointer',
        fontWeight: 'bold',
    };

    return (
        <ListGroup>
            {
                props.list.map((item) => (
                    <ListGroup.Item 
                        className="px-4 py-2" style={listItemStyle} 
                        key={item.id} onClick={() => props.handleClick(item.id)}
                        active={props.currentProgramId === item.id}>
                        {item.name}
                    </ListGroup.Item>
                ))
            }
        </ListGroup>
    )
}

export default ProgramList