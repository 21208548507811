import React, { useState, useEffect, useContext } from "react";
import { changeHandler as oldChangeHandler } from "../../Helpers/ChangeHandler";
import * as $ from "jquery";
import axios from "axios";
import DataTable from "../../components/DataTable";
import SelectBox from "../../components/SelectBox";
import BoxHeader from "../../components/BoxHeader";
import SideBarItem from "../../components/SideBarItem";
import Accordion from "react-bootstrap/Accordion";
import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";
import ClientProgressView from "./ClientProgressView";
import ClientProgressImages from "./ClientProgressImages";
import ClientProgressAnalysis from "./ClientProgressAnalysis";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function ClientNotes(props) {
  const [activityTracker, setactivityTracker] = useState([]);

  const params = useParams();
  var date_today = new Date();
  date_today.setDate(date_today.getDate() - 7);
  var finalDate =
    date_today.getFullYear() +
    "-" +
    (date_today.getMonth() + 1) +
    "-" +
    date_today.getDate();
  var dateF;
  var month;

  if (date_today.getDate() > 9) {
    dateF = date_today.getDate();
  } else {
    dateF = "0" + date_today.getDate();
  }
  if (date_today.getMonth() + 1 > 9) {
    month = date_today.getMonth() + 1;
  } else {
    month = "0" + (date_today.getMonth() + 1);
  }
  var year = date_today.getFullYear();
  var finalDate = year + "-" + month + "-" + dateF;

  const [fromDate, setFromDate] = useState(
    props?.clientData?.createdAt?.split("T")[0]
  );
  const [toDate, setToDate] = useState(new Date().toISOString()?.split("T")[0]);

  const [state, setState] = useState({
    newTableRow: {
      id: "00123456",
      Name: "Nabeel Ahmed Qureshi",
      Gender: "Male",
      Age: "21",
      LastLogin: "13-08-2022",
      FoodType: "N.A",
      UserType: "New User",

      Source: "Select",
      Phone: "03333707005",
      EmergencyContact: "03332109184",
      PresentAddress: "ABC",
      PermanentAddress:
        "G-804, SAIMA SQUAREONE, NEAR MILLINEUM, GULISTANE JAUHAR, KARACHI",
      DOB: "2001-08-07",
      Gender: "Male",
      CNIC: "42201-1375055-3",
      Domicile: "Karachi",
      Nationality: "Pakistan",
      Religion: "Islam",
      BloodGroup: "-",
      FatherName: "Nadeem Ahmed Qureshi",
      FatherCNIC: "42201-8974882-0",
      GuardianName: "Nadeem Ahmed Qureshi",
      GuardianCNIC: "42201-8974882-0",
      MaritalStatus: "Single",
      Image: "",
    },
    editingActivated: false,
    sourcesList: [],
    diseaseList: [],
    tabShown: "About",
  });
  const contextState = useContext(stateContext);
  const { configToken } = contextState.state;
  // const { clientdataAdd } = contextState

  // console.log(clientdataAdd,'clientdataAdd....');
  function changeHandler(e) {
    oldChangeHandler(e, state, setState);
  }
  // let date=new Date()
  let rightNow = new Date();
  let date = rightNow.toISOString().slice(0, 10);

  const [first, setfirst] = useState({});

  const handleChangeFromDate = (e) => {
    setFromDate(e.target.value);
  };

  const handleChangeToDate = (e) => {
    setToDate(e.target.value);
  };

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  const getActivityTracker = () => {
    let parameters = {
      leadId: props.lead_id,

      fromDate: !!fromDate ? fromDate : null,

      toDate: !!toDate ? toDate : null,

      timezone: encodeURIComponent(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      ),
    };

    const queryString = Object.entries(parameters)
      .filter(([_, value]) => value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    axios
      .get(
        `${ATLAS_URI}/getActivityTrackerByActivityDateAndLeadId/?${queryString}`,
        configToken
      )
      .then((res) => {
        if (res.status == 200) {
          setactivityTracker(res.data.activity);
          return;
        }
      })
      .catch((err) => {
        console.log("err", JSON.stringify(err, null, 2));
      });
  };

  /* Tab show */

  const [progressTabShow, setProgressTabShow] = useState({
    tabShown: "Client Progress",
  });
  function setTabShown(e) {
    $(".profileInfo li").removeClass("active");
    $(e.target).addClass("active");
    const tabShown = $(e.target).html();
    setProgressTabShow((prevState) => ({ ...prevState, tabShown: tabShown }));
  }

  /*  Progress Analytical  */

  const [weight, setWeight] = useState([]);
  const [neck, setNeck] = useState([]);
  const [leftArm, setLeftArm] = useState([]);
  const [xaxis, setXaxis] = useState([]);
  const [rightArm, setRightArm] = useState([]);
  const [leftforeArm, setLeftforeArm] = useState([]);
  const [rightForeArm, setRightForeArm] = useState([]);
  const [shoulder, setShoulder] = useState([]);
  const [lowerAbdomen, setLowerAbdomen] = useState([]);
  const [upperAbdomen, setUpperAbdomen] = useState([]);
  const [hip, setHip] = useState([]);
  const [chest, setChest] = useState([]);
  const [leftThigh, setLeftThigh] = useState([]);
  const [rightThigh, setRightThigh] = useState([]);
  const [leftCalf, setLeftCalf] = useState([]);
  const [rightCalf, setRightCalf] = useState([]);

  const handleFromDate = () => {
    var date = new Date();
    date.setDate(date.getDate() - 7);
    var finalDate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    var dateF;
    var month;

    if (date.getDate() > 9) {
      dateF = date.getDate();
    } else {
      dateF = "0" + date.getDate();
    }
    if (date.getMonth() + 1 > 9) {
      month = date.getMonth() + 1;
    } else {
      month = "0" + (date.getMonth() + 1);
    }
    var year = date.getFullYear();
    var finalDate = year + "-" + month + "-" + dateF;

    setFromDate(finalDate);
  };
  useEffect(() => {
    // handleFromDate();

    axios
      .get(
        `${ATLAS_URI}/getAnalyticalBodyMeasurements?leadId=${props.lead_id}&fromDate=2023-01-01&toDate=2023-01-15`,
        configToken
      )
      .then(
        (res) => (
          setWeight(res.data.map((c) => parseInt(c.weight)).slice(0, 8)),
          setNeck(res.data.map((c) => c.neck).slice(0, 8)),
          setLeftArm(res.data.map((c) => parseInt(c.left_arm)).slice(0, 8)),
          setXaxis(res.data.map((c) => c.progress_datetime.slice(0, 10))),
          setRightArm(res.data.map((c) => c.right_arm).slice(0, 8)),
          setLeftforeArm(res.data.map((c) => c.left_forearm).slice(0, 8)),
          setRightForeArm(res.data.map((c) => c.right_forearm).slice(0, 8)),
          setShoulder(res.data.map((c) => c.shoulder).slice(0, 8)),
          setLowerAbdomen(res.data.map((c) => c.lower_abdomen).slice(0, 8)),
          setUpperAbdomen(res.data.map((c) => c.upper_abdomen).slice(0, 8)),
          setHip(res.data.map((c) => c.hip).slice(0, 8)),
          setChest(res.data.map((c) => c.chest).slice(0, 8)),
          setLeftThigh(res.data.map((c) => c.left_thigh).slice(0, 8)),
          setRightThigh(res.data.map((c) => c.right_thigh).slice(0, 8)),
          setLeftCalf(res.data.map((c) => c.left_calf).slice(0, 8)),
          setRightCalf(res.data.map((c) => c.right_calf).slice(0, 8)),
          console.log("res checking in analytical", res)
        )
      )
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getActivityTracker();
  }, [fromDate, toDate]);
  // console.log(activityTracker,'activityTracker...');
  return (
    <section className="profileInfo">
      <nav className="tabNavigation">
        <ul>
          <li className="active" onClick={setTabShown}>
            Client Progress
          </li>
          <li onClick={setTabShown}>Progress View</li>
          <li onClick={setTabShown}>Progress Images</li>
          {/* <li onClick={setTabShown}>Progress Analysis</li> */}
        </ul>
      </nav>
      <div className="profileTab">
        {progressTabShow.tabShown === "Client Progress" && (
          <div>
            <BoxHeader title={`Client Progress`} />

            <div className="box-body bozero ">
              <div className="row m-3">
                <div className="col-md-3">
                  <div className="form-group">
                    <h5>Actively Started</h5>
                    <table
                      className="table table-bordered"
                      style={{ width: "250px" }}
                    >
                      <tbody>
                        <tr className="table table-primary">
                          <th>Weight </th>
                          <td>
                            {activityTracker.started &&
                              activityTracker?.started?.weight}
                          </td>
                        </tr>
                        <tr className="table table-info">
                          <th>BMI </th>
                          <td>
                            {activityTracker.started &&
                              activityTracker?.started?.bmi}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <h5>Progress Currently</h5>
                    <table class="table table-striped">
                      <tbody>
                        <tr className="table table-primary">
                          <th>Weight </th>
                          <td>
                            {activityTracker.current &&
                              activityTracker?.current?.weight}
                          </td>
                        </tr>
                        <tr className="table table-info">
                          <th>BMI </th>
                          <td>
                            {activityTracker.current &&
                              activityTracker?.current?.bmi}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <h5>Difference</h5>
                    <table class="table table-striped">
                      <tbody>
                        <tr className="table table-success">
                          <th>Weight </th>
                          <td>
                            {activityTracker.target?.weight &&
                              activityTracker.current?.weight &&
                              (
                                activityTracker?.target?.weight -
                                activityTracker?.current?.weight
                              ).toFixed(2)}
                          </td>
                        </tr>
                        <tr className="table table-secondary">
                          <th>BMI </th>
                          <td>
                            {activityTracker.target?.bmi &&
                              activityTracker.current?.bmi &&
                              (
                                activityTracker?.target?.bmi -
                                activityTracker?.current?.bmi
                              ).toFixed(1)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <h5>Target</h5>

                    <table
                      class="table table-bordered "
                      style={{ width: "250px" }}
                    >
                      <tbody>
                        <tr className="table table-success">
                          <th>Weight </th>
                          <td>
                            {activityTracker.target &&
                              activityTracker?.target?.weight}
                          </td>
                        </tr>
                        <tr className="table table-secondary">
                          <th>BMI </th>
                          <td>
                            {activityTracker.target &&
                              activityTracker?.target?.bmi}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              &nbsp;
              <div className="row">
                <div className="col-md-2">
                  <strong>From Date</strong>
                  <br />
                  <input
                    type="date"
                    value={fromDate}
                    onChange={handleChangeFromDate}
                    onKeyDown={(e) =>
                      new Date().toISOString().split("T")[0] &&
                      e.preventDefault()
                    }
                  />
                </div>
                <div className="col-md-2">
                  <strong>To Date</strong>
                  <br />
                  <input
                    type="date"
                    value={toDate}
                    onChange={handleChangeToDate}
                    min={new Date().toISOString().split("T")[0]}
                    onKeyDown={(e) =>
                      new Date().toISOString().split("T")[0] &&
                      e.preventDefault()
                    }
                  />
                </div>
              </div>
              <div className="row m-2">
                <div className="col-md-12">
                  <div className="form-group">
                    <table className="table table-bordered">
                      <tbody
                        style={{
                          borderRight: "1px solid lightgrey",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <tr className="table-info">
                          <th>Date</th>
                          <th>Steps</th>
                          <th>Consumed Calories</th>
                          <th>Calories Burnt</th>
                          <th>Water Intake</th>
                          <th>Sleep(in hours)</th>
                        </tr>
                      </tbody>
                      <tbody>
                        {activityTracker?.activity_tracker?.length &&
                          activityTracker?.activity_tracker?.map((tracker) => (
                            <tr class="table-warning">
                              <td>
                                {tracker.activityDate !== null
                                  ? tracker.activityDate
                                  : "-"}
                              </td>
                              <td>
                                {tracker &&
                                tracker?.Steps &&
                                tracker?.Steps?.quantity
                                  ? tracker?.Steps?.quantity
                                  : "-"}
                              </td>
                              <td>
                                {tracker &&
                                tracker?.caloriesConsumed &&
                                tracker?.caloriesConsumed?.quantity
                                  ? parseFloat(
                                      tracker?.caloriesConsumed?.quantity
                                    ).toFixed(2)
                                  : "-"}
                              </td>
                              <td>
                                {tracker?.caloriesBurned
                                  ? tracker?.caloriesBurned
                                  : "-"}
                              </td>
                              <td>
                                {tracker &&
                                tracker?.Water &&
                                tracker?.Water?.quantity
                                  ? tracker?.Water?.quantity
                                  : "-"}
                              </td>
                              <td>
                                {tracker && tracker?.Sleep?.duration
                                  ? secondsToHms(tracker?.Sleep?.duration)
                                  : "-"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {progressTabShow.tabShown === "Progress View" && (
          <ClientProgressView leadId={props.lead_id} />
        )}
        {progressTabShow.tabShown === "Progress Images" && (
          <ClientProgressImages leadId={props.lead_id} />
        )}
        {/* {progressTabShow.tabShown==='Progress Analysis'&&<ClientProgressAnalysis 
              weight={weight} neck={neck} leftArm={leftArm} xaxis={xaxis}
              rightArm={rightArm}
              leftforeArm={leftforeArm}
              rightForeArm={rightForeArm}
              shoulder={shoulder}
              lowerAbdomen={lowerAbdomen}
              upperAbdomen={upperAbdomen}
              hip={hip}
              chest={chest}
              leftThigh={leftThigh}
              rightThigh={rightThigh}
              leftCalf={leftCalf}
              rightCalf={rightCalf}
              />}   */}
        {/* // :progressTabShow.tabShown==='Progress Images'&&<ClientProgressImages/>} */}
      </div>
    </section>
  );
}

export default ClientNotes;
