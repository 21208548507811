// LeadContext.js
import React from 'react';
import { useState, useEffect } from 'react'

// Create a new context
const LeadContext = React.createContext();

// Create the provider component
const LeadContextProvider = (props) => {

  
  let providerData = null
  
  // this local storge var is set in lead details page
  let useCached = false
  /*
  if (localStorage.getItem('useCachedData')) {
    useCached = true
    // localStorage.removeItem('useCachedData')
  }
  */
 
  const data = {
    columns: props.columns,
    leadList: props.leadList,
    open: props.open,
    page: props.page,
    totalRecords: props.totalRecords,
  }
  providerData = data

  return (
    <LeadContext.Provider value={providerData}>
      {props.children}
    </LeadContext.Provider>
  );
};


export default LeadContextProvider;
export { LeadContext }