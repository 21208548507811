// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `   .floatClass{
    float: right;
    margin-right: 20px;
    /* min-width: 100px; */
    
   }


   #alignment{
    width: 300px;
    position: relative;
    right: 90px;
   }
   .leadstatus{
      float: right;
    margin-right: 30px;
    position: sticky;
    bottom: 400px;
    /* align-self: flex-start; */
    left: 340px;
    position: -webkit-sticky;
    
   }

   
input.input-error,
select.input-error
{
      
    background-color: #fce4e4;
    border: 0.1px solid #ff01416a;
    outline: none;
}

label.checkbox-error,
.gender-error{
    color:red
}

p.error-message{
    color: red;
    display: flex;
    justify-content: center;
}

   `, "",{"version":3,"sources":["webpack://./src/Pages/Leads/LeadDetails.css"],"names":[],"mappings":"GAAG;IACC,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;;GAEvB;;;GAGA;IACC,YAAY;IACZ,kBAAkB;IAClB,WAAW;GACZ;GACA;MACG,YAAY;IACd,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,4BAA4B;IAC5B,WAAW;IACX,wBAAwB;;GAEzB;;;AAGH;;;;IAII,yBAAyB;IACzB,6BAA6B;IAC7B,aAAa;AACjB;;AAEA;;IAEI;AACJ;;AAEA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":["   .floatClass{\n    float: right;\n    margin-right: 20px;\n    /* min-width: 100px; */\n    \n   }\n\n\n   #alignment{\n    width: 300px;\n    position: relative;\n    right: 90px;\n   }\n   .leadstatus{\n      float: right;\n    margin-right: 30px;\n    position: sticky;\n    bottom: 400px;\n    /* align-self: flex-start; */\n    left: 340px;\n    position: -webkit-sticky;\n    \n   }\n\n   \ninput.input-error,\nselect.input-error\n{\n      \n    background-color: #fce4e4;\n    border: 0.1px solid #ff01416a;\n    outline: none;\n}\n\nlabel.checkbox-error,\n.gender-error{\n    color:red\n}\n\np.error-message{\n    color: red;\n    display: flex;\n    justify-content: center;\n}\n\n   "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
