// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-con {
   display: flex;
   gap:10px
}

.img-con img {
    width: 50px;
    height: 50px;
    border-radius:6px;
}

.select-date {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/clientPlans/MealPlate.css"],"names":[],"mappings":"AAAA;GACG,aAAa;GACb;AACH;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":[".img-con {\n   display: flex;\n   gap:10px\n}\n\n.img-con img {\n    width: 50px;\n    height: 50px;\n    border-radius:6px;\n}\n\n.select-date {\n    display: flex;\n    justify-content: flex-end;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
