import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";

import LoopIcon from "@mui/icons-material/Loop";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// import moment from 'moment-timezone';
import { capitalizeFirstLetter } from "../../Utlis/general";

import * as formik from "formik";
import * as yup from "yup";

import Modal from "react-bootstrap/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "./Divider.css";

import ProgramRender from "./ProgramRender";
import ProgramList from "./ProgramList";
import SessionList from "./SessionList";
import AttendeeList from "./AttendeeList";

import { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";

//======================
import stateContext from "../../context/StateContext";
import { ATLAS_URI } from "../../Constants";
import { toast } from "react-toastify";
import { timeZones } from "../../Utlis/timeZone";

import myImage from "./logo.png";
import ReactPlayer from "react-player";
import moment from "moment-timezone";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ViewSession from "./ViewSession";
import SessionHistory from "./SessionHistory";
import ParticipantList from "./ParticipantList";
import AskHtml from "./AskHtml";

//======================
const popupControlClasses = "my-3";

function updateArray(arr, char) {
  if (arr.includes(char)) {
    // Character exists in the array, so remove it
    return arr.filter((item) => item !== char);
  } else {
    // Character doesn't exist in the array, so add it
    return [...arr, char];
  }
}

export default function Studio(props) {
  const contextState = useContext(stateContext);
  const { configToken } = contextState.state;

  useEffect(() => {
    function getPrograms() {
      let URL = `${ATLAS_URI}/getProducts`;
      axios.get(`${URL}`, configToken).then((res) => {
        setPrograms(
          res.data
            .filter(
              (e) =>
                String(e.type).toLowerCase() ===
                  "Live Coaching".toLowerCase() && e.active_flg
            )
            .map((e) => ({ id: e.id, name: e.name }))
        );
      });
    }
    function getConstants() {
      let URL = `${ATLAS_URI}/admin-programSessionConstants`;
      axios.get(`${URL}`, configToken).then((res) => {
        setLevels(res.data.body.VMAX_WORKOUT_LEVEL);
        setFormats(res.data.body.VMAX_WORKOUT_FORMAT);
      });
    }
    function getTrainers() {
      let URL = `${ATLAS_URI}/admin-programSessionCoaches`;
      axios.get(`${URL}`, configToken).then((res) => {
        setTrainers(res.data.body);
      });
    }

    getConstants();
    getPrograms();
    getTrainers();
  }, [configToken]);

  const { Formik } = formik;

  const [activeTab, setActiveTab] = React.useState("1");
  const [programs, setPrograms] = React.useState([]);
  const [currentProgramId, setCurrentProgramId] = React.useState(0);
  const [currentSessionId, setCurrentSessionId] = React.useState(0);
  const [sessions, setSessions] = React.useState([]);
  const [attendees, setAttendees] = React.useState([]);

  const [modalShow, setModalShow] = React.useState(false);

  //for view
  const [modalShowView, setModalShowView] = React.useState(false);
  const [viewSessionForm, setViewSessionForm] = React.useState({});

  //for repeating session history
  const [modalShowHistory, setModalShowHistory] = React.useState(false);
  const [repeatingSessionsHistory, setRepeatingSessionsHistory] = useState([]);
  // for repeating session meeting participant
  const [modalShowParticipants, setModalShowParticipants] =
    React.useState(false);
  const [meetingParticipants, setMeetingParticipants] = useState([]);

  const [levels, setLevels] = React.useState([]);
  const [formats, setFormats] = React.useState([]);
  const [trainers, setTrainers] = React.useState([]);

  const [crudType, setCrudType] = React.useState("create");

  // const [repeatBy, setRepeatBy] = useState('day');
  const [repeatDaysOfWeek, setRepeatDaysOfWeek] = useState([]);

  const [sessionImage, setSessionImage] = useState(myImage);

  const handleImageFileChange = (event, cb) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSessionImage(reader.result);
        cb("session_image", file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  // Player
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const hidePlayer = () => {
    setShowPlayerModal(false);
  };

  const coachSelected = (id, cb) => {
    if (id) {
      const trainer = findTrainerById(id);
      if (trainer) {
        cb("coach_name", trainer.firstName + " " + trainer.lastName);
        cb("coach_details", trainer.about);
      }
    }
  };

  const repeatTypeChanged = (id, cb) => {
    switch (id) {
      case "single":
        cb("is_recurring", false);
        // customEvent.target.value = false
        // cb(customEvent)

        break;
      case "repeating":
        cb("is_recurring", true);
        // customEvent.target.value = true
        // cb(customEvent)
        break;
      default:
        break;
    }
  };

  const repeatByMonthOnChanged = (id, cb) => {
    // setRepeatByMonthOn(id)
    const customEvent = {
      target: {
        name: "repeat_by_month_day_type_criteria",
        value: id,
      },
    };
    cb(customEvent);
  };

  function findTrainerById(input) {
    return trainers.find((obj) => obj.id === parseInt(input));
  }

  const minDate = new Date().toISOString().split("T")[0];

  const defaultForm = {
    start_url: "",

    session_name: "",
    program_id: 0,
    format_id: 0,
    level_id: 0,
    session_image: "",
    session_details: "",
    start_date: minDate,
    start_time: "",
    timezone: "",
    duration: "",
    duration_unit: "",
    trainer_id: 0,
    coach_name: "",
    coach_details: "",
    moderator_name: "",
    equipments_required: "",
    benefits: "",
    routine: "",
    who_should_attend: "",
    who_should_avoid: "",

    // Repeating session fields
    is_recurring: false,
    repeat_by: "", // single value out of - "day", "week", "month"
    interval_value: 1, // ex. 2
    repeat_on_weekdays: [], // ex. "sunday", "monday" ..
    repeat_by_month_day_type_criteria: "", // single value out of - "On Monthly Fixed Day", "On Monthly Dynamic Day"
    repeat_by_month_static_day: "", // number (1-31)
    repeat_by_month_dynamic_day_ordinal: "", // single value out of - "first", "second", "third"..
    repeat_by_month_dynamic_day_weekday: "", // single value out of - "sunday", "monday" ..
    recurrence_ends_at: "",
  };
  // const fmik = useFormikContext(); // Access the Formik context
  // const { values, touched, errors, setFieldValue } = fmik; // Destructure necessary values

  const [sessionForm, setSessionForm] = React.useState(defaultForm);

  const formRef = useRef(null);
  const sessImgRef = useRef(null);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setCurrentSessionId(0);
    setCurrentProgramId(0);
  };

  const addDaysOfWeek = (chosenDay, cb) => {
    setRepeatDaysOfWeek((oldDays) => {
      // useState needed to compare older value
      const newArr = updateArray(oldDays, chosenDay);
      // cb('repeat_on_weekdays', newArr)
      const customEvent = {
        target: {
          name: "repeat_on_weekdays",
          value: newArr,
        },
      };
      cb(customEvent);
      return newArr || [];
    });
  };

  const schema = yup.object().shape({
    session_name: yup
      .string()
      .required("Please enter the name of session")
      .min(3, "Session name must be atleast 3 characters")
      .max(100, "Session name cannot exceed 100 characters"),

    program_id: yup
      .string() // auto selected based on program selection
      .required("Program is not selected"),

    session_details: yup
      .string()
      .required("Session details should be provided")
      .min(3, "Session details must be atleast 3 characters")
      .max(500, "Session details cannot exceed 500 characters"),

    format_id: yup
      .number("Please provide the session format")
      .required("Please provide the session format")
      .min(1, "Please provide the session format"),

    level_id: yup
      .number("Please provide the level")
      .required("Please provide the level")
      .min(1, "Please provide the level"),

    start_date: yup
      .string()
      .required("Please provide the start date")
      .test(
        "is-valid-date-acc-weekday",
        "Start date must be in the allowed week days",
        function (value) {
          let isValid = true;
          if (this.parent.is_recurring && this.parent.repeat_by === "week") {
            const dateString = this.parent.start_date;
            const mtDate = moment(dateString);
            if (mtDate.isValid()) {
              const dayOnStartDay = mtDate.format("dddd").toLowerCase();
              const repeat_on_weekdays = this.parent.repeat_on_weekdays;
              if (!repeat_on_weekdays.includes(dayOnStartDay)) {
                isValid = false;
              }
            }
          }

          return isValid;
        }
      )
      .test(
        "is-valid-time-for-monthly-static",
        "Start date should match the monthly repeating date",
        function (value) {
          let isValid = true;
          if (
            this.parent.is_recurring &&
            this.parent.repeat_by === "month" &&
            this.parent.repeat_by_month_day_type_criteria === "static"
          ) {
            if (value && value !== "") {
              const dateArr = value.split("-");
              const datePart = dateArr[2];
              if (
                parseInt(datePart) !==
                parseInt(this.parent.repeat_by_month_static_day)
              ) {
                isValid = false;
              }
            }
          }

          return isValid;
        }
      ),

    start_time: yup
      .string()
      .required("Please provide the start time")
      .test("is-valid-time", "Invalid start time", function (value) {
        let isValid = true;

        const dateString = this.parent.start_date;
        const timeString = value;
        const selectedTimezone = this.parent.timezone;

        if (dateString && timeString && selectedTimezone) {
          const dateTimeString = `${dateString} ${timeString}`;
          const momentObj = moment.tz(
            dateTimeString,
            "YYYY-MM-DD HH:mm",
            selectedTimezone
          );
          const m1 = momentObj;
          const m2 = moment();

          if (m1.unix() < m2.unix()) {
            isValid = false;
          }
        }

        //for update it can be ignored
        if (crudType === "update") {
          return true;
        }

        return isValid;
      }),

    timezone: yup.string().required("Please select the timezone"),

    duration: yup
      .string()
      .required("Please provide the session duration")
      .max(60, "Session duration value cannot exceed 60")
      .min(1, "Session duration should be atleast 1"),

    duration_unit: yup.string().required("Please select the unit"),

    trainer_id: yup
      .number()
      .required("Please select the coach from the list provided")
      .min(1, "Please select the coach from the list provided"),

    coach_name: yup
      .string()
      .required("Please provide the coach name")
      .max(50, "Coach name cannot exceed 50 characters")
      .min(2, "Coach name should be atleast 2 characters"),

    coach_details: yup
      .string()
      .required("Please provide details about the coach")
      .max(500, "Coach details cannot exceed 500 characters")
      .min(3, "Coach details should be atleast 3 characters"),

    moderator_name: yup
      .string()
      .required("Please provide the moderator name")
      .max(50, "Moderator name cannot exceed 50 characters")
      .min(3, "Moderator name should be atleast 3 characters"),

    equipments_required: yup
      .string()
      .required("Please provide details about equipments")
      .max(500, "Equipments details cannot exceed 500 characters")
      .min(3, "Equipments details should be atleast 3 characters"),

    benefits: yup
      .string()
      .required("Please provide some session benefits")
      .max(500, "Session benefits cannot exceed 500 characters")
      .min(3, "Session benefits should be atleast 3 characters"),

    routine: yup
      .string()
      .required("Please provide routine information")
      .max(500, "Routine information cannot exceed 500 characters")
      .min(3, "Routine information should be atleast 3 characters"),

    who_should_attend: yup
      .string()
      .required("Who should join recommendation info is required")
      .max(
        1000,
        "Who should join recommendation info cannot exceed 1000 characters"
      )
      .min(
        3,
        "Who should join recommendation info should be atleast 3 characters"
      ),

    who_should_avoid: yup
      .string()
      .required("Recommended info is required")
      .max(
        1000,
        "Who should avoid recommendation info cannot exceed 1000 characters"
      )
      .min(
        3,
        "Who should avoid recommendation info should be atleast 3 characters"
      ),

    // ============================================ REPEATING ============================================ //
    repeat_by: yup.string().when("is_recurring", {
      is: (value) => value === true,
      then: yup.string().required("Please provide the repeat interval unit"), // Rule to apply when the condition is met
      otherwise: yup.string().notRequired().nullable(), // Rule to apply when the condition is not met
    }),

    interval_value: yup
      .number()
      .when("is_recurring", {
        is: (value) => value,
        then: yup
          .number()
          .required("Please provide the repeat interval amount")
          .min(1, "Minimum allowed value is 1"), // Rule to apply when the condition is met
        otherwise: yup.number().notRequired().nullable(), // Rule to apply when the condition is not met
      })
      .test(
        "is-valid-interval-acc-repeat-by",
        "Interval should be 1 when repetition is by week",
        function (value) {
          let isValid = true;
          if (this.parent.is_recurring) {
            const repeatingBy = this.parent.repeat_by;
            if (repeatingBy === "week") {
              if (this.parent.interval_value > 1) {
                isValid = false;
              }
            }
          }

          return isValid;
        }
      ),

    repeat_on_weekdays: yup.array().when(["is_recurring", "repeat_by"], {
      is: (is_recurring, repeat_by) => {
        return is_recurring === true && repeat_by === "week";
      },
      then: yup
        .array()
        .required("Please provide the week days on which to repeat")
        .min(1, "Atleast one weekday must be provided"), // Rule to apply when the condition is met
      otherwise: yup.array().notRequired().nullable(), // Rule to apply when the condition is not met
    }),

    repeat_by_month_day_type_criteria: yup
      .string()
      .when(["is_recurring", "repeat_by"], {
        is: (is_recurring, repeat_by) => is_recurring && repeat_by === "month",
        then: yup
          .string()
          .required("Please provide the repetition by month criteria"), // Rule to apply when the condition is met
        otherwise: yup.string().notRequired(), // Rule to apply when the condition is not met
      }),

    repeat_by_month_static_day: yup
      .number()
      .when(
        ["is_recurring", "repeat_by", "repeat_by_month_day_type_criteria"],
        {
          is: (is_recurring, repeat_by, repeat_by_month_day_type_criteria) =>
            is_recurring &&
            repeat_by === "month" &&
            repeat_by_month_day_type_criteria === "static",
          then: yup
            .number()
            .required("Please provide the day of month on which to repeat"), // Rule to apply when the condition is met
          otherwise: yup.number().notRequired().nullable(), // Rule to apply when the condition is not met
        }
      ),

    repeat_by_month_dynamic_day_ordinal: yup
      .string()
      .when(
        ["is_recurring", "repeat_by", "repeat_by_month_day_type_criteria"],
        {
          is: (is_recurring, repeat_by, repeat_by_month_day_type_criteria) =>
            is_recurring &&
            repeat_by === "month" &&
            repeat_by_month_day_type_criteria === "dynamic",
          then: yup.string().required("Please provide the value"), // Rule to apply when the condition is met
          otherwise: yup.string().notRequired().nullable(), // Rule to apply when the condition is not met
        }
      ),

    repeat_by_month_dynamic_day_weekday: yup
      .string()
      .when(
        ["is_recurring", "repeat_by", "repeat_by_month_day_type_criteria"],
        {
          is: (is_recurring, repeat_by, repeat_by_month_day_type_criteria) =>
            is_recurring &&
            repeat_by === "month" &&
            repeat_by_month_day_type_criteria === "dynamic",
          then: yup.string().required("Please provide the week day"), // Rule to apply when the condition is met
          otherwise: yup.string().notRequired().nullable(), // Rule to apply when the condition is not met
        }
      ),

    recurrence_ends_at: yup
      .string()
      .when("is_recurring", {
        is: (value) => value,
        then: yup
          .string()
          .required(
            "Please provide the date when to stop repeating the session"
          ), // Rule to apply when the condition is met
        otherwise: yup.string().notRequired().nullable(), // Rule to apply when the condition is not met
      })
      .test(
        "is-valid-date-acc-start-date",
        "Date needs to be more than start date",
        function (value) {
          let isValid = true;
          if (this.parent.is_recurring) {
            const startDateString = this.parent.start_date;
            const mtStartDate = moment(startDateString);
            if (mtStartDate.isValid()) {
              const startRecEndString = value;
              const mtRecEndDate = moment(startRecEndString);

              if (mtRecEndDate.isValid()) {
                if (mtStartDate.unix() >= mtRecEndDate.unix()) {
                  isValid = false;
                }
              }
            }
          }

          return isValid;
        }
      ),

    // =================================================================================================== //
  });

  const createSchema = schema.clone().shape({
    session_image: yup.string().required("Please provide an image for session"),
  });

  const updateSchema = schema.clone().shape({});

  // const createSchema = yup.mixed();
  // const updateSchema = yup.mixed();

  const handleRepeatAmountChange = (e, handleChange) => {
    const { value } = e.target;
    if (value < 1) {
      toast.dismiss();
      toast.warning("Amount must be atleast 1", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      e.preventDefault();
      return;
    }
    // Update the value in the form state
    handleChange(e);
  };

  const [readOnly, setReadOnly] = useState(false);
  const handleRepeatByChange = (e, handleChange) => {
    const { value } = e.target;
    if (value === "week") {
      const customEvent = {
        target: {
          name: "interval_value",
          value: 1,
        },
      };
      handleChange(customEvent);
      setReadOnly(true);
    } else setReadOnly(false);
    // Update the value in the form state
    handleChange(e);
  };

  const getWeekDayWdgetClass = (day) => {
    if (repeatDaysOfWeek) {
      return repeatDaysOfWeek.includes(day) ? "primary" : "secondary";
    } else {
      return "secondary";
    }
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={activeTab} centered>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
              centered
            >
              <Tab label="UPCOMING" value="1" />
              <Tab label="COMPLETED" value="2" />
              <Tab label="MISSED" value="3" />
            </TabList>
          </Box>

          {/* UPCOMING SESSIONS */}
          <TabPanel value="1">
            <ProgramRender
              showAddBtn={true}
              showModal={() => {
                setCrudType("create");
                setModalShow(true);
              }}
              currentProgramId={currentProgramId}
              currentSessionId={currentSessionId}
            >
              {/* PROGRAM LIST */}
              <ProgramList
                list={programs}
                handleClick={programSelected}
                currentProgramId={currentProgramId}
              />

              {/* SESSION LIST */}
              {currentProgramId ? (
                <SessionList
                  list={sessions}
                  handleView={sessionView}
                  handleEdit={sessionEdit}
                  handleSelection={fetchSessionAttendees}
                  handleCancel={sessionCancel}
                  handleStartMeeting={sessionStartMeeting}
                  handleHistory={(id) =>
                    fetchRepeatingSessionHistory(id, "upcoming")
                  }
                  currentSessionId={currentSessionId}
                  action="edit"
                />
              ) : (
                ""
              )}

              {/* ATTENDEE LIST */}
              {currentSessionId ? <AttendeeList list={attendees} /> : ""}
            </ProgramRender>
          </TabPanel>

          {/* COMPLETED SESSIONS */}
          <TabPanel value="2">
            <ProgramRender
              currentProgramId={currentProgramId}
              currentSessionId={currentSessionId}
            >
              <ProgramList
                list={programs}
                handleClick={programSelected}
                currentProgramId={currentProgramId}
              />

              {/* SESSION LIST */}
              {currentProgramId ? (
                <SessionList
                  list={sessions}
                  handleView={sessionView}
                  handlePlay={sessionPlay}
                  handleSelection={fetchSessionAttendees}
                  handleCancel={sessionCancel}
                  handleStartMeeting={sessionStartMeeting}
                  handleHistory={(id) =>
                    fetchRepeatingSessionHistory(id, "completed")
                  }
                  currentSessionId={currentSessionId}
                  action="play"
                  showAddBtn={false}
                />
              ) : (
                ""
              )}
              {currentSessionId ? <AttendeeList list={attendees} /> : ""}
            </ProgramRender>
          </TabPanel>

          {/* MISSED SESSIONS */}
          <TabPanel value="3">
            <ProgramRender
              currentProgramId={currentProgramId}
              currentSessionId={currentSessionId}
            >
              <ProgramList
                list={programs}
                handleClick={programSelected}
                currentProgramId={currentProgramId}
              />

              {/* SESSION LIST */}
              {currentProgramId ? (
                <SessionList
                  list={sessions}
                  handleView={sessionView}
                  handlePlay={sessionPlay}
                  handleSelection={fetchSessionAttendees}
                  handleCancel={sessionCancel}
                  handleStartMeeting={sessionStartMeeting}
                  handleHistory={(id) =>
                    fetchRepeatingSessionHistory(id, "missed")
                  }
                  currentSessionId={currentSessionId}
                  action=""
                  showAddBtn={false}
                />
              ) : (
                ""
              )}
              {currentSessionId ? <AttendeeList list={attendees} /> : ""}
            </ProgramRender>
          </TabPanel>
        </TabContext>
      </Box>

      {/* ==== Modal for Edit ==== */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-add-program-session-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-add-program-session-vcenter">
            {crudType === "create" ? "Add Session" : "Update Session"}
          </Modal.Title>
          <p style={{ color: "black" }}>
            <span
              onClick={() => setModalShow(false)}
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <HighlightOffIcon
                style={{ fontSize: "36px", color: "grey", cursor: "pointer" }}
              />
            </span>
          </p>
        </Modal.Header>

        <Modal.Body>
          <Formik
            validationSchema={
              crudType === "create" ? createSchema : updateSchema
            }
            onSubmit={formSubmit}
            initialValues={sessionForm}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form noValidate onSubmit={handleSubmit} ref={formRef}>
                  <Row className={popupControlClasses}>
                    <Col md={6}>
                      <Form.Group controlId="sessionName">
                        <Form.Label>Session Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter the name of session"
                          name="session_name"
                          value={values.session_name}
                          onChange={handleChange}
                          isValid={touched.session_name && !errors.session_name}
                          isInvalid={!!errors.session_name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.session_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="sessionProgram">
                        <Form.Label>Program</Form.Label>
                        <Form.Control
                          as="select"
                          disabled
                          name="program_id"
                          value={values.program_id}
                          onChange={handleChange}
                          isValid={touched.program_id && !errors.program_id}
                          isInvalid={!!errors.program_id}
                        >
                          {programs.map((e) => {
                            return (
                              <option value={e.id} key={e.id}>
                                {e.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.program_id}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className={popupControlClasses}>
                    <Col md={6}>
                      <Row>
                        <Col md={12}>
                          <Row>
                            <Col md={6}>
                              <Form.Group controlId="sessionImage">
                                <Form.Label>Session Image</Form.Label>
                                {/* HTML file element cannot be edited */}
                                {/* using value={values.session_image} give error */}
                                <Form.Control
                                  ref={sessImgRef}
                                  type="file"
                                  name="session_image"
                                  onChange={(e) => {
                                    handleImageFileChange(e, setFieldValue);
                                  }}
                                  isValid={
                                    touched.session_image &&
                                    !errors.session_image
                                  }
                                  isInvalid={!!errors.session_image}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.session_image}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Image
                                src={sessionImage}
                                rounded
                                width={150}
                                style={{ background: "aliceblue" }}
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col md={12}>
                          <Row className={popupControlClasses}>
                            <Col md={6}>
                              <Form.Group controlId="format">
                                <Form.Label>Format</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="format_id"
                                  value={values.format_id}
                                  onChange={handleChange}
                                  isValid={
                                    touched.format_id && !errors.format_id
                                  }
                                  isInvalid={!!errors.format_id}
                                >
                                  <option value="">Choose a format</option>
                                  {formats.map((e) => {
                                    return (
                                      <option value={e.id} key={e.id}>
                                        {e.name}
                                      </option>
                                    );
                                  })}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.format_id}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group controlId="level">
                                <Form.Label>Level</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="level_id"
                                  value={values.level_id}
                                  onChange={handleChange}
                                  isValid={touched.level_id && !errors.level_id}
                                  isInvalid={!!errors.level_id}
                                >
                                  <option value="">Choose a level</option>
                                  {levels.map((e) => {
                                    return (
                                      <option value={e.id} key={e.id}>
                                        {e.name}
                                      </option>
                                    );
                                  })}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.level_id}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="sessionDetails">
                        <Form.Label>Session Details</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          name="session_details"
                          value={values.session_details}
                          onChange={handleChange}
                          isValid={
                            touched.session_details && !errors.session_details
                          }
                          isInvalid={!!errors.session_details}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.session_details}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* ONE-TIME OR REPEATING */}

                  <hr className="divider" />
                  <ButtonGroup>
                    <ToggleButton
                      className={
                        !values.is_recurring
                          ? "bg-primary text-white"
                          : "bg-white text-dark"
                      }
                      type="radio"
                      name="session_occurance"
                      value="single"
                      checked={!values.is_recurring}
                      onChange={(e) => {
                        e.preventDefault();
                        repeatTypeChanged(e.currentTarget.value, setFieldValue);
                      }}
                    >
                      <ArrowForwardIcon /> <b>Single Session</b>
                    </ToggleButton>
                    <ToggleButton
                      className={
                        values.is_recurring
                          ? "bg-primary text-white"
                          : "bg-white text-dark"
                      }
                      type="radio"
                      name="session_occurance"
                      value="repeating"
                      checked={values.is_recurring}
                      onChange={(e) => {
                        e.preventDefault();
                        repeatTypeChanged(e.currentTarget.value, setFieldValue);
                      }}
                    >
                      <LoopIcon /> <b>Repeating Session</b>
                    </ToggleButton>
                  </ButtonGroup>

                  <Row className={popupControlClasses}>
                    <Col md={6}>
                      <Row>
                        <Col md={6}>
                          <Form.Group controlId="startDate">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                              type="date"
                              name="start_date"
                              value={values.start_date}
                              onChange={handleChange}
                              isValid={touched.start_date && !errors.start_date}
                              isInvalid={!!errors.start_date}
                              min={minDate}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.start_date}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="startTime">
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control
                              type="time"
                              name="start_time"
                              value={values.start_time}
                              onChange={handleChange}
                              isValid={touched.start_time && !errors.start_time}
                              isInvalid={!!errors.start_time}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.start_time}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={6}>
                      <Row>
                        <Col md={4}>
                          <Form.Group controlId="startTime">
                            <Form.Label>Time Zone</Form.Label>
                            {/* value={timeZone} onChange={handleTimeZoneChange} */}
                            <Form.Control
                              as="select"
                              name="timezone"
                              value={values.timezone}
                              onChange={handleChange}
                              isValid={touched.timezone && !errors.timezone}
                              isInvalid={!!errors.timezone}
                            >
                              <option value="">Select</option>
                              {timeZones.map((e) => {
                                return (
                                  <option value={e} key={e}>
                                    {e}
                                  </option>
                                );
                              })}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.timezone}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group controlId="duration">
                            <Form.Label>Duration</Form.Label>
                            <Form.Control
                              type="number"
                              min="1"
                              max="100"
                              name="duration"
                              value={values.duration}
                              onChange={handleChange}
                              isValid={touched.duration && !errors.duration}
                              isInvalid={!!errors.duration}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.duration}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group controlId="durationUnit">
                            <Form.Label>Duration Unit</Form.Label>
                            <Form.Control
                              as="select"
                              name="duration_unit"
                              value={values.duration_unit}
                              onChange={handleChange}
                              isValid={
                                touched.duration_unit && !errors.duration_unit
                              }
                              isInvalid={!!errors.duration_unit}
                            >
                              <option value="">Choose a unit</option>
                              <option value="minute">Minutes</option>
                              <option value="hour">Hours</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.duration_unit}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        {values.is_recurring && (
                          <div>
                            <Form.Text muted className="pull-right">
                              In case of repeating sessions the Duration and
                              unit is per session
                            </Form.Text>
                          </div>
                        )}
                      </Row>
                    </Col>
                  </Row>

                  {/* recurring */}
                  <Row>
                    {/* Repeat by - day, week, month */}
                    {values.is_recurring && (
                      <Row>
                        {/* =====================================================================
                                            <p>Every: {values.interval_value}</p>
                                            <p>Repeat By: {values.repeat_by}</p>
                                            <p>Repeat Week days: {values.repeat_on_weekdays}</p>
                                            <p>Repeat By Month on: {values.repeat_by_month_day_type_criteria}</p>
                                            <p>Repeat By Day of Month on: {values.repeat_by_month_static_day}</p>
                                            <p>Repeat On Nth of Weekday of Month: {values.repeat_by_month_dynamic_day_ordinal}</p>
                                            <p>Repeat On Weekday of Month: {values.repeat_by_month_dynamic_day_weekday}</p>
                                            
                                            ===================================================================== */}
                        {/* ----{values.interval_value} */}
                        <Col md={2}>
                          <Form.Group controlId="repeatAmount">
                            <Form.Label>Every</Form.Label>
                            <Form.Control
                              type="number"
                              readOnly={readOnly}
                              name="interval_value"
                              value={values.interval_value}
                              onChange={(e) =>
                                handleRepeatAmountChange(e, handleChange)
                              }
                              isValid={
                                touched.interval_value && !errors.interval_value
                              }
                              isInvalid={!!errors.interval_value}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.interval_value}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        {/* --repeat int--{values.repeat_by} */}
                        <Col md={2}>
                          <Form.Group controlId="repeatBy">
                            <Form.Label>Repeat By</Form.Label>
                            <Form.Control
                              as="select"
                              name="repeat_by"
                              value={values.repeat_by}
                              isValid={touched.repeat_by && !errors.repeat_by}
                              isInvalid={!!errors.repeat_by}
                              onChange={(e) =>
                                handleRepeatByChange(e, handleChange)
                              }
                            >
                              <option value="">Select</option>
                              {["day", "week", "month"].map((e) => {
                                return (
                                  <option value={e} key={e}>
                                    {capitalizeFirstLetter(e)}
                                  </option>
                                );
                              })}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.repeat_by}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        {/* For 'day' no need extra widgets */}

                        {/* CHOICE: WEEK */}
                        {values.repeat_by === "week" && (
                          <Col md={6} className="d-flex align-items-center">
                            <Form.Group controlId="repeatWeekDays">
                              <h5>
                                {[
                                  "sunday",
                                  "monday",
                                  "tuesday",
                                  "wednesday",
                                  "thursday",
                                  "friday",
                                  "saturday",
                                ].map((day, index) => (
                                  <Badge
                                    key={index}
                                    pill
                                    variant="secondary"
                                    className="mr-2 inline"
                                    bg={getWeekDayWdgetClass(day)}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) =>
                                      addDaysOfWeek(day, handleChange)
                                    }
                                  >
                                    {day.charAt(0).toUpperCase() + day.slice(1)}
                                  </Badge>
                                ))}
                              </h5>
                              <Form.Control.Feedback type="invalid">
                                {errors.repeat_on_weekdays}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        )}

                        {/* CHOICE : MONTH -I */}
                        {values.repeat_by === "month" && (
                          <Col md={3}>
                            <Form.Group>
                              <Form.Label></Form.Label>
                              <Form.Check
                                onChange={(e) =>
                                  repeatByMonthOnChanged("static", handleChange)
                                }
                                type="radio"
                                label="On Monthly Fixed Day"
                                name="repeat_by_month_day_type_criteria"
                                value="static"
                                id="repeatByMonthOn-static"
                              />
                              <Form.Check
                                onChange={(e) =>
                                  repeatByMonthOnChanged(
                                    "dynamic",
                                    handleChange
                                  )
                                }
                                type="radio"
                                label="On Monthly Dynamic Day"
                                name="repeat_by_month_day_type_criteria"
                                value="dynamic"
                                id="repeatByMonthOn-dynamic"
                              />
                              {/* ----{repeatByMonthOn} */}
                              <Form.Control.Feedback type="invalid">
                                {errors.repeat_by_month_day_type_criteria}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        )}

                        {/* CHOICE : MONTH - I - I (12th of month) */}
                        {values.repeat_by === "month" &&
                          values.repeat_by_month_day_type_criteria ===
                            "static" && (
                            <Col md={2}>
                              <Form.Group controlId="repeatOnDayOfMonth">
                                <Form.Label>On Day</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="repeat_by_month_static_day"
                                  value={values.repeat_by_month_static_day}
                                  isValid={
                                    touched.repeat_by_month_static_day &&
                                    !errors.repeat_by_month_static_day
                                  }
                                  isInvalid={
                                    !!errors.repeat_by_month_static_day
                                  }
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  {[...Array(31).keys()]
                                    .map((num) => num + 1)
                                    .map((e) => {
                                      return (
                                        <option value={e} key={e}>
                                          {e}
                                        </option>
                                      );
                                    })}
                                </Form.Control>

                                {/* ----{values.repeat_by_month_static_day} */}
                                <Form.Control.Feedback type="invalid">
                                  {errors.repeat_by_month_static_day}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          )}

                        {/* CHOICE : MONTH - I - II - I ( ['first']* - ['Sunday']) */}
                        {values.repeat_by === "month" &&
                          values.repeat_by_month_day_type_criteria ===
                            "dynamic" && (
                            <Col md={2}>
                              <Form.Group controlId="repeatOnNthDayOfMonthOridinal">
                                <Form.Label>On the</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="repeat_by_month_dynamic_day_ordinal"
                                  value={
                                    values.repeat_by_month_dynamic_day_ordinal
                                  }
                                  isValid={
                                    touched.repeat_by_month_dynamic_day_ordinal &&
                                    !errors.repeat_by_month_dynamic_day_ordinal
                                  }
                                  isInvalid={
                                    !!errors.repeat_by_month_dynamic_day_ordinal
                                  }
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  <option value="first">First</option>
                                  <option value="second">Second</option>
                                  <option value="third">Third</option>
                                  <option value="fourth">Fourth</option>
                                  <option value="last">Last</option>
                                </Form.Control>

                                {/* ----{values.repeat_by_month_dynamic_day_ordinal} */}
                                <Form.Control.Feedback type="invalid">
                                  {errors.repeat_by_month_dynamic_day_ordinal}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          )}

                        {/* CHOICE : MONTH - I - II - II ( ['first'] - ['Sunday']*) */}
                        {values.repeat_by === "month" &&
                          values.repeat_by_month_day_type_criteria ===
                            "dynamic" && (
                            <Col md={2}>
                              <Form.Group controlId="repeatOnNthDayOfMonthWeekday">
                                <Form.Label> Weekday </Form.Label>
                                <Form.Control
                                  as="select"
                                  name="repeat_by_month_dynamic_day_weekday"
                                  value={
                                    values.repeat_by_month_dynamic_day_weekday
                                  }
                                  isValid={
                                    touched.repeat_by_month_dynamic_day_weekday &&
                                    !errors.repeat_by_month_dynamic_day_weekday
                                  }
                                  isInvalid={
                                    !!errors.repeat_by_month_dynamic_day_weekday
                                  }
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  {[
                                    "sunday",
                                    "monday",
                                    "tuesday",
                                    "wednesday",
                                    "thursday",
                                    "friday",
                                    "saturday",
                                  ].map((e) => {
                                    return (
                                      <option value={e} key={e}>
                                        {capitalizeFirstLetter(e)}
                                      </option>
                                    );
                                  })}
                                </Form.Control>

                                {/* ----{values.repeat_by_month_dynamic_day_weekday} */}
                                <Form.Control.Feedback type="invalid">
                                  {errors.repeat_by_month_dynamic_day_weekday}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          )}

                        <Col md={4}>
                          <Form.Group controlId="startDate">
                            <Form.Label>Stop Repetition on</Form.Label>
                            <Form.Control
                              type="date"
                              name="recurrence_ends_at"
                              value={values.recurrence_ends_at}
                              onChange={handleChange}
                              isValid={
                                touched.recurrence_ends_at &&
                                !errors.recurrence_ends_at
                              }
                              isInvalid={!!errors.recurrence_ends_at}
                              min={minDate}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.recurrence_ends_at}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                  </Row>
                  <Row className={popupControlClasses}>
                    <Col md={2}>
                      <Form.Group controlId="trainerId">
                        <Form.Label>Coach</Form.Label>
                        <Form.Control
                          as="select"
                          name="trainer_id"
                          value={values.trainer_id}
                          onChange={(e) => {
                            handleChange(e);
                            coachSelected(e.target.value, setFieldValue);
                          }}
                          isValid={touched.trainer_id && !errors.trainer_id}
                          isInvalid={!!errors.trainer_id}
                        >
                          <option value="">Select</option>
                          {trainers.map((e) => {
                            return (
                              <option value={e.id} key={e.id}>
                                {e.firstName} {e.lastName}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.trainer_id}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group controlId="coachName">
                        <Form.Label>Coach Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="coach_name"
                          value={values.coach_name}
                          onChange={handleChange}
                          isValid={touched.coach_name && !errors.coach_name}
                          isInvalid={!!errors.coach_name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.coach_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="moderatorName" className="my-2">
                        <Form.Label>Moderator Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="moderator_name"
                          value={values.moderator_name}
                          onChange={handleChange}
                          isValid={
                            touched.moderator_name && !errors.moderator_name
                          }
                          isInvalid={!!errors.moderator_name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.moderator_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="my-2">
                      <Form.Group controlId="coachDetails">
                        <Form.Label>Coach Details</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={6}
                          name="coach_details"
                          value={values.coach_details}
                          onChange={handleChange}
                          isValid={
                            touched.coach_details && !errors.coach_details
                          }
                          isInvalid={!!errors.coach_details}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.coach_details}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className={popupControlClasses}>
                    <Col md={12}>
                      <Form.Group controlId="equipmentsRequired">
                        <Form.Label>Equipments Required</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={6}
                          name="equipments_required"
                          value={values.equipments_required}
                          onChange={handleChange}
                          isValid={
                            touched.equipments_required &&
                            !errors.equipments_required
                          }
                          isInvalid={!!errors.equipments_required}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.equipments_required}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className={popupControlClasses}>
                    <Col md={6}>
                      <Form.Group controlId="benefits">
                        <Form.Label>Benefits</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="benefits"
                          value={values.benefits}
                          onChange={handleChange}
                          isValid={touched.benefits && !errors.benefits}
                          isInvalid={!!errors.benefits}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.benefits}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="routine">
                        <Form.Label>Routine</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="routine"
                          value={values.routine}
                          onChange={handleChange}
                          isValid={touched.routine && !errors.routine}
                          isInvalid={!!errors.routine}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.routine}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className={popupControlClasses}>
                    <Col md={12}>
                      <Form.Group controlId="whoShouldAttend">
                        <Form.Label>Who can attend the session? </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          name="who_should_attend"
                          value={values.who_should_attend}
                          onChange={handleChange}
                          isValid={
                            touched.who_should_attend &&
                            !errors.who_should_attend
                          }
                          isInvalid={!!errors.who_should_attend}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.who_should_attend}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className={popupControlClasses}>
                    <Col md={12}>
                      <Form.Group controlId="whoShouldAvoid">
                        <Form.Label>Who should avoid?</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          name="who_should_avoid"
                          value={values.who_should_avoid}
                          onChange={handleChange}
                          isValid={
                            touched.who_should_avoid && !errors.who_should_avoid
                          }
                          isInvalid={!!errors.who_should_avoid}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.who_should_avoid}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} className="d-flex justify-content-end">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isButtonDisabled}
                      >
                        {crudType === "create" ? "   SAVE   " : "UPDATE"}
                      </Button>
                    </Col>

                    <Col xs={6} className="d-flex justify-content-start">
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => {
                          setSessionForm(defaultForm);
                          setModalShow(false);
                          setSessionImage(myImage);
                        }}
                      >
                        CANCEL
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* ==== Modal for View ==== */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-view-program-session-vcenter"
        centered
        show={modalShowView}
        onHide={() => setModalShowView(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-view-program-session-vcenter">
            Session Details
          </Modal.Title>
          <p style={{ color: "black" }}>
            <span
              onClick={() => setModalShowView(false)}
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <HighlightOffIcon
                style={{ fontSize: "36px", color: "grey", cursor: "pointer" }}
              />
            </span>
          </p>
        </Modal.Header>

        <Modal.Body>
          <ViewSession
            data={viewSessionForm}
            programs={programs}
            formats={formats}
            levels={levels}
            trainers={trainers}
          ></ViewSession>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              setViewSessionForm({});
              setModalShowView(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ==== Modal for Repeating history ==== */}
      <Modal
        size="sm"
        className="repeating-history-modal"
        show={modalShowHistory}
        onHide={() => setModalShowHistory(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Session History</Modal.Title>
          <p style={{ color: "black" }}>
            <span
              onClick={() => setModalShowHistory(false)}
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <HighlightOffIcon
                style={{ fontSize: "36px", color: "grey", cursor: "pointer" }}
              />
            </span>
          </p>
        </Modal.Header>

        <Modal.Body>
          <SessionHistory
            tab={activeTab}
            list={repeatingSessionsHistory}
            handleMeetingClick={getMeetingParticipants}
            handlePlay={sessionPlay}
          ></SessionHistory>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              setModalShowHistory(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ==== Modal for Repeating session participants ==== */}
      <Modal
        size="sm"
        className="repeating-participant-modal"
        show={modalShowParticipants}
        onHide={() => setModalShowParticipants(false)}
        backdrop="static"
        keyboard={false}
        width={400}
      >
        <Modal.Header closeButton>
          <Modal.Title>Session Participants</Modal.Title>
          <p style={{ color: "black" }}>
            <span
              onClick={() => setModalShowParticipants(false)}
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <HighlightOffIcon
                style={{ fontSize: "36px", color: "grey", cursor: "pointer" }}
              />
            </span>
          </p>
        </Modal.Header>

        <Modal.Body>
          <ParticipantList list={meetingParticipants}></ParticipantList>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              setModalShowParticipants(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ==== Modal for Play ==== */}
      <Modal show={showPlayerModal} onHide={hidePlayer} centered>
        <Modal.Body>
          <ReactPlayer url={videoUrl} controls width="100%" height="auto" />
        </Modal.Body>
      </Modal>
    </>
  );

  // ===== helper function =====\\
  function sessionStartMeeting(meeting) {
    if (!meeting) {
      return alert("Meeting not found");
    } else {
      confirmAlert({
        customUI: ({ onClose }) => (
          <AskHtml meeting={meeting} onClose={onClose} />
        ),
      });
    }
  }

  function getSessions(programId) {
    setSessionForm((old) => {
      return {
        ...old,
        program_id: programId,
      };
    });

    let type = "upcoming";
    if (parseInt(activeTab) === 1) {
      type = "upcoming";
    } else if (parseInt(activeTab) === 2) {
      type = "completed";
    } else if (parseInt(activeTab) === 3) {
      type = "missed";
    }

    let URL = `${ATLAS_URI}/sessionsByProgramId/${programId}?type=${type}`;

    axios.get(`${URL}`, configToken).then((res) => {
      setSessions(
        res.data.body.map((e) => {
          let meeting = null;
          let meetings = e.meetings;
          if (meetings && meetings.length) {
            meeting = meetings[0];
          }
          return {
            id: e.id,
            session_name: e.session_name,
            meeting,
            is_recurring: e.is_recurring,
          };
        })
      );
    });
  }

  // ===== event handler =====\\
  function programSelected(programId) {
    // alert(programId)
    toast.dismiss();
    toast.success("Program selected", {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 1000,
    });
    setCurrentProgramId(programId);
    getSessions(programId);
    setAttendees([]); //reset attendee
  }

  function fetchSessionAttendees(sessionId, meetingId) {
    setCurrentSessionId(sessionId);
    let URL = `${ATLAS_URI}/programSessionAttendees/${sessionId}/${meetingId}`;
    axios.get(`${URL}`, configToken).then((res) => {
      const attendees = res.data.body;
      setAttendees(attendees);
    });
  }

  function sessionEdit(sessionId) {
    setCurrentSessionId(sessionId);
    setCrudType("update");

    let URL = `${ATLAS_URI}/getProgramSession/${sessionId}`;
    axios.get(`${URL}`, configToken).then((res) => {
      const session = res.data.body;

      let start_url = "";
      if (session.meetings && session.meetings.length) {
        const meeting = session.meetings[0];
        start_url = meeting.start_url;
      }

      // restore image
      setSessionImage(session.image_url);

      setSessionForm({
        start_url, //readonly

        session_name: session.session_name,
        program_id: session.program_id,
        session_image: session.session_image,
        session_details: session.session_details,
        start_date: session.start_date,
        start_time: session.start_time,
        timezone: session.timezone,
        duration: session.duration,
        duration_unit: session.duration_unit,
        trainer_id: session.trainer_id,
        coach_name: session.coach_name,
        coach_details: session.coach_details,
        moderator_name: session.moderator_name,
        equipments_required: session.equipments_required,
        benefits: session.benefits,
        routine: session.routine,
        who_should_attend: session.who_should_attend,
        who_should_avoid: session.who_should_avoid,
        format_id: session.format_id,
        level_id: session.level_id,

        is_recurring: session.is_recurring,

        //repeating
        repeat_by: session.repeat_by,
        interval_value: session.interval_value,

        repeat_on_weekdays: session.repeat_on_weekdays,

        repeat_by_month_day_type_criteria:
          session.repeat_by_month_day_type_criteria,
        repeat_by_month_static_day: session.repeat_by_month_static_day,
        repeat_by_month_dynamic_day_ordinal:
          session.repeat_by_month_dynamic_day_ordinal,
        repeat_by_month_dynamic_day_weekday:
          session.repeat_by_month_dynamic_day_weekday,
        recurrence_ends_at: session.recurrence_ends_at,
      });

      if (session.repeat_on_weekdays && session.repeat_on_weekdays.length) {
        setRepeatDaysOfWeek(session.repeat_on_weekdays); // undefined , null check
      }

      setModalShow(true);
    });
  }

  function sessionCancel(sessionId) {
    let URL = `${ATLAS_URI}/getProgramSession/${sessionId}`;
    axios.get(`${URL}`, configToken).then((res) => {
      const session = res.data.body;
      const sessionId = res.data.body.id;

      if (session.is_recurring) {
        confirmAlert({
          title: "Please confirm",
          message: `This is a repeating session, please select the choice`,
          buttons: [
            {
              label: "Cancel just the upcoming item in this session only",
              onClick: () => {
                let URL = `${ATLAS_URI}/repeatingProgramSessionMeetings/${sessionId}`;
                axios.get(`${URL}`, configToken).then((res) => {
                  const session = res.data.body;

                  if (session.meetings && session.meetings.length) {
                    const meet = session.meetings[0];
                    const userTimezone = moment.tz.guess();
                    const userDatetime = moment
                      .tz(meet.start_time, "UTC")
                      .tz(userTimezone);
                    const meetingOn =
                      userDatetime.format("DD/MM/YYYY hh:mm A") +
                      " (" +
                      userTimezone +
                      " timezone)";

                    confirmAlert({
                      title: "Please confirm",
                      message: `The upcoming session on "${meetingOn}" will be cancelled`,
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => {
                            let URL = `${ATLAS_URI}/cancelProgramSession/${sessionId}/${meet.id}`;
                            axios
                              .post(`${URL}`, { cancel: 1 }, configToken)
                              .then((res) => {
                                toast.success("Session has been cancelled", {
                                  position: toast.POSITION.TOP_CENTER,
                                });
                                getSessions(currentProgramId);
                              });
                          },
                        },
                        {
                          label: "No",
                          // onClick: () => alert('Click No')
                        },
                      ],
                    });
                  }
                });
              },
            },
            {
              label: "Cancel all upcoming items in this session",
              onClick: () => {
                let URL = `${ATLAS_URI}/cancelProgramSession/${sessionId}`;
                axios.post(`${URL}`, { cancel: 2 }, configToken).then((res) => {
                  toast.success("Session has been cancelled", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                  getSessions(currentProgramId);
                });
              },
            },
            {
              label: "No",
              // onClick: () => alert('Click No')
            },
          ],
        });
      } else {
        let meetingOn = "";
        let meetingId = "";

        if (session.meetings && session.meetings.length) {
          const userTimezone = moment.tz.guess();
          const userDatetime = moment
            .tz(session.meetings[0].start_time, "UTC")
            .tz(userTimezone);
          meetingOn =
            userDatetime.format("DD/MM/YYYY hh:mm A") +
            " (" +
            userTimezone +
            " timezone)";
          meetingId = session.meetings[0].id;
        }

        confirmAlert({
          title: "Please confirm",
          message: `Are you sure you want to cancel session going to be held on ${meetingOn} `,
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                let URL = `${ATLAS_URI}/cancelProgramSession/${sessionId}/${meetingId}`;
                axios.post(`${URL}`, {}, configToken).then((res) => {
                  toast.success("Session has been cancelled", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                  getSessions(currentProgramId);
                });
              },
            },
            {
              label: "No",
              // onClick: () => alert('Click No')
            },
          ],
        });
      }
    });
  }

  function sessionPlay(meeting) {
    if (!meeting) {
      return alert("Meeting not found");
    }

    let URL = `${ATLAS_URI}/admin-getProgramSessionPlayUrl?meeting_id=${meeting.id}`;
    axios
      .get(`${URL}`, configToken)
      .then((res) => {
        const url = res.data.body;

        if (url && url.length) {
          setVideoUrl(url);
          setShowPlayerModal(true);
        } else {
          alert("Recording not found");
        }
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  }

  function sessionView(sessionId) {
    let URL = `${ATLAS_URI}/getProgramSession/${sessionId}`;
    axios.get(`${URL}`, configToken).then((res) => {
      const session = res.data.body;
      setViewSessionForm(session);
      setModalShowView(true);
    });
  }

  function fetchRepeatingSessionHistory(sessionId, type) {
    let URL = `${ATLAS_URI}/repeatingSessionHistory/${sessionId}?type=${type}`;
    axios.get(`${URL}`, configToken).then((res) => {
      setRepeatingSessionsHistory(res.data.body);
      setModalShowHistory(true);
    });
  }

  function getMeetingParticipants(sessionId, meetingId) {
    let URL = `${ATLAS_URI}/programSessionAttendees/${sessionId}/${meetingId}`;
    axios.get(`${URL}`, configToken).then((res) => {
      setMeetingParticipants(res.data.body);
      setModalShowParticipants(true);
    });
  }

  function formSubmit(form, { setSubmitting }) {
    const formData = new FormData(formRef.current);
    //append non input values
    formData.append("is_recurring", form.is_recurring ? true : false);
    //remove repeat related data
    formData.delete("interval_value");
    formData.delete("repeat_by");
    formData.delete("repeat_on_weekdays");
    formData.delete("repeat_by_month_day_type_criteria");
    formData.delete("repeat_by_month_static_day");
    formData.delete("repeat_by_month_dynamic_day_ordinal");
    formData.delete("repeat_by_month_dynamic_day_weekday");
    formData.delete("recurrence_ends_at");

    if (form.is_recurring) {
      if (form.interval_value) {
        formData.append("interval_value", form.interval_value);
      }

      if (form.repeat_by) {
        formData.append("repeat_by", form.repeat_by);
      }

      if (form.repeat_on_weekdays) {
        //convert json array to form data array
        for (let i = 0; i < form.repeat_on_weekdays.length; i++) {
          const element = form.repeat_on_weekdays[i];
          formData.append(`repeat_on_weekdays[${i}]`, element);
        }
      }

      if (form.repeat_by_month_day_type_criteria) {
        formData.append(
          "repeat_by_month_day_type_criteria",
          form.repeat_by_month_day_type_criteria
        );
      }

      if (form.repeat_by_month_static_day) {
        formData.append(
          "repeat_by_month_static_day",
          form.repeat_by_month_static_day
        );
      }

      if (form.repeat_by_month_dynamic_day_ordinal) {
        formData.append(
          "repeat_by_month_dynamic_day_ordinal",
          form.repeat_by_month_dynamic_day_ordinal
        );
      }

      if (form.repeat_by_month_dynamic_day_weekday) {
        //single value not an array
        formData.append(
          "repeat_by_month_dynamic_day_weekday",
          form.repeat_by_month_dynamic_day_weekday
        );
      }

      if (form.recurrence_ends_at) {
        formData.append("recurrence_ends_at", form.recurrence_ends_at);
      }
    }

    // for (let [key, value] of formData.entries()) {
    //     console.log('Key:', key);
    //     console.log('Value:', value);
    // }

    let { configToken } = contextState.state;

    let URL = `${ATLAS_URI}/createProgramSession/${currentProgramId}`;
    if (crudType === "update") {
      URL = `${ATLAS_URI}/updateProgramSession/${currentSessionId}`;
    } else {
      if (!sessImgRef.current.files.length) {
        return toast.error("Please choose some image for session", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }

    setIsButtonDisabled(true);
    axios
      .post(URL, formData, configToken)
      .then((response) => {
        setModalShow(false);
        setSessionForm(defaultForm);
        // reset image
        setSessionImage(myImage);
        setRepeatDaysOfWeek([]);

        if (crudType === "create") {
          getSessions(response.data.body.program_id);
        } else if (crudType === "update") {
          getSessions(response.data.body.program_id);
        }

        toast.success("Session created Successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .then(() => {
        setIsButtonDisabled(false);
      });
    setSubmitting(false);
  }
}
