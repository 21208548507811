

const dietform = [
    {
        "type": "Autocomplete",
        "label": "Diet Preference",
        "name": "diet_preference",
        "rules": {

        }
    },
    {
        "type": "TextField",
        "label": "Any Allergies",
        "name": "allergies",
        "rules": {

        }
    },
    {
        "type": "Autocomplete",
        "label": "Prefered Cuisine",
        "name": "preferred_cuisine",
        "rules": {

        }
    },
    {
        "type": "TextField",
        "label": "Food Dislikes",
        "name": "food_dislikes",
        "rules": {

        }
    },
    {
        "type": "Autocomplete",
        "label": "Meal Timings",
        "name": "meal_timings",
        "rules": {

        }
    },
    {
        "type": "Autocomplete",
        "label": "Cravings",
        "name": "cravings",
        "rules": {

        }
    },
    {
        "type": "Autocomplete",
        "label": "Water Intake",
        "name": "water_intake",
        "rules": {

        }
    }
    
    
]

export default dietform
