// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.todayTask {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #fff;
  border-radius: 5px;
  margin: 0 50px;
  padding: 20px;
}

.task-table {
  width: 100%;
  border-collapse: collapse;
  padding: 20px;
  font-size: 18px;
  border: 2px solid #d0d0d0;
}

.task-table_header th,
.task-table_body td {
  padding: 20px;
  border-left: 2px solid #d0d0d0;
  border-right: 2px solid #d0d0d0;
}

.task-table_header th {
  border-bottom: 2px solid #d0d0d0;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Miscellaneous/TodayTask.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,2CAA2C;EAC3C,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,aAAa;AACf;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,eAAe;EACf,yBAAyB;AAC3B;;AAEA;;EAEE,aAAa;EACb,8BAA8B;EAC9B,+BAA+B;AACjC;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".todayTask {\n  width: 100%;\n  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;\n  background-color: #fff;\n  border-radius: 5px;\n  margin: 0 50px;\n  padding: 20px;\n}\n\n.task-table {\n  width: 100%;\n  border-collapse: collapse;\n  padding: 20px;\n  font-size: 18px;\n  border: 2px solid #d0d0d0;\n}\n\n.task-table_header th,\n.task-table_body td {\n  padding: 20px;\n  border-left: 2px solid #d0d0d0;\n  border-right: 2px solid #d0d0d0;\n}\n\n.task-table_header th {\n  border-bottom: 2px solid #d0d0d0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
