import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { ATLAS_URI } from '../../Constants'
import stateContext from "../../context/StateContext";


const ClientProgressImages = (props) => {
  const contextState = useContext(stateContext);
  const { configToken } = contextState.state;

  const [progressImages, setProgressImages] = useState({})
  useEffect(() => {
 axios.get(`${ATLAS_URI}/getProgressImagesToCRM/${props.leadId}`,configToken).then(res=>(setProgressImages(res.data),console.log('res in progree images', res))).catch(err=>console.log(err))
  }, [])
  
  return (
    <div>
       <table >
        <tbody>

        <th>Back Facing</th><br/>
        <tr>
          <td>  
           <img src={progressImages.back_facing_before_image} style={{'height':'200px'}} /></td>
           <td> <img src={progressImages.back_facing_after_image} style={{'height':'200px'}} /></td>
           
        </tr>
        <th>Front Facing</th>
        <tr>
        <td><img src={progressImages.front_facing_before_image} style={{'height':'200px'}} /></td>
       <td> <img src={progressImages.front_facing_after_image} style={{'height':'200px'}} /></td> 
       
        </tr>
        <th>Left Facing</th>
        <tr>
        <td><img src={progressImages.left_facing_before_image}style={{'height':'200px'}}/></td>
        <td><img src={progressImages.left_facing_after_image} style={{'height':'200px'}} /></td>
          
        </tr>
        <th>Right Facing</th>

        <tr>
        <td><img src={progressImages.right_facing_before_image} style={{'height':'200px'}} /></td>
        <td><img src={progressImages.right_facing_after_image} style={{'height':'200px'}} /></td>

        </tr>
        <div >
        {/* <div className='row'>
          <div className='col-md-6 ' style={{'position':'relative','left':"300px"}}>
            <label>Before Back facing</label><br/>
           <img src={progressImages.back_facing_before_image} style={{'height':'200px'}} />
          </div>
           <div className='col-md-6' >
          <img src={progressImages.back_facing_before_image} style={{'height':'200px'}}/>
          </div>
        </div> */}
         

          {/* <div className='row'>
          <div className='col-md-6 ' style={{'position':'relative','left':"300px"}}>
           <img src={progressImages.front_facing_before_image} style={{'height':'200px'}} />
            </div>

          <div className='col-md-6 '>
          <img src={progressImages.front_facing_after_image} style={{'height':'200px'}} />
          </div>
            </div> */}

       {/* <div className='row'>
        <div className='col-md-6' style={{'position':'relative','left':"300px"}}>
        <img src={progressImages.left_facing_before_image}style={{'height':'200px'}}/>
        </div>
        <div className='col-md-6'>
          <img src={progressImages.left_facing_after_image} style={{'height':'200px'}} />
        </div>
       </div> */}

       {/* <div className='row'>
        <div className='col-md-6' style={{'position':'relative','left':"300px"}}>
          <img src={progressImages.right_facing_before_image} style={{'height':'200px'}} />

        </div>
        <div className='col-md-6'>

        <img src={progressImages.right_facing_after_image} style={{'height':'200px'}} />
        </div>
       </div> */}
       </div>
         
        
        </tbody>
       </table>
    </div>
  )
}

export default ClientProgressImages