import React, { Component, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import moment from 'moment'
import { ATLAS_URI } from "../../Constants";



// function ClientProgressAnalysis () {
  

  
//     super(props);
//     let days = [];
//     let daysRequired = 7
//    console.log('this.props.neck', this.props.neck)
//    console.log('this.props.weight.map(c=>(c))', this.props.weight.map(c=>(c)))

//     for (let i = 0; i <= daysRequired; i++) {
//       days.push( moment().add(i, 'days').format(' D') )
//     }

//     this.state = {
//       options: {
//         chart: {
//           id: "basic-bar",
        
//         },
//         type:'line',

//         xaxis: {
//           // categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
//           categories: days.map((c)=>(c))

//         }
//       },
//       series: [
//         {
//           name: "Weight",
//           // data: [30, 30, 30, 30, 30, 30, 30, 30]
//           data: this.props?.weight&&this.props?.weight?.map(c=>(c))

//         },
//         {
//           name:'Neck',
//           data:this.props.neck.map(c=>(c))
//         },{
//           name:'Left Arm',
//           data:this.props.leftArm.map(c=>(c))
//         }
//       ]
//     };
//   }
 

//   render() {
//     return (
//       <div className="app">
//         <div className="row">
//           <div className="mixed-chart">
//             <Chart
//               options={this.state.options}
//               series={this.state.series}
//               type="line"
//               width="500"
//             />
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default ClientProgressAnalysis;
export default function ClientProgressAnalysis(props) {

  // const [first, setfirst] = useState([])

 console.log('props.xaxis', props.xaxis)
  

  let days = [];
    let daysRequired = 7
  //  console.log('this.props.neck', this.props.neck)
  //  console.log('this.props.weight.map(c=>(c))', this.props.weight.map(c=>(c)))

    for (let i = 0; i <= daysRequired; i++) {
      days.push( moment().add(i, 'days').format(' D') )
    }
    console.log('props.weight', props.weight)
  const series = [
    {
                name: "Weight",
                // data: [30, 30, 30, 30, 30, 30, 30]
                data: props?.weight
      
              },
               {
                name:'Neck',
                data:props.neck
              },{
                name:'Left Arm',
                data:props.leftArm
           },{
            name:'Right Arm',
            data:props.rightArm
           },{
            name:'Left ForeArm',
            data:props.leftforeArm
           },{
            name:'Right ForeArm',
            data:props.rightForeArm
           },{
            name:'Shoulder',
            data:props.shoulder
           },{
            name:'Lower Abdomen',
            data:props.lowerAbdomen
           },{
            name:'Upper Abdomen',
            data:props.upperAbdomen
           },{
            name:'Hip',
            data:props.hip
           },{
            name:'Chest',
            data:props.chest
           },{
            name:'Left Thigh',
            data:props.leftThigh
           },{
            name:'Right Thigh',
            data:props.rightThigh
           },{
            name:'Left Calf',
            data:props.leftCalf
           },{
            name:'Right Calf',
            data:props.rightCalf
           }
  ];
  const options = {
    chart: {
      id: "simple-bar"
    },
    xaxis: {
      // categories: days.map((c)=>(c)) 
      //will be displayed on the x-asis
      categories:props?.xaxis
    }
  };
  return (
    <div>
      <Chart options={options} type="line" series={series} width="1200" />
    </div>
  );
}