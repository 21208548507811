// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 800px) {
  .modal.show .modal-dialog {
    min-width: 900px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/context/Plivo.css"],"names":[],"mappings":"AAAA;EACE;IACE,2BAA2B;EAC7B;AACF","sourcesContent":["@media (max-width: 800px) {\n  .modal.show .modal-dialog {\n    min-width: 900px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
