import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import {
  ATLAS_URI,
  CALLER_ID,
  CALL_METHOD,
  INDIA_CALLER_ID,
} from "../../Constants";
import stateContext from "../../context/StateContext";
import BoxHeader from "../../components/BoxHeader";
import Dialog from "../../components/Dialog";
import { useHistory } from "react-router-dom";
import "./ListClient.css";
import { Button, Modal } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { BiListCheck } from "react-icons/bi";
import { FaHistory } from "react-icons/fa";

import AddIcon from "@mui/icons-material/Add";

import TaskModal from "../../components/TaskModal/TaskModal";

import FollowupModal from "../../components/FollowupModal";
import {
  closeInputModal,
  openInputModalLeads,
  openFollowUpModal,
  closeFollowUpModal,
  openEmailModal,
  closeEmailModal,
  updateFollowUp,
  updateFollowUpStatus,
  deleteFollowUp,
} from "../../Helpers/ModalHandler";
import PhoneInput from "react-phone-input-2";
import { PlivoContext } from "../../context/PlivoContext";
import { IconButton, Menu, MenuItem, Paper } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import HistoryIcon from "@mui/icons-material/History";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import {
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  getGridStringOperators,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid-pro";
import CallPopup from "../Call/CallPopup";

// import { useNavigate } from 'react-router-dom';

import { useDispatch } from "react-redux";
import { addLeadId } from "../store/leads/leadIdSlice";
import DataTable from "../../components/DataTable/DataTable";

import useListClient from "../../hooks/tableColumns/useClientList";

const ListClient = () => {
  const dispatch = useDispatch();

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [userAssign, setUserAssign] = useState("");
  const [selectedIds, setSelectedIds] = useState(null);
  const contextState = useContext(stateContext);
  const {
    updateEditDetails,
    updateList,
    plivoBrowserSdk,
    setCurrentCallStatus,
    setCallDurationInfo,
    duration,
    setDuration,
    setIsTimerOn,
  } = contextState;
  const [leadId, setLeadId] = useState("");
  const [selectedLead, setSelectedLead] = useState(null);
  const [display, setDisplay] = useState(false);

  const {
    plivo,
    setStatePlivo,
    statePlivo,
    newTableRowPlivo,
    microphoneDevices,
    speakerDevices,
  } = useContext(PlivoContext);

  const [selectedRows, setSelectedRows] = useState([]);

  const [openTaskModal, setOpenTaskModal] = useState(false);

  const [isShowPopup, setIsShowPopup] = useState(false);
  const { configToken, operator, currentUser, featureAccessible } =
    contextState.state;
  const [clientdataAdd, setclientdataAdd] = useState([]);
  const [newTableRow, setNewTableRow] = useState({
    id: "",
    email_id: "",
    contact_number: "",
    createdAt: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  let [selectedRow, setSelectedRow] = useState(null);
  const open = Boolean(anchorEl);

  const { visibileClientColumns, clientColumnsList } = useListClient(
    handleClick,
    open
  );

  const [show, setShow] = useState(false);
  const [deleteIds, setDeleteIds] = useState([]);
  const [text, setText] = useState("Delete");

  const [currentLead, setCurrentLead] = useState("");
  const [selectedSpeaker, setSelectedSpeaker] = useState("");
  const [selectedMicroPhone, setSelectedMicroPhone] = useState("");

  const [currentId, setCurrentId] = useState("");

  const [state, setState] = useState({
    tableBodyList: [],
    userName: [],
    dialogInfo: {
      isOpened: false,
      text: "",
      type: "",
    },
    isFollowUpModalOpen: false,
  });
  const [searchBy, setSearchBy] = useState("Name");

  const [isCallModalShow, setIsCallModalShow] = useState(false);

  const [totalCount, setTotalCount] = useState(0);

  const [isMasterDataLoaded, setIsMasterDataLoaded] = useState(false);
  const [masterData, setMasterData] = useState({
    leadStages: [],
    leadDisposition: [],
    leadSubDisposition: [],
    leadNotInterested: [],
    users: [],
    sources: [],
    interactionCategories: [],
  });

  const [dietitianList, setDietitianList] = useState([]);
  const [trainerList, setTrainerList] = useState([]);

  const [selectedTrainer, setSelectedTrainer] = useState("");
  const [selectedDiettian, setSelectedDiettian] = useState("");

  useEffect(() => {
    axiosFetch({
      url: `/lead-screen-master-data`,
      method: "get",
    })
      .then((res) => {
        setIsMasterDataLoaded(true);
        setMasterData(res.data);
      })
      .catch(() => {});
  }, []);

  function getCalNo(item) {
    //props?.selectedRow?.LeadID?.contact_number   props.selectedRow?.contact_number
    // order is imp
    if (item && item.LeadID) {
      return item.LeadID.contact_number;
    } else if (item && item.contact_number) {
      return item.contact_number;
    } else {
      return "-";
    }
  }

  const handleTaskModal = () => {
    setOpenTaskModal(true);
    setAnchorEl(null);
  };

  const handleWhatsapp = () => {
    const phoneNumber = getCalNo(currentLead);
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsappLink, "_blank");
  };

  const handleChangeSpeaker = (e) => {
    setSelectedSpeaker(e.target.value);
    plivo.client.audio.speakerDevices.set(e.target.value);
  };

  const handleChangeMicroPhone = (e) => {
    setSelectedMicroPhone(e.target.value);
    plivo.client.audio.microphoneDevices.set(e.target.value);
  };

  const callObjectInfo = useRef(null);

  const paramVal = useRef(null);

  // let selectUser = (e) => {
  //   setUserAssign(e.target.value);
  // };

  let history = useHistory();
  function editRecord(e, redirect) {
    const temp = currentId.row;

    // setclientdataAdd(e.data.lead_id)
    // updateEditDetails({
    //   id: temp.id,
    //   editingActivated: true,
    //   redirectFrom: "Clients/listClients",
    //   redirectTo: "/Clients/addClient",
    // });
    // navigate('/Clients/clientDetail')
    history.push({
      pathname: "/Clients/clientDetail/" + temp.Lead,
      state: temp,
    });

    localStorage.setItem("backToTable", "/Clients/listClients");
  }

  async function downloadHFReport(clientInfo) {
    await axios
      .get(`${ATLAS_URI}/downloadByUser/${clientInfo.lead_id}`, configToken)
      .then((res) => {
        if (res.status == 200) {
          window.open(res.data);
        }
      });
  }

  function openDialog(e) {
    setDisplay(true);

    const clientCount = e.length == 1 ? "This Client" : " These Clients";
    const newDialogInfo = {
      isOpened: true,
      delID: e,
      text: ` ${clientCount} will be deleted permanently from VMax system`,
      type: "Confirm",
    };
    setState({ ...state, dialogInfo: newDialogInfo });
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    const updateData = (data) => {
      setRowData(data);
    };

    // fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
    //   .then((resp) => resp.json())
    //   .then((data) => updateData(data));
  };

  function changeHandler(e) {
    setSearchBy(e.target.value);
  }

  const [leadIdForCall, setleadIdForCall] = useState();
  const [gridColumnList, setGridColumnList] = useState([]);
  const defautlColHeader = () => {
    axiosFetch({
      url: `/getListViewByUserIdAndScreen/${operator.id}/2`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          let obj = {
            name: true,
            id: true,
            // account_name: true,
            age: false,
            height: false,
            // dob: true,
            gender: true,
            // lead_status: true,
            email_id: true,
            contact_number: true,
            // createdAt: true,
            height: false,
            current_weight: false,
            city: false,
            state: false,
            address: false,
            country: false,
            // pincode: false,
            // sleep_goal: false,
            mother_tongue: false,
            // preferred_language: false,
            assigned_to_user: false,
            referral_code: false,
            // occupation: false,
            // work_hours: false,
            // family_details: false,
            // emergency_contact: false,
            // medications: false,
            // medical_history: false,
            current_medical_condition: false,
            weight_goal: false,
            bmi: false,
            vmax_fit_goal: false,
            ClientDietitianInfo: false,
            ClientTrainerInfo: false,
            call_status: false,

            Status: false,
            next_followup_date: false,
            preferred_language: false,
            account_id: false,
            call_category: false,
            program_status: false,
            activity_status: false,
            tenure_end_date: false,
            created_date: false,
            update_date: false,
            update_by: false,
          };
          // modif ying data based on selected header

          Object.keys(obj).filter((key) => {
            if (res.data !== null && res.data.fieldList.includes(key)) {
              obj[key] = true;
            } else {
              obj[key] = false;
            }
          });

          setColumnVisibilityModel(obj);
        } else if (res.status === 400) {
          toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    defautlColHeader();
  }, []);

  function postListView() {
    const listViewData = {
      fieldList: gridColumnList,
      userId: operator.id,
      screen: 2, // client screen
    };
    axios
      .post(`${ATLAS_URI}/postListView`, listViewData, configToken)
      .then((res) => {
        if (res.status == 200) {
          toast.success("List view saved", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error("List view save has problem", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  function getDisplayValueById(arr, id, fn) {
    try {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
          return fn(arr[i]);
          // arr[i][attrName];
        }
      }
    } catch (error) {
      let err = error;
    }
    return "-";
  }

  const clientFieldList = [
    "name",
    "ClientDietitianInfo",
    "ClientTrainerInfo",
    "email_id",
    "contact_number",
    "account_name",
    "dob",
    "gender",
    "height",
    "current_weight",
    "address",
    "city",
    "state",
    "country",
    "pincode",
    "mother_tongue",
    "preferred_language",
    "occupation",
    "work_hours",
    "family_details",
    "emergency_contact",
    "medications",
    "medical_history",
    "current_medical_condition",
    "weight_goal",
    "nutrition_goal",
    "sleep_goal",
    "water_goal",
    "steps_goal",
    "fitness_specifications",
    "food_specifications",
    "body_measurements",
    "LeadStatus",
    "assignToUser",
  ];

  /*   let getUsersURL = `${ATLAS_URI}/getUsers/`;

  if (
    operator.Feature?.find((features) => features.sub_feature_id === 8)
      .is_edit &&
    operator.Feature.find((features) => features.sub_feature_id === 23).is_edit
  ) {
    getUsersURL = `${ATLAS_URI}/getDietitianList/`;
  }

  if (
    operator.Feature?.find((features) => features.sub_feature_id === 8)
      .is_edit &&
    operator.Feature.find((features) => features.sub_feature_id === 24).is_edit
  ) {
    getUsersURL = `${ATLAS_URI}/getTrainerList/`;
  }

  useEffect(() => {
    axios
      .all([
        // fortemprary dont use server pagination
        axios.get(`${getUsersURL}`, configToken),
        axios.get(`${ATLAS_URI}/getRoles/`, configToken),
      ])
      .then(
        axios.spread((users, role) => {
          const usersData = users.data;
          const rolesData = role.data;

          const newUsersData = usersData.map((user) => {
            const temp = rolesData.filter((role) => role.id === user.Role);
            if (temp.length !== 0) {
              user.Roles = temp[0].Role;
            } else {
              user.Role = "Select";
              user.Roles = "";
            }
            return user;
          });
          setState((prevState) => ({ ...prevState, userName: newUsersData }));
        })
      )
      .catch((err) => console.error(err));
  }, []); */

  const getDietAndTrainerList = async () => {
    const dietitianList = await axiosFetch({
      url: "/getDietitianList",
      method: "get",
    }).then((res) => res.data);

    const trainerList = await axiosFetch({
      url: "/getTrainerList",
      method: "get",
    }).then((res) => res.data);

    setTrainerList(trainerList);
    setDietitianList(dietitianList);
  };

  useEffect(() => {
    getDietAndTrainerList();
  }, []);

  function deleteFromTable(id) {
    // const delID = state.dialogInfo.delID;
    const delID = deleteIds;

    axios
      .delete(`${ATLAS_URI}/deleteManyLeads/` + delID, configToken)
      .then((x) => {
        const newTableBodyList = state.tableBodyList.filter(
          (data) => data.id !== delID
        );

        updateList("clientsList", newTableBodyList);
        setState({
          ...state,
          tableBodyList: newTableBodyList,
          dialogInfo: { isOpened: false, text: "", delID: "" },
        });

        getClients();
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  function restore() {
    const CLIENT_LC_STORAGE_DEFAULT = {
      filter: {
        filterModel: {
          items: [],
          logicOperator: "and",
          quickFilterLogicOperator: "and",
          quickFilterValues: [],
        },
      },
      pagination: {
        paginationModel: {
          page: 0,
          pageSize: 25,
        },
      },
      preferencePanel: {
        open: true,
        openedPanelValue: "filters",
      },
      sorting: {
        sortModel: [],
      },
    };

    let CLIENT_LC_STORAGE = null;
    CLIENT_LC_STORAGE = localStorage.getItem("CLIENT_LC_STORAGE") || null;

    if (CLIENT_LC_STORAGE) {
      try {
        CLIENT_LC_STORAGE = JSON.parse(CLIENT_LC_STORAGE) || null;
        if (CLIENT_LC_STORAGE) {
          for (const key in CLIENT_LC_STORAGE_DEFAULT) {
            if (!CLIENT_LC_STORAGE.hasOwnProperty(key)) {
              CLIENT_LC_STORAGE[key] = CLIENT_LC_STORAGE_DEFAULT[key];
            }
          }
        }

        //Note: only restore if there is some filter in saved state json, otherwise it causes appearance of a empty filter box
        if (
          CLIENT_LC_STORAGE.filter.filterModel.items.length ||
          CLIENT_LC_STORAGE.pagination.paginationModel.page != 0
        ) {
          apiRef.current.restoreState(CLIENT_LC_STORAGE);
          //clear the data
          // localStorage.removeItem("CLIENT_LC_STORAGE")
        }
      } catch (e) {
        console.error("Could not parse mui grid state from localstorage");
      }
    }

    getClients(CLIENT_LC_STORAGE);
  }

  useEffect(() => {
    // This function will run after the component has rendered
    let isRestore = localStorage.getItem("RESTORE_CLIENT_LISTING_SCREEN");
    if (isRestore) {
      restore();
      localStorage.removeItem("RESTORE_CLIENT_LISTING_SCREEN");
    } else {
      getClients();
    }
  }, [currentUser]);

  const checkbox = (params) => {
    return params.node.group === true;
  };
  const autoGroupColumnDef = useMemo((params) => {
    return {
      headerName: "Athlete",
      field: "athlete",
      minWidth: 250,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        checkbox,
      },
    };
  }, []);

  const onSelectionChanged = (event) => {
    setSelectedIds(
      event.api.getSelectedRows().map((selected) => {
        return selected.id;
      })
    );
    if (event.api.getSelectedRows().length >= 2) {
      setText("Delete All");
      setShow(true);
      setDeleteIds(event.api.getSelectedRows());
    } else {
      setText("Delete");
      setShow(true);
      setDeleteIds(event.api.getSelectedRows());
    }
    if (event.api.getSelectedRows().length == 0) {
      setShow(false);
    }
  };

  const rowSelectionType = "multiple";
  /*   const post_assigned_user = () => {
    let assignUserURL;

    if (
      operator.Feature.find((features) => features.sub_feature_id === 8)
        .is_edit &&
      operator.Feature.find((features) => features.sub_feature_id === 23)
        .is_edit
    ) {
      assignUserURL = `${ATLAS_URI}/updateDietitianAssignment/${userAssign}`;
    }

    if (
      operator.Feature.find((features) => features.sub_feature_id === 8)
        .is_edit &&
      operator.Feature.find((features) => features.sub_feature_id === 24)
        .is_edit
    ) {
      assignUserURL = `${ATLAS_URI}/updateTrainerAssignment/${userAssign}`;
    }

    if (!assignUserURL) {
      alert("do not have access to assign");
      return;
    }

    if (!selectedIds) {
      return alert("Ids are not selected");
    }

    axios
      .put(`${assignUserURL}`, { ids: selectedIds }, configToken)
      .then((res) => {
        toast.success("Allocation done successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getClients();
      })
      .catch((err) => console.error("Error", err));
  }; */

  // const [columnDefs] = useState( [{field: 'Action'}] );

  const handleAssignedUser = async () => {
    if (!selectedIds) {
      toast.error("Please select the Dietitian and Trainer List", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    let trainerResponse;
    let dietitianResponse;

    if (selectedTrainer) {
      trainerResponse = await axiosFetch({
        url: `updateTrainerAssignment/${selectedTrainer}`,
        method: "put",
        requestConfig: { ids: selectedIds },
      });
    }

    if (selectedDiettian) {
      dietitianResponse = await axiosFetch({
        url: `updateDietitianAssignment/${selectedDiettian}`,
        method: "put",
        requestConfig: { ids: selectedIds },
      });
    }

    if (trainerResponse?.status === 200 || dietitianResponse.status === 200) {
      setSelectedDiettian("");
      setSelectedTrainer("");
      toast.success("Allocation done successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
      getClients();
    }
  };

  let clientsData = state?.tableBodyList?.map((c) => {
    let clientId;
    const lead_id = c?.Lead;
    const assignToUser = c?.LeadID?.assigned_to_user?.Username ?? null;
    let dieticianName;
    let trainerName;

    if (c.ClientDietitianInfo !== undefined && c.ClientDietitianInfo !== null) {
      if (c.ClientDietitianInfo.Name !== null) {
        let dieticianInfo = c.ClientDietitianInfo;
        if (dieticianInfo.firstName !== null) {
          dieticianName = dieticianInfo.firstName;
        }
        if (dieticianInfo.lastName !== null) {
          if (dieticianName !== undefined) {
            dieticianName = dieticianName + dieticianInfo.lastName;
          } else {
            dieticianName = dieticianInfo.lastName;
          }
        }
      }
    }

    if (c.ClientTrainerInfo !== undefined && c.ClientTrainerInfo !== null) {
      if (c.ClientTrainerInfo.Name !== null) {
        let trainerInfo = c.ClientTrainerInfo;
        if (trainerInfo.firstName !== null) {
          trainerName = trainerInfo.firstName;
        }
        if (trainerInfo.lastName !== null) {
          if (trainerName !== undefined) {
            trainerName = trainerName + trainerInfo.lastName;
          } else {
            trainerName = trainerInfo.lastName;
          }
        }
      }
    }
    const ClientDietitianInfo =
      c?.ClientDietitianInfo?.Name !== undefined
        ? c?.ClientDietitianInfo?.Name
        : `${
            c?.ClientDietitianInfo?.firstName
              ? c?.ClientDietitianInfo?.firstName
              : null
          } ${
            c?.ClientDietitianInfo?.lastName
              ? c?.ClientDietitianInfo?.lastName
              : null
          }`;
    // c?.ClientDietitianInfo?.Name ?? ''
    const ClientTrainerInfo = c?.ClientTrainerInfo?.Name
      ? c?.ClientTrainerInfo?.Name
      : `${
          c?.ClientTrainerInfo?.firstName
            ? c?.ClientTrainerInfo?.firstName
            : null
        } ${
          c?.ClientTrainerInfo?.lastName ? c?.ClientTrainerInfo?.lastName : null
        }`;
    // c?.ClientTrainerInfo?.Name ?? ''
    // c = c.LeadID;
    if (lead_id !== null && lead_id !== undefined) {
      c.lead_id = lead_id;
    }

    // if(clientId !== null && clientId !== undefined && c.id !== null){
    //   c.id = clientId;
    // }

    if (!!c && !!c.id) {
      clientId = c.id;
    }
    if (!!c && !!c.id && !!clientId) {
      c.ClientID = clientId;
    }

    if (!!assignToUser && !!c) {
      c.assignToUser = assignToUser;
    }
    if (!!c && !!dieticianName) {
      c.ClientDietitianInfo = dieticianName;
    }
    if (!!c && trainerName !== null && trainerName !== undefined) {
      c.ClientTrainerInfo = trainerName;
    }

    return c;
  });

  /* search Criteria */

  const searchCriteria = () => {};

  function viewCallModal(params) {
    paramVal.current = params;

    if (!operator.Mobile_number) {
      toast.error("Agent mobile number is not provided", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    callObjectInfo.current = {
      agentPhoneNumber: operator.Mobile_number,
      leadPhoneNumber: state.tableBodyList.filter(
        (data) => data.id == params
      )[0].Phone,
      leadId: state.tableBodyList.filter((data) => data.id == params)[0]
        .lead_id,
      userId: operator.id,
      alternativeNumber: "",
    };

    setIsCallModalShow(true);
  }

  function sanitizeNumber(input) {
    let sanitized = input.replace("-", "");
    sanitized = sanitized.replace(" -", "");
    sanitized = sanitized.replace("- ", "");
    // sanitized     = sanitized.replace('+','');
    sanitized = sanitized.replace(/\s+/g, "");
    return sanitized;
  }

  function callAction(callNumber) {
    setIsCallModalShow(false);

    const callObj = { ...callObjectInfo.current, leadPhoneNumber: callNumber };

    if (CALL_METHOD == "WebRTC") {
      const dest = sanitizeNumber(callObj.leadPhoneNumber);

      let customCallerId = CALLER_ID;
      if (dest.startsWith("+91")) {
        customCallerId = INDIA_CALLER_ID;
      }

      /* setState(prevState => ({ ...prevState, currentCallUUID: plivoBrowserSdk.current.client.getCallUUID() }))
      setNewTableRow({ ...paramVal.current.data, id: paramVal.current.data.lead_id })
      openFollowUpModal(callObjectInfo.current.leadId, state, setState)
      setCurrentCallStatus('Ringing') */

      const extraHeaders = {
        "X-PH-Test1": "test1",
        "X-PH-header1": customCallerId,
        "X-PH-leadId": callObjectInfo.current.leadId,
        "X-PH-userId": operator.id,
        "X-PH-page": 2,
        "X-PH-setState": setState,
        "X-PH-state": { ...state },
        "X-PH-setNewTableRow": setNewTableRow,
        "X-PH-Obj": { kk: 1 },
        "X-PH-Arr": [1, 2],
        ooo: 123,
        ppp: { kk: 0 },
      };

      setStatePlivo(state);

      plivo.client.call(dest, extraHeaders);
    } else {
      axios
        .post(`${ATLAS_URI}/makeOutboundCall/`, callObj, configToken)
        .then((callRes) => {
          if (callRes.status == 200) {
            setState((prevState) => ({
              ...prevState,
              currentCallUUID: callRes.data.requestUuid,
            }));
            setNewTableRow({
              ...paramVal.current.data,
              id: paramVal.current.data.lead_id,
            });
            openFollowUpModal(callObjectInfo.current.leadId, state, setState);
          }
        });
    }
  }

  /* DataGrid */

  function handleClick(event, params) {
    setCurrentLead(params.row);

    dispatch(addLeadId(params.row.Lead));
    let selected_row = params.row;
    selected_row.contact_number = params?.row?.Phone;
    // selected_row.id = params?.row?.Lead;
    setleadIdForCall(params?.row?.Lead);
    setSelectedRow(selected_row);
    setAnchorEl(event.currentTarget);
    setCurrentId(params);
    setNewTableRow({ ...params.row, id: params.row.lead_id });
  }

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    name: true,
    id: true,
    // account_name: true,
    age: false,
    height: false,
    // dob: true,
    gender: true,
    // lead_status: true,
    email_id: true,
    contact_number: true,
    // createdAt: true,
    height: false,
    current_weight: false,
    city: false,
    state: false,
    address: false,
    country: false,
    // pincode: false,
    // sleep_goal: false,
    mother_tongue: false,
    // preferred_language: false,
    assigned_to_user: false,
    referral_code: false,
    // occupation: false,
    // work_hours: false,
    // family_details: false,
    // emergency_contact: false,
    // medications: false,
    // medical_history: false,
    current_medical_condition: false,
    weight_goal: false,
    bmi: false,
    vmax_fit_goal: false,
    ClientDietitianInfo: false,
    ClientTrainerInfo: false,
    call_status: false,
    // water_goal: false,
    // nutrition_goal: false,
    // steps_goal: false,
    // fitness_specifications: false,
    // food_specifications: false,
    // body_measurements: false,
    // updated_by_details: false,
    // notes: false,
    // created_by_details: false,
    // updatedAt: false,
  });

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const programStatusList = [
    { id: "premium", name: "Premium" },
    { id: "free", name: "Free" },
  ];
  // -------- Add new operator "not contains" --------
  // Get the base operators
  const stringOperators = getGridStringOperators();
  // Find the "contains" operator
  const containsOperator = stringOperators.find(
    (operator) => operator.value === "contains"
  );

  // Create a "not contains" operator by cloning the "contains" operator and customizing it
  const notContainsOperator = { ...containsOperator };
  notContainsOperator.value = "notEquals";
  notContainsOperator.label = "notEquals";
  stringOperators.splice(1, 0, notContainsOperator);

  const formatColumns = {
    assigned_to_user: {},
    ClientDietitianInfo: {},
    ClientTrainerInfo: {},
  };

  const updateColumns = (newColumns, originalColumns) => {
    let newColArr = [];
    originalColumns.map((item) => {
      if (newColumns[item.field] == true) {
        newColArr.push(item.field);
      }
    });

    //*Bug in data-grid when "show all" is enabled it gives all item in newColumns as false */
    const allFalse = Object.values(newColumns).every(
      (value) => value === false
    );
    if (allFalse) {
      //enable all columns
      const cols = originalColumns.map((e) => e.field);
      setGridColumnList(cols);
    } else {
      setGridColumnList(newColArr);
    }
  };

  const onChangeColumn = (newModel) => {
    setColumnVisibilityModel(newModel);
    updateColumns(newModel, clientColumnsList);
  };

  const handleChangeRowSelection = (arr, id) => {
    let leadIds = arr.map((item) => item?.Lead);

    setDeleteIds(leadIds);

    if (arr.length > 0) {
      setSelectedIds(arr);
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const makeCall = () => {
    setIsShowPopup(true);
    handleClose();
  };

  const closePopup = () => {
    setIsShowPopup(false);
  };

  const getClients = (gridState = null) => {
    let getClientsURL = `${ATLAS_URI}/getClients`;
    if (
      operator.Feature?.find((features) => features?.sub_feature_id === 8)
        ?.is_edit ||
      operator.Feature?.find((features) => features?.sub_feature_id === 4)
        ?.is_edit
    ) {
    } else if (
      operator.Feature?.find((features) => features?.sub_feature_id === 44)
        ?.is_view ||
      operator.Feature.find((features) => features.sub_feature_id === 23)
        .is_edit ||
      operator.Feature.find((features) => features.sub_feature_id === 24)
        .is_edit
    ) {
      // for temporary dont use server pagination
      getClientsURL = `${ATLAS_URI}/getClientsByAssignedToUser`;
    } else {
    }

    // gridState will be provided when there is restore (data-grid state restored from saved data from localstorage) user navigating from edit to listing
    let fullstate = null;
    if (gridState) {
      fullstate = gridState;
    } else {
      fullstate = apiRef.current.exportState();
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    getClientsURL += `?page=${
      fullstate.pagination.paginationModel.page + 1
    }&pageSize=${
      fullstate.pagination.paginationModel.pageSize
    }&timezone=${timezone}`;

    // to fix initial page reload problem
    // when page is reloaded or initially opened and user go to edit (from page 1 and no filter) any client
    // and when come back from edit to listing dont want to restore the older localstorage data from a previous browser login
    let CLIENT_LC_STORAGE = localStorage.getItem("CLIENT_LC_STORAGE");
    if (CLIENT_LC_STORAGE) {
      CLIENT_LC_STORAGE = JSON.parse(CLIENT_LC_STORAGE) || null;
      if (CLIENT_LC_STORAGE.filter) {
        CLIENT_LC_STORAGE.filter.filterModel = fullstate.filter.filterModel;
      }
      if (CLIENT_LC_STORAGE.pagination) {
        CLIENT_LC_STORAGE.pagination.paginationModel =
          fullstate.pagination.paginationModel;
      }

      localStorage.setItem(
        "CLIENT_LC_STORAGE",
        JSON.stringify(CLIENT_LC_STORAGE)
      );
    }

    axios
      .post(`${getClientsURL}`, fullstate.filter.filterModel, configToken)
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          tableBodyList: response.data.rows,
        }));
        setTotalCount(response.data.count);
        // setclientdataAdd(response.data.map(data=>data.Lead))
      })
      .catch((err) => console.error(err));
  };

  const apiRef = useGridApiRef();

  function handleDataGridChange() {
    const fullstate = apiRef.current.exportState();
    localStorage.setItem("CLIENT_LC_STORAGE", JSON.stringify(fullstate));
    getClients();
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        id="myGrid"
        style={{
          height: "100%",
          width: "100%",
        }}
        className="ag-theme-alpine"
      >
        {/* changes added -start */}
        {state.dialogInfo.isOpened && (
          <Dialog
            onFalse={(e) =>
              setState({ ...state, dialogInfo: { isOpened: false, text: "" } })
            }
            onTrue={deleteFromTable}
            dialogInfo={state.dialogInfo}
          />
        )}
        {/* End */}

        {/* start */}

        {operator.Feature.find((features) => features.sub_feature_id === 8)
          .is_edit ? (
          <div className="col-md-12">
            <Dialog
              onFalse={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  dialogInfo: { isOpened: false, text: "" },
                }))
              }
              onTrue={(e) => deleteFromTable(e)}
              dialogInfo={state.dialogInfo}
            />
            <div className="box box-primary">
              <BoxHeader title="Clients List" />
              <div className="d-flex align-items-center mb-4">
                {operator.Feature?.find(
                  (features) => features.sub_feature_id === 8
                ).is_edit &&
                  operator.Feature.find(
                    (features) => features.sub_feature_id === 23
                  ).is_edit && (
                    <div className="box-body">
                      <div className="btn-group col-md-12 ">
                        <div className="form-group">
                          <label className="mb-2">Dietitian List</label>
                          <select
                            style={{
                              backgroundColor: "",
                              color: "",
                              borderRadius: "5px",
                            }}
                            id="searchFollowUps"
                            className="form-control"
                            onChange={(e) =>
                              setSelectedDiettian(e.target.value)
                            }
                            disabled={!show}
                            value={selectedDiettian}
                          >
                            <option value={""} selected disabled>
                              Assigned To
                            </option>
                            {dietitianList.map((user, id) => {
                              return (
                                <>
                                  <option value={user.id}>
                                    {user.Name
                                      ? user.Name
                                      : `${
                                          user.firstName ? user.firstName : ""
                                        } ${
                                          user.lastName ? user.lastName : ""
                                        }`}
                                  </option>
                                </>
                              );
                            })}
                          </select>

                          <span className="text-danger"></span>
                        </div>
                      </div>
                      {/* <div
                      className="col-md-3 "
                      style={{
                        position: "relative",
                        bottom: "25px",
                        left: "240px",
                      }}
                    >
                      <Button onClick={post_assigned_user} id="button">
                        Submit
                      </Button>
                    </div> */}
                    </div>
                  )}

                {operator.Feature?.find(
                  (features) => features.sub_feature_id === 8
                ).is_edit &&
                  operator.Feature.find(
                    (features) => features.sub_feature_id === 24
                  ).is_edit && (
                    <div className="box-body">
                      <div className="btn-group col-md-9 ">
                        <div className="form-group">
                          <label className="mb-2">Trainer List</label>
                          <select
                            style={{
                              backgroundColor: "",
                              color: "",
                              borderRadius: "5px",
                            }}
                            id="searchFollowUps"
                            className="form-control"
                            onChange={(e) => setSelectedTrainer(e.target.value)}
                            disabled={!show}
                            value={selectedTrainer}
                          >
                            <option value={""} selected disabled>
                              Assigned To
                            </option>
                            {trainerList.map((user, id) => {
                              return (
                                <>
                                  <option value={user.id}>
                                    {user.Name
                                      ? user.Name
                                      : `${
                                          user.firstName ? user.firstName : ""
                                        } ${
                                          user.lastName ? user.lastName : ""
                                        }`}
                                  </option>
                                </>
                              );
                            })}
                          </select>

                          <span className="text-danger"></span>
                        </div>
                      </div>
                      {/* <div
                     className="col-md-3 "
                     style={{
                       position: "relative",
                       bottom: "25px",
                       left: "240px",
                     }}
                   >
                     <Button onClick={post_assigned_user} id="button">
                       Submit
                     </Button>
                   </div> */}
                    </div>
                  )}

                <div className="align-self-end">
                  <Button
                    size="small"
                    onClick={handleAssignedUser}
                    id="button"
                    className=""
                    style={{ backgroundColor: "#2d2d2d", border: "none" }}
                  >
                    Submit
                  </Button>
                </div>
                <div className="col-auto mt-3">
                  {featureAccessible.find(
                    (feature) =>
                      feature.sub_feature_id === 84 && feature.is_edit == true
                  ) && (
                    <Button
                      // style={{ marginRight: "30px", color: "white" }}
                      className="btn btn-danger ms-3 mb-2"
                      // onClick={() => openDialog(deleteIds.map((c) => c.lead_id))}
                      onClick={() => openDialog(deleteIds)}
                      disabled={!show}
                      style={{
                        backgroundColor: "#2d2d2d",
                        border: "none",
                        color: "white",
                      }}
                    >
                      <MdDelete fontSize="25px" title="Delete" />
                    </Button>
                  )}
                </div>
                <div className="col-auto mt-3 mr-5">
                  <Button
                    // style={{ "margin-right": "30px" }}
                    className="ms-3 mb-2"
                    onClick={() => history.push("/callHistory/client")}
                    style={{
                      backgroundColor: "#2d2d2d",
                      border: "none",
                      color: "white",
                    }}
                  >
                    <FaHistory fontSize="25px" title="Call History" />
                  </Button>
                </div>
                <div className="col-auto mt-2">
                  <Button
                    className="ms-2"
                    onClick={postListView}
                    style={{
                      backgroundColor: "#2d2d2d",
                      border: "none",
                      color: "white",
                    }}
                  >
                    <BiListCheck fontSize="25px" title="Save List View" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-12">
            <div className="box box-primary">
              <BoxHeader title="Clients List" />

              <div>
                <Button
                  style={{ "margin-right": "30px" }}
                  className="ms-3 mb-2"
                  onClick={() => history.push("/callHistory/client")}
                >
                  <FaHistory fontSize="25px" title="Call History" />
                </Button>

                <Button style={{ marginLeft: "90%" }} onClick={postListView}>
                  <BiListCheck fontSize="25px" title="Save List View" />
                </Button>
              </div>
            </div>
          </div>
        )}

        {/* {state.isFollowUpModalOpen && (
          <FollowupModal
            state={state}
            newTableRow={newTableRow}
            closeFollowUpModal={(e) => closeFollowUpModal(setState)}
            updateFollowUp={(e) =>
              updateFollowUp(e, contextState, state, setState)
            }
            deleteFollowUp={(e) =>
              deleteFollowUp(e, contextState, state, setState)
            }
            updateFollowUpStatus={(e) =>
              updateFollowUpStatus(e, contextState, state, setState)
            }
            currentCallUUID={state.currentCallUUID}
            selectedLead={selectedLead}
          />
        )}

        {statePlivo.isFollowUpModalOpen && (
          <FollowupModal
            state={statePlivo}
            newTableRow={newTableRowPlivo}
            closeFollowUpModal={(e) => closeFollowUpModal(setStatePlivo)}
            updateFollowUp={(e) =>
              updateFollowUp(e, contextState, statePlivo, setStatePlivo)
            }
            deleteFollowUp={(e) =>
              deleteFollowUp(e, contextState, statePlivo, setStatePlivo)
            }
            updateFollowUpStatus={(e) =>
              updateFollowUpStatus(e, contextState, statePlivo, setStatePlivo)
            }
            currentCallUUID={statePlivo.currentCallUUID}
            selectedLead={selectedLead}
          />
        )} */}

        <div className="d-flex flex-direction-row">
          <BoxHeader title="Clients" />
        </div>

        <Paper
          sx={{
            height: 700,
            width: "100%",
          }}
        >
          <DataTable
            apiRef={apiRef}
            columns={clientColumnsList}
            rows={clientsData}
            count={totalCount}
            checkbox={true}
            handlePagination={handleDataGridChange}
            onFilterChange={handleDataGridChange}
            slots={{ toolbar: CustomToolbar }}
            columnVisibilityModel={visibileClientColumns}
            onColumnVisibilityModelChange={onChangeColumn}
            handleChangeRowSelection={handleChangeRowSelection}
          />

          {/* <DataGridPro
            slots={{ toolbar: CustomToolbar }}
            apiRef={apiRef}
            rowCount={totalCount}
            rows={clientsData.filter((item) => item !== null)}
            columns={clientListColumns}
            paginationMode="server"
            filterMode="server"
            onFilterModelChange={handleDataGridChange}
            onPaginationModelChange={handleDataGridChange}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              onChangeColumn(newModel)
            }
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            pagination
            pageSizeOptions={[25, 50, 100]}
            checkboxSelection
            onRowSelectionModelChange={(id) => {
              setSelectedIds(id);
              const selectedRows = state.tableBodyList.filter((row) => {
                for (let i in id) {
                  if (id[i] == row.id) {
                    return row;
                  }
                }
              });
              handleChangeRowSelection(selectedRows, id);
              setSelectedRows(selectedRows);
            }}
            disableRowSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? "Mui-even"
                : "Mui-odd"
            }
            onRowClick={(row) => {
              setSelectedLead(row.row);
              setLeadId(row.id);
            }}
            sx={{
              ".MuiDataGrid-row.Mui-even": {
                backgroundColor: "#DCD6D0",
              },
              ".MuiDataGrid-row.Mui-odd": {
                backgroundColor: "#fff",
              },
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold !important",
                overflow: "visible !important",
              },
            }}
          /> */}

          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            style={{
              top: "0px",
              left: "-200px",
            }}
          >
            <MenuItem onClick={(params) => editRecord()}>
              &nbsp; <EditIcon /> &nbsp;Edit
            </MenuItem>
            <hr />
            <MenuItem
              onClick={(e) => {
                // openFollowUpModal(e, state, setState);
                handleWhatsapp();
                handleClose();
              }}
            >
              &nbsp; <WhatsAppIcon /> &nbsp; WhatsApp
            </MenuItem>
            <hr />
            <MenuItem onClick={makeCall}>
              &nbsp; <LocalPhoneIcon /> &nbsp; Call
            </MenuItem>
            <hr />
            <MenuItem onClick={handleTaskModal}>
              &nbsp; <AddIcon /> &nbsp; Add Task
            </MenuItem>
          </Menu>
        </Paper>
      </div>

      {/* </form> */}

      {isCallModalShow && (
        <Modal show={isCallModalShow}>
          <Modal.Header closeButton>
            <Modal.Title>Call Option</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex" }}>
              <label>Lead Mobile Number: </label>{" "}
              <p>{callObjectInfo.current.leadPhoneNumber}</p>
              <button
                className="btn btn-default btn-xs no-focus"
                title="Call"
                onClick={() =>
                  callAction(callObjectInfo.current.leadPhoneNumber)
                }
              >
                <i
                  className="fas fa-phone-alt text-green"
                  aria-hidden="true"
                ></i>
              </button>
            </div>

            <div>
              <label>Speaker</label>
              <select value={selectedSpeaker} onChange={handleChangeSpeaker}>
                {speakerDevices.map((item) => (
                  <option value={item.deviceId}>{item.label}</option>
                ))}
              </select>
            </div>
            <br />
            <div>
              <label>Micro Phone</label>
              <select
                value={selectedMicroPhone}
                onChange={handleChangeMicroPhone}
              >
                {microphoneDevices.map((item) => (
                  <option value={item.deviceId}>{item.label}</option>
                ))}
              </select>
            </div>
            <br />

            <div>
              <label>Alternative Number: </label>
              <label /* className='col-md-8 floatclass' */>
                <PhoneInput
                  value={callObjectInfo.current.alternativeNumber}
                  onChange={(e) => {
                    // setCallObjectInfo(prevState => ({ ...prevState, alternativeNumber: "+" + e }))
                    callObjectInfo.current.alternativeNumber = "+" + e;
                  }}
                  country={"in"}
                  enableSearch={true}
                  inputStyle={{
                    paddingLeft: "15%",
                    backgroundColor: "#fce4e4",
                    borderColor: "#ff01416a",
                  }}
                />
              </label>
              <button
                className="btn btn-default btn-xs no-focus"
                title="Call"
                onClick={() => {
                  // setCallObjectInfo(prevState => ({...prevState, leadPhoneNumber: callObjectInfo.alternativeNumber }))
                  callAction(callObjectInfo.current.alternativeNumber);
                }}
              >
                <i
                  className="fas fa-phone-alt text-green"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setIsCallModalShow(false);
              }}
            >
              Close
            </Button>
            {/* <Button variant="primary" onClick={()=>handleClose()}>
                    Save Changes
                  </Button> */}
          </Modal.Footer>
        </Modal>
      )}

      {isShowPopup ? (
        <CallPopup
          close={closePopup}
          callType={"plivo"}
          selectedRow={selectedRow}
          leadId={leadIdForCall}
        />
      ) : (
        ""
      )}

      {openTaskModal && (
        <TaskModal
          closeModel={() => setOpenTaskModal(false)}
          leadId={currentLead.Lead}
          isClient={true}
        />
      )}
    </div>
  );
};

export default ListClient;
