// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-list {
  padding: 46px;
  margin: -1% 1%;
}

.alloc-btn {
  float: right;
}
.alloc-table {
  margin-top: 38px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/AutoAllocation/AllocationList.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".container-list {\n  padding: 46px;\n  margin: -1% 1%;\n}\n\n.alloc-btn {\n  float: right;\n}\n.alloc-table {\n  margin-top: 38px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
