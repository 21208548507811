import React from 'react'
import DietPreferrence1 from './DietPreferrence'
import WorkoutPreferrence1 from './WorkoutPreferrence'
import MedicalAssesment from './MedicalAssesment'
import Others1 from '../../Leads/LeadProfile/Others';

const Index = ({tab,WorkoutPreferrence,DietPreferrence,MedicalAssessmentTab,Others,props}) => {
  return (
    <div>
      {tab===DietPreferrence && <DietPreferrence1 props={props} />}
        
        {tab===WorkoutPreferrence && <WorkoutPreferrence1 props={props} />}
        {tab===MedicalAssessmentTab && <MedicalAssesment props={props}/>}
        {tab===Others && <Others1 props={props}/>}
        
    </div>
  )
}

export default Index