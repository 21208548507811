import React from 'react';
import { Table } from 'react-bootstrap';
import DoneIcon from '@mui/icons-material/Done';

const ParticipantList = (props) => {
  return (
    <>
    {
        props.list.length === 0? (<h4 className='text-muted'>No Participant found</h4>): (
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th width='10%'>ID</th>
                    <th width='70%'>Name</th>
                    <th>Has Attended</th>
                </tr>
                </thead>
                <tbody>
                    
                {props.list.map((e, i)=> {
                    return (<tr>
                        <td>{i+1}</td>
                        <td>{e?.participant?.name}</td>
                        <td className='text-center'>{e.has_attended? <DoneIcon width="20" />: ''}</td>
                    </tr>)
                })}
                
                </tbody>
            </Table>
        )
    }
    </>
  );
};

export default ParticipantList;
