// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin: 15px;
}

.header-txt {
  font-weight: 600 !important;
  font-size: 22px !important;
  margin-right: 34% !important ;
}

.sub-header {
  font-weight: 500 !important;
  font-size: 22px !important;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Programs/styles/AddProgram.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,2BAA2B;EAC3B,0BAA0B;AAC5B","sourcesContent":[".container {\n  margin: 15px;\n}\n\n.header-txt {\n  font-weight: 600 !important;\n  font-size: 22px !important;\n  margin-right: 34% !important ;\n}\n\n.sub-header {\n  font-weight: 500 !important;\n  font-size: 22px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
