// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal.css */

.Modal_modal-overlay__8HdBb {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.379);
  z-index: 100;
}

.Modal_modal__DJDMv {
  z-index: 200;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  /* animation: slideIn 0.3s ease-in-out forwards; */
  animation: Modal_slideIn__lscor 0.3s;
}

@keyframes Modal_slideIn__lscor {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.module.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,sCAAsC;EACtC,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd;uCACqC;EACrC,kDAAkD;EAClD,oCAAuB;AACzB;;AAEA;EACE;IACE,UAAU;IACV,yCAAyC;EAC3C;EACA;IACE,UAAU;IACV,yCAAyC;EAC3C;AACF","sourcesContent":["/* Modal.css */\n\n.modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  background-color: rgba(0, 0, 0, 0.379);\n  z-index: 100;\n}\n\n.modal {\n  z-index: 200;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: white;\n  border-radius: 8px;\n  padding: 20px;\n  max-width: 100%;\n  max-height: 100%;\n  overflow: auto;\n  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,\n    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;\n  /* animation: slideIn 0.3s ease-in-out forwards; */\n  animation: slideIn 0.3s;\n}\n\n@keyframes slideIn {\n  from {\n    opacity: 0;\n    transform: translate(-50%, -50%) scale(0);\n  }\n  to {\n    opacity: 1;\n    transform: translate(-50%, -50%) scale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-overlay": `Modal_modal-overlay__8HdBb`,
	"modal": `Modal_modal__DJDMv`,
	"slideIn": `Modal_slideIn__lscor`
};
export default ___CSS_LOADER_EXPORT___;
