import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ATLAS_URI,
  CALLER_ID,
  CALL_METHOD,
  INDIA_CALLER_ID,
} from "../Constants";
import stateContext from "../context/StateContext";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { BiRefresh } from "react-icons/bi";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import {
  closeFollowUpModal,
  deleteFollowUp,
  openFollowUpModal,
  updateFollowUp,
  updateFollowUpStatus,
} from "../Helpers/ModalHandler";
import { useHistory } from "react-router-dom";
import FollowupModal from "./FollowupModal";
import { PlivoContext } from "../context/PlivoContext";

function EventSection(props) {
  const contextState = useContext(stateContext);

  const {
    onCallRemoteRinging,
    setCurrentCallStatus,
    setCallDurationInfo,
    callDurationInfo,
    plivoBrowserSdk,
    duration,
    setDuration,
    setIsTimerOn,
  } = contextState;

  const { plivo, setStatePlivo, statePlivo, newTableRowPlivo } =
    useContext(PlivoContext);

  const { configToken, operator } = contextState.state;
  const [rowData, setRowData] = useState([]);

  const [isCallModalShow, setIsCallModalShow] = useState(false);

  // const [paramVal, setParamVal] = useState({})

  const paramVal = useRef(null);

  /* const [callObjectInfo, setCallObjectInfo] = useState({
    agentPhoneNumber: '',
    leadPhoneNumber: '',
    leadId: '',
    userId: '',
    alternativeNumber: '',
    isClient: false
  }) */

  const callObjectInfo = useRef(null);

  const [state, setState] = useState({
    tableBodyList: [],
    userName: [],
    dialogInfo: {
      isOpened: false,
      text: "",
      type: "",
    },
    isFollowUpModalOpen: false,
  });

  let history = useHistory();

  const [newTableRow, setNewTableRow] = useState({
    id: "",
    email_id: "",
    contact_number: "",
    createdAt: "",
  });

  const [columnDefs, setColumnDefs] = useState([
    { field: "Date" },
    { field: "Time" },
    { field: "Duration (minutes)" },
    { field: "Lead ID/Client ID" },
    { field: "Lead Name/Client Name" },
    //{ field: 'FollowupTakenBy' },
    {
      field: "PhoneNumber",
      cellRenderer: (params, id) => {
        return (
          <>
            <button
              className="btn btn-default btn-xs no-focus"
              title="Call"
              onClick={() => viewCallModal(params.data)}
            >
              <i className="fas fa-phone-alt text-green" aria-hidden="true"></i>
            </button>
            {params.value}
          </>
        );
      },
    },
  ]);

  // useEffect(() => {
  //   // function initPhone(){
  //     const options = {
  //         "debug":"ERROR",
  //         "permOnClick":true,
  //         "codecs":["OPUS","PCMU"],
  //         "enableIPV6":false,
  //         "audioConstraints":{"optional":[{"googAutoGainControl":false}]},
  //         "dscp":true,
  //         "enableTracking":true
  //       };
  //   plivoBrowserSdk.current = new window.Plivo(options);
  // // setPlivoBrowserSdk(plivoBrowserSdk)
  // }, [])

  //   useEffect(() => {
  //     console.log('useEffect inside')
  //     plivoBrowserSdk.current.client.login(operator.plivoEndPointUserName, 'welcome')
  //       plivoBrowserSdk.current.client.on('onLogin', () => {console.log('login' )} );
  //       plivoBrowserSdk.current.client.on('onLoginFailed', () => {console.log('login failed' )});

  //       plivoBrowserSdk.current.client.on('onCalling', () => {
  //         console.log('onCalling == ', plivoBrowserSdk.current.client.getCallUUID() )
  //         setCallDurationInfo(prevState => {
  //             return {...prevState, duration: ''}
  //         })
  //     });

  //     plivoBrowserSdk.current.client.on('onCallRemoteRinging', () => {
  //       setDuration(0);
  //       // onCallRemoteRinging(plivoBrowserSdk.current.client.getCallUUID(), 'Ringing', callObjectInfo.current)

  //       console.log('plivoBrowserSdk.current.client.getCallUUID() === ', plivoBrowserSdk.current.client.getCallUUID())
  //       console.log('callObjectInfo in onCallRemoteRinging::: ', callObjectInfo)

  //       if (callObjectInfo.current && callObjectInfo.current.isClient == false) {
  //         onCallRemoteRinging(plivoBrowserSdk.current.client.getCallUUID(), 'Ringing', callObjectInfo.current)
  //       } else if (callObjectInfo.current && callObjectInfo.current.isClient == true) {
  //         console.log('onCallRemoteRinging paramVal::: ', paramVal)

  //         setState(prevState => ({ ...prevState, currentCallUUID: plivoBrowserSdk.current.client.getCallUUID() }))
  //         setNewTableRow({ ...paramVal.current.data, id: paramVal.current.data.lead_id })
  //         openFollowUpModal(callObjectInfo.current.leadId, state, setState)
  //         setCurrentCallStatus('Ringing')
  //       }

  //       /* setCallDurationInfo(prevState => {
  //         return { ...prevState, duration: '', timerID: '' }
  //       }) */
  //     }

  //     );

  //       plivoBrowserSdk.current.client.on('onCallFailed', () => { console.log('Call failed!!') });

  //       plivoBrowserSdk.current.client.on('onIncomingCallCanceled', () => { console.log('on incoming call cancelled!!') });

  //       let interval = null;

  //       plivoBrowserSdk.current.client.on('onCallAnswered', () => {
  //         const startDate = new Date()
  //         console.log('call answered!!')
  //         setCurrentCallStatus('answered')

  //         setIsTimerOn(true);

  //         interval = setInterval(() => {
  //           let endDate = new Date();
  //           let elapsed = endDate - startDate;
  //           let hours = Math.floor(elapsed / 3600000);
  //           let minutes = Math.floor((elapsed - hours * 3600000) / 60000);
  //           let seconds = Math.floor(
  //             (elapsed - hours * 3600000 - minutes * 60000) / 1000
  //           );
  //           if (hours < 10) {
  //             hours = "0" + hours;
  //           }
  //           if (minutes < 10) {
  //             minutes = "0" + minutes;
  //           }
  //           if (seconds < 10) {
  //             seconds = "0" + seconds;
  //           }
  //           let display = hours + ":" + minutes + ":" + seconds;

  //           setDuration(display);
  //         }, 1000);

  //         /* const timerID = setInterval(() => {
  //             let endDate = new Date();
  //             let elapsed  = endDate - startDate;
  //             let hours    = Math.floor( (elapsed / 3600000) );
  //             let minutes  = Math.floor( (elapsed - (hours * 3600000)) / 60000);
  //             let seconds  = Math.floor((elapsed - (hours * 3600000) - (minutes * 60000)) / 1000);
  //             if( hours    < 10) { hours   = "0"+hours;}
  //             if( minutes  < 10) { minutes = "0"+minutes;}
  //             if( seconds  < 10) { seconds = "0"+seconds;}
  //             let display  = hours+":"+minutes+":"+seconds;
  //             setCallDurationInfo(prevState => {
  //                 return {...prevState, duration: display}
  //             })
  //         }, 1000)
  //         setCallDurationInfo(prevState => {
  //             return {...prevState, timerID: timerID}
  //         }) */
  //     });
  //       plivoBrowserSdk.current.client.on('onCallTerminated', () => {
  //         console.log('call terminated!!')
  //         setCurrentCallStatus('call ended')
  //         /* setCallDurationInfo(prevState => {
  //             clearInterval(prevState.timerID)
  //             return {...prevState, timerID: '', duration: ''}
  //         }) */

  //         setIsTimerOn(false);
  //     //   setDuration(0);
  //       clearInterval(interval);
  //       // setDuration("");

  //     });

  //       console.log('initPhone ready!')

  // }, [])

  function viewCallModal(data) {
    paramVal.current = data;

    if (!operator.Mobile_number) {
      toast.error("Agent mobile number is not provided", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    /* const callObject = {
      agentPhoneNumber: operator.Mobile_number,
      leadPhoneNumber: data?.LeadInfo?.contact_number,
      leadId: data?.LeadInfo?.id,
      userId: operator.id,
      alternativeNumber: '',
      isClient: data?.LeadInfo?.ClientInfo ? true : false,
      followupEventId: data?.followupEventId
    } */

    callObjectInfo.current = {
      agentPhoneNumber: operator.Mobile_number,
      leadPhoneNumber: data?.LeadInfo?.contact_number,
      leadId: data?.LeadInfo?.id,
      userId: operator.id,
      alternativeNumber: "",
      isClient: data?.LeadInfo?.ClientInfo ? true : false,
      followupEventId: data?.followupEventId,
    };

    // setCallObjectInfo(callObject)
    setIsCallModalShow(true);
  }

  function sanitizeNumber(input) {
    let sanitized = input.replace("-", "");
    sanitized = sanitized.replace(" -", "");
    sanitized = sanitized.replace("- ", "");
    // sanitized     = sanitized.replace('+','');
    sanitized = sanitized.replace(/\s+/g, "");
    return sanitized;
  }

  function callAction(callNumber) {
    if (callObjectInfo.current.isClient == false) {
      setIsCallModalShow(false);

      const callObj = {
        ...callObjectInfo.current,
        leadPhoneNumber: callNumber,
      };

      if (CALL_METHOD == "WebRTC") {
        const dest = sanitizeNumber(callObj.leadPhoneNumber);

        let customCallerId = CALLER_ID;
        if (dest.startsWith("+91")) {
          customCallerId = INDIA_CALLER_ID;
        }

        const extraHeaders = {
          "X-PH-Test1": "test1",
          "X-PH-header1": customCallerId,
          "X-PH-leadId": callObjectInfo.current.leadId,
          "X-PH-userId": operator.id,
          "X-PH-page": 1,
        };

        plivo.client.call(dest, extraHeaders);
      } else {
        axios
          .post(
            `${ATLAS_URI}/makeOutboundCall?callPlace=eventPage&followupEventId=${callObjectInfo.current.followupEventId}`,
            callObj,
            configToken
          )
          .then((callRes) => {
            if (callRes.status == 200) {
              // setState(prevState => ({ ...prevState, currentCallUUID: callRes.data.requestUuid }))

              // openFollowUpModal(callObjectInfo.leadId, state, setState)
              history.push({
                pathname: "/Leads/leadView/" + callObjectInfo.current.leadId,
                state: {
                  currentCallUUID: callRes.data.requestUuid,
                  isCallHistoryView: true,
                },
              });
            }
          });
      }
    } else {
      setIsCallModalShow(false);

      const callObj = {
        ...callObjectInfo.current,
        leadPhoneNumber: callNumber,
      };

      if (CALL_METHOD == "WebRTC") {
        const dest = sanitizeNumber(callObj.leadPhoneNumber);

        let customCallerId = CALLER_ID;
        if (dest.startsWith("+91")) {
          customCallerId = INDIA_CALLER_ID;
        }

        // setState(prevState => ({ ...prevState, currentCallUUID: plivoBrowserSdk.current.client.getCallUUID() }))
        // setNewTableRow({ ...paramVal.current.data, id: paramVal.current.data.lead_id })
        // openFollowUpModal(callObjectInfo.current.leadId, state, setState)
        // setCurrentCallStatus('Ringing')

        const extraHeaders = {
          "X-PH-Test1": "test1",
          "X-PH-header1": customCallerId,
          "X-PH-leadId": callObjectInfo.current.leadId,
          "X-PH-userId": operator.id,
          "X-PH-page": 2,
        };

        setStatePlivo(state);

        plivo.client.call(dest, extraHeaders);
      } else {
        axios
          .post(
            `${ATLAS_URI}/makeOutboundCall?callPlace=eventPage&followupEventId=${callObjectInfo.current.followupEventId}`,
            callObj,
            configToken
          )
          .then((callRes) => {
            if (callRes.status == 200) {
              setState((prevState) => ({
                ...prevState,
                currentCallUUID: callRes.data.requestUuid,
              }));
              setNewTableRow({
                ...paramVal.current.data,
                id: paramVal.current.LeadInfo?.id,
              });
              openFollowUpModal(callObjectInfo.current.leadId, state, setState);
            }
          });
      }
    }
    /* setIsCallModalShow(false)
    console.log('callObjectInfo:::: in callAction::: ', callObjectInfo)
    const callObj = {...callObjectInfo, leadPhoneNumber: callNumber}
    console.log('callObj', callObj)
    axios.post(`${ATLAS_URI}/makeOutboundCall/`, callObj, configToken).then(callRes => {
                console.log('callRes.data::: ', callRes.data)
                console.log('callRes.data::: ', callRes.data.requestUuid)
                if (callRes.status == 200) {
                    setState(prevState => ({ ...prevState, currentCallUUID: callRes.data.requestUuid }))
                    // openFollowUpModal(callObjectInfo.leadId, state, setState)
                    history.push({
                        pathname: '/Leads/leadView/'+callObjectInfo.leadId,
                        state: {
                            currentCallUUID: callRes.data.requestUuid,
                            isCallHistoryView: true
                        }
                    })
                }
            }) */
  }

  useEffect(() => {
    getFollowupsFromToday();
  }, []);

  const getFollowupsFromToday = () => {
    axios
      .get(`${ATLAS_URI}/getFollowupsFromToday`, configToken)
      .then((response) => {
        console.log("response.data", response.data);

        setState((prevState) => ({
          ...prevState,
          tableBodyList: response.data?.map((a) => {
            a.followupEventId = a.id;
            a.id = a.LeadId;
            return a;
          }),
        }));
        let offSet = 5.5 * 60;

        setRowData(
          response.data?.map((c) => {
            //  c.LeadName = c?.LeadInfo?.name;
            // c.FollowupTakenBy = c?.FollowUpTakenByUser?.Username;
            c.Date =
              c?.NextFollowUpDate &&
              new Date(c?.NextFollowUpDate).toDateString();
            c.Id = c?.LeadId;
            c["Lead ID/Client ID"] = c?.LeadInfo?.ClientInfo?.id
              ? c?.LeadInfo?.ClientInfo?.id
              : c?.LeadId;
            c["Lead Name/Client Name"] = c?.LeadInfo?.name;
            c.PhoneNumber = c?.LeadInfo?.contact_number;
            c["Duration (minutes)"] = c?.duration && c?.duration;
            c.Time = new Date(
              c?.NextFollowUpDate &&
                new Date(c?.NextFollowUpDate) + 5.5 * 60 * 60 * 1000
            ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

            return c;
          })
        );
        return;
      })
      .catch((err) => console.log(err.response));
  };

  const containerStyle = useMemo(
    () => ({ width: "100%", height: "720px" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      // allow every column to be aggregated
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true,
      sortable: true,
      filter: true,
    };
  }, []);

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  return (
    <section className="content">
      <div className="row">
        <div className="col-md-12">
          {/* AG GRID */}

          <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-alpine">
              <Button
                style={{
                  margin: "1% 0 1% 0",
                  backgroundColor: "transparent",
                  borderColor: "#999",
                }}
                onClick={getFollowupsFromToday}
              >
                <BiRefresh
                  className="text-dark"
                  fontSize="25px"
                  title="Refresh the table"
                />
              </Button>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                // onGridReady={onGridReady}
                sideBar={{
                  toolPanels: [
                    {
                      id: "columns",
                      labelDefault: "Columns",
                      labelKey: "columns",
                      iconKey: "columns",
                      toolPanel: "agColumnsToolPanel",
                      toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressSideButtons: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                      },
                    },
                  ],
                  position: "bottom",
                  defaultToolPanel: "columns",
                }}
                defaultExportParams={{
                  columnKeys: [
                    "Date",
                    "Time",
                    "Duration (minutes)",
                    "Id",
                    "LeadName",
                    "PhoneNumber",
                  ],
                }}
                pagination={true}
                paginationPageSize={15}
                paginationNumberFormatter={paginationNumberFormatter}
              ></AgGridReact>
            </div>
          </div>
        </div>
      </div>

      {isCallModalShow && (
        <Modal show={isCallModalShow}>
          <Modal.Header closeButton>
            <Modal.Title>Call Option</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex" }}>
              <label>Lead Mobile Number: </label>{" "}
              <p>{callObjectInfo.current.leadPhoneNumber}</p>
              <button
                className="btn btn-default btn-xs no-focus"
                title="Call"
                onClick={() => {
                  callAction(callObjectInfo.current.leadPhoneNumber);
                }}
              >
                <i
                  className="fas fa-phone-alt text-green"
                  aria-hidden="true"
                ></i>
              </button>
            </div>

            <div>
              <label>Alternative Number: </label>
              <label /* className='col-md-8 floatclass' */>
                <PhoneInput
                  value={callObjectInfo.current.alternativeNumber}
                  onChange={(e) => {
                    // setCallObjectInfo(prevState => ({ ...prevState, alternativeNumber: "+" + e }))
                    callObjectInfo.current.alternativeNumber = "+" + e;
                  }}
                  country={"in"}
                  enableSearch={true}
                  inputStyle={{
                    paddingLeft: "15%",
                    backgroundColor: "#fce4e4",
                    borderColor: "#ff01416a",
                  }}
                />
              </label>
              <button
                className="btn btn-default btn-xs no-focus"
                title="Call"
                onClick={() => {
                  // setCallObjectInfo(prevState => ({...prevState, leadPhoneNumber: callObjectInfo.alternativeNumber }))
                  callAction(callObjectInfo.current.alternativeNumber);
                }}
              >
                <i
                  className="fas fa-phone-alt text-green"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setIsCallModalShow(false);
              }}
            >
              Close
            </Button>
            {/* <Button variant="primary" onClick={()=>handleClose()}>
                    Save Changes
                  </Button> */}
          </Modal.Footer>
        </Modal>
      )}

      {state.isFollowUpModalOpen && (
        <FollowupModal
          state={state}
          newTableRow={newTableRow}
          closeFollowUpModal={(e) => closeFollowUpModal(setState)}
          updateFollowUp={(e) =>
            updateFollowUp(e, contextState, state, setState)
          }
          deleteFollowUp={(e) =>
            deleteFollowUp(e, contextState, state, setState)
          }
          updateFollowUpStatus={(e) =>
            updateFollowUpStatus(e, contextState, state, setState)
          }
          currentCallUUID={state.currentCallUUID}
        />
      )}

      {statePlivo.isFollowUpModalOpen && (
        <FollowupModal
          state={statePlivo}
          newTableRow={newTableRowPlivo}
          closeFollowUpModal={(e) => closeFollowUpModal(setStatePlivo)}
          updateFollowUp={(e) =>
            updateFollowUp(e, contextState, statePlivo, setStatePlivo)
          }
          deleteFollowUp={(e) =>
            deleteFollowUp(e, contextState, statePlivo, setStatePlivo)
          }
          updateFollowUpStatus={(e) =>
            updateFollowUpStatus(e, contextState, statePlivo, setStatePlivo)
          }
          currentCallUUID={statePlivo.currentCallUUID}
        />
      )}
    </section>
  );
}

export default EventSection;
