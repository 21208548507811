// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-table {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* .data-container {
    margin-top: 10px;
    width: 95%;
    height: 50%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
 */

.payment-table {
  margin-top: 10px;
  width: 95%;
  height: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.header {
  background-color: transparent;
  border: none;
}

.payment-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Payments/ListOfPayments.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;;;;;;EAME;;AAEF;EACE,gBAAgB;EAChB,UAAU;EACV,WAAW;EACX,iDAAiD;AACnD;;AAEA;EACE,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB","sourcesContent":[".data-table {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n/* .data-container {\n    margin-top: 10px;\n    width: 95%;\n    height: 50%;\n    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n}\n */\n\n.payment-table {\n  margin-top: 10px;\n  width: 95%;\n  height: 50%;\n  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n}\n\n.header {\n  background-color: transparent;\n  border: none;\n}\n\n.payment-header {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
