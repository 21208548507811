import Paper from "@mui/material/Paper";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NestedMenuItem from "material-ui-nested-menu-item";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import HistoryIcon from "@mui/icons-material/History";
import EditIcon from "@mui/icons-material/Edit";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DownloadIcon from "@mui/icons-material/Download";
import Call from "../../assets/Call.png";
import Edit from "../../assets/Edit.png";
import WhatsApp from "../../assets/whatsapp.png";
import { useHistory } from "react-router-dom";

import LeadContextProvider from "./LeadContextProvider";
import { LeadContext } from "./LeadContextProvider";

import { useContext, useEffect } from "react";
import CallPopup from "../Call/CallPopup";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { addLeadId } from "../store/leads/leadIdSlice";

import useListLead from "../../hooks/tableColumns/useLeadList.jsx";

import TeleCall from "../Call/TeleCall.jsx";

import AddIcon from "@mui/icons-material/Add";

import TaskModal from "../../components/TaskModal/TaskModal";

import DataTable from "../../components/DataTable/DataTable";

export default function LeadTable(props) {
  const dispatch = useDispatch();
  const ctx = useContext(LeadContext);
  const history = useHistory();
  const apiRef = useGridApiRef();

  const [openAddTaskModal, setOpenAddTaskModal] = useState(false);

  const [isShowPopup, setIsShowPopup] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  let { visibileLeadColumns, leadColumnsList } = useListLead(
    props.handleClick,
    props.open
  );

  const handleTaskModal = () => {
    setOpenAddTaskModal(true);
    props.handleClose();
  };

  const openPopup = () => {
    setIsShowPopup(true);
    props.handleClose();
  };

  const closePopup = () => {
    setIsShowPopup(false);
    props.handleClose();
  };

  const handleWhatsapp = () => {
    const phoneNumber = props.currentLead.contact_number;
    const whatsappLink = `https://wa.me/${phoneNumber}`;

    window.open(whatsappLink, "_blank");
  };

  //plugin number starts from 0
  const defaultPaginationModel = {
    pageSize: 25,
    page: props.page ? props.page : 0,
  };

  // ================== RESTORE ==================
  function restore() {
    let LEAD_LC_STORAGE = localStorage.getItem("LEAD_LC_STORAGE_GRID") || null;
    if (LEAD_LC_STORAGE) {
      try {
        LEAD_LC_STORAGE = JSON.parse(LEAD_LC_STORAGE) || null;
        if (LEAD_LC_STORAGE) {
          //Note: only restore if there is some filter in saved state json, otherwise it causes appearance of a empty filter box
          if (
            LEAD_LC_STORAGE.filter.filterModel.items.length ||
            LEAD_LC_STORAGE.pagination.paginationModel.page != 0
          ) {
            apiRef.current.restoreState(LEAD_LC_STORAGE);
          }
        }
      } catch (e) {
        console.error("Could not parse mui grid state from localstorage");
      }
    }
  }

  function handleChange() {
    const fullstate = apiRef.current.exportState();
    saveState(fullstate);
    props.handleChange(fullstate);
  }

  function saveState(state) {
    localStorage.setItem("LEAD_LC_STORAGE_GRID", JSON.stringify(state));
  }

  // Reset filters on ui
  const handleResetFilters = () => {
    if (apiRef.current) {
      if (props.clearChildFilter !== 0) {
        apiRef.current.setPage(defaultPaginationModel.page);
        apiRef.current.setPageSize(defaultPaginationModel.pageSize);
        apiRef.current.setFilterModel({
          items: [],
          logicOperator: "and",
          quickFilterValues: [],
          quickFilterLogicOperator: "and",
        });
      }
    }
  };
  // Unselect rows
  const handleResetRowTicks = () => {
    if (apiRef.current) {
      if (props.clearRowTicks !== 0) {
        apiRef.current.setRowSelectionModel([]);
      }
    }
  };

  const navigateToLeadInfo = () => {
    if (selectedLeadId !== null && selectedLeadId !== undefined) {
      history.push(`/lead/${selectedLeadId}`);
      localStorage.setItem("backToTable", "/Leads/listLeads");
      if (props.selectedRow !== null && props.selectedRow !== undefined) {
        history.push(`/lead/${props.selectedRow}`);
      }
    }
  };

  useEffect(() => {
    // This function will run after the component has rendered
    let isRestore = localStorage.getItem("RESTORE_LEAD_LISTING_SCREEN_GRID");
    if (isRestore) {
      restore();
      localStorage.removeItem("RESTORE_LEAD_LISTING_SCREEN_GRID");
    }
  }, []);

  // to clear filters when clear button is pressed
  useEffect(() => {
    handleResetFilters();
  }, [props.clearChildFilter]);

  // to clear row checkbox ticks when user assign lead operation completes
  useEffect(() => {
    handleResetRowTicks();
  }, [props.clearRowTicks]);

  // to clear row checkbox ticks when user assign lead operation completes
  useEffect(() => {
    const fullstate = apiRef.current.exportState();
    saveState(fullstate);
  }, [props.saveGrid]);

  return (
    <Paper
      sx={{
        height: 700,
        width: "100%",
        "& .super-app.negative": {
          backgroundColor: "#ffa94d",
          color: "#1a3e72",
          fontWeight: "600",
        },
        "& .super-app.positive": {
          backgroundColor: "#fa5252",
          color: "#1a3e72",
          fontWeight: "600",
        },
      }}
    >
      {/* to reset page everytime filter is changed */}
      {/* <Box
        
      > */}

      <DataTable
        apiRef={apiRef}
        columns={leadColumnsList}
        rows={ctx.leadList}
        count={ctx.totalRecords}
        checkbox={true}
        handlePagination={handleChange}
        onFilterChange={handleChange}
        slots={{ toolbar: props.CustomToolbar }}
        columnVisibilityModel={props.columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          props.onChangeColumn(newModel);
        }}
      />

      {/* <DataGridPro
        page={ctx.page}
        onPageChange={(newPage) => props.setPage(newPage)}
        apiRef={apiRef}
        getRowId={props.getRowId}
        rowCount={ctx.totalRecords}
        rows={ctx.leadList}
        columns={ctx.columns}
        paginationMode="server"
        filterMode="server"
        onFilterModelChange={handleChange}
        onPaginationModelChange={handleChange}
        columnVisibilityModel={props.columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          props.onChangeColumn(newModel);
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pagination
        pageSizeOptions={[25, 50, 100]}
        checkboxSelection
        onRowSelectionModelChange={(id) => {
          props.handleChangeRowSelection(id);
        }}
        disableRowSelectionOnClick
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
        }
        slots={{ toolbar: props.CustomToolbar }}
        loading={props.islead_Loading}
        onRowClick={(row) => {
          dispatch(addLeadId(row.id));
          props.setLeadId(row.id);
          setSelectedRow(row.row);
          setSelectedLeadId(row.id);
          if (row.row.vmax_lead_hf_report !== null) {
            props.setIsReportExist(true);
          } else {
            props.setIsReportExist(false);
          }
        }}
        sx={{
          ".MuiDataGrid-row.Mui-even": {
            backgroundColor: "#DCD6D0",
          },
          ".MuiDataGrid-row.Mui-odd": {
            backgroundColor: "#fff",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
            overflow: "visible !important",
          },
        }}
      /> */}
      {/* </Box> */}

      <Menu
        id="simple-menu"
        anchorEl={props.anchorEl}
        open={ctx.open}
        onClose={props.handleClose}
        style={{
          top: "-100px",
          left: "-100px",
          width: "200px",
        }}
      >
        <MenuItem
          onClick={openPopup}
          style={{
            width: "450px",
          }}
        >
          &nbsp;{" "}
          <img
            src={Call}
            style={{
              width: "18.61px",
              height: "18.61px",
              top: "2.55px",
              left: "2.93px",
              border: "2px",
            }}
          />{" "}
          &nbsp; <span style={{ width: "32px", height: "24px" }}>Call</span>
        </MenuItem>
        <hr />
        <MenuItem onClick={handleWhatsapp}>
          &nbsp;{" "}
          <img
            src={WhatsApp}
            style={{
              width: "20.61px",
              height: "20.61px",
              top: "2.55px",
              left: "2.93px",
              border: "2px",
            }}
          />
          &nbsp; <span style={{ width: "32px", height: "24px" }}>WhatsApp</span>
        </MenuItem>
        <hr />
        <MenuItem onClick={navigateToLeadInfo}>
          &nbsp;{" "}
          <img
            src={Edit}
            style={{
              width: "20.12px",
              height: "20.12px",
              top: "1.88px",
              left: "2px",
              border: "2px",
            }}
          />{" "}
          &nbsp;<span style={{ width: "30px", height: "24px" }}>Edit</span>
        </MenuItem>
        <hr />
        <MenuItem onClick={handleTaskModal}>
          &nbsp;
          <AddIcon style={{ fontSize: "24px", color: "#6B6B6B" }} />
          &nbsp;
          <span style={{ width: "30px", height: "24px" }}>Add Task</span>
        </MenuItem>
      </Menu>

      {isShowPopup ? (
        // <CallPopup close={closePopup} selectedRow={selectedRow} />
        <TeleCall close={closePopup} selectedRow={selectedRow} />
      ) : (
        ""
      )}

      {openAddTaskModal && (
        <TaskModal
          closeModel={() => setOpenAddTaskModal(false)}
          leadId={props.selectedRow}
          isClient={false}
        />
      )}
    </Paper>
  );
}
